import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as projectActions from '~actions/project-actions';

import ApplicationLink from '~components/general/application-link';
import IString from '~components/general/i-string';
import Time from '~components/general/time';
import UserLink from '~components/general/user-link';

import * as projectSelectors from '~selectors/project-selectors';

const ProjectSessionItem = ({ session }) =>
  <tr>
    <td>{session.id}</td>
    <td><UserLink user={{ id: session.user_id }} /></td>
    <td><ApplicationLink appId={session.source_id} /></td>
    <td><Time value={session.created_time} /></td>
    <td>{session.state}</td>
    <td>{String(session.ephemeral)}</td>
  </tr>;

ProjectSessionItem.propTypes = {
  session: PropTypes.shape({
    created_time: PropTypes.string,
    ephemeral: PropTypes.bool,
    id: PropTypes.number,
    source_id: PropTypes.number,
    state: PropTypes.string,
    user_id: PropTypes.number
  })
};

const ProjectSessions = (props) => {

  const { dispatch, project: { id }, sessions } = props;

  useEffect(() => {
    dispatch(projectActions.fetchProjectSessions(id));

    return () => {
      dispatch(projectActions.clearProjectSessions());
    };
  }, [ dispatch, id ]);

  return (
    <>
      <div className="sectionTitle flex">
        <IString stringKey="project.sessions.section" />
      </div>

      <div className="tableContainer">
        { sessions && sessions.length > 0 ?
          <table className="contentListing">
            <thead>
              <tr>
                <th>Session ID</th>
                <th>User</th>
                <th>Application</th>
                <th>Created</th>
                <th>State</th>
                <th>Temporary</th>
              </tr>
            </thead>
            <tbody>
              { sessions.map(s => <ProjectSessionItem session={s} key={s.id} />) }
            </tbody>
          </table> :
          <div className="emptyListMessage">
            <IString stringKey="project.sessions.none" />
          </div>
        }
      </div>
    </>
  );
};

ProjectSessions.propTypes = {
  dispatch: PropTypes.func,
  project: PropTypes.shape({
    id: PropTypes.number
  }),
  sessions: PropTypes.array
};

const mapStateToProps = (state) => ({
  sessions: projectSelectors.getCurrentProjectSessions(state)
});

export default connect(mapStateToProps)(ProjectSessions);
