import { call, put } from 'redux-saga/effects';

import * as projectActions from '~actions/project-actions';

import * as projectsAPI from '~api/projects';

export function* fetchProjectChildren(action) {
  const { data } = yield call(projectsAPI.getProjectChildren, action.projectId);
  yield put(projectActions.fetchProjectChildrenSuccess(action.projectId, data.projects));
}

export function* fetchProjectSessions(action) {
  const { data } = yield call(projectsAPI.getProjectSessions, action.projectId);
  yield put(projectActions.fetchProjectSessionsSuccess(action.projectId, data.sessions));
}
