import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as dialogTypes from '~components/dialogs/dialog-types';
import ModalDialog from '~components/dialogs/modal-dialog';
import IString from '~components/general/i-string';

import * as taskSelectors from '~selectors/task-selectors';

function ApplicationTaskDialog({ data: {  details, onFinish, onSubmit, purpose }, task }) {
  const [ disableConfirm, setDisableConfirm ] = useState(false);

  useEffect(() => {
    if (task && task.finished) {
      setDisableConfirm(false);
      if (onFinish) {
        onFinish(task);
      }
    }
  }, [ onFinish, task, setDisableConfirm ]);

  const handleSubmit = (event) => {
    event.preventDefault();

    setDisableConfirm(true);

    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <ModalDialog type={dialogTypes.APPLICATION_TASK_DIALOG}>
      <div className="purpose">{purpose}</div>
      <form onSubmit={handleSubmit}>
        <div className="formSection">
          {details}
        </div>
        <div className="actions">
          <button type="submit" disabled={disableConfirm} autoFocus><IString stringKey="general.ok" /></button>
        </div>
      </form>
      {task &&
        <div className="statusMessage status showing">
          <div className="message">status = {task.status}</div>
          <div className="message">description = {task.description}</div>
          {/*<div className='messageClose' onClick={this.closeMessage} />*/}
        </div>
      }
    </ModalDialog>
  );
}

ApplicationTaskDialog.propTypes = {
  data: PropTypes.shape({
    details: PropTypes.node.isRequired,
    onFinish: PropTypes.func,
    onSubmit: PropTypes.func,
    purpose: PropTypes.node.isRequired
  }),
  task: PropTypes.object
};

const mapStateToProps = (state) => ({
  task: taskSelectors.getCurrentTask(state)
});

export default connect(mapStateToProps)(ApplicationTaskDialog);
