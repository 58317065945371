import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import * as uiActions from '~actions/ui-actions';

import * as dialogs from '~components/dialogs/dialog-types';
import ModalDialog from '~components/dialogs/modal-dialog';
import ActionButton from '~components/general/action-button';
import IString from '~components/general/i-string';

import * as accountType from '~constants/account-type';

const ACCOUNT_PROPERTIES = {
  TYPE: 'type',
  NAME: 'name',
  DISPLAY_NAME: 'display_name'
};

export function AccountCreateDialog(props) {

  const { dispatch, onSubmit } = props;

  const [ accountData, setAccountData ] = useState({
    'type': accountType.ORGANIZATION,
    'name': null,
    'display_name': null
  });

  const handleChange = (event, property) => {
    event.preventDefault();

    setAccountData({ ...accountData, [property]: event.target.value });
  };

  const handleClear = (event, property) => {
    event.preventDefault();

    setAccountData({ ...accountData, [property]: null });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(uiActions.openDialog({
      type: dialogs.CONFIRM_DIALOG,
      data: {
        purpose: 'Creating new account',
        details: `Are you sure you want to create a new ${accountData.type} account?`,
        confirm: () => {
          if (onSubmit) {
            onSubmit(accountData);
          }
          dispatch(uiActions.closeDialog()); // close confirm dialog
        }
      }
    }));
  };

  return (
    <ModalDialog type={dialogs.ACCOUNT_DETAILS_EDITOR_DIALOG}>
      <div className="purpose">
        Creating Account
      </div>
      <form noValidate onSubmit={handleSubmit}>

        <fieldset className="chunk">
          <label htmlFor="accountType" className="fieldLabel">
            Type
          </label>
          <select
            className="full"
            id="accountType"
            onChange={e => handleChange(e, ACCOUNT_PROPERTIES.TYPE)}
            value={accountData.type}
          >
            {accountType.ALL_TYPES.map(n => <option value={n} key={n}>{n}</option>)}
          </select>
        </fieldset>

        <fieldset className="chunk">
          <label htmlFor="accountName" className="fieldLabel">
            Internal Name
          </label>
          <div className="flex">
            <input
              className="full"
              id="accountName"
              onChange={e => handleChange(e, ACCOUNT_PROPERTIES.NAME)}
              type="text"
              value={accountData.name || ''}
            />
            <div className="actionBar inline" style={{ paddingTop: '3px' }}>
              <ActionButton className="delete noTitle" onClick={e => handleClear(e, ACCOUNT_PROPERTIES.NAME)} />
            </div>
          </div>
        </fieldset>

        <fieldset className="chunk">
          <label htmlFor="accountDisplayName" className="fieldLabel">
            Display Name
          </label>
          <div className="flex">
            <input
              className="full"
              id="accountDisplayName"
              onChange={e => handleChange(e, ACCOUNT_PROPERTIES.DISPLAY_NAME)}
              type="text"
              value={accountData.display_name || ''}
            />
            <div className="actionBar inline" style={{ paddingTop: '3px' }}>
              <ActionButton className="delete noTitle" onClick={e => handleClear(e, ACCOUNT_PROPERTIES.DISPLAY_NAME)} />
            </div>
          </div>
        </fieldset>

        <div className="actions">
          <button type="submit"><IString stringKey="general.save" /></button>
        </div>

      </form>
    </ModalDialog>
  );
}

AccountCreateDialog.propTypes = {
  dispatch: PropTypes.func,
  onSubmit: PropTypes.func
};

export default connect()(AccountCreateDialog);
