import PropTypes from 'prop-types';
import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import * as accountActions from '~actions/account-actions';

import GetMore from '~components/general/get-more';
import GettingMore from '~components/general/getting-more';
import IString from '~components/general/i-string';
import SpaceLink from '~components/general/space-link';
import Time from '~components/general/time';

import { translate } from '~i18n/localize';

import * as accountSelectors from '~selectors/account-selectors';

const AccountSpaceItem = ({ space }) =>
  <tr>
    <td><SpaceLink spaceId={space.id} /></td>
    <td>{space.name}</td>
    <td><Time value={space.created_time} /></td>
    <td>{space.user_count}</td>
    <td>{space.content_count}</td>
    <td>{space.state}</td>
  </tr>;

AccountSpaceItem.propTypes = {
  space: PropTypes.shape({
    content_count: PropTypes.number,
    created_time: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    state: PropTypes.string,
    user_count: PropTypes.number
  })
};

function AccountSpaces(props) {
  const { dispatch, account: { id }, spaces, offset, hasMore, loading, total } = props;
  const showEmptyList = !loading && (!!spaces && spaces.length === 0);
  let listStatus = null;

  const fetchMoreAccountSpaces = useCallback((offset) => {
    dispatch(accountActions.fetchAccountSpaces(id, offset));
  }, [ dispatch, id ]);

  useEffect(() => {
    // get an updated "first" page every time we load
    fetchMoreAccountSpaces();
    return () => {
      dispatch(accountActions.clearCurrentAccountSpaces());
    };
  }, [ fetchMoreAccountSpaces, dispatch ]);

  if (loading) {
    // getting more spinner
    listStatus = <GettingMore label={translate('account.spaces.loading')} />;
  } else if (hasMore) {
    // get more "button"
    listStatus = <GetMore label={translate('account.spaces.getMore')} onClick={() => fetchMoreAccountSpaces(offset)} autoFire />;
  }

  return (
    <>
      <div className="sectionTitle flex">
        <IString stringKey="account.spaces.section" />
      </div>

      <div className="smallMarginBelow">
        <div className="right">
          <IString stringKey="general.total" placeholders={{ total }} />
        </div>
      </div>

      <div className="tableContainer">
        {showEmptyList ?
          <div className="emptyListMessage">
            <IString stringKey="account.spaces.none" />
          </div> :
          <table className="contentListing">
            <thead>
              <tr>
                <th><IString stringKey="account.spaces.table.id" /></th>
                <th><IString stringKey="general.name" /></th>
                <th><IString stringKey="general.created" /></th>
                <th><IString stringKey="account.spaces.table.user_count" /></th>
                <th><IString stringKey="general.content" /></th>
                <th><IString stringKey="general.state" /></th>
              </tr>
            </thead>
            <tbody>
              { spaces?.map(s => <AccountSpaceItem space={s} key={s.id} />) }
            </tbody>
          </table> }
      </div>
      {listStatus}
    </>
  );
}

AccountSpaces.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number
  }),
  dispatch: PropTypes.func,
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  offset: PropTypes.number,
  spaces: PropTypes.array,
  total: PropTypes.number
};

const mapStateToProps = (state) => ({
  hasMore: accountSelectors.hasMore(state),
  loading: accountSelectors.isAccountSpacesLoading(state),
  spaces: accountSelectors.getAccountSpacesList(state),
  total: accountSelectors.getAccountSpacesTotal(state),
  offset: accountSelectors.getOffset(state)
});

export default connect(mapStateToProps)(AccountSpaces);
// export unconnected component for testing
export { AccountSpaces };
