import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as uiActions from '~actions/ui-actions';

import StatusMessage from '~components/status/status-message';

import * as uiSelectors from '~selectors/ui-selectors';

function ModalDialog({ allowClose, children, dispatch, isTopDialog, showStatus }) {
  // TODO: status message

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      // clean up event listeners
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleClose = () => {
    if (allowClose) {
      dispatch(uiActions.closeDialog());
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape' && isTopDialog) {
      handleClose();
    }
  };

  return (
    <div className="dialogContainer">
      <div className="modalDialog" style={{ width: '600px' }}>
        {allowClose && <button type="button" className="modalClose" onClick={handleClose} />}
        {children}
        {showStatus && <StatusMessage />}
      </div>
    </div>
  );
}

ModalDialog.propTypes = {
  allowClose: PropTypes.bool,
  children: PropTypes.node,
  dispatch: PropTypes.func,
  isTopDialog: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  showStatus: PropTypes.bool,
  type: PropTypes.string.isRequired
};

ModalDialog.defaultProps = {
  allowClose: true,
  showStatus: true
};

const mapStateToProps = (state, ownProps) => {
  return {
    // because we could have multiple dialogs open,
    // we only want to close this button when the user presses Esc
    // if it's the top dialog.
    // note: this assumes that only one dialog of each type is ever at a time,
    // which is what dialogs.js assumes too
    isTopDialog: uiSelectors.getTopDialog(state) === ownProps.type
  };
};

export default connect(mapStateToProps)(ModalDialog);
