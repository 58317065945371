import * as types from '~actions/action-types';

export const fetchUserSearchPage = (query, offset) => ({
  type: types.FETCH_USER_SEARCH_PAGE,
  query,
  offset
});

export const clearUserSearch = () => ({
  type: types.CLEAR_USER_SEARCH
});

export const fetchUserSearchPageSuccess = (users, total, offset) => ({
  type: types.FETCH_USER_SEARCH_PAGE_SUCCESS,
  users,
  total,
  offset
});

export const fetchUserSearchPageFailure = () => ({
  type: types.FETCH_USER_SEARCH_PAGE_FAILURE
});

export const setCurrentUser = (userId) => ({
  type: types.SET_CURRENT_USER,
  userId
});

export const clearCurrentUser = () => ({
  type: types.CLEAR_CURRENT_USER
});

export const fetchUserSuccess = (user) => ({
  type: types.FETCH_USER_SUCCESS,
  user
});

export const updateUserSuccess = (user) => ({
  type: types.UPDATE_USER_SUCCESS,
  user
});

export const deleteUserSuccess = (user) => ({
  type: types.DELETE_USER_SUCCESS,
  user
});

export const createUser = (user) => ({
  type: types.CREATE_USER,
  user
});

export const createUserSuccess = (user) => ({
  type: types.CREATE_USER_SUCCESS,
  user
});

export const fetchCurrentUserOwnedAccounts = () => ({
  type: types.FETCH_CURRENT_USER_OWNED_ACCOUNTS
});

export const fetchCurrentUserOwnedAccountsSuccess = (accounts) => ({
  type: types.FETCH_CURRENT_USER_OWNED_ACCOUNTS_SUCCESS,
  accounts
});
