import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import * as uiActions from '~actions/ui-actions';

import Layout from '~components/general/layout';
import Unauthorized from '~components/general/unauthorized';
import AccountsPage from '~components/pages/accounts-page';
import ApplicationsPage from '~components/pages/applications-page';
import ContentPage from '~components/pages/content-page';
import HomePage from '~components/pages/home-page';
import LoginPage from '~components/pages/login-page';
import SpacesPage from '~components/pages/spaces-page';
import UsersPage from '~components/pages/users-page';
import RequireAuthentication from '~components/util/require-authentication';

import { getStore } from './store';

import '../node_modules/rs-styles/cloud-mechanic/cloud-mechanic.css';

const store = getStore();
store.dispatch(uiActions.initialize());

ReactDOM.render(
  <Provider store={store}>
    <Router basename="/app">
      <Layout>
        <Switch>
          <Route exact path="/" component={RequireAuthentication(HomePage)} />
          <Route path="/users" component={RequireAuthentication(UsersPage)} />
          <Route path="/accounts" component={RequireAuthentication(AccountsPage)} />
          <Route path="/content" component={RequireAuthentication(ContentPage)} />
          <Route path="/spaces" component={RequireAuthentication(SpacesPage)} />
          <Route path="/applications" component={RequireAuthentication(ApplicationsPage)} />
          <Route path="/login" component={LoginPage} />
          <Route path="/unauthorized" component={Unauthorized} />
        </Switch>
      </Layout>
    </Router>
  </Provider>,
  document.getElementById('root')
);
