import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import * as applicationActions from '~actions/application-actions';

import AccountLink from '~components/general/account-link';
import ApplicationLink from '~components/general/application-link';
import GettingMore from '~components/general/getting-more';
import IString from '~components/general/i-string';
import Search from '~components/general/search';
import Time from '~components/general/time';

import * as applicationSelectors from '~selectors/application-selectors';

const ApplicationSearchResult = ({ application }) =>
  <tr>
    <td><ApplicationLink appId={application.id} /></td>
    <td>{application.name}</td>
    <td>{application.type}</td>
    <td>{application.status}</td>
    <td><AccountLink accountId={application.account_id} /></td>
    <td><Time value={application.created_time} /></td>
    <td><Time value={application.updated_time} /></td>
  </tr>;

ApplicationSearchResult.propTypes = {
  application: PropTypes.shape({
    account_id: PropTypes.number,
    created_time: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    updated_time: PropTypes.string
  })
};

function ApplicationSearch({ dispatch, loading, query, results }) {

  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const [ search, setSearch ] = useState(params.get('query'));

  const isEmpty = !!results && results.length === 0 && !loading;

  useEffect(() => {
    history.replace({ search: search ? `?query=${search}` : '' });
  }, [ history, search ]);

  useEffect(() => {
    if (search) {
      dispatch(applicationActions.setApplicationSearch(search));
    } else {
      dispatch(applicationActions.clearApplicationSearch());
    }
  }, [ dispatch, search ]);

  return (
    <>
      <div className="sectionTitle flex">
        <IString stringKey={`application.search.title.${query ? 'results' : 'prompt' }`} />
      </div>

      <div className="section">
        <Search value={query} submit={setSearch} />
      </div>

      { query ?
        <div className="tableContainer">
          { isEmpty ?
            <div className="emptyListMessage"><IString stringKey="general.empty" /></div>
            : <>
              <table className="contentListing">
                <thead>
                  <tr>
                    <th><IString stringKey="general.application" /></th>
                    <th><IString stringKey="general.name" /></th>
                    <th><IString stringKey="general.type" /></th>
                    <th><IString stringKey="general.status" /></th>
                    <th><IString stringKey="general.account" /></th>
                    <th><IString stringKey="general.created" /></th>
                    <th><IString stringKey="general.updated" /></th>
                  </tr>
                </thead>
                <tbody>
                  {results && results.map(t => <ApplicationSearchResult application={t} key={t.id} />)}
                </tbody>
              </table>
              { loading && <GettingMore label={<IString stringKey="general.loading" />} /> }
            </> }
        </div>
        : <div className="emptyListMessage"><IString stringKey="application.search.prompt" /></div> }
    </>
  );
}

ApplicationSearch.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  query: PropTypes.string,
  results: PropTypes.array
};

const mapStateToProps = (state) => {
  return {
    loading: applicationSelectors.isSearchLoading(state),
    query: applicationSelectors.getSearchQuery(state),
    results: applicationSelectors.getSearchResults(state)
  };
};

export default connect(mapStateToProps)(ApplicationSearch);
