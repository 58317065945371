import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import * as uiActions from '~actions/ui-actions';

import * as dialogTypes from '~components/dialogs/dialog-types';
import ModalDialog from '~components/dialogs/modal-dialog';
import ActionButton from '~components/general/action-button';
import IString from '~components/general/i-string';

function AccountLicenseUsageCreditsCreateEditDialog(props) {

  const { data, dispatch, onSubmit } = props;
  const { create, usageCredits } = data;

  const [ creditHours, setCreditHours ] = useState(usageCredits.credit_hours || '');
  const [ expireCycles, setExpireCycles ] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    const usageCredits = {
      credit_hours: parseInt(creditHours),
      expire_cycles: parseInt(expireCycles)
    };

    if (create) {
      onSubmit(usageCredits);
    } else {
      dispatch(uiActions.openDialog({
        type: dialogTypes.CONFIRM_DIALOG,
        data: {
          purpose: <IString stringKey="account.usageCredits.modal.edit.purpose" />,
          details: <IString stringKey="account.usageCredits.modal.edit.details" />,
          confirm: () => {
            onSubmit(usageCredits);
            dispatch(uiActions.closeDialog()); // close confirm dialog
          }
        }
      }));
    }
  };

  return (
    <ModalDialog type={dialogTypes.ACCOUNT_USAGE_CREDITS_CREATE_EDIT_DIALOG}>
      <div className="purpose">
        <IString stringKey={`account.usageCredits.modal.${create ? 'create' : 'edit'}.title`} />
      </div>
      <form noValidate onSubmit={handleSubmit}>

        <fieldset className="chunk">
          <label htmlFor="creditHoursInput" className="fieldLabel">
            <IString stringKey="account.usageCredits.creditHours" />
          </label>
          <div className="flex">
            <input
              className="full"
              id="creditHoursInput"
              type="number"
              value={creditHours}
              onChange={(e) => setCreditHours(e.target.value)}
            />
            <div className="actionBar inline" style={{ paddingTop: '3px' }}>
              <ActionButton
                className="delete noTitle"
                onClick={() => setCreditHours('')}
              />
            </div>
          </div>
        </fieldset>

        <fieldset className="chunk">
          <label htmlFor="expireCyclesInput" className="fieldLabel">
            <IString stringKey="account.usageCredits.expireCycles" />
          </label>
          <div className="flex">
            <input
              className="full"
              id="expireCyclesInput"
              type="number"
              value={expireCycles}
              onChange={(e) => setExpireCycles(e.target.value)}
            />
            <div className="actionBar inline" style={{ paddingTop: '3px' }}>
              <ActionButton
                className="delete noTitle"
                onClick={() => setExpireCycles('')}
              />
            </div>
          </div>
        </fieldset>

        <div className="actions">
          <button type="submit"><IString stringKey="general.save" /></button>
        </div>

      </form>
    </ModalDialog>
  );
}

AccountLicenseUsageCreditsCreateEditDialog.propTypes = {
  data: PropTypes.shape({
    create: PropTypes.bool,
    usageCredits: PropTypes.shape({
      credit_hours: PropTypes.number,
      expires: PropTypes.string
    })
  }),
  dispatch: PropTypes.func,
  onSubmit: PropTypes.func
};

export default connect()(AccountLicenseUsageCreditsCreateEditDialog);
