import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as accountActions from '~actions/account-actions';
import * as uiActions from '~actions/ui-actions';

import AccountPropertyItem from '~components/accounts/account-property-item';
import * as dialogsTypes from '~components/dialogs/dialog-types';
import ActionButton from '~components/general/action-button';
import IString from '~components/general/i-string';

import * as accountSelectors from '~selectors/account-selectors';

function AccountProperties(props) {
  const { accountId, dispatch, properties } = props;

  useEffect(() => {
    dispatch(accountActions.fetchAccountProperties(accountId));
  }, [ dispatch, accountId ]);

  const handleAdd = () => {
    dispatch(uiActions.openDialog({
      type: dialogsTypes.ACCOUNT_PROPERTIES_CREATE_EDIT_DIALOG,
      data: {
        create: true,
        property: {}
      },
      onSubmit: (property) => {
        dispatch(accountActions.createAccountProperty(accountId, property));
        dispatch(uiActions.closeDialog());
      }
    }));
  };

  return (
    <div className="splitColumns withMobileMargins">
      <div className="column50">
        <div className="sectionTitle flex">
          <IString stringKey="account.properties.section" />
          <div className="actionBar inline showTitles">
            <ActionButton
              className="action new"
              title={<IString stringKey="general.add" />}
              onClick={handleAdd}
            />
          </div>
        </div>
        <div className="tableContainer">
          { properties?.length > 0 ?
            <table className="contentListing column100">
              <thead>
                <tr>
                  <th><IString stringKey="general.name" /></th>
                  <th><IString stringKey="general.value" /></th>
                  <th />
                </tr>
              </thead>
              <tbody>
                { properties && properties.map( p => <AccountPropertyItem accountId={accountId} property={p} key={p.id} /> ) }
              </tbody>
            </table> :
            <div className="emptyListMessage">
              <IString stringKey="account.properties.none" />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

AccountProperties.propTypes = {
  accountId: PropTypes.number,
  dispatch: PropTypes.func,
  properties: PropTypes.array
};

const mapStateToProps = (state) => ({
  properties: accountSelectors.getAccountProperties(state)
});

export default connect(mapStateToProps)(AccountProperties);

export { AccountProperties };
