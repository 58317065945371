import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import * as userActions from '~actions/user-actions';

import IString from '~components/general/i-string';
import UserAuthoredContent from '~components/users/user-authored-content';
import UserDetails from '~components/users/user-details';
import UserMenu from '~components/users/user-menu';

import * as userSelectors from '~selectors/user-selectors';

function UserPage({ dispatch, user }) {
  const { params, path } = useRouteMatch();

  const userId = +params.userId;

  useEffect(() => {
    dispatch(userActions.setCurrentUser(userId));

    // returned function will be called on component unmount
    return () => {
      dispatch(userActions.clearCurrentUser());
    };

  }, [ dispatch, userId ]);

  if (user) {
    return (
      <>
        <h1><IString stringKey="user.title" placeholders={{ id: userId }} /></h1>
        <UserMenu userId={userId} />
        <Switch>
          <Route exact path={`${path}/`}>
            <UserDetails />
          </Route>
          <Route path={`${path}/content`}>
            <UserAuthoredContent userId={userId} />
          </Route>
        </Switch>
      </>
    );
  } else {
    return (
      <span>Loading...</span>
    );
  }
}

UserPage.propTypes = {
  dispatch: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })
};

const mapStateToProps = (state) => {
  return {
    user: userSelectors.getCurrentUser(state)
  };
};

export default connect(mapStateToProps)(UserPage);
// export unconnected component for testing
export { UserPage };
