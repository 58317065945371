import PropTypes from 'prop-types';
import React from 'react';

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value
    };

    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== this.props.value) {
      this.setState({ value });
    }
  }

  render() {
    const { value } = this.state;
    const changed = !!value || !!this.props.value;

    return (
      <div className="searchContainer">
        <input type="text" maxLength="255" value={value || ''} onChange={this.onChange} onKeyDown={this.onKeyDown} />
        { !!changed && <button type="button" className="searchKiller" onClick={this.resetSearch} />}
      </div>
    );
  }

  onChange(event) {
    this.setState({ value: event.target.value });
  }

  onKeyDown(event) {
    const { submit, value } = this.props;
    const currentValue = this.state.value;

    if (event.key === 'Enter') {
      if (currentValue !== value) {
        // the search string changed, so submit that
        submit(currentValue || null); // make sure we always send the same "blank" thing: null
      }
    } else if (event.key === 'Escape') {
      // user is clearing out the search
      this.resetSearch();
    }
  }

  resetSearch() {
    const { submit, value } = this.props;
    if (value !== null) {
      // we're clearing an active search
      submit(null);
    }
    // make sure we clear out our local state too
    this.setState({ value: null });
  }
}

Search.propTypes = {
  submit: PropTypes.func.isRequired,
  value: PropTypes.string
};

Search.defaultProps = {
  value: null
};

export default Search;
