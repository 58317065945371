import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as accountActions from '~actions/account-actions';
import * as uiActions from '~actions/ui-actions';

import * as dialogs from '~components/dialogs/dialog-types';
import ActionButton from '~components/general/action-button';
import IString from '~components/general/i-string';
import classnames from '~components/util/classnames';

import { isUnlimited, isDefault } from '~constants/license-entitlements';

import { getAccountLicenseEntitlement, getCurrentAccountId } from '~selectors/account-selectors';

export function AccountLicenseEntitlement(props) {
  const { entitlement, dispatch, accountId, licenseType, entitlementType } = props;

  const [ showEntitlementDescription, setShowEntitlementDescription ] = useState(false);

  useEffect(() => {
    dispatch(accountActions.fetchAccountLicenseEntitlement(accountId, licenseType, entitlementType));
  }, [ dispatch, accountId, licenseType, entitlementType ]);

  const handleEdit = (e) => {
    e.preventDefault();
    dispatch(uiActions.openDialog({
      type: dialogs.ACCOUNT_LICENSE_ENTITLEMENT_EDITOR_DIALOG,
      data: {
        entitlement
      },
      onSubmit: (changed) => {
        if (isDefault(entitlement)) {
          dispatch(accountActions.createAccountLicenseEntitlement(accountId, licenseType, entitlementType, changed));
        } else {
          dispatch(accountActions.updateAccountLicenseEntitlement(accountId, licenseType, entitlementType, changed));
        }
        dispatch(uiActions.closeDialog()); // close editor dialog
      }
    }));
  };

  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(accountActions.deleteAccountLicenseEntitlement(accountId, licenseType, entitlementType));
  };

  // Color, border, and padding matches the toggleableInfo CSS class
  const entitlementDescriptionTooltipStyle = {
    backgroundColor: '#f9f8f8',
    border: '1px solid #e5e4e4',
    lineHeight: 1.5,
    padding: 15,
    position: 'absolute',
    textAlign: 'left',
    top: 30,
    zIndex: 1
  };

  if (entitlement) {
    return (
      <tr>
        <td>
          <div className="actionBar inline smallMarginRight">
            <div
              className={classnames({ 'action toggleInfo first': true, 'current': showEntitlementDescription })}
              onMouseEnter={() => setShowEntitlementDescription(true)}
              onMouseLeave={() => setShowEntitlementDescription(false)}
            >
              {showEntitlementDescription &&
                <div style={entitlementDescriptionTooltipStyle}>
                  <IString stringKey={`account.license.entitlement.description.${entitlement.type}`} />
                </div>
              }
            </div>
          </div>
          { entitlement.type }
        </td>
        <td>
          { entitlement.min !== undefined &&
            <div>
              <IString
                placeholders={{
                  value: isUnlimited(entitlement.min)
                    ? <IString stringKey="account.license.entitlement.configuration.unlimited" />
                    : entitlement.min
                }}
                stringKey="account.license.entitlement.configuration.min"
              />
            </div> }
          { entitlement.max !== undefined &&
            <div>
              <IString
                placeholders={{
                  value: isUnlimited(entitlement.max)
                    ? <IString stringKey="account.license.entitlement.configuration.unlimited" />
                    : entitlement.max
                }}
                stringKey="account.license.entitlement.configuration.max"
              />
            </div> }
          { entitlement.enabled !== undefined && <div>{String(entitlement.enabled)}</div> }
        </td>
        <td style={{ textAlign: 'right' }}>
          { isDefault(entitlement)
            ? <em><IString stringKey="account.license.entitlement.configuration.default" /></em>
            : <span style={{ color: '#419599' }}><IString className="fontBold" stringKey="account.license.entitlement.configuration.configured" /></span> }
        </td>
        <td>

          <div className="actionBar inline">
            <ActionButton className="edit noTitle" onClick={handleEdit} />
            { !isDefault(entitlement) && <ActionButton className="delete noTitle" onClick={handleDelete} /> }
          </div>
        </td>
      </tr>
    );
  } else {
    return (
      <tr>
        <td>
          {entitlementType}
        </td>
        <td><IString stringKey="general.loading" /></td>
        <td />
        <td />
      </tr>
    );
  }
}

AccountLicenseEntitlement.propTypes = {
  accountId: PropTypes.number,
  dispatch: PropTypes.func,
  entitlement: PropTypes.shape({
    enabled: PropTypes.bool,  // boolean entitlements have an "enabled" field
    id: PropTypes.number,
    max: PropTypes.number,    // numeric entitlements often have max
    min: PropTypes.number,    // range entitlements have min as well as max
    type: PropTypes.string
  }),
  entitlementType: PropTypes.string,
  licenseType: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  const accountId = parseInt(getCurrentAccountId(state));
  return {
    accountId,
    entitlement: getAccountLicenseEntitlement(state, accountId, ownProps.licenseType, ownProps.entitlementType)
  };
};

export default connect(mapStateToProps)(AccountLicenseEntitlement);
