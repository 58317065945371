import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as userActions from '~actions/user-actions';

import IString from '~components/general/i-string';
import UserDetailsAccountListItem from '~components/users/user-details-account-list-item';

import * as userSelectors from '~selectors/user-selectors';

function UserDetailsAccountList({ currentUserId, currentUsersAccounts, dispatch }) {
  const hasAccounts = currentUsersAccounts && currentUsersAccounts.length > 0;

  useEffect(() => {
    if (currentUserId) {
      dispatch(userActions.fetchCurrentUserOwnedAccounts());
    }
  }, [ dispatch, currentUserId ]);

  if (currentUsersAccounts) {
    return (
      <div className="tableContainer">
        {!hasAccounts ?
          <div className="emptyListMessage">
            <IString stringKey="user.details.accounts.noAccounts" />
          </div> :
          <table className="contentListing">
            <thead>
              <tr>
                <th><span>Account</span></th>
                <th><span>Name</span></th>
                <th><span>Type</span></th>
                <th><span>License Name</span></th>
              </tr>
            </thead>
            <tbody>
              {currentUsersAccounts.map((account) => (
                <UserDetailsAccountListItem key={account.id} account={account} />))}
            </tbody>
          </table>
        }
      </div>
    );
  }
}

UserDetailsAccountList.propTypes = {
  currentUserId: PropTypes.number,
  currentUsersAccounts: PropTypes.array,
  dispatch: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    currentUserId: userSelectors.getCurrentUserId(state),
    currentUsersAccounts: userSelectors.getCurrentUsersAccounts(state)
  };
};

export default connect(mapStateToProps)(UserDetailsAccountList);
