import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import * as uiSelectors from '~selectors/ui-selectors';

import * as datetime from '~util/datetime';

/**
 * Formats the given UTC value (string or number) and renders it inside of a <time> tag
 *
 * @param   {string|number} value UTC value to format (either ISO standard string or epoch timestamp)
 * @returns {JSX.Element}         A <time> element with given datetime value formatted
 */
export default function Time({ value }) {
  const utc = useSelector(uiSelectors.getUTC);
  return <time>{datetime.format(value, utc) || ''}</time>;
}

Time.propTypes = {
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
};
