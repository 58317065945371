import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import AccountMembersCloud from '~components/accounts/members/account-members-cloud';
import AccountMembersMenu from '~components/accounts/members/account-members-menu';
import AccountMembersShinyapps from '~components/accounts/members/account-members-shinyapps';
import IString from '~components/general/i-string';

function AccountMembersPage({ account }) {

  const { path } = useRouteMatch();

  if (account) {
    return (
      <>
        <div className="sectionTitle flex">
          <IString stringKey="account.members.title" />
        </div>
        <AccountMembersMenu accountId={account.id} />
        <Switch>
          <Route path={`${path}/cloud`}>
            <AccountMembersCloud account={account} />
          </Route>
          <Route path={`${path}/shinyapps`}>
            <AccountMembersShinyapps account={account} />
          </Route>
          <Redirect from={`${path}`} to={`${path}/cloud`} exact />
        </Switch>
      </>
    );
  } else {
    return (
      <span>Loading...</span>
    );
  }

}

AccountMembersPage.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number
  })
};

export default AccountMembersPage;
