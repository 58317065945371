import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

function ContentLink({ contentId }) {

  return (
    <Link to={`/content/${contentId}`}><span>Content {contentId}</span></Link>
  );
}

ContentLink.propTypes = {
  contentId: PropTypes.number
};

export default ContentLink;
