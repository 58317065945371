import PropTypes from 'prop-types';
import React from 'react';

export class HTMLText extends React.Component {
  render() {
    return (
      <span className={this.props.className} dangerouslySetInnerHTML={{ __html: this.props.children }} />
    );
  }
}

HTMLText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default HTMLText;
