import PropTypes from 'prop-types';
import React from 'react';

import UserIcon from '~components/general/user-icon';
import UserName from '~components/general/user-name';

class User extends React.Component {
  render() {
    const { user } = this.props;

    if (user) {
      return (
        <div className="user">
          <UserIcon {...user} />
          <div className="userName"><UserName user={user} /></div>
        </div>
      );
    } else {
      return (
        <div className="user">
          <div className="userIcon departed" />
          Unknown
        </div>
      );
    }
  }
}

User.propTypes = {
  user: PropTypes.object
};

export default User;
