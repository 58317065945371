import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import * as contentActions from '~actions/content-actions';

import AccountLink from '~components/general/account-link';
import ContentLink from '~components/general/content-link';
import GetMore from '~components/general/get-more';
import GettingMore from '~components/general/getting-more';
import IString from '~components/general/i-string';
import Search from '~components/general/search';
import SpaceLink from '~components/general/space-link';
import Time from '~components/general/time';
import UserLink from '~components/general/user-link';

import { translate } from '~i18n/localize';

import * as contentSelectors from '~selectors/content-selectors';

function ContentSearch(props) {
  const { dispatch, loading, query, results, total } = props;

  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const [ search, setSearch ] = useState(params.get('query'));

  const empty = !loading && results?.length === 0;
  let listStatus = null;

  // since pagination works by continually getting more (vs. stepping through
  // discrete pages one by one) the offset is always the length of the list of items
  const offset = results?.length ?? 0;
  const hasMore = results?.length < total;

  const fetchMoreContent = useCallback((offset) => {
    dispatch(contentActions.setContentSearch(query, offset));
  }, [ dispatch, query ]);

  if (loading) {
    // getting more spinner
    listStatus = <GettingMore label={translate('general.loading')} />;
  } else if (hasMore) {
    // get more "button"
    listStatus = <GetMore autoFire label={translate('content.search.getMore')} onClick={() => fetchMoreContent(offset)} />;
  }

  useEffect(() => {
    history.replace({ search: search ? `?query=${encodeURIComponent(search)}` : '' });
  }, [ search, history ]);

  useEffect(() => {
    if (search) {
      dispatch(contentActions.setContentSearch(search));
    } else {
      dispatch(contentActions.clearContentSearch());
    }

    return () => dispatch(contentActions.clearContentSearch());
  }, [ search, dispatch ]);

  const handleSearch = (newSearch) => {
    setSearch(newSearch);
  };

  return (
    <>
      <div className="sectionTitle flex">
        <IString stringKey={`content.search.title.${query ? 'results' : 'prompt'}`} />
      </div>

      <div className="section">
        <Search value={query} submit={handleSearch} />
      </div>

      <div className="smallMarginBelow">
        <div className="right">
          {total !== undefined && <IString stringKey="general.total" placeholders={{ total }} />}
        </div>
      </div>

      {query ?
        <div className="tableContainer">
          {empty ? <div className="emptyListMessage"><IString stringKey="general.empty" /></div>
            : <table className="contentListing">
              <thead>
                <tr>
                  <th><IString stringKey="general.id" /></th>
                  <th><IString stringKey="general.name" /></th>
                  <th><IString stringKey="general.type" /></th>
                  <th><IString stringKey="general.state" /></th>
                  <th><IString stringKey="general.mode" /></th>
                  <th><IString stringKey="general.account" /></th>
                  <th><IString stringKey="general.space" /></th>
                  <th><IString stringKey="general.author" /></th>
                  <th><IString stringKey="general.created" /></th>
                  <th><IString stringKey="general.updated" /></th>
                </tr>
              </thead>
              <tbody>
                {results?.map(content =>
                  <tr key={content.id}>
                    <td><ContentLink contentId={content.id} /></td>
                    <td>{content.name || <em><IString stringKey="general.untitled" /></em>}</td>
                    <td>{content.content_type}</td>
                    <td>{content.state}</td>
                    <td>{content.project_mode}</td>
                    <td><AccountLink accountId={content.account_id} /></td>
                    <td>{content.space_id ? <SpaceLink spaceId={content.space_id} /> : <em><IString stringKey="general.notSet" /></em>}</td>
                    <td><UserLink user={content.author} /></td>
                    <td><Time value={content.created_time} /></td>
                    <td><Time value={content.updated_time} /></td>
                  </tr>
                )}
              </tbody>
            </table>}
        </div>
        : <div className="emptyListMessage"><IString stringKey="content.search.prompt" /></div>
      }
      {listStatus}
    </>
  );
}

ContentSearch.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  query: PropTypes.string,
  results: PropTypes.array,
  total: PropTypes.number
};

const mapStateToProps = (state) => ({
  loading: contentSelectors.isSearchLoading(state),
  query: contentSelectors.getSearchQuery(state),
  results: contentSelectors.getSearchResults(state),
  total: contentSelectors.getSearchResultsTotal(state)
});

export default connect(mapStateToProps)(ContentSearch);
// export unconnected component for testing
export { ContentSearch };
