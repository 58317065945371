import * as taskActions from '~actions/task-actions';

import * as tasksAPI from '~api/tasks';

import { translate } from '~i18n/localize';

/**
 * Thunk action creator to check whether a task has completed
 *
 * @param   {number}  task_id   ID of the task to check if it is finished
 * @param   {boolean} useLegacy True if we want to retrieve the legacy data structure of the task, false otherwise
 * @returns                     A thunk that checks until a task is complete and then returns that task
 */
export const checkUntilDone = (task_id, useLegacy = true) => async (dispatch) => {
  try {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      try {
        const { data: task } = await tasksAPI.getTask(task_id, useLegacy);

        dispatch(taskActions.setCurrentTask(task));

        // Check whether the task has finished
        if (task.finished) {

          // If the task was not successful then throw the error from the task
          const status = useLegacy ? task.status : task.state;
          if (status !== 'success') {
            throw useLegacy ? task.error : task.result.error;

          // The task succeeded, hooray!
          } else {
            return task;
          }

        // The task has not finished yet, so let's wait for 1 second
        } else {
          await new Promise(resolve => setTimeout(resolve, 1000));
        }

      } catch (error) {
        // Prepend the error with the task id to make debugging a bit easier
        throw translate('application.task.error', { task_id, error });
      }
    }
  } finally {
    dispatch(taskActions.clearCurrentTask());
  }
};
