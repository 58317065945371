import PropTypes from 'prop-types';
import React from 'react';

import { IString } from '~components/general/i-string';

function SummaryTable(props) {
  const { columns, renderer: Renderer, rows } = props;

  return (
    <table className="contentListing allTightRight">
      <thead>
        <tr>
          <th><IString stringKey="general.id" /></th>
          <th><IString stringKey="general.name" /></th>
          { columns.map((col, i) =>
            <th key={`header-${i}`}>
              <IString stringKey={`usage.table.columns.${col}`} />
            </th>) }
        </tr>
      </thead>
      <tbody>
        { rows.map((row, i) =>
          <tr key={`row-${i}`}>
            <td><Renderer {...row} /></td>
            <td>{String(row.name)}</td>

            { columns.map((col, j) =>
              <td key={`row-${i}-col-${j}`}>
                {row.summary[col]}
              </td>)}
          </tr> )}
      </tbody>
    </table>
  );
}

SummaryTable.propTypes = {
  columns: PropTypes.array,
  facet: PropTypes.string,
  renderer: PropTypes.elementType,
  rows: PropTypes.array
};

SummaryTable.defaultProps = {
  columns: [],
  rows: []
};

export default SummaryTable;
