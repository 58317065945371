import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import * as dialogs from '~components/dialogs/dialog-types';
import ModalDialog from '~components/dialogs/modal-dialog';
import IString from '~components/general/i-string';
import MarkedMessage, { marks } from '~components/general/marked-message';

import * as licenseEntitlements from '~constants/license-entitlements';

export default function AccountLicenseEntitlementEditorDialog({ data, onSubmit }) {

  const { entitlement } = data;

  const [ changed, setChanged ] = useState(false);
  const [ enabled, setEnabled ] = useState(entitlement.enabled);
  const [ max, setMax ] = useState(entitlement.max);
  const [ min, setMin ] = useState(entitlement.min);

  useEffect(() => {
    setChanged(enabled !== entitlement.enabled || max !== entitlement.max || min !== entitlement.min);
  }, [ enabled, entitlement, max, min ]);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({ ...entitlement, max, min, enabled });
  };

  return (
    <ModalDialog type={dialogs.ACCOUNT_LICENSE_ENTITLEMENT_EDITOR_DIALOG}>
      <div className="purpose">
        <IString stringKey="account.license.entitlement.editor.title" placeholders={{ entitlement: entitlement.type }} />
      </div>
      <form noValidate onSubmit={handleSubmit}>

        {entitlement.min !== undefined &&
          <fieldset className="chunk">
            <label htmlFor="entitlementMin" className="fieldLabel">
              <IString stringKey="account.license.entitlement.editor.min" />
            </label>
            <div className="flex">
              <input
                className="full"
                id="entitlementMin"
                onChange={e => setMin(e.target.value ? parseInt(e.target.value) : null)}
                type="number"
                value={min === null ? '' : min}
              />
            </div>
          </fieldset>
        }

        { entitlement.max !== undefined &&
          <fieldset className="chunk">
            <label htmlFor="entitlementMax" className="fieldLabel">
              <IString stringKey="account.license.entitlement.editor.max" />
              { !entitlement.min && <IString className="smallerFont" stringKey="account.license.entitlement.editor.unlimited" /> }
            </label>
            <div className="flex">
              <input
                className="full"
                id="entitlementMax"
                onChange={e => setMax(e.target.value ? parseInt(e.target.value) : null)}
                type="number"
                value={max === null ? '' : max}
              />
            </div>
          </fieldset>
        }

        { entitlement.enabled !== undefined &&
          <fieldset className="chunk">
            <input
              checked={enabled}
              id="entitlementEnabled"
              onChange={e => setEnabled(e.target.checked)}
              type="checkbox"
            />
            <label htmlFor="entitlementEnabled">
              <IString stringKey="account.license.entitlement.editor.enabled" />
            </label>
          </fieldset>
        }

        {[ licenseEntitlements.ACCOUNT_MEMBER_MAX, licenseEntitlements.ACCOUNT_PARTICIPANT_MAX ].includes(entitlement.type) &&
          <MarkedMessage mark={marks.IMPORTANT}>
            <p><IString stringKey={`account.license.entitlement.editor.warning.wasHere.${entitlement.type}`} /></p>
            <p><IString stringKey="account.license.entitlement.editor.warning.nowThere" /></p>
          </MarkedMessage>
        }

        <div className="actions">
          <button type="submit" disabled={!changed}>
            <IString stringKey="general.save" />
          </button>
        </div>

      </form>
    </ModalDialog>
  );
}

AccountLicenseEntitlementEditorDialog.propTypes = {
  data: PropTypes.shape({
    entitlement: PropTypes.shape({
      enabled: PropTypes.bool,
      max: PropTypes.number,
      min: PropTypes.number,
      type: PropTypes.string
    })
  }),
  onSubmit: PropTypes.func
};
