import { createSelector } from 'reselect';

import * as status from '~constants/application-status';

export const deleteable = (content) => status.isTerminated(content?.source) || status.isPurged(content?.source);

export const getContent = (state) => state.contentState.content;
export const getContentById = (state, id) => getContent(state)[id];

// content search selectors
const getSearchResultIds = (state) => state.contentState.search.resultIds;

export const getSearchQuery = (state) => state.contentState.search.query;
export const getSearchResults = createSelector(
  [ getContent, getSearchResultIds ],
  (content, ids) => ids.map(id => content[id])
);

export const getSearchResultsTotal = (state) => state.contentState.search.total;
export const isSearchLoading = (state) => state.contentState.search.loading;

