import axios from 'axios';

const getConfigURL = () => {
  const baseURL = location.protocol + '//' + location.host;
  return new URL('/config', baseURL).toString();
};

export const getConfig = () => {
  return axios.get(getConfigURL());
};
