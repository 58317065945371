import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import IString from '~components/general/i-string';

export default function UserMenu(props) {

  const { userId } = props;

  return (
    <div className="menu showOnMobile">
      <div className="menuItems">
        <NavLink to={`/users/${userId}/`} className="menuItem" exact activeClassName="active">
          <IString stringKey="user.menu.details" />
        </NavLink>
        <NavLink to={`/users/${userId}/content`} className="menuItem" activeClassName="active">
          <IString stringKey="user.menu.authoredContent" />
        </NavLink>
      </div>
    </div>

  );
}

UserMenu.propTypes = {
  userId: PropTypes.number
};
