import PropTypes from 'prop-types';
import React from 'react';

import AccountDetailsLicenseEntitlement from '~components/accounts/license/account-license-entitlement';
import IString from '~components/general/i-string';

import * as licenseEntitlements from '~constants/license-entitlements';
import * as licenseType from '~constants/license-type';

function AccountLicenseEntitlements({ license }) {

  return (
    <div id="licenseEntitlements">
      {license.type === licenseType.CLOUD &&
        <table className="propertiesTable">
          <thead>
            <tr>
              <th>
                <IString stringKey="account.license.entitlement.table.columns.name" />
              </th>
              <th style={{ paddingLeft: 0, paddingRight: 0 }}>
                <IString stringKey="account.license.entitlement.table.columns.configuration" />
              </th>
              <th style={{ paddingRight: 0 }}>
                <IString stringKey="general.state" />
              </th>
              <th />
            </tr>
          </thead>

          { Object.keys(licenseEntitlements.cloudEntitlements).map(category =>
            <tbody key={category}>
              <tr >
                <td className="tableTitle sectionLabel" colSpan={4} >
                  <IString stringKey={`account.license.entitlement.table.sections.${category}`} />
                </td>
              </tr>
              { licenseEntitlements.cloudEntitlements[category].map(entitlement =>
                <AccountDetailsLicenseEntitlement entitlementType={entitlement} key={entitlement} licenseType={licenseType.CLOUD} />
            )}
            </tbody>
          )}
        </table>
      }
    </div>
  );
}

AccountLicenseEntitlements.propTypes = {
  license: PropTypes.shape({
    type: PropTypes.string
  })
};

export default AccountLicenseEntitlements;
