import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import ApplicationMetricsChart from '~components/applications/metrics/application-metrics-chart';
import IString from '~components/general/i-string';
import InfoTitle from '~components/general/info-title';
import RangePicker from '~components/general/range-picker';

import * as applicationMetricTypes from '~constants/application-metric-types';

import * as formatter from '~util/formatter';
import mo, { ISO_DATE } from '~util/mo';

// map of range keys (drop down options) to intervals the API can understand
// for example, if the range size is "day", we want to render a chart of
// single hours, and so we need to request an interval of "1h".
const rangeToInterval = {
  day: '1h',
  month: '1d',
  week: '1d'
};

const iso = `${ISO_DATE}THH:mm:ss`;

const ApplicationMetrics = ({ applicationId }) => {
  const [ from, setFrom ] = useState();
  const [ interval, setInterval ] = useState();
  const [ until, setUntil ] = useState();

  const handleRangeChange = useCallback((from, until, rangeSize) => {
    setFrom(mo(true, from).format(iso));
    setInterval(rangeToInterval[rangeSize]);
    setUntil(mo(true, until).format(iso));
  }, []);

  return (
    <>
      <div className="sectionTitle">
        <IString stringKey="application.metrics.title" />
      </div>

      <RangePicker
        earliest={mo(true).subtract(90, 'days').format(ISO_DATE)}
        onRangeChange={handleRangeChange}
        utc
      />

      <InfoTitle
        title={<IString stringKey="application.metrics.memory.title" />}
        subtitle={<IString stringKey="application.metrics.memory.subtitle" />}
      />
      <ApplicationMetricsChart
        applicationId={applicationId}
        from={from}
        interval={interval}
        metricType={applicationMetricTypes.MEMORY}
        valueFormatter={formatter.bytesToGiB}
        until={until}
      />

      <InfoTitle
        title={<IString stringKey="application.metrics.cpu.title" />}
        subtitle={<IString stringKey="application.metrics.cpu.subtitle" />}
      />
      <ApplicationMetricsChart
        applicationId={applicationId}
        from={from}
        interval={interval}
        metricType={applicationMetricTypes.CPU}
        until={until}
      />

      <InfoTitle
        title={<IString stringKey="application.metrics.connections.title" />}
        subtitle={<IString stringKey="application.metrics.connections.subtitle" />}
      />
      <ApplicationMetricsChart
        applicationId={applicationId}
        from={from}
        interval={interval}
        metricType={applicationMetricTypes.CONNECTIONS}
        until={until}
      />

      <InfoTitle
        title={<IString stringKey="application.metrics.workers.title" />}
        subtitle={<IString stringKey="application.metrics.workers.subtitle" />}
      />
      <ApplicationMetricsChart
        applicationId={applicationId}
        from={from}
        interval={interval}
        metricType={applicationMetricTypes.WORKERS}
        until={until}
      />
    </>
  );
};

ApplicationMetrics.propTypes = {
  applicationId: PropTypes.number
};

export default ApplicationMetrics;
