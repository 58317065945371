import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import TimeseriesChart, { formats } from '~components/general/timeseries-chart';
import ChartContainer from '~components/usage/chart-container';

import * as applicationMetricTypes from '~constants/application-metric-types';

import { translate } from '~i18n/localize';

import * as applicationOperations from '~operations/application-operations';

import * as formatter from '~util/formatter';
import mo from '~util/mo';

const LINE_COLOR = '#419599';

const tooltipFormatter = (tip, data) =>
  `${data.datasets[tip.datasetIndex].label}: ${formatter.compactify(tip.value)}`;

const intervalToUnit = {
  '1d': 'day',
  '1h': 'hour',
  '1m': 'minute'
};

/**
 * Component to handle fetching application metrics for the given application and metric type,
 * and subsequently formatting the data to render in a line chart.
 * If there are multiple metrics for the specified type, a legend will be shown.
 *
 * @param   {number}   applicationId  Id of the application for which to display metrics.
 * @param   {string}   from           Date string for the start of the range in YYYY-MM-DD-THH:mm:ss format.
 * @param   {string}   interval       Interval for the data granularity, e.g. "1d", "1h", or "1m", sent to the API.
 * @param   {string}   metricType     Type of metric to display information for.
 * @param   {string}   until          Date string for the end of the range in YYYY-MM-DD-THH:mm:ss format.
 * @param   {function} valueFormatter Formatter function to apply to series values before charting data.
 * @returns                           A component to render a chart with timeseries data for a given metric type and application.
 */
const ApplicationMetricsChart = ({ applicationId, from, interval, metricType, until, valueFormatter }) => {

  const dispatch = useDispatch();

  const [ datasets, setDatasets ] = useState();

  useEffect(() => {

    const fetchMetricsData = async () => {
      const result = await dispatch(applicationOperations.getMetrics(
        applicationId,
        applicationMetricTypes.metricTypes[metricType],
        [
          [ 'metric', applicationMetricTypes.metrics[metricType] ],
          [ 'interval', interval ],
          [ 'from', from ],
          [ 'until', until ]
        ]
      ));

      let dataset;

      if (result?.data?.points) {
        const metric = applicationMetricTypes.metrics[metricType];
        const format = valueFormatter || (f => f);

        dataset = [ {
          backgroundColor: LINE_COLOR,
          borderColor: LINE_COLOR,
          borderWidth: 1,
          data: result.data.points.map(p => ({ x: p.time, y: format(p[metric]) })),
          fill: false,
          label: translate(`application.metrics.${metricType}.${metric}`),
          lineTension: 0,
          pointHitRadius: 5,
          pointHoverRadus: 3,
          pointRadius: 2
        } ];
      }

      setDatasets(dataset);
    };

    if (from && until && interval) {
      fetchMetricsData();
    }
  }, [ applicationId, dispatch, from, interval, metricType, until, valueFormatter ]);

  const tooltipFormatters = {
    label: tooltipFormatter,
    title: (tips, data) =>
      mo(
        true, data.datasets[tips[0].datasetIndex].data[tips[0].index].x
      ).format(formats.tooltip[intervalToUnit[interval]])
  };

  return (
    <ChartContainer empty={!datasets}>
      <TimeseriesChart
        chartType="line"
        datasets={datasets}
        tooltipFormatters={tooltipFormatters}
        unit={intervalToUnit[interval]}
        xAxisFormatter={(value, index, ticks) =>
          mo(
            true,
            ticks[index].value
          ).format(formats.axis[intervalToUnit[interval]])
        }
        yAxisFormatter={formatter.compactify}
      />
    </ChartContainer>
  );
};

ApplicationMetricsChart.propTypes = {
  applicationId: PropTypes.number.isRequired,
  from: PropTypes.string,
  interval: PropTypes.string,
  metricType: PropTypes.oneOf(applicationMetricTypes.all).isRequired,
  until: PropTypes.string,
  valueFormatter: PropTypes.func
};

export default ApplicationMetricsChart;
