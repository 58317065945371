import PropTypes from 'prop-types';
import React from 'react';

import classnames from '~components/util/classnames';

function BannerMessage(props) {
  const { children, className, onClose, showing = true, type } = props;

  return (
    <div className={classnames({ [className]: className, statusMessage: true, showing, [type]: type })}>
      <div className="message">
        {children}
      </div>
      { onClose && <div className="messageClose" onClick={onClose} /> }
    </div>
  );
}

BannerMessage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  showing: PropTypes.bool,
  type: PropTypes.string
};

export default BannerMessage;
