import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import IString from '~components/general/i-string';
import RangePicker, { rangeToUnit } from '~components/general/range-picker';
import SpaceLink from '~components/general/space-link';
import TimeseriesChart, { formats } from '~components/general/timeseries-chart';
import ChartContainer from '~components/usage/chart-container';
import OverviewCards from '~components/usage/overview-cards';
import SummaryTable from '~components/usage/summary-table';

import * as usageMetrics from '~constants/usage-metrics';

import * as accountOperations from '~operations/account-operations';

import * as uiSelectors from '~selectors/ui-selectors';

import mo, { ISO_DATE } from '~util/mo';
import * as usageUtils from '~util/usage';

export const SpaceID = ({ id }) =>
  id ? <SpaceLink spaceId={id} /> : String(id); // handle the nullspace

SpaceID.propTypes = {
  id: PropTypes.number
};

export const AccountUsage = ({ accountId }) => {
  const dispatch = useDispatch();

  const [ from, setFrom ] = useState();
  const [ rangeSize, setRangeSize ] = useState();
  const [ until, setUntil ] = useState();

  const [ overview, setOverview ] = useState();
  const [ series, setSeries ] = useState();
  const [ summaries, setSummaries ] = useState();

  const utc = useSelector(uiSelectors.getUTC);

  const handleRangeChange = useCallback((from, until, rangeSize) => {
    setFrom(from);
    setRangeSize(rangeSize);
    setUntil(until);
  }, []);

  useEffect(() => {
    const options = { from, until };

    const fetchUsageOverviewData = async () => {
      const overview = await dispatch(
        accountOperations.getAccountUsageOverview(accountId, options)
      );
      setOverview(overview);
    };

    const fetchUsageSummaryData = async () => {
      const summaries = await dispatch(
        accountOperations.getAccountUsageSummary(accountId, 'space', options)
      );

      // nullspace summary data for the account doesn't come back with the list of space usage summaries,
      // since it's not a Real Space. So we get it separately and add it to the top of the list of summaries:
      const nullspace = await dispatch(
        accountOperations.getAccountUsageOverview(accountId, { ...options, space_id: null })
      );

      setSummaries([
        { id: null, name: '(none)', summary: nullspace },
        ...summaries
      ]);
    };

    if (accountId && from && until) {
      fetchUsageOverviewData();
      fetchUsageSummaryData();
    }

  }, [ accountId, dispatch, from, until ]);

  useEffect(() => {
    const fetchUsageSeriesData = async () => {
      const series = await dispatch(
        accountOperations.getAccountUsageSeries(accountId, { from, interval: usageUtils.rangeToInterval[rangeSize], until })
      );
      setSeries(series);
    };

    if (accountId && from && rangeSize && until) {
      fetchUsageSeriesData();
    }

  }, [ accountId, dispatch, from, rangeSize, until ]);

  const tooltipFormatters = {
    label: usageUtils.computeHoursFormatter,
    title: (tips, data) =>
      mo(
        utc,
        data.datasets[tips[0].datasetIndex].data[tips[0].index].x
      ).format(formats.tooltip[rangeToUnit[rangeSize]])
  };

  return (
    <>
      <div className="sectionTitle">
        <IString stringKey="account.usage.title" />
      </div>

      <RangePicker
        earliest={mo(utc).subtract(1, 'year').format(ISO_DATE)}
        onRangeChange={handleRangeChange}
        utc={utc}
      />

      <OverviewCards metrics={usageMetrics.allMetrics} data={overview} />

      <ChartContainer loading={!series} empty={series && series.length === 0}>
        <TimeseriesChart
          chartType="bar"
          datasets={usageUtils.buildDataset(series)}
          tooltipFormatters={tooltipFormatters}
          xAxisFormatter={
            (value, index, ticks) =>
              mo(utc, ticks[index].value).format(formats.axis[rangeToUnit[rangeSize]])
          }
          unit={rangeToUnit[rangeSize]}
        />
      </ChartContainer>

      <SummaryTable
        columns={usageMetrics.allMetrics}
        renderer={SpaceID}
        rows={summaries}
      />
    </>
  );
};

AccountUsage.propTypes = {
  accountId: PropTypes.number
};

export default AccountUsage;
