import { combineReducers } from 'redux';

import * as types from '~actions/action-types';

import { removeOne, update, updateOne, updateMany } from '~reducers/reducer-utils';

const accountsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.CREATE_ACCOUNT_SUCCESS:
    case types.FETCH_ACCOUNT_SUCCESS:
    case types.UPDATE_ACCOUNT_SUCCESS:
      return updateOne(state, action.account);

    case types.FETCH_CURRENT_USER_OWNED_ACCOUNTS_SUCCESS:
    case types.FETCH_ACCOUNT_SEARCH_PAGE_SUCCESS:
      return updateMany(state, action.accounts);

    case types.DELETE_ACCOUNT_SUCCESS:
      return removeOne(state, action.accountId);

    case types.UPDATE_ACCOUNT_LICENSE_SUCCESS:
      return {
        ...state,
        [action.accountId]: {
          ...state[action.accountId],
          licenses: state[action.accountId].licenses.map((l) => {
            if (l.type === action.license.type) {
              return action.license;
            }
            return l;
          })
        }
      };
  }
  return state;
};

const applicationsReducer = (state = { applications: {}, ordered: [], total: undefined }, action) => {
  switch (action.type) {
    case types.FETCH_ACCOUNT_APPLICATIONS_SUCCESS:
      return update(state, {
        applications: updateMany(state.applications, action.applications),
        total: action.total,
        ordered: [ ...state.ordered, ...action.applications.map(a => a.id) ]
      });

    case types.CLEAR_CURRENT_ACCOUNT:
    case types.CLEAR_ACCOUNT_APPLICATIONS:
    case types.FETCH_ACCOUNT_APPLICATIONS_FAILURE:
    case types.CLEAR_ACCOUNT_RESOURCES:
      return update(state, { applications: {}, ordered: [], total: undefined });
  }
  return state;
};

const currentAccountReducer = ( state = { id: undefined }, action) => {
  switch (action.type) {
    case types.SET_CURRENT_ACCOUNT:
      return update(state, { id: action.accountId });

    case types.CLEAR_CURRENT_ACCOUNT:
      return update(state, { id: undefined });
  }
  return state;
};

const subscriptionReducer = (state = {}, action ) => {
  switch (action.type) {
    case types.FETCH_ACCOUNT_SUBSCRIPTION_SUCCESS:
      return update(state, { [action.licenseType]: action.subscription });

    case types.FETCH_ACCOUNT_SUBSCRIPTION_FAILURE:
    case types.DELETE_ACCOUNT_SUBSCRIPTION_SUCCESS:
      return removeOne(state, action.licenseType);

    case types.CLEAR_CURRENT_ACCOUNT:
      return {};
  }
  return state;
};

const subscriptionEntitlementsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_ACCOUNT_SUBSCRIPTION_ENTITLEMENT_STATUS_SUCCESS:
      return update(state, { [action.entitlement]: action.entitlementStatus });

    case types.CLEAR_CURRENT_ACCOUNT:
      return {};
  }
  return state;
};

const entitlementsReducer = ( state = { accountLicenseEntitlements: {} }, action) => {
  switch (action.type) {
    case types.UPDATE_ACCOUNT_LICENSE_ENTITLEMENT_SUCCESS:
    case types.CREATE_ACCOUNT_LICENSE_ENTITLEMENT_SUCCESS:
    case types.FETCH_ACCOUNT_LICENSE_ENTITLEMENT_SUCCESS:
      return {
        ...state,
        accountLicenseEntitlements: {
          ...state.accountLicenseEntitlements,
          [action.accountId]: {
            ...action.accountId in state.accountLicenseEntitlements ?
              state.accountLicenseEntitlements[action.accountId] :
              {},
            [action.licenseType]: {
              ...action.accountId in state.accountLicenseEntitlements &&
                action.licenseType in state.accountLicenseEntitlements[action.accountId] ?
                state.accountLicenseEntitlements[action.accountId][action.licenseType] :
                {},
              [action.entitlement.type]: action.entitlement
            }
          }
        }
      };

    case types.DELETE_ACCOUNT_LICENSE_ENTITLEMENT_SUCCESS:
      return {
        ...state,
        accountLicenseEntitlements: {
          ...state.accountLicenseEntitlements,
          [action.accountId]: {
            ...action.accountId in state.accountLicenseEntitlements ?
              state.accountLicenseEntitlements[action.accountId] :
              {},
            [action.licenseType]: {
              ...action.accountId in state.accountLicenseEntitlements &&
              action.licenseType in state.accountLicenseEntitlements[action.accountId] ?
                state.accountLicenseEntitlements[action.accountId][action.licenseType] :
                {},
              [action.entitlementType]: null
            }
          }
        }
      };
  }
  return state;
};

const searchReducer = ( state = { loading: false, query: undefined, resultIds: [], total: undefined }, action) => {
  switch (action.type) {
    case types.FETCH_ACCOUNT_SEARCH_PAGE:
      return update(state, { loading: true, query: action.query });

    case types.FETCH_ACCOUNT_SEARCH_PAGE_SUCCESS:
      return update(state,
        { loading: false,
          resultIds: (action.offset === 0 ? [] : state.resultIds).concat(action.accounts.map(a => a.id)),
          total: action.total });

    case types.CLEAR_ACCOUNT_SEARCH:
    case types.FETCH_ACCOUNT_SEARCH_PAGE_FAILURE:
      return update(state, { loading: false, query: undefined, resultIds: [], total: undefined });

    case types.DELETE_ACCOUNT_SUCCESS:
      return update(state, { resultIds: state.resultIds.filter(id => id !== action.accountId) });
  }
  return state;
};

const spacesReducer = (state = { ordered: [], total: 0, loading: false }, action) => {
  switch (action.type) {
    case types.FETCH_ACCOUNT_SPACES:
      return update(state, { loading: true });

    case types.FETCH_ACCOUNT_SPACES_SUCCESS:
      return update(state, {
        total: action.total,
        loading: false,
        ordered: [ ...state.ordered, ...action.spaces.map(space => space.id) ]
      });

    case types.FETCH_ACCOUNT_SPACES_FAILURE:
    case types.CLEAR_CURRENT_ACCOUNT:
    case types.CLEAR_CURRENT_ACCOUNT_SPACES:
    case types.CLEAR_ACCOUNT_RESOURCES:
      return update(state, { ordered: [], total: 0, loading: false });

    case types.DELETE_SPACE_SUCCESS:
      return update(state, { ordered: state.ordered.filter(id => id !== action.spaceId) });

  }
  return state;
};

const propertiesReducer = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_ACCOUNT_PROPERTIES_SUCCESS:
      return action.properties;

    case types.DELETE_ACCOUNT_PROPERTY_SUCCESS: {
      return state.filter(n => n.name != action.property);
    }

    case types.CLEAR_CURRENT_ACCOUNT:
      return [];
  }
  return state;
};

const accountContentInitialState = { ordered: [], total: undefined, loading: false };
const accountContentReducer = (state = accountContentInitialState, action) => {
  switch (action.type) {
    case types.FETCH_ACCOUNT_CONTENT:
      return update(state, { ordered: [], loading: true });
    case types.FETCH_ACCOUNT_CONTENT_SUCCESS:
      return update(state, {
        total: action.total,
        loading: false,
        ordered: [ ...state.ordered, ...action.content.map(c => c.id) ]
      });

    case types.FETCH_ACCOUNT_CONTENT_FAILURE:
    case types.CLEAR_CURRENT_ACCOUNT:
    case types.CLEAR_ACCOUNT_RESOURCES:
      return update(state, accountContentInitialState);

    case types.DELETE_CONTENT_SUCCESS:
      return update(state, { ordered: state.ordered.filter(id => id !== action.id) });

  }
  return state;
};

const accountMembersReducer = (state = { members: {}, ordered: [], total: undefined }, action) => {
  switch (action.type) {
    case types.FETCH_ACCOUNT_MEMBERS_PAGE_SUCCESS:
      return update(state, {
        members: updateMany(state.members, action.members),
        total: action.total,
        ordered: [ ...state.ordered, ...action.members.map(m => m.id) ]
      });

    case types.UPDATE_ACCOUNT_CLOUD_ROLE_SUCCESS:
      return {
        ...state,
        members: updateOne(state.members, action.member)
      };

    case types.FETCH_ACCOUNT_MEMBERS_PAGE_FAILURE:
    case types.CLEAR_CURRENT_ACCOUNT:
    case types.CLEAR_ACCOUNT_MEMBERS:
      return update(state, { members: {}, ordered: [], total: undefined });
  }
  return state;
};

const usageCreditsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.CREATE_ACCOUNT_USAGE_CREDITS_SUCCESS:
    case types.FETCH_ACCOUNT_USAGE_CREDITS_SUCCESS:
    case types.UPDATE_ACCOUNT_USAGE_CREDITS_SUCCESS:
      return update(state, { [action.accountId]: { [action.licenseType]: action.usageCredits } });

    case types.DELETE_ACCOUNT_USAGE_CREDITS_SUCCESS: {
      const updated = { ...state };
      delete updated[action.accountId][action.licenseType];
      return updated;
    }

    case types.CLEAR_CURRENT_ACCOUNT:
      return {};
  }
  return state;
};

export default combineReducers({
  accounts: accountsReducer, // object of this shape { [account.id]: account }
  applications: applicationsReducer, // applications, total
  currentAccount: currentAccountReducer,  // currentAccountId
  entitlements: entitlementsReducer,  // accountLcenseEntitlements
  members: accountMembersReducer,  // members, total
  properties: propertiesReducer,  // properties
  search: searchReducer, // loading, query, resultIds, total
  spaces: spacesReducer, // orderedIds, loading, total
  subscription: subscriptionReducer,
  subscriptionEntitlements: subscriptionEntitlementsReducer,
  usageCredits: usageCreditsReducer,
  accountContent: accountContentReducer // orderedIds, total, loading
});
