import { API } from '~api/api';

export const getProjectChildren = (projectId) => {
  return API().get('/projects', {
    params: { filter: `parent_id:eq:${projectId}` }
  });
};

export const getProjectOutputs = (projectId, order) => {
  return API().get('/outputs', {
    params: {
      filter: `project_id:eq:${projectId}`,
      order,
      count: 100
    }
  });
};

export const getProjectSessions = (projectId) => {
  return API().get(`/projects/${projectId}/sessions`);
};

export const updateProject = (projectId, project = {}) => API().patch(`/projects/${projectId}`, project);
