import PropTypes from 'prop-types';
import React, { useState } from 'react';

import InfoToggle from '~components/general/info-toggle';

/**
 * Title component with an optional subtitle, followed by a section of toggleable info text.
 *
 * @param   {node} info     Info to display in the expandable section.
 * @param   {node} title    Title to display (required)
 * @param   {node} subtitle Smaller subtitle to display after the title
 * @returns                 Component to render small title followed by an optional subtitle and toggleable information
 */
const InfoTitle = ({ info, title, subtitle }) => {
  const [ open, setOpen ] = useState(false);
  return (
    <>
      <div className="chartHeader flexSpaceAfter">
        <div className="flexAlignBaseline flexSpaceAfter flexAllowWrap">
          <div className="sectionTitle small">
            {title}
          </div>
          { subtitle &&
            <div className="sectionTitleDetails small lightText">
              {subtitle}
            </div>
          }
        </div>
        { info && <InfoToggle className="first" onToggle={() => setOpen(!open)} toggled={open} />}
      </div>

      { info && open &&
        <div className="toggleableInfo">
          {info}
        </div>
      }
    </>
  );
};

InfoTitle.propTypes = {
  info: PropTypes.node,
  subtitle: PropTypes.node,
  title: PropTypes.node.isRequired
};

export default InfoTitle;
