import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import * as spaceActions from '~actions/space-actions';

import AccountLink from '~components/general/account-link';
import ActionButton from '~components/general/action-button';
import GettingMore from '~components/general/getting-more';
import IString from '~components/general/i-string';
import Search from '~components/general/search';
import SpaceLink from '~components/general/space-link';
import Time from '~components/general/time';

import { translate } from '~i18n/localize';

import * as spaceSelectors from '~selectors/space-selectors';

const SpaceSearchResult = ({ space }) =>
  <tr>
    <td><SpaceLink spaceId={space.id} /></td>
    <td>{space.name}</td>
    <td><AccountLink accountId={space.account_id} /></td>
    <td><Time value={space.created_time} /></td>
    <td><Time value={space.updated_time} /></td>
    <td>{space.state}</td>
  </tr>;

SpaceSearchResult.propTypes = {
  space: PropTypes.shape({
    account_id: PropTypes.number,
    created_time: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    state: PropTypes.string,
    updated_time: PropTypes.string
  })
};

export function SpaceSearch() {
  const dispatch = useDispatch();
  const loading = useSelector(spaceSelectors.isSearchLoading);
  const results = useSelector(spaceSelectors.getSearchResults);

  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const search = params.get('query');

  const empty = !loading && results?.length === 0;

  useEffect(() => {
    dispatch(search ? spaceActions.setSpaceSearch(search) : spaceActions.clearSpaceSearch());
  }, [ search, dispatch ]);

  const handleSearch = (newSearch) => {
    history.replace({ search: newSearch ? `?query=${newSearch}` : '' });
  };

  const handleListGlobalTemplateSource = () => {
    history.replace({ search: '?query=listGlobalTemplateSources' });
  };

  let titleKey = search ? 'results' : 'prompt';
  if (search === 'listGlobalTemplateSources') {
    titleKey = 'global_template_sources';
  }

  return (
    <>
      <div className="sectionTitle flex">
        <IString stringKey={`space.search.title.${titleKey}`} />
        <div className="actionBar inline showTitles">
          <ActionButton
            className="showTemplateSources"
            title={translate('space.search.list_global_template_sources')}
            onClick={handleListGlobalTemplateSource}
          />
        </div>
      </div>

      <div className="section">
        <Search value={search} submit={handleSearch} />
      </div>

      { search ?
        <div className="tableContainer">
          { empty ?
            <div className="emptyListMessage"><IString stringKey="general.empty" /></div>
            : <>
              <table className="contentListing">
                <thead>
                  <tr>
                    <th><IString stringKey="general.space" /></th>
                    <th><IString stringKey="general.name" /></th>
                    <th><IString stringKey="general.account" /></th>
                    <th><IString stringKey="general.created" /></th>
                    <th><IString stringKey="general.updated" /></th>
                    <th><IString stringKey="general.state" /></th>
                  </tr>
                </thead>
                <tbody>
                  { results && results.map(t => <SpaceSearchResult space={t} key={t.id} />) }
                </tbody>
              </table>
              { loading && <GettingMore label={<IString stringKey="general.loading" />} /> }
            </>
          }
        </div>
        : <div className="emptyListMessage"><IString stringKey="space.search.prompt" /></div> }
    </>
  );
}

export default SpaceSearch;
