import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as dialogs from '~components/dialogs/dialog-types';
import ModalDialog from '~components/dialogs/modal-dialog';
import IString from '~components/general/i-string';
import classnames from '~components/util/classnames';

import * as accountType from '~constants/account-type';

import { translate } from '~i18n/localize';

import * as contentOperations from '~operations/content-operations';

const SHINYAPPS = 'shinyapps';
const CLOUD = 'cloud';

export function TransferAccountDialog(props) {
  const { data: { account }, dispatch, onSubmit } = props;

  // initially true so that content is only rendered after useEffect runs
  const [ loading, setLoading ] = useState(true);

  const [ branding, setBranding ] = useState(SHINYAPPS);
  const [ email, setEmail ] = useState('');
  const [ error, setError ] = useState();

  const [ nullspaceContent, setNullspaceContent ] = useState();
  const [ nullspaceContentTotal, setNullspaceContentTotal ] = useState(0);

  const transferable = nullspaceContentTotal < 1;

  useEffect(() => {
    const getNullspaceContent = async () => {
      setLoading(true);

      // individual accounts are the only ones with a null space
      if (account.type === accountType.INDIVIDUAL) {
        const data = await dispatch(contentOperations.getAccountOwnerNullspaceContent(account));
        setNullspaceContent(data.content);
        setNullspaceContentTotal(data.total);
      } else {
        setNullspaceContent();
        setNullspaceContentTotal(0);
      }

      setLoading(false);
    };

    getNullspaceContent();
  }, [ account, dispatch ]);

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!event.target.newOwner.validity.valid) {
      setError('account.details.dialog.transfer.error');
      return;
    }

    onSubmit?.(email, branding);
  };

  return (
    <ModalDialog type={dialogs.TRANSFER_ACCOUNT_DIALOG}>
      <div className="purpose">
        <IString stringKey="account.details.dialog.transfer.purpose" placeholders={{ id: account?.id }} />
      </div>

      {!loading && <form noValidate onSubmit={handleSubmit}>
        { transferable ?
          <>
            <p className="details">
              <IString
                placeholders={{
                  email: account?.owner?.email
                }}
                stringKey="account.details.dialog.transfer.details"
              />
            </p>

            <p className="details"><IString stringKey="account.details.dialog.transfer.branding.details" /></p>

            <fieldset className="chunk">
              <label htmlFor="newOwner" className={classnames({ fieldLabel: true, error })}>
                <IString stringKey="account.details.dialog.transfer.email" />
                {error && <IString className="errorText" stringKey={error} aria-live="polite" />}
              </label>
              <input
                className="full"
                id="newOwner"
                name="newOwner"
                onChange={handleChange}
                placeholder={translate('account.details.dialog.transfer.placeholder')}
                required
                type="email"
                value={email}
              />
            </fieldset>
            <div className="controlGroup">
              <fieldset className="chunk">
                <legend>
                  <IString stringKey="account.details.dialog.transfer.branding.title" />
                </legend>
                <div className="smallMarginBelow">
                  <input
                    checked={branding === CLOUD}
                    id="cloudBranding"
                    name="branding"
                    onChange={e => setBranding(e.target.value)}
                    required
                    type="radio"
                    value={CLOUD}
                  />
                  <label htmlFor="cloudBranding">
                    <IString stringKey="account.details.dialog.transfer.branding.cloud" />
                  </label>
                </div>
                <div>
                  <input
                    checked={branding === SHINYAPPS}
                    id="shinyappsBranding"
                    name="branding"
                    onChange={e => setBranding(e.target.value)}
                    required
                    type="radio"
                    value={SHINYAPPS}
                  />
                  <label className="midMarginRight" htmlFor="shinyappsBranding">
                    <IString stringKey="account.details.dialog.transfer.branding.shinyapps" />
                  </label>
                </div>
              </fieldset>
            </div>

            <div className="details">
              <div className="chunk messageContainer mark important">
                <div className="message">
                  <p>
                    <IString
                      stringKey="account.details.dialog.transfer.transferDetails"
                      trustPlaceholders
                      placeholders={{ email: account?.owner?.email }}
                    />
                  </p>
                </div>
              </div>
            </div>
          </>
        :
          <div className="marginBelow">
            <p>
              <IString stringKey="account.details.dialog.transfer.content.title" />
            </p>
            <div className="smallMarginBelow">
              <IString stringKey="account.details.dialog.transfer.content.total" placeholders={{ total: nullspaceContentTotal }} />
            </div>
            <table className="contentListing allTightRight">
              <thead>
                <tr>
                  <th><IString stringKey="general.id" /></th>
                  <th><IString stringKey="general.name" /></th>
                  <th><IString stringKey="general.status" /></th>
                </tr>
              </thead>
              <tbody>
                {nullspaceContent?.map(c =>
                  <tr key={c.id}>
                    <td>{c.id}</td>
                    <td>{c.name}</td>
                    <td>{c.state}</td>
                  </tr>)}
              </tbody>
            </table>
          </div>
        }

        <div className="actions">
          <button type="submit" disabled={!transferable}><IString stringKey="general.save" /></button>
        </div>
      </form>}

    </ModalDialog>
  );
}

TransferAccountDialog.propTypes = {
  data: PropTypes.shape({
    account: PropTypes.shape({
      id: PropTypes.number,
      owner: PropTypes.shape({
        email: PropTypes.string
      }),
      type: PropTypes.oneOf(accountType.ALL_TYPES)
    })
  }),
  dispatch: PropTypes.func,
  onSubmit: PropTypes.func
};

export default connect()(TransferAccountDialog);
