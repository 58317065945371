import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import IString from '~components/general/i-string';

function AccountMembersMenu({ accountId }) {
  return (
    <div className="menu showOnMobile">
      <div className="menuItems">
        <NavLink to={`/accounts/${accountId}/members/cloud`} className="menuItem" activeClassName="active">
          <IString stringKey="account.members.menu.cloud" />
        </NavLink>
        <NavLink to={`/accounts/${accountId}/members/shinyapps`} className="menuItem" activeClassName="active">
          <IString stringKey="account.members.menu.shinyapps" />
        </NavLink>
      </div>
    </div>
  );
}

AccountMembersMenu.propTypes = {
  accountId: PropTypes.number
};

export default AccountMembersMenu;
