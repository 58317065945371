export const CONNECTIONS = 'connections';
export const CPU = 'cpu';
export const MEMORY = 'memory';
export const WORKERS = 'workers';

export const metricTypes = {
  [CONNECTIONS]: 'container_status',
  [CPU]: 'docker_container_cpu',
  [MEMORY]: 'docker_container_mem',
  [WORKERS]: 'container_status'
};

export const metrics = {
  [CONNECTIONS]: 'connect_count',
  [CPU]: 'usage_in_usermode',
  [MEMORY]: 'total_rss',
  [WORKERS]: 'connect_procs'
};

export const all = Object.keys(metricTypes);
