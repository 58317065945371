import { call, put, select } from 'redux-saga/effects';

import * as contentActions from '~actions/content-actions';
import * as uiActions from '~actions/ui-actions';
import * as userActions from '~actions/user-actions';

import * as api from '~api';
import * as contentAPI from '~api/content';

import * as statusTypes from '~components/status/status-types';

import * as userSelectors from '~selectors/user-selectors';

export function* searchUsers(action) {
  const { query, offset } = action;
  try {
    const { data } = yield call(api.listUsers, query, offset);
    yield put(userActions.fetchUserSearchPageSuccess(data.users, data.total, offset));
  } catch (err) {
    const message = err.response ? api.getErrorMessage(err.response) : err.toString();
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
    yield put(userActions.fetchUserSearchPageFailure());
  }
}

export function* createUser(action) {
  try {
    const { data } = yield call(api.createUser, action.user);
    yield put(userActions.createUserSuccess(data));
    yield put(uiActions.closeDialog());
  } catch (err) {
    const message = api.getErrorMessage(err.response);
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
  }
}

export function* fetchUser(action) {
  try {
    const { data } = yield call(api.getUser, action.userId);
    yield put(userActions.fetchUserSuccess(data));
  } catch (err) {
    const message = err.response ? api.getErrorMessage(err.response) : err.toString();
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
  }
}

export function* fetchCurrentUserOwnedAccounts() {
  try {
    const userId = yield select(userSelectors.getCurrentUserId);
    const { data } = yield call(api.listAccounts, { owner_id: userId });
    yield put(userActions.fetchCurrentUserOwnedAccountsSuccess(data.accounts));
  } catch (err) {
    const message = api.getErrorMessage(err.response);
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
  }
}

export function* fetchUserAuthoredContent(action) {
  const { userId, order } = action;
  try {
    const { data } = yield call(contentAPI.getContentByAuthor, userId, order);
    yield put(contentActions.fetchContentByAuthorSuccess(data.content));
  } catch (err) {
    const message = err.response ? api.getErrorMessage(err.response) : err.toString();
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
  }
}
