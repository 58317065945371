import * as spaceActions from '~actions/space-actions';
import * as uiActions from '~actions/ui-actions';

import * as spaceApi from '~api/spaces';

/**
 * Thunk action creator to retrieve connections in a space
 *
 * @param   {number}   spaceId ID of the space to retrieve connections for
 * @returns {function}         Thunk to retrieve connections in a space
 */
export const getConnections = (spaceId, offset = 0) => async dispatch => {
  try {
    const { data } = await spaceApi.listSpaceConnections(spaceId, null, null, offset);
    dispatch(spaceActions.fetchSpaceConnectionsSuccess(spaceId, data.connections, data.total));
  } catch (error) {
    dispatch(uiActions.setErrorMessage(error));
  }
};

/**
 * Thunk action creator to update a space
 *
 * @param   {number}   spaceId   ID of the space to update
 * @param   {object}   spaceData Updates to apply
 * @returns {function}           Thunk to update a space.
 *                               Shows an error message and re-throwsif the update fails.
 */
export const updateSpace = (spaceId, spaceData) => async dispatch => {
  try {
    const { data } = await spaceApi.updateSpace(spaceId, spaceData);
    dispatch(spaceActions.fetchSpaceSuccess(data));
  } catch (error) {
    dispatch(uiActions.setErrorMessage(error));
    throw error;
  }
};
