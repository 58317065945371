import * as types from '~actions/action-types';

export const setCurrentTask = (taskData) => ({
  type: types.SET_CURRENT_TASK,
  taskData
});

export const clearCurrentTask = () => ({
  type: types.CLEAR_CURRENT_TASK
});
