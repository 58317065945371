import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as uiActions from '~actions/ui-actions';

import * as dialogTypes from '~components/dialogs/dialog-types';
import ModalDialog from '~components/dialogs/modal-dialog';
import IString from '~components/general/i-string';
import * as statusTypes from '~components/status/status-types';

import * as applicationOperations from '~operations/application-operations';

export default function ExportApplicationDialog({ data: { application } }) {
  const dispatch = useDispatch();
  const [ pending, setPending ] = useState(true);
  const [ downloadURL, setDownloadURL ] = useState();

  useEffect(() => {
    (async () => {
      try {
        const url = await dispatch(applicationOperations.exportApplication(application.id));
        if (url) {
          setDownloadURL(url);
        } else {
          dispatch(uiActions.setStatusMessage({
            type: statusTypes.ERROR_MESSAGE,
            message: 'application.export.errorMessage'
          }));
        }
      } catch (error) {
        /* ignored */
      } finally {
        setPending(false);
      }
    })();
  }, [ application.id, dispatch ]);

  const handleDownload = () => dispatch(uiActions.closeDialog());

  const isInColdStorage = application.hibernating;
  const progressBarStatus = isInColdStorage ? 'autoTwoMinutes' : 'autoThirty';
  const titleKey = downloadURL ? 'ready' : 'downloading';
  return (
    <ModalDialog type={dialogTypes.EXPORT_APPLICATION_DIALOG}>
      <div className="purpose"><IString stringKey={`application.export.title.${titleKey}`} /></div>
      <div className="formSection noSeparator">
        { !downloadURL && <p><IString placeholders={{ applicationId: application.id }} stringKey="application.export.creating" /></p> }
        <div className="progressBarBackground">
          <div className={`progressBar ${pending ? progressBarStatus : 'done'}`} />
        </div>
        <div className="details">
          {downloadURL
            ? <p><IString stringKey="application.export.created" placeholders={{ applicationId: application.id }} /></p>
            : <>
              {isInColdStorage &&
                <div className="messageContainer mark important marginAbove">
                  <div className="message">
                    <IString stringKey="application.export.applicationIsCold" />
                  </div>
                </div>
              }
            </>
          }
        </div>
      </div>
      {downloadURL &&
        <div className="actions">
          <a className="button default" href={downloadURL} download={`application-${application.id}`} onClick={handleDownload}>
            <IString stringKey="general.download" />
          </a>
        </div>
      }
    </ModalDialog>
  );
}

ExportApplicationDialog.propTypes = {
  data: PropTypes.shape({
    application: PropTypes.shape({
      hibernating: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};
