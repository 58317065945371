import { API, createAdvancedSearchParams, listParamsToString } from '~api/api';

export const getContentByAccountId = (accountId, order) =>
  API().get('/content', {
    params: {
      filter: `account_id:eq:${accountId}`,
      order
    }
  });

export const getContentByAuthor = (userId, order) => {
  return API().get('/content', {
    params: {
      filter: `author_id:eq:${userId}`,
      order
    }
  });
};

export const getContentById = (id) => {
  return API().get(`/content/${id}`);
};

export const getContentBySourceId = (sourceId) =>
  API().get(`/content?filter=${encodeURIComponent(`source_id:eq:${sourceId}`)}`);

/**
 * Executes a GET request to fetch all content, using the provided filters.
 *
 * @param   {object}                      filters Any filters to apply
 * @param   {object}                      order   Any ordering rules to apply
 * @param   {number}                      offset  Offset, for pagination. Defaults to 0.
 * @param   {number}                      count   Count (aka page size), for pagination. Defaults to 25.
 * @returns {Promise<AxiosResponse<any>>}         A promise for the Axios response that contains the paginated content.
 */
export const getFilteredContentList = (filters, order = null, offset = 0, count = 25) => {
  const query = filters || order ? `?${listParamsToString(filters, order)}` : '';  // include a ? only if we've got a query string
  return API().get(`/content${query}`, {
    params: { offset, count }
  });
};

// list content (returns paginated list of content)
export const listContent = (filters = {}, offset = 0, count = 25) => {
  return API().get('/content?' + listParamsToString(filters), {
    params: { offset, count }
  });
};

export const searchContent = (search, offset = 0, count = 25) => {
  if (!search?.trim()) {
    return Promise.reject();
  }

  return API().get('/content', {
    params: {
      ...createAdvancedSearchParams(search, [ 'name' ]),
      count,
      offset
    }
  });
};
