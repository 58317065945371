/**
 * Builds a usage dataset object for a single timeseries, to be used in a timeseries chart.
 *
 * @param   {array} data The timeseries data to be charted.
 * @returns              A dataset object to pass to a chart.js chart.
 */
export const buildDataset = (data) =>
  [ {
    backgroundColor: '#99CC6A',
    barPercentage: 0.66,
    borderColor: '#99CC6A',
    borderWidth: 1,
    data,
    fill: false,
    hoverBackgroundColor: '#99CC6A',
    hoverBorderColor: '#99CC6A',
    pointRadius: 1
  } ];

/**
 * Callback to format a "usage" tooltip label for data points in a usage chart,
 * where the values being plotted are compute hours.
 *
 * @param   {object} tip
 * @returns              A string suitable for use as the tooltip label
 */
export const computeHoursFormatter = (tip) => `${tip.yLabel} hr`;

// map of range keys (drop down options) to intervals the API can understand
// for example, if the range size is "day", we want to render a chart of
// single hours, and so we need to request an interval of "1h".
export const rangeToInterval = {
  day: '1h',
  month: '1d',
  week: '1d'
};
