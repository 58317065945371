import * as accountActions from '~actions/account-actions';
import * as uiActions from '~actions/ui-actions';

import * as accountAPI from '~api/accounts';
import * as api from '~api/api';
import * as apiUtils from '~api/api-utils';
import * as applicationAPI from '~api/applications';

import * as statusTypes from '~components/status/status-types';

import * as applicationStatus from '~constants/application-status';

import { translate } from '~i18n/localize';

import * as taskOperations from '~operations/task-operations';

import * as accountSelectors from '~selectors/account-selectors';
import * as uiSelectors from '~selectors/ui-selectors';

import * as timeseriesUtils from '~util/timeseries';

/**
 * Thunk action creator to get the account overview data for a given account
 *
 * @param   {number} accountId The ID of the account for which to get usage data
 * @param   {object} options   from, until, TODO: what else
 * @returns                    A thunk that gets the usage data for an account
 */
export const getAccountUsageOverview = (accountId, options) => async () => {
  const { data } = await accountAPI.getAccountUsageSummary(accountId, options);
  return {
    ...data.results,
    compute: Number(data.results.compute.toFixed(2))
  };
};

/**
 * Thunk action creator to get the account usage series data for a given account
 *
 * @param   {number} accountId The ID of the account for which to get usage data
 * @param   {object} options   from, until, rangeSize (which is turned into interval), TODO: what else
 * @returns                    A thunk that gets the usage data for an account
 */
export const getAccountUsageSeries = (accountId, options) => async (dispatch, getState) => {
  const utc = uiSelectors.getUTC(getState());

  const { data } = await accountAPI.getAccountUsageSeries(accountId, {
    ...options,
    interval: utc ? options.interval : '1h' // non-utc data must be aggregated manually
  });
  let series = [];
  const values = data?.results?.[0]?.values;
  if (values) {
    if (utc) {
      series = timeseriesUtils.translate(values, 'compute', v => Number(v.toFixed(2)));
    } else {
      series = timeseriesUtils.bucketize(values, 'compute', v => Number(v.toFixed(2)), timeseriesUtils.intervalToGrain(options.interval));
    }
  }

  return series;
};

/**
 * Thunk action creator to get the account summary (table) data for a given account
 *
 * @param   {number} accountId The ID of the account for which to get usage data
 * @param   {string} facet     How to group the data
 * @param   {object} options   from, until, TODO: what else
 * @returns                    A thunk that gets the usage data for an account
 */
export const getAccountUsageSummary = (accountId, facet, options) => async () => {
  const groupby = `${facet}_id`;
  const { data } = await accountAPI.getAccountUsageSummary(accountId, { ...options, groupby });
  const summaries =  data.results.map(result => ({
    ...result,
    id: result[groupby],
    name: result[`${facet}_name`],
    summary: {
      ...result.summary,
      compute: Number(result.summary.compute.toFixed(2)),
      content_type: result.content_type && (translate(`general.${result.content_type}`) || undefined)
    }
  }));

  return summaries;
};

/**
 * Thunk action creator to transfer the owner of an account
 *
 * @param   {number}  accountId The ID of the account to transfer ownership for
 * @param   {boolean} email     The email of the new account owner
 * @returns                     A thunk that changes the owner of an account
 */
export const transferAccount = (accountId, email, branding) => async (dispatch) => {
  try {
    const { data } = await accountAPI.transferAccount(accountId, email, branding);
    dispatch(accountActions.updateAccountSuccess(data));
  } catch (err) {
    const message = api.getErrorMessage(err.response);
    dispatch(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
  }
};

/**
 * Thunk action creator to downgrade a shinyapps.io subscription to Free.
 * Before downgrading the subscription, this will terminate all shinaypps.io applications.
 * This process will fail if the account has an active Stripe subscription: that
 * must be manually canceled first.
 *
 * See:
 * https://positpbc.atlassian.net/l/cp/LzAtm5NU#Cancelling-a-%5BinlineCard%5D--subscription
 *
 * @param   {number} accountId The ID of the account to downgrade
 * @returns                    A thunk that downgrades a shinyapps.io subscription
 */
export const downgradeShinyappsSubscription = (accountId) => async (dispatch, getState) => {
  const account = accountSelectors.getAccount(getState(), accountId);

  if (!account) {
    throw new Error('Something went wrong: account not found.');
  }

  if (account.subscription.plan) {
    throw new Error('This account has an active Stripe subscription, which must be canceled first.');
  }

  const filter = [ `account_id:eq:${accountId}`, 'type:shiny', `status:ne:${applicationStatus.TERMINATED}` ];

  const { data } = await applicationAPI.getApplications({ filter });

  if (data?.total > 0) {

    const taskIds = [];
    // do these serially so we can update status in a way that the user can see
    // these tasks don't finish immediately anyway, so making these calls serially
    // (instead of all at once) doesn't really slow things down too much, and may
    // lead to less polling over all
    for await (const [ index, app ] of data.applications.entries()) {
      dispatch(uiActions.setActivityMessage(`Terminating application #${app.id} (${index + 1} of ${data.total})`));
      const { data: { task_id } } = await applicationAPI.terminateApplication(app.id);
      taskIds.push(task_id);
    }

    dispatch(uiActions.setActivityMessage('Waiting for applications to finish terminating'));

    // tasks take time to complete. if we poll them serially, this may give the later ones a bit
    // more time to finish, which may mean fewer calls than if we, say, used Promise.all() here
    for await (const taskId of taskIds) {
      await dispatch(taskOperations.checkUntilDone(taskId));
    }

    const remaining = await apiUtils.countItems(applicationAPI.getApplications, { filter });

    if (remaining > 0) {
      throw new Error('Subscription was not downgraded: unterminated applications remain.');
    }
  }

  dispatch(uiActions.setActivityMessage('Downgrading subscription'));
  await accountAPI.updateAccountShinyappsSubscription(accountId, { type: 'free' });

  const { data: accountData } = await accountAPI.getAccount(accountId);
  dispatch(accountActions.fetchAccountSuccess(accountData));
};

/**
 * Thunk action creator to upgrade a Free shinyapps.io subscription to Professional.
 * This process will fail if the account has an active Stripe subscription: that
 * must be manually canceled first.
 *
 * See:
 * https://positpbc.atlassian.net/l/cp/LzAtm5NU#Upgrading-to-a-PO-(aka-invoiced-plan)-from-free-subscription
 *
 * @param   {number} accountId The ID of the account to upgrade
 * @returns                    A thunk that upgrades a shinyapps.io subscription
 */
export const upgradeShinyappsSubscription = (accountId) => async (dispatch, getState) => {
  const account = accountSelectors.getAccount(getState(), accountId);

  if (!account) {
    throw new Error('Something went wrong: account not found.');
  }

  if (account.subscription.plan) {
    throw new Error('This account has an active Stripe subscription, which must be canceled first.');
  }

  await accountAPI.updateAccountShinyappsSubscription(accountId, { type: 'professional' });

  const { data: updatedAccount } = await accountAPI.getAccount(accountId);
  dispatch(accountActions.fetchAccountSuccess(updatedAccount));
};

/**
 * Thunk action creator to create an account license entitlement
 *
 * @param   {number} accountId The ID of the account to create the license entitlement for
 * @returns                    A thunk that creates an account license entitlement
 */
export const createAccountLicenseEntitlement = (accountId, licenseType, entitlementName, entitlementData) => async (dispatch) => {
  const { data } = await accountAPI.createAccountLicenseEntitlement(accountId, licenseType, entitlementName, entitlementData);
  dispatch(accountActions.createAccountLicenseEntitlementSuccess(accountId, licenseType, data));

  return data;
};

/**
 * Thunk action creator to delete an account license entitlement
 *
 * @param   {number} accountId The ID of the account to delete the license entitlement for
 * @returns                    A thunk that deletes an account license entitlement
 */
export const deleteAccountLicenseEntitlement = (accountId, licenseType, entitlementName) => async (dispatch) => {
  await accountAPI.deleteAccountLicenseEntitlement(accountId, licenseType, entitlementName);
  dispatch(accountActions.deleteAccountLicenseEntitlementSuccess(accountId, licenseType, entitlementName));
  // kick off an action that will get the current state of this entitlement
  dispatch(accountActions.fetchAccountLicenseEntitlement(accountId, licenseType, entitlementName));
};
