import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import * as accountActions from '~actions/account-actions';
import * as uiActions from '~actions/ui-actions';

import * as dialogsTypes from '~components/dialogs/dialog-types';
import ActionButton from '~components/general/action-button';
import IString from '~components/general/i-string';

function AccountPropertyItem({ accountId, dispatch, property }) {

  const handleDelete = (e) => {
    e.preventDefault();

    dispatch(uiActions.openDialog({
      type: dialogsTypes.CONFIRM_DIALOG,
      data: {
        purpose: <IString stringKey="account.properties.modal.delete.purpose" />,
        details: <IString stringKey="account.properties.modal.delete.details" placeholders={{ name: property.name }} />,
        confirm: () => {
          dispatch(accountActions.deleteAccountProperty(accountId, property.name));
          dispatch(uiActions.closeDialog()); // close confirm dialog
        }
      }
    }));
  };

  const handleEdit = (e) => {
    e.preventDefault();

    dispatch(uiActions.openDialog({
      type: dialogsTypes.ACCOUNT_PROPERTIES_CREATE_EDIT_DIALOG,
      data: {
        create: false,
        property
      },
      onSubmit: (property) => {
        dispatch(accountActions.createAccountProperty(accountId, property));
        dispatch(uiActions.closeDialog());
      }
    }));
  };

  return (
    <tr>
      <td>{property.name}</td>
      <td>{`${property.value}`}</td>
      <td>
        <div className="actionBar inline">
          <ActionButton
            className="edit"
            title={<IString stringKey="general.edit" />}
            onClick={handleEdit}
          />
          <ActionButton
            className="delete noTitle"
            title={<IString stringKey="general.delete" />}
            onClick={handleDelete}
          />
        </div>
      </td>
    </tr>
  );
}

AccountPropertyItem.propTypes = {
  accountId: PropTypes.number,
  dispatch: PropTypes.func,
  property: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number
    ])
  })
};

export default connect()(AccountPropertyItem);
