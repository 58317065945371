import PropTypes from 'prop-types';
import React from 'react';

import { useSortContext } from '~components/general/sort/sort-context';
import classnames from '~components/util/classnames';

import * as sortOrder from '~constants/sort-order';

/**
 * A <th> component that sets the sorting order for its column contents.
 * This component must be used within a <table> component.
 *
 * This components uitlizes the SortContext to manage the state of its values.
 *
 * The parent <table> component must be wrapped in a <SortContextProvider>
 * to access the `sortBy` and `order` values.
 *
 * @param  {JSXElement}  children   Content to display in the table header
 * @param  {string}      field      The API field name this header maps to (sorting key)
 * @param  {object}      otherProps All of the remaining props to spread on the <th>
 * @return {JSX.Element}            A <th> component to use when the column is sortable
 */
export function SortableHeader({ children, field, ...otherProps }) {
  const { order, sortBy, setSortBy } = useSortContext();

  const sortOrderClass = sortOrder.orderClass(order);

  return (
    <th
      {...otherProps}
      className={classnames({ [`sort ${sortOrderClass}`]: sortBy === field })}
      onClick={() => setSortBy(field)}
    >
      {children}
    </th>
  );
}

SortableHeader.propTypes = {
  children: PropTypes.node,
  field: PropTypes.string
};

export default SortableHeader;
