import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import IString from '~components/general/i-string';

function SpaceMenu(props) {

  const { spaceId } = props;

  return (
    <div className="menu showOnMobile">
      <div className="menuItems">
        <NavLink to={`/spaces/${spaceId}/`} className="menuItem" exact activeClassName="active">
          <IString stringKey="space.menu.details" />
        </NavLink>
        <NavLink to={`/spaces/${spaceId}/members`} className="menuItem" activeClassName="active">
          <IString stringKey="space.menu.members" />
        </NavLink>
        <NavLink to={`/spaces/${spaceId}/content`} className="menuItem" activeClassName="active">
          <IString stringKey="general.menu.content" />
        </NavLink>
        <NavLink to={`/spaces/${spaceId}/usage`} className="menuItem" activeClassName="active">
          <IString stringKey="space.menu.usage" />
        </NavLink>
        <NavLink to={`/spaces/${spaceId}/connections`} className="menuItem" activeClassName="active">
          <IString stringKey="space.menu.connections" />
        </NavLink>
      </div>
    </div>
  );
}

SpaceMenu.propTypes = {
  spaceId: PropTypes.number
};

export default SpaceMenu;
