import { API, createAdvancedSearchParams, makeParams, listParamsToString } from '~api/api';

export const searchSpaces = (search) => {
  if (!search?.trim()) {
    return Promise.reject();
  }

  return API().get('/spaces', {
    params: createAdvancedSearchParams(search, [ 'name' ])
  });
};

export const getSpace = (id) => {
  return API().get(`spaces/${id}`);
};

export const deleteSpace = (id) => {
  return API().delete(`/spaces/${id}`);
};

export const updateSpace = (id, data) => {
  return API().patch(`/spaces/${id}`, data);
};

export const getSpaceMembers = (spaceId, options = {}) => {
  return API().get(`/spaces/${spaceId}/members${makeParams(options)}`);
};

// fetch paginated list of space members
// NOTE:the shape of the order param: {[sortBy]: sortOrder}
export const listSpaceMembers = (space_id, filters = {}, order = null, offset = 0, count = 25) => {
  const query = filters ? `?${listParamsToString(filters, order)}` : '';  // include a ? only if we've got a query string
  return API().get(`/spaces/${space_id}/members${query}`, {
    params: { offset, count }
  });
};

export const listSpaces = (filters = {}, offset = 0, count = 25) => {
  const query = filters ? `?${listParamsToString(filters)}` : '';  // include a ? only if we've got a query string
  return API().get(`/spaces${query}`, {
    params: { offset, count }
  });
};

export const updateSpaceRole = (spaceId, memberId, role) => {
  return API().patch(`/spaces/${spaceId}/members/${memberId}`, { space_role: role });
};

/**
 * Executes a GET request to retrieve connections for a space
 *
 * @param   {number}                      spaceId ID of the space to retrieve connections for
 * @param   {object}                      filters Any filters to apply
 * @param   {object}                      order   Any ordering rules to apply
 * @param   {number}                      offset  Offset, for pagination. Defaults to 0
 * @param   {number}                      count   Number of connections to fetch (aka page size). Defaults to 25
 * @returns {Promise<AxiosResponse<any>>}         A promise for the Axios response that contains the paginated content.
 */
export const listSpaceConnections = (spaceId, filters = null, order = null, offset = 0, count = 25) => {
  let query = listParamsToString(filters, order);
  query = query ? `?${query}` : query;  // include a ? only if we've got a query string
  return API().get(`/spaces/${spaceId}/connections${query}`, {
    params: { offset, count }
  });
};
