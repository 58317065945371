import PropTypes from 'prop-types';
import React, { useEffect  } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as accountActions from '~actions/account-actions';
import * as uiActions from '~actions/ui-actions';

import CloudSubscription from '~components/accounts/cloud-subscription';
import ShinyappsSubscription from '~components/accounts/shinyapps-subscription';
import * as dialogs from '~components/dialogs/dialog-types';
import ActionButton from '~components/general/action-button';
import IString from '~components/general/i-string';
import MarkedMessage, { marks } from '~components/general/marked-message';

import * as licenseName from '~constants/license-name';
import * as licenseType from '~constants/license-type';

import { translate } from '~i18n/localize';

import * as accountOperations from '~operations/account-operations';

import * as accountSelectors from '~selectors/account-selectors';

function AccountSubscription({ account }) {
  const dispatch = useDispatch();

  const license = useSelector(accountSelectors.getCurrentAccountCloudLicense);
  const subscription = useSelector(accountSelectors.getCurrentAccountCloudSubscription);

  useEffect(() => {
    dispatch(accountActions.fetchAccountSubscription(account.id, licenseType.CLOUD));
  }, [ dispatch, account.id ]);

  const handleDeleteCloudSubscription = (e) => {
    e.preventDefault();

    dispatch(uiActions.openDialog({
      type: dialogs.CONFIRM_DIALOG,
      data: {
        purpose: <IString stringKey="account.subscription.delete.title" placeholders={{ id: account.id }} />,
        details: <>
          <p>
            <IString
              placeholders={{
                subscriptionLicense: subscription.metadata.license_name,
                id: account.id
              }}
              stringKey="account.subscription.delete.cloud.details"
            />
          </p>
          <p><IString stringKey={`account.subscription.delete.cloud.result.${licenseName.isCloudCustom(license) ? 'custom' : 'default'}`} /></p>
        </>,
        confirm: () => {
          dispatch(accountActions.deleteAccountSubscription(account.id, licenseType.CLOUD));
          dispatch(uiActions.closeDialog());
          dispatch(uiActions.setSuccessMessage('account.subscription.delete.cloud.success'));
        }
      }
    }));
  };

  const checkShinyappsSubscriptionManageability = (action) => {
    if (account.subscription.plan) {
      // if the account has an active Stripe shinyapps.io subscription, that must be canceled
      // before the account can be downgraded
      dispatch(uiActions.openDialog({
        type: dialogs.CONFIRM_DIALOG,
        data: {
          details: <p><IString stringKey="account.subscription.manage.error.shinyappsStripe" /></p>,
          purpose: <IString stringKey={`account.subscription.${action}.title`} placeholders={{ id: account.id }} />
        }
      }));

    }
    return !account.subscription.plan;
  };

  const handleDowngradeShinyapps = () => {
    if (checkShinyappsSubscriptionManageability('delete')) {
      dispatch(uiActions.openDialog({
        type: dialogs.CONFIRM_DIALOG,
        data: {
          purpose: <IString stringKey="account.subscription.delete.title" placeholders={{ id: account.id }} />,
          details: <>
            <p>
              <IString
                placeholders={{
                  subscriptionName: account.subscription.type.name,
                  id: account.id
                }}
                stringKey="account.subscription.delete.shinyapps.details"
              />
            </p>
            <MarkedMessage mark={marks.IMPORTANT}>
              <p><IString stringKey="account.subscription.delete.shinyapps.result" /></p>
            </MarkedMessage>

          </>,
          confirm: async () => {
            try {
              dispatch(uiActions.setActivityMessage('account.subscription.activity.downgrade'));
              await dispatch(accountOperations.downgradeShinyappsSubscription(account.id));
              dispatch(uiActions.closeDialog());
              dispatch(uiActions.setSuccessMessage('account.subscription.delete.shinyapps.success'));
            } catch (error) {
              dispatch(uiActions.setErrorMessage(error));
              throw error;
            }
          }
        }
      }));
    }
  };

  const handleUpgradeShinyapps = () => {
    if (checkShinyappsSubscriptionManageability('upgrade')) {
      dispatch(uiActions.openDialog({
        type: dialogs.CONFIRM_DIALOG,
        data: {
          purpose: <IString stringKey="account.subscription.upgrade.title" placeholders={{ id: account.id }} />,
          details: <p>
            <IString
              placeholders={{ id: account.id }}
              stringKey="account.subscription.upgrade.shinyapps.details"
            />
          </p>,
          confirm: async () => {
            try {
              dispatch(uiActions.setActivityMessage('account.subscription.activity.upgrade'));
              await dispatch(accountOperations.upgradeShinyappsSubscription(account.id));
              dispatch(uiActions.closeDialog());
              dispatch(uiActions.setSuccessMessage('account.subscription.upgrade.shinyapps.success'));
            } catch (error) {
              dispatch(uiActions.setErrorMessage(error));
              throw error;
            }
          }
        }
      }));
    }
  };

  return (
    <div className="splitColumns withMobileMargins">
      <div className="column60">
        <div className="sectionTitle flex">
          <IString stringKey="account.subscription.cloud.title" />
          { subscription &&
            <div className="actionBar inline showTitles">
              <ActionButton
                className="delete"
                onClick={handleDeleteCloudSubscription}
                title={translate('account.subscription.actions.cancel')}
              />
            </div>
          }
        </div>
        <div className="section">
          <CloudSubscription subscription={subscription} />
        </div>

        <div className="sectionTitle flex">
          <IString stringKey="account.subscription.shinyapps.title" />
          <div className="actionBar inline showTitles">
            { account.subscription?.type?.premium
              ? <ActionButton
                  className="edit"
                  onClick={handleDowngradeShinyapps}
                  title={translate('account.subscription.actions.downgrade')}
                />
              : <ActionButton
                  className="edit"
                  onClick={handleUpgradeShinyapps}
                  title={translate('account.subscription.actions.upgrade')}
                />
            }
          </div>
        </div>

        <div className="section">
          <ShinyappsSubscription subscription={account.subscription} id={account.id} />
        </div>
      </div>
    </div>

  );
}

AccountSubscription.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number,
    subscription: PropTypes.shape({
      plan: PropTypes.object,
      type: PropTypes.shape({
        name: PropTypes.string,
        premium: PropTypes.bool
      })
    })
  })
};

export default AccountSubscription;
