import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import * as accountActions from '~actions/account-actions';

import AccountLink from '~components/general/account-link';
import GetMore from '~components/general/get-more';
import GettingMore from '~components/general/getting-more';
import IString from '~components/general/i-string';
import Search from '~components/general/search';
import Time from '~components/general/time';
import UserLink from '~components/general/user-link';

import { translate } from '~i18n/localize';

import * as accountSelectors from '~selectors/account-selectors';

const AccountSearchResult = ({ account }) =>
  <tr>
    <td><AccountLink accountId={account.id} /></td>
    <td>{account.name}</td>
    <td>{account.type}</td>
    <td><UserLink user={account.owner} /></td>
    <td>{account.licenses.map(l => l.name).join(', ')}</td>
    <td><Time value={account.created_time} /></td>
    <td><Time value={account.updated_time} /></td>
  </tr>;

AccountSearchResult.propTypes = {
  account: PropTypes.shape({
    created_time: PropTypes.string,
    id: PropTypes.number,
    licenses: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
    name: PropTypes.string,
    owner: PropTypes.object,
    type: PropTypes.string,
    updated_time: PropTypes.string
  })
};

export function AccountSearch(props) {
  const { dispatch, hasMore, loading, offset, query, results, total } = props;

  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const [ search, setSearch ] = useState(params.get('query'));

  const empty = !!results && results.length === 0 && !loading;

  const fetchMoreAccounts = useCallback((offset) => {
    dispatch(accountActions.fetchAccountSearchPage(query, offset));
  }, [ dispatch, query ]);

  useEffect(() => {
    history.replace({ search: search ? `?query=${search}` : '' });
  }, [ history, search ]);

  useEffect(() => {
    if (search) {
      dispatch(accountActions.fetchAccountSearchPage(search, 0));
    } else {
      dispatch(accountActions.clearAccountSearch());
    }
  }, [ dispatch, search ]);

  const handleSearch = (newSearch) => {
    setSearch(newSearch);
  };

  let listStatus = null;
  if (loading) {
    // getting more spinner
    listStatus = <GettingMore label={translate('general.loading')} />;
  } else if (hasMore) {
    // get more "button"
    listStatus = <GetMore autoFire label={translate('account.search.getMore')} onClick={() => fetchMoreAccounts(offset)} />;
  }
  return (
    <>
      <div className="sectionTitle flex">
        <IString stringKey={`account.search.title.${query ? 'results' : 'prompt' }`} />
      </div>

      <div className="section">
        <Search value={query} submit={handleSearch} />
      </div>

      <div className="smallMarginBelow">
        <div className="right">
          { total !== undefined && <IString stringKey="general.total" placeholders={{ total }} /> }
        </div>
      </div>

      { query ?
        <div className="tableContainer">
          { empty ?
            <div className="emptyListMessage"><IString stringKey="general.empty" /></div>
            : <>
              <table className="contentListing">
                <thead>
                  <tr>
                    <th><IString stringKey="general.user" /></th>
                    <th><IString stringKey="general.name" /></th>
                    <th><IString stringKey="general.type" /></th>
                    <th><IString stringKey="general.owner" /></th>
                    <th><IString stringKey="account.search.table.licenses" /></th>
                    <th><IString stringKey="general.created" /></th>
                    <th><IString stringKey="general.updated" /></th>
                  </tr>
                </thead>
                <tbody>
                  {results?.map(t => <AccountSearchResult account={t} key={t.id} />)}
                </tbody>
              </table>
              {listStatus}
            </> }
        </div>
        : <div className="emptyListMessage"><IString stringKey="account.search.prompt" /></div> }
    </>
  );
}

AccountSearch.propTypes = {
  dispatch: PropTypes.func,
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  offset: PropTypes.number,
  query: PropTypes.string,
  results: PropTypes.array,
  total: PropTypes.number
};

const mapStateToProps = (state) => {
  return {
    hasMore: accountSelectors.hasMoreAccounts(state),
    loading: accountSelectors.isAccountSearchLoading(state),
    offset: accountSelectors.getAccountsSearchOffset(state),
    query: accountSelectors.getAccountSearchQuery(state),
    results: accountSelectors.getAccountSearchResults(state),
    total: accountSelectors.getSearchAccountResultsTotal(state)
  };
};

export default connect(mapStateToProps)(AccountSearch);
