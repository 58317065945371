import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import AccountPage from '~components/accounts/account-page';
import AccountSearch from '~components/accounts/account-search';
import StandardPage from '~components/pages/standard-page';

function AccountsPage() {

  const { path } = useRouteMatch();

  return (
    <StandardPage>
      <Switch>
        <Route path={`${path}/:accountId`}>
          <AccountPage />
        </Route>
        <Route path={path}>
          <AccountSearch />
        </Route>
      </Switch>
    </StandardPage>
  );
}

export default AccountsPage;
