import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import * as uiActions from '~actions/ui-actions';
import * as userActions from '~actions/user-actions';

import * as dialogsTypes from '~components/dialogs/dialog-types';
import ActionButton from '~components/general/action-button';
import GetMore from '~components/general/get-more';
import GettingMore from '~components/general/getting-more';
import IString from '~components/general/i-string';
import Search from '~components/general/search';
import Time from '~components/general/time';
import UserLink from '~components/general/user-link';

import { translate } from '~i18n/localize';

import * as userSelectors from '~selectors/user-selectors';

const UserSearchResult = ({ user }) =>
  <tr>
    <td><UserLink user={{ id: user.id }} /></td>
    <td>{user.email}</td>
    <td>{user.first_name}</td>
    <td>{user.last_name}</td>
    <td>{user.display_name}</td>
    <td><Time value={user.created_time} /></td>
    <td><Time value={user.updated_time} /></td>
  </tr>;

UserSearchResult.propTypes = {
  user: PropTypes.shape({
    created_time: PropTypes.string,
    display_name: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    id: PropTypes.number,
    last_name: PropTypes.string,
    updated_time: PropTypes.string
  })
};

function UserSearch(props) {
  const { dispatch, hasMore, loading, offset, query, results, total } = props;

  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const [ search, setSearch ] = useState(params.get('query'));

  const empty = !loading && !!results && results.length === 0;
  let listStatus = null;

  const fetchMoreUsers = useCallback((offset) => {
    dispatch(userActions.fetchUserSearchPage(query, offset));
  }, [ dispatch, query ]);

  if (loading) {
    // getting more spinner
    listStatus = <GettingMore label={translate('general.loading')} />;
  } else if (hasMore) {
    // get more "button"
    listStatus = <GetMore autoFire label={translate('user.search.getMore')} onClick={() => fetchMoreUsers(offset)} />;
  }

  useEffect(() => {
    history.replace({ search: search ? `?query=${encodeURIComponent(search)}` : '' });
  }, [ search, history ]);

  useEffect(() => {
    if (search) {
      dispatch(userActions.fetchUserSearchPage(search, 0));
    } else {
      dispatch(userActions.clearUserSearch());
    }
  }, [ search, dispatch ]);

  const handleSearch = (newSearch) => {
    setSearch(newSearch);
  };

  const handleAdd = () => {

    dispatch(uiActions.openDialog({
      type: dialogsTypes.USER_CREATE_DIALOG,
      onSubmit: (formData) => {
        if (formData) {
          dispatch(userActions.createUser(formData));
        }
      }
    }));

  };

  return (
    <>
      <div className="sectionTitle flex">
        <IString stringKey={`user.search.title.${query ? 'results' : 'prompt' }`} />
        <div className="actionBar inline">
          <ActionButton className="new noTitle" title={translate('user.search.add_user')} onClick={handleAdd} />
        </div>
      </div>

      <div className="section">
        <Search value={query} submit={handleSearch} />
      </div>

      <div className="smallMarginBelow">
        <div className="right">
          { total !== undefined && <IString stringKey="general.total" placeholders={{ total }} /> }
        </div>
      </div>

      {query ?
        <div className="tableContainer">
          {empty ? <div className="emptyListMessage"><IString stringKey="general.empty" /></div>
            : <table className="contentListing">
              <thead>
                <tr>
                  <th><IString stringKey="general.user" /></th>
                  <th><IString stringKey="general.email" /></th>
                  <th><IString stringKey="general.first_name" /></th>
                  <th><IString stringKey="general.last_name" /></th>
                  <th><IString stringKey="general.display_name" /></th>
                  <th><IString stringKey="general.created" /></th>
                  <th><IString stringKey="general.updated" /></th>
                </tr>
              </thead>
              <tbody>
                {results?.map(t => <UserSearchResult user={t} key={t.id} />)}
              </tbody>
            </table>}
        </div>
        : <div className="emptyListMessage"><IString stringKey="user.search.prompt" /></div>
      }
      {listStatus}
    </>
  );
}

UserSearch.propTypes = {
  dispatch: PropTypes.func,
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  offset: PropTypes.number,
  query: PropTypes.string,
  results: PropTypes.array,
  total: PropTypes.number
};

const mapStateToProps = (state) => ({
  hasMore: userSelectors.hasMoreUsers(state),
  loading: userSelectors.isSearchLoading(state),
  offset: userSelectors.getOffset(state),
  query: userSelectors.getSearchQuery(state),
  results: userSelectors.getSearchResults(state),
  total: userSelectors.getSearchResultsTotal(state)
});

export default connect(mapStateToProps)(UserSearch);
// export unconnected component for testing
export { UserSearch };
