import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

function SpaceLink(props) {
  const { spaceId } = props;

  return (
    <Link to={`/spaces/${spaceId}`}><span>Space {spaceId}</span></Link>
  );
}

SpaceLink.propTypes = {
  spaceId: PropTypes.number
};

export default SpaceLink;
