import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import IString from '~components/general/i-string';

function AccountMenu(props) {

  const { accountId } = props;

  return (
    <div className="menu showOnMobile">
      <div className="menuItems">
        <NavLink to={`/accounts/${accountId}/`} className="menuItem" exact activeClassName="active">
          <IString stringKey="account.menu.details" />
        </NavLink>
        <NavLink to={`/accounts/${accountId}/license`} className="menuItem" activeClassName="active">
          <IString stringKey="account.menu.license" />
        </NavLink>
        <NavLink to={`/accounts/${accountId}/subscription`} className="menuItem" activeClassName="active">
          <IString stringKey="account.menu.subscription" />
        </NavLink>
        <NavLink to={`/accounts/${accountId}/members`} className="menuItem" activeClassName="active">
          <IString stringKey="account.menu.members" />
        </NavLink>
        <NavLink to={`/accounts/${accountId}/content`} className="menuItem" activeClassName="active">
          <IString stringKey="general.menu.content" />
        </NavLink>
        <NavLink to={`/accounts/${accountId}/spaces`} className="menuItem" activeClassName="active">
          <IString stringKey="general.menu.spaces" />
        </NavLink>
        <NavLink to={`/accounts/${accountId}/usage`} className="menuItem" activeClassName="active">
          <IString stringKey="account.menu.usage" />
        </NavLink>
        <NavLink to={`/accounts/${accountId}/properties`} className="menuItem" activeClassName="active">
          <IString stringKey="account.menu.properties" />
        </NavLink>
        <NavLink to={`/accounts/${accountId}/applications`} className="menuItem" activeClassName="active">
          <IString stringKey="general.menu.applications" />
        </NavLink>
      </div>
    </div>

  );
}

AccountMenu.propTypes = {
  accountId: PropTypes.number
};

export default AccountMenu;
