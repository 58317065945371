import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import GettingMore from '~components/general/getting-more';
import IString from '~components/general/i-string';
import Time from '~components/general/time';

import * as applicationType from '~constants/application-type';

import { translate } from '~i18n/localize';

import * as applicationSelectors from '~selectors/application-selectors';

export function ApplicationDeployment({ application }) {
  const deploymentFields = [ 'id', 'created_time', 'updated_time' ];
  const propertyFields = [
    'connect.version', 'package.cache', 'instances.agent-pool', 'instances.idle-threshold', 'instances.load.factor',
    'instances.start', 'instances.template', 'shiny.timeout.conn', 'shiny.timeout.idle', 'shiny.timeout.init',
    'shiny.timeout.read', 'shiny.timeout.session', 'shiny.scheduler.load.factor', 'shiny.scheduler.max.processes',
    'shiny.scheduler.max.requests', 'shiny.scheduler.min.processes'
  ];
  if (application) {
    return (
      <>
        <div className="sectionTitle flex">
          <IString stringKey="application.deployment.title" />
        </div>
        {application.deployment
          ? <table className="propertiesTable">
            <tbody>
              <tr>
                <th className="label"><IString stringKey="general.type" /></th>
                <td className="value">{application.type}</td>
              </tr>
              {deploymentFields.map(property =>
                <tr key={property}>
                  <th className="label"><IString stringKey={`application.deployment.${property}`} /></th>
                  <td className="value">
                    {property.includes('time') ? <Time value={application.deployment[`${property}`]} /> : application.deployment[`${property}`]}
                  </td>
                </tr>)
              }
              { // these properties are only relevant for shinyapps
              applicationType.isShiny(application) &&
              propertyFields.map(property =>
                <tr key={property}>
                  <th className="label"><IString stringKey={`application.deployment.${property}`} /></th>
                  <td className="value">{String(application.deployment.properties[`application.${property}`])}</td>
                </tr>)
              }
            </tbody>
          </table>
          : <div className="emptyListMessage"><IString stringKey="application.deployment.empty" /></div>
        }
      </>
    );
  } else {
    return (
      <GettingMore label={translate('general.loading')} />
    );
  }
}

ApplicationDeployment.propTypes = {
  application: PropTypes.shape({
    deployment: PropTypes.shape({
      created_time: PropTypes.string,
      id: PropTypes.number,
      properties: PropTypes.shape({
        'application.connect.version': PropTypes.string,
        'application.instances.agent-pool': PropTypes.string,
        'application.instances.idle-threshold': PropTypes.number,
        'application.instances.load.factor': PropTypes.number,
        'application.instances.start': PropTypes.number,
        'application.instances.template': PropTypes.string,
        'application.package.cache': PropTypes.bool,
        'application.shiny.scheduler.load.factor': PropTypes.number,
        'application.shiny.scheduler.max.processes': PropTypes.number,
        'application.shiny.scheduler.max.requests': PropTypes.number,
        'application.shiny.scheduler.min.processes': PropTypes.number,
        'application.shiny.timeout.conn': PropTypes.number,
        'application.shiny.timeout.idle': PropTypes.number,
        'application.shiny.timeout.init': PropTypes.number,
        'application.shiny.timeout.read': PropTypes.number,
        'application.shiny.timeout.session': PropTypes.number
      }),
      type: PropTypes.string,
      updated_time: PropTypes.string
    }),
    type: PropTypes.string
  })
};

const mapStateToProps = (state) => ({
  application: applicationSelectors.getCurrentApplication(state)
});

export default connect(mapStateToProps)(ApplicationDeployment);
