import * as uiActions from '~actions/ui-actions';

import * as api from '~api/api';
import * as applicationsAPI from '~api/applications';

import * as statusTypes from '~components/status/status-types';

import * as taskOperations from '~operations/task-operations';

/**
 * Thunk action creator to export an application
 *
 * @param   {number} applicationId ID of the application to export
 * @returns                        A thunk that exports the application and returns the URL to download the application
 */
export const exportApplication = (applicationId) => async (dispatch) => {
  try {
    // Create a task to export an application
    const { data: { task_id } } = await applicationsAPI.exportApplication(applicationId);

    // Once that task completes, we need to update the dialog by showing a download button
    const { result: { download_url } } = await dispatch(taskOperations.checkUntilDone(task_id, false));
    return download_url;

  } catch (error) {
    const message = api.getErrorMessage(error.response);
    dispatch(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
    throw error;
  }
};

/**
 * Thunk action creator to fetch timeseries data for a given metric for an application.
 *
 * @param   {number}   applicationId The ID of the application for which to fetch metrics
 * @param   {string}   metricType    The type of the metric for which to fetch timeseries data
 * @param   {object}   options       The options to apply when fetching metrics:
 * @returns {function}               Thunk to fetch the timeseries metric data.
 */
export const getMetrics = (applicationId, metricType, options) => async dispatch => {
  try {
    const metrics = await applicationsAPI.getApplicationMetrics(applicationId, metricType, options);
    return metrics;
  } catch (error) {
    const message = api.getErrorMessage(error.response);
    dispatch(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
  }
};
