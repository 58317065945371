import React from 'react';
import { useSelector } from 'react-redux';

import User from '~components/general/user';
import UserPopup from '~components/general/user-popup';
import { useOpenState } from '~components/util/hooks';

import * as userSelectors from '~selectors/auth-selectors';

export default function CurrentUser() {
  const currentUser = useSelector(userSelectors.getAuthUser);

  const [ open, setOpen ] = useOpenState();

  const toggleMenu = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <div className="popupButtonAndMenuContainer">
      <div id="currentUser" onClick={toggleMenu}>
        <User user={currentUser} />
      </div>
      {open &&
        <div className="popupMenu shiftRight open">
          <UserPopup user={currentUser} />
        </div>
      }
    </div>
  );
}
