import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import * as actions from '~actions/account-actions';

import AccountApplications from '~components/accounts/account-applications';
import AccountContent from '~components/accounts/account-content';
import AccountDetails from '~components/accounts/account-details';
import AccountMenu from '~components/accounts/account-menu';
import AccountProperties from '~components/accounts/account-properties';
import AccountSpaces from '~components/accounts/account-spaces';
import AccountSubscription from '~components/accounts/account-subscription';
import AccountUsage from '~components/accounts/account-usage';
import AccountLicense from '~components/accounts/license/account-license';
import AccountMembersPage from '~components/accounts/members/account-members-page';

import * as selectors from '~selectors/account-selectors';

function AccountPage({ account, dispatch }) {
  const { name, id } = account || {};

  const { params, path } = useRouteMatch();

  const [ accountId, setAccountId ] = useState(params.accountId);

  useEffect(() => {
    dispatch(actions.setCurrentAccount(accountId));

    return () => {
      dispatch(actions.clearCurrentAccount());
    };
  }, [ dispatch, accountId ]);

  useEffect(() => {
    setAccountId(params.accountId);
  }, [ params ]);

  if (account) {
    return (
      <>
        <h1>Account: {name} (id: {id})</h1>
        <AccountMenu accountId={id} />
        <Switch>
          <Route exact path={`${path}/`}>
            <AccountDetails account={account} />
          </Route>
          <Route path={`${path}/usage`}>
            <AccountUsage accountId={account.id} />
          </Route>
          <Route path={`${path}/license`}>
            <AccountLicense accountId={account.id} />
          </Route>
          <Route path={`${path}/subscription`}>
            <AccountSubscription account={account} />
          </Route>
          <Route path={`${path}/members`}>
            <AccountMembersPage account={account} />
          </Route>
          <Route path={`${path}/spaces`}>
            <AccountSpaces account={account} />
          </Route>
          <Route path={`${path}/content`}>
            <AccountContent accountId={account.id} />
          </Route>
          <Route path={`${path}/applications`}>
            <AccountApplications accountId={account.id} />
          </Route>
          <Route path={`${path}/properties`}>
            <AccountProperties accountId={account.id} />
          </Route>
        </Switch>
      </>
    );
  } else {
    return (
      <span>Loading...</span>
    );
  }

}

AccountPage.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }),
  dispatch: PropTypes.func
};

const mapStateToProps = (state) => ({
  account: selectors.getCurrentAccount(state)
});

export default connect(mapStateToProps)(AccountPage);
