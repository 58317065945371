export const shallowDiffObject = (obj1, obj2) => {
  const ret = {};
  for (const i in obj2) {
    if (!Object.prototype.hasOwnProperty.call(obj1, i) || obj2[i] !== obj1[i]) {
      ret[i] = obj2[i];
    }
  }
  return ret;
};

export const hasDiff = (obj1, obj2) => {
  const diff = shallowDiffObject(obj1, obj2);
  return Object.keys(diff).length > 0;
};
