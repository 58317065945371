import PropTypes from 'prop-types';
import React from 'react';

import IString from '~components/general/i-string';

/**
 * Component to handle the (surprisingly complicated) job
 * of displaying a user's name, based on the provided user object.
 * This also handles:
 * 1. if no name fields are populated, it shows the user id, and
 * 2. if the user/user id is not set, it shows the "ex-user" text.
 *
 * @param   {object}      user User to display a name for
 * @returns {JSX.Element}      The name of the provided user
 */
export default function UserName({ user }) {
  if (!user?.id) {
    return <em><IString stringKey="general.exUser" /></em>;
  }

  if (user.display_name) {
    return user.display_name;
  } else if (user.first_name && user.last_name) {
    return user.first_name + ' ' + user.last_name;
  } else if (user.first_name || user.last_name) {
    return user.first_name || user.last_name;
  }

  return <>User {user.id}</>;
}

UserName.propTypes = {
  user: PropTypes.shape({
    display_name: PropTypes.string,
    first_name: PropTypes.string,
    id: PropTypes.number,
    last_name: PropTypes.string
  })
};
