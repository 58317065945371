import * as applicationScheduler from '~constants/application-scheduler';

export const PENDING = 'pending';
export const PENDING_ARCHIVED = 'pending-archived';
export const RUNNING = 'running';
export const SLEEPING = 'sleeping';
export const TERMINATED = 'terminated';
export const ARCHIVED = 'archived';
export const TERMINATED_ARCHIVED = 'terminated-archived';
export const PURGED = 'purged';

export const isArchived = (app) => app?.status === ARCHIVED;
export const isPending = (app) => app?.status === PENDING || app?.status === PENDING_ARCHIVED;
export const isPurged = (app) => app?.status === PURGED;
export const isRunning = (app) => app?.status === RUNNING;
export const isSleeping = (app) => app?.status === SLEEPING;
export const isTerminated = (app) => app?.status === TERMINATED || app?.status === TERMINATED_ARCHIVED;

export const deployable = (app) => applicationScheduler.isLegacy(app) && isTerminated(app);
export const purgeable = (app) => isTerminated(app);
export const restartable = (app) => applicationScheduler.isK8s(app) && (isRunning(app) || isSleeping(app));
export const resumable = (app) => applicationScheduler.isK8s(app) && (isSleeping(app) || isArchived(app));
export const suspendible = (app) => applicationScheduler.isK8s(app) && isRunning(app);
export const terminateable = (app) => isPending(app) || isRunning(app) || isSleeping(app) || isArchived(app);
