import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Content from '~components/content/content';
import ContentSearch from '~components/content/content-search';
import StandardPage from '~components/pages/standard-page';

function ContentPage() {

  const { path } = useRouteMatch();

  return (
    <StandardPage>
      <Switch>
        <Route path={`${path}/:contentId`}>
          <Content />
        </Route>
        <Route path={path} >
          <ContentSearch />
        </Route>
      </Switch>
    </StandardPage>
  );
}

export default ContentPage;
