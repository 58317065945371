// Reducers
import accountReducer from '~reducers/account-reducer';
import applicationReducer from '~reducers/application-reducer';
import authReducer from '~reducers/auth-reducer';
import contentReducer from '~reducers/content-reducer';
import spaceReducer from '~reducers/space-reducer';
import taskReducer from '~reducers/task-reducer';
import uiReducer from '~reducers/ui-reducer';
import userReducer from '~reducers/user-reducer';

import projectReducer from './project-reducer';

// Combine Reducers
const reducers = {
  uiState: uiReducer,
  authState: authReducer,
  userState: userReducer,
  taskState: taskReducer,
  accountState: accountReducer,
  spaceState: spaceReducer,
  contentState: contentReducer,
  projectState: projectReducer,
  applicationState: applicationReducer
};

export default reducers;
