import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import StandardPage from '~components/pages/standard-page';
import SpacePage from '~components/spaces/space-page';
import SpaceSearch from '~components/spaces/space-search';

function SpacesPage() {

  const { path } = useRouteMatch();

  return (
    <StandardPage>
      <Switch>
        <Route path={`${path}/:spaceId`}>
          <SpacePage />
        </Route>
        <Route path={path}>
          <SpaceSearch />
        </Route>
      </Switch>
    </StandardPage>
  );
}

export default SpacesPage;
