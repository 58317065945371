import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import ActionButton from '~components/general/action-button';
import IString from '~components/general/i-string';

import { translate } from '~i18n/localize';

import mo, { ISO_DATE } from '~util/mo';

const rangeDeltas = {
  day: [ 0, 'days' ],
  month: [ 1, 'month' ],
  week: [ 6, 'days' ]
};

/**
 * Conversion helper for range options to unit granularity.
 */
export const rangeToUnit = {
  day: 'hour',
  month: 'day',
  week: 'day'
};

/**
 * Component for selecting date range information for showing timeseries data.
 *
 * @param   {string}   earliest      Date string of the earliest date to allow, in YYYY-MM-DD format.
 * @param   {function} onRangeChange Callback called when the selected range changes.
 *                                   With arguments:
 *                                   from        - date in milliseconds,
 *                                   until       - date in milliseconds,
 *                                   rangeSize   - "day", "week", or "month"
 * @param   {boolean}  utc           Whether or not date selection should be utc. If unspecified, uses local time.
 * @returns                          A date range selector for choosing a start date and a range size when displaying
 *                                   timeseries data. Selecting a new range size preserves the current end date, adjusting the start accordingly.
 */
const RangePicker = ({ earliest, onRangeChange, utc }) => {
  const [ rangeInfo, setRangeInfo ] = useState({ rangeSize: 'day', startDate: mo(utc).format(ISO_DATE) }); // this is an object in state because we want to batch updates to these fields

  const maxDate = mo(utc).format(ISO_DATE);
  const minDate = earliest;

  const getEnd = useCallback(
    (startDate, rangeSize) =>
      mo(utc, startDate).add(...rangeDeltas[rangeSize]).endOf('day'),
    [ utc ]
  );

  const getStart = useCallback(
    (endDate, rangeSize) =>
      mo(utc, endDate).subtract(...rangeDeltas[rangeSize]).startOf('day'),
    [ utc ]
  );

  const handleRangeChange = (e) => {
    const newRange = e.target.value;

    setRangeInfo(prev => {
      const currentEnd = moment.min(getEnd(prev.startDate, prev.rangeSize), mo(utc, maxDate));

      const startDate = moment.max(
        mo(utc, minDate),
        getStart(currentEnd, newRange)
      ).format(ISO_DATE);

      return {
        rangeSize: newRange,
        startDate
      };
    });
  };

  const handleStartChange = (e) => {
    if (e.target.value) {
      const startDate = moment.max(
        mo(utc, minDate),
        mo(utc, e.target.value)
      ).format(ISO_DATE);

      setRangeInfo(prev => ({
        ...prev,
        startDate
      }));
    }
  };

  const stepBack = () => setRangeInfo(prev => {
    const startDate = moment.max(
      mo(utc, minDate),
      mo(utc, prev.startDate).subtract(1, prev.rangeSize)
    ).format(ISO_DATE);

    return {
      ...prev,
      startDate
    };
  });

  const stepForward = () => setRangeInfo(prev => {
    const startDate = moment.min(
      mo(utc, maxDate),
      mo(utc, prev.startDate).add(1, prev.rangeSize)
    ).format(ISO_DATE);

    return {
      ...prev,
      startDate
    };
  });

  useEffect(() => {
    const end = moment.min(
      getEnd(rangeInfo.startDate, rangeInfo.rangeSize), getEnd(maxDate, 'day')
    );

    onRangeChange(
      mo(utc, `${rangeInfo.startDate}T00:00:00`).valueOf(),
      end.valueOf(),
      rangeInfo.rangeSize
    );
  }, [ getEnd, maxDate, onRangeChange, rangeInfo, utc ]);

  return (
    <div className="flexSpaceBetween midMarginBelow lightGray midPadding">

      <div className="flexSpaceAfter flexAlignBaseline flexAllowWrap">
        <IString
          className="smallMarginRight dontBreak"
          stringKey="general.timeseries.range.view"
        />

        <select
          className="periodPicker midMarginRight"
          onChange={handleRangeChange}
          value={rangeInfo.rangeSize}
        >
          <option value="day">{translate('general.timeseries.range.options.day')}</option>
          <option value="week">{translate('general.timeseries.range.options.week')}</option>
          <option value="month">{translate('general.timeseries.range.options.month')}</option>
        </select>

        <label>
          <IString
            className="smallMarginRight dontBreak"
            stringKey="general.timeseries.range.start"
          />
          <input
            type="date"
            max={maxDate}
            min={minDate}
            onChange={handleStartChange}
            required
            value={rangeInfo.startDate}
          />

          <IString
            className="smallMarginLeft smallMarginRight dontBreak"
            placeholders={{
              zone: <span className="smallFont">
                { utc ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone }
              </span>
            }}
            stringKey="general.timeseries.range.time"
          />
        </label>
      </div>

      <div className="actionBar inline">
        <ActionButton
          aria-label={translate('general.timeseries.range.steps.back', { rangeSize: rangeInfo.rangeSize })}
          className="back"
          disabled={rangeInfo.startDate <= minDate}
          onClick={stepBack}
        />
        <ActionButton
          aria-label={translate('general.timeseries.range.steps.forward', { rangeSize: rangeInfo.rangeSize })}
          className="forward"
          disabled={mo(utc, rangeInfo.startDate).add(1, rangeInfo.rangeSize).format(ISO_DATE) > maxDate}
          onClick={stepForward}
        />
      </div>
    </div>
  );
};

RangePicker.propTypes = {
  earliest: PropTypes.string,
  onRangeChange: PropTypes.func.isRequired,
  utc: PropTypes.bool
};

export default RangePicker;
