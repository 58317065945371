import Chart from 'chart.js';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

export const formats = {
  axis: {
    day: 'MMM D',
    hour: 'hA',
    minute: 'h:mm a'
  },
  tooltip: {
    day: 'MMM DD, YYYY',
    hour: 'MMM DD, YYYY h A',
    minute: 'MMM DD, YYYY hh:mm A'
  }
};

/**
 *
 * @param   {string}   chartType         The type of chart to render for the timeseries.
 * @param   {array}    datasets          An array of dataset objects. See chart.js documentation:
 *                                       - datasets for bars: https://www.chartjs.org/docs/2.9.4/charts/bar.html#dataset-properties
 *                                       - datasets for lines: https://www.chartjs.org/docs/2.9.4/charts/line.html#dataset-properties
 * @param   {function} tooltipFormatters Callbacks to format the tooltip. Functions defined here should return a string for rendering.
 *                                       See chart.js documentation:
 *                                       - callback functions: https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html#tooltip-callbacks
 *                                       - tooltip parameter: https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html#tooltip-item-interface
 *                                       - (data parameter is the data object set on the chart itself)
 * @param   {string}   unit              "Granularity" unit for timeseries data to determine how to dates on the x-axis. Options are "day", "hour", "minute", defaults to "day".
 * @param   {fuction}  xAxisFormatter    Callback to format the xAxis tick labels, should return a string for rendering.
 *                                       See chart.js documentation: https://www.chartjs.org/docs/2.9.4/axes/labelling.html#creating-custom-tick-formats
 * @param   {fuction}  yAxisFormatter    Callback to format the yAxis tick labels, should return a string for rendering.
 *                                       See chart.js documentation: https://www.chartjs.org/docs/2.9.4/axes/labelling.html#creating-custom-tick-formats
 * @returns                              A component to render a timeseries chart with the given data and configurations.
 */
const TimeseriesChart = ({ chartType, datasets, tooltipFormatters, unit = 'day', xAxisFormatter, yAxisFormatter = f => f }) => {

  const canvasRef = useRef();
  const chartRef = useRef();

  useEffect(() => {
    chartRef.current = new Chart(canvasRef.current, {
      type: chartType,
      options: {
        legend: { display: false },
        maintainAspectRatio: false,
        scales: {
          yAxes: [ {
            ticks: {
              beginAtZero: true
            }
          } ],
          xAxes: [ {
            offset: true,
            id: 'time-axis',
            type: 'time',
            ticks: {
              autoSkip: true,
              source: 'data'
            },
            gridLines: {
              display: true,
              drawOnChartArea: false,
              drawTicks: true
            }
          } ]
        },
        tooltips: {
          mode: 'index'
        }
      }
    });

    return () => chartRef.current.destroy();
  }, [ chartType ]);

  useEffect(() => {
    chartRef.current.options.tooltips.callbacks = tooltipFormatters;
    chartRef.current.update();
  }, [ tooltipFormatters ]);

  useEffect(() => {
    chartRef.current.options.scales.xAxes[0].ticks.callback = xAxisFormatter;
    chartRef.current.options.scales.xAxes[0].time = {
      tooltipFormat: formats.tooltip[unit],
      unit
    };
    chartRef.current.update();
  }, [ unit, xAxisFormatter ]);

  useEffect(() => {
    chartRef.current.options.scales.yAxes[0].ticks.callback = yAxisFormatter;
    chartRef.current.update();
  }, [ yAxisFormatter ]);

  useEffect(() => {
    chartRef.current.data.datasets = datasets;

    chartRef.current.options.legend = {
      align: 'left',
      display: datasets?.length > 1,
      labels: {
        boxWidth: 15
      }
    };

    chartRef.current.update(0); // don't animate when the data buckets change

  }, [ datasets ]);

  return (
    <div className="chartContainer shorter">
      <canvas ref={canvasRef} />
    </div>
  );
};

TimeseriesChart.propTypes = {
  chartType: PropTypes.string,
  datasets: PropTypes.array,
  tooltipFormatters: PropTypes.shape({
    label: PropTypes.func,
    title: PropTypes.func
  }),
  unit: PropTypes.oneOf([ 'day', 'hour', 'minute' ]),
  xAxisFormatter: PropTypes.func,
  yAxisFormatter: PropTypes.func
};

export default TimeseriesChart;
