import PropTypes from 'prop-types';
import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import * as spaceActions from '~actions/space-actions';

import ContentLink from '~components/general/content-link';
import GetMore from '~components/general/get-more';
import GettingMore from '~components/general/getting-more';
import IString from '~components/general/i-string';
import Time from '~components/general/time';
import UserLink from '~components/general/user-link';

import { translate } from '~i18n/localize';

import * as spaceSelectors from '~selectors/space-selectors';

function SpaceContent({ dispatch, hasMore, loading, offset, content, spaceId, total }) {
  const emptyList = !loading && (!!content && content.length === 0);
  let listStatus = null;

  const fetchMoreSpaceContent = useCallback((offset) => {
    dispatch(spaceActions.fetchSpaceContentPage(spaceId, offset));
  }, [ dispatch, spaceId ]);

  useEffect(() => {
    // get an updated "first" page every time we load
    return () => {
      dispatch(spaceActions.clearSpaceContent());
    };
  }, [ fetchMoreSpaceContent, dispatch ]);

  useEffect(() => {
    if (spaceId) {
      dispatch(spaceActions.fetchSpaceContentPage(spaceId));
    }
  }, [ dispatch, spaceId ]);

  if (loading) {
    // getting more spinner
    listStatus = <GettingMore label={translate('space.content.loading')} />;
  } else if (hasMore) {
    // get more "button"
    listStatus = <GetMore label={translate('space.content.getMore')} onClick={() => fetchMoreSpaceContent(offset)} autoFire />;
  }

  return (
    <div className="splitColumns withMobileMargins">
      <div className="column100">

        <div className="sectionTitle flex">
          <IString stringKey="general.content" />
        </div>

        <div className="smallMarginBelow">
          <div className="right">
            <IString stringKey="general.total" placeholders={{ total }} />
          </div>
        </div>

        <div className="tableContainer">
          {emptyList
            ? <div className="emptyListMessage"><IString stringKey="general.empty" /></div>
            : <table className="contentListing">
              <thead>
                <tr>
                  <th><IString stringKey="general.id" /></th>
                  <th><IString stringKey="general.name" /></th>
                  <th><IString stringKey="general.type" /></th>
                  <th><IString stringKey="general.state" /></th>
                  <th><IString stringKey="general.mode" /></th>
                  <th><IString stringKey="general.author" /></th>
                  <th><IString stringKey="general.created" /></th>
                  <th><IString stringKey="general.updated" /></th>
                </tr>
              </thead>
              <tbody>
                {content && content.map(c =>
                  <tr key={c.id}>
                    <td><ContentLink contentId={c.id} /></td>
                    <td>{c.name}</td>
                    <td>{c.content_type}</td>
                    <td>{c.state}</td>
                    <td>{c.project_mode}</td>
                    <td><UserLink user={c.author} /></td>
                    <td><Time value={c.created_time} /></td>
                    <td><Time value={c.updated_time} /></td>
                  </tr>
                )}
              </tbody>
            </table> }
        </div>
        {listStatus}
      </div>
    </div>
  );
}

SpaceContent.propTypes = {
  content: PropTypes.array,
  dispatch: PropTypes.func,
  fetchMore: PropTypes.func,
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  offset: PropTypes.number,
  spaceId: PropTypes.number,
  total: PropTypes.number
};

const mapStateToProps = (state) => ({
  content: spaceSelectors.getSpaceContentList(state),
  hasMore: spaceSelectors.hasMore(state),
  loading: spaceSelectors.isSpaceContentListLoading(state),
  offset: spaceSelectors.getOffset(state),
  total: spaceSelectors.getSpaceContentListTotal(state)
});

export default connect(mapStateToProps)(SpaceContent);
// export unconnected component for testing
export { SpaceContent };
