import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as accountActions from '~actions/account-actions';

import * as dialogs from '~components/dialogs/dialog-types';
import ModalDialog from '~components/dialogs/modal-dialog';
import ExpandoPropertiesTable from '~components/general/expando-properties-table';
import IString from '~components/general/i-string';

import * as applicationStatus from '~constants/application-status';
import * as licenseName from '~constants/license-name';

import * as accountSelectors from '~selectors/account-selectors';
import * as contentSelectors from '~selectors/content-selectors';

export function AccountDeleteDialog(props) {
  const { account, applications, applicationsTotal, cloudLicense, content, contentTotal, data: { onSubmit }, dispatch, shinyappsSubscription, spaces, spacesTotal } = props;

  const isDowngraded = account && licenseName.isCloudFree(cloudLicense) && shinyappsSubscription?.type?.name === 'free';
  const hasUntrashedContent = content?.some(c => !contentSelectors.deleteable(c));
  const hasUnterminatedApplications = applications?.some(a => !applicationStatus.purgeable(a));

  const deleteable = !!account && isDowngraded && !hasUntrashedContent && !hasUnterminatedApplications;

  useEffect(() => {
    if (account?.id) {
      dispatch(accountActions.fetchAccountResources(account.id));
    }

    return () => {
      if (account?.id) {
        dispatch(accountActions.clearAccountResources(account.id));
      }
    };
  }, [ account?.id, dispatch ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <ModalDialog type={dialogs.ACCOUNT_DELETE_DIALOG}>
      <div className="purpose">
        <IString stringKey="account.delete.dialog.purpose" placeholders={{ id: account?.id, name: account?.name }} />
      </div>

      <form noValidate onSubmit={handleSubmit}>
        { deleteable &&
          <div className="marginBelow">
            <div className="description">
              <p><strong><IString stringKey="account.delete.dialog.warning" /></strong></p>
              <p><IString stringKey="account.delete.dialog.willDelete" /></p>
            </div>

            <ExpandoPropertiesTable open={spaces?.length > 0} title={<IString stringKey="account.delete.dialog.spaces" placeholders={{ total: spacesTotal }} />}>
              <tr>
                <td className="value">{spaces?.map(s => `${s.name} (id: ${s.id})`).join(', ') || <IString stringKey="general.none" />}</td>
              </tr>
            </ExpandoPropertiesTable>

            <ExpandoPropertiesTable open={content?.length > 0} title={<IString stringKey="account.delete.dialog.content.title" placeholders={{ total: contentTotal }} />}>
              <tr>
                <td className="value">{ content?.map(p => `${p.name} (id: ${p.id})`).join(', ') || <IString stringKey="general.none" />}</td>
              </tr>
            </ExpandoPropertiesTable>

            <ExpandoPropertiesTable open={applications?.length > 0} title={<IString stringKey="account.delete.dialog.applications.title" placeholders={{ total: applicationsTotal }} />}>
              <tr>
                <td className="value">{ applications?.map(a => `${a.name} (id: ${a.id})`).join(', ') || <IString stringKey="general.none" />}</td>
              </tr>
            </ExpandoPropertiesTable>
          </div> }

        { !isDowngraded &&
          <div className="marginBelow">
            <p><IString stringKey="account.delete.dialog.subscription.exists" /></p>
            <ExpandoPropertiesTable open title={<IString stringKey="account.delete.dialog.subscription.title" />}>
              <>
                <tr>
                  <th className="label"><IString stringKey="account.delete.dialog.subscription.cloud" /></th>
                  <td className="value">{cloudLicense?.name || <IString stringKey="general.none" />}</td>
                </tr>
                <tr>
                  <th className="label"><IString stringKey="account.delete.dialog.subscription.shinyapps" /></th>
                  <td className="value">{shinyappsSubscription?.type?.name || <IString stringKey="general.none" /> }</td>
                </tr>
              </>
            </ExpandoPropertiesTable>
          </div> }

        { hasUntrashedContent &&
          <div className="marginBelow">
            <p>
              <IString stringKey="account.delete.dialog.content.untrashed" />
            </p>
            <div className="smallMarginBelow">
              <IString stringKey="account.delete.dialog.content.title" placeholders={{ total: contentTotal }} />
            </div>
            <table className="contentListing allTightRight">
              <thead>
                <tr>
                  <th><IString stringKey="general.id" /></th>
                  <th><IString stringKey="general.name" /></th>
                  <th><IString stringKey="general.space" /></th>
                  <th><IString stringKey="general.status" /></th>
                </tr>
              </thead>
              <tbody>
                { content?.map(p =>
                  <tr key={p.id}>
                    <td>{p.id}</td>
                    <td>{p.name}</td>
                    <td>{p.space_id || <em><IString stringKey="general.none" /></em>}</td>
                    <td>{p.source?.status}</td>
                  </tr>) }
              </tbody>
            </table>
          </div> }

        { hasUnterminatedApplications &&
          <div className="marginBelow">
            <p>
              <IString stringKey="account.delete.dialog.applications.unterminated" />
            </p>
            <div className="smallMarginBelow">
              <IString stringKey="account.delete.dialog.applications.title" placeholders={{ total: applicationsTotal }} />
            </div>
            <table className="contentListing allTightRight">
              <thead>
                <tr>
                  <th><IString stringKey="account.applications.table.id" /></th>
                  <th><IString stringKey="general.name" /></th>
                  <th><IString stringKey="general.type" /></th>
                  <th><IString stringKey="general.status" /></th>
                </tr>
              </thead>
              <tbody>
                { applications?.map(a =>
                  <tr key={a?.id}>
                    <td>{a?.id}</td>
                    <td>{a?.name}</td>
                    <td>{a?.type}</td>
                    <td>{a?.status}</td>
                  </tr>) }
              </tbody>
            </table>
          </div> }

        <div className="actions marginAbove">
          <button type="submit" disabled={!deleteable}><IString stringKey="general.ok" /></button>
        </div>
      </form>
    </ModalDialog>
  );
}

AccountDeleteDialog.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }),
  applications: PropTypes.array,
  applicationsTotal: PropTypes.number,
  cloudLicense: PropTypes.shape({
    name: PropTypes.string
  }),
  content: PropTypes.array,
  contentTotal: PropTypes.number,
  data: PropTypes.shape({
    onSubmit: PropTypes.func
  }),
  dispatch: PropTypes.func,
  shinyappsSubscription: PropTypes.shape({
    plan: PropTypes.shape({
      name: PropTypes.string
    }),
    type: PropTypes.shape({
      name: PropTypes.string
    })
  }),
  spaces: PropTypes.array,
  spacesTotal: PropTypes.number
};

const mapStateToProps = (state) => ({
  account: accountSelectors.getCurrentAccount(state),
  applications: accountSelectors.getAccountApplications(state),
  applicationsTotal: accountSelectors.getAccountApplicationsTotal(state),
  cloudLicense: accountSelectors.getCurrentAccountCloudLicense(state),
  content: accountSelectors.getAccountContent(state),
  contentTotal: accountSelectors.getAccountContentTotal(state),
  shinyappsSubscription: accountSelectors.getCurrentAccount(state)?.subscription,
  spaces: accountSelectors.getAccountSpacesList(state),
  spacesTotal: accountSelectors.getAccountSpacesTotal(state)
});

export default connect(mapStateToProps)(AccountDeleteDialog);
