import { createSelector } from 'reselect';

import * as licenseType from '~constants/license-type';
import { MAX_APPLICATION_HOURS_LIMIT } from '~constants/subscription-entitlements';

const getEntitlements = (state) => state.accountState.entitlements.accountLicenseEntitlements;

export const getAccounts = (state) => state.accountState.accounts;

export const getAccount = (state, id) => getAccounts(state)[id];

export const isAccountSearchLoading = (state) => state.accountState.search.loading;
export const getAccountSearchQuery = (state) => state.accountState.search.query;
export const getAccountSearchResults = createSelector(
  [ getAccounts, (state) => state.accountState.search.resultIds ],
  (accounts, ids) => ids.map(id => accounts[id])
);

export const hasMoreAccounts = ({ accountState: { search } }) => search.resultIds.length < search.total;

// since pagination works by continually getting more (vs. stepping through
// discrete pages one by one) the offset is always the length of the list of items
export const getAccountsSearchOffset = (state) => getAccountSearchResults(state).length;

export const getSearchAccountResultsTotal = (state) => state.accountState.search.total;

export const getCurrentAccountId = (state) => state.accountState.currentAccount.id;

export const getCurrentAccount = createSelector(
  [ getAccounts, getCurrentAccountId ],
  (accounts, currentAccountId) => {
    return accounts[currentAccountId];
  }
);

// Account Members
export const getAccountMembers = (state) => state.accountState.members.members;
export const getAccountMembersTotal = (state) => state.accountState.members.total;

export const getAccountMembersList = createSelector(
  [ state => state.accountState.members.members,
    state => state.accountState.members.ordered ],
  (members, orderedIds) => orderedIds.map(id => members[id])
);

// account properties
export const getAccountProperties = (state) => state.accountState.properties;

// account spaces
export const getAccountSpacesList = createSelector(
  [ state => state.spaceState.spaces,
    state => state.accountState.spaces.ordered ],
  (spaces, orderedIds) => orderedIds.map(id => spaces[id])
);

export const hasMore = createSelector(
  [ getAccountSpacesList,
    state => state.accountState.spaces.total ],
  (spaces, total) => !!spaces && spaces.length < total);

export const getAccountSpacesTotal = (state) => state.accountState.spaces.total;
export const isAccountSpacesLoading = (state) => state.accountState.spaces.loading;

// since pagination works by continually getting more (vs. stepping through
// discrete pages one by one) the offset is always the length of the list of items
export const getOffset = createSelector(getAccountSpacesList, (spaces) => spaces.length);

// get account license/subscription entitlement by type
export const getAccountLicenseEntitlement = (state, accountId, licenseType, entitlementType) => {
  const entitlements = getEntitlements(state);
  if (accountId in entitlements &&
      licenseType in entitlements[accountId]) {
    return entitlements[accountId][licenseType][entitlementType];
  } else {
    return null;
  }
};

// get all configured entitlements for an account's cloud license
export const getAllConfiguredAccountLicenseEntitlements = createSelector(
  getEntitlements,
  (state, accountId) => accountId,
  (entitlements, accountId) => {
    if (accountId in entitlements && 'cloud' in entitlements[accountId]) {
      const licenseEntitlements = entitlements[accountId]['cloud'];
      return Object.keys(licenseEntitlements)
        .filter(type => !!licenseEntitlements[type]?.id)
        .map(entitlement => licenseEntitlements[entitlement]);
    } else {
      return [];
    }
  });

export const getCurrentAccountCloudLicense = createSelector(
  [ getAccounts, getCurrentAccountId ],
  (accounts, currentAccountId) => {
    return accounts[currentAccountId] && accounts[currentAccountId].licenses.find(l => l.type === licenseType.CLOUD);
  }
);

export const getCurrentAccountCloudSubscription = (state) => state.accountState.subscription[licenseType.CLOUD];

export const getMaxApplicationHoursLimitEntitlementStatus = (state) => state.accountState.subscriptionEntitlements[MAX_APPLICATION_HOURS_LIMIT];

export const getAccountUsageCredits = (state, accountId, licenseType) =>
  state.accountState.usageCredits[accountId] && state.accountState.usageCredits[accountId][licenseType] || undefined;

// account content
export const getAccountContent = createSelector(
  [ state => state.contentState.content, state => state.accountState.accountContent.ordered ],
  (content, orderedIds) => orderedIds.map(id => content[id])
);

export const getAccountContentTotal = (state) => state.accountState.accountContent.total;
export const isAccountContentLoading = (state) => state.accountState.accountContent.loading;

// current account applications
export const getAccountApplications = createSelector(
  [ state => state.accountState.applications.applications,
    state => state.accountState.applications.ordered ],
  (applications, ordered) => ordered.map(id => applications[id])
);

export const getAccountApplicationsTotal = (state) => state.accountState.applications.total;
