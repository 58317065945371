import * as contentActions from '~actions/content-actions';

import * as outputsAPI from '~api/outputs';

/**
 * Thunk action creator to update an output's state
 *
 * @param   {number}   id    ID of the output to update
 * @param   {string}   state The state to update the output to
 * @returns {function}       Thunk to update an output's state
 */
export const updateOutputState = (id, state) => async (dispatch) => {
  const { data: output } = await outputsAPI.updateOutput(id, { state });
  dispatch(contentActions.updateContentSuccess(output));
};
