import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import UserName from '~components/general/user-name';

/**
 * Creates a link to the details for a user record, with the
 * user's name as link text. If the user has been deleted,
 * this will display the default "ex-user" text, as determined
 * by the UserName component, with no link.
 *
 * To render a link with a user id, try <UserLink user={{ id }} />
 * instead of passing the whole user object (see UserName for details).
 *
 * @param   {object}      user User to link to
 * @returns {JSX.Element}      A link to the details of the specified user.
 */
const UserLink = ({ user }) =>
  user?.id
    ? <Link to={`/users/${user.id}`}><UserName user={user} /></Link>
    : <UserName user={user} />;

UserLink.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number
  })
};

export default UserLink;
