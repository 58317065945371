import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as spaceActions from '~actions/space-actions';
import * as uiActions from '~actions/ui-actions';

import * as dialogTypes from '~components/dialogs/dialog-types';
import ActionButton from '~components/general/action-button';
import GetMore from '~components/general/get-more';
import GettingMore from '~components/general/getting-more';
import IString from '~components/general/i-string';
import { useSortContext, withSortContext } from '~components/general/sort/sort-context';
import SortableHeader from '~components/general/sortable-header';
import UserLink from '~components/general/user-link';

import * as memberSortBy from '~constants/member-sortby';

import { translate } from '~i18n/localize';

import * as spaceSelectors from '~selectors/space-selectors';

export function SpaceMembers({ spaceId }) {
  const dispatch = useDispatch();

  const hasMore = useSelector(spaceSelectors.hasMoreMembers);
  const loading = useSelector(spaceSelectors.isSpaceMembersLoading);
  const members = useSelector(spaceSelectors.getSpaceMembersList);
  const offset = useSelector(spaceSelectors.getMembersOffset);
  const total = useSelector(spaceSelectors.getSpaceMembersTotal);

  const [ showAdmins, setShowAdmins ] = useState(true);
  const [ showModerators, setShowModerators ] = useState(true);
  const [ showContributors, setShowContributors ] = useState(true);
  const [ showViewers, setShowViewers ] = useState(true);

  const [ filter, setFilter ] = useState();
  const { order, sortBy } = useSortContext();

  const fetchMoreSpaceMembers = useCallback((offset) => {
    dispatch(spaceActions.fetchSpaceMembersPage(spaceId, filter, { [sortBy]: order }, offset));
  }, [ dispatch, filter, order, spaceId, sortBy ]);

  const handleEdit = (member) => {
    dispatch(uiActions.openDialog({
      type: dialogTypes.SPACE_MEMBER_ROLE_EDITOR_DIALOG,
      data: {
        member,
        onSubmit: (newRole) => {
          if (newRole) {
            dispatch(spaceActions.updateSpaceRole(spaceId, member.id, newRole));
          }
          dispatch(uiActions.closeDialog());
        }
      }
    }));
  };

  useEffect(() => {
    const options = {
      admin: showAdmins,
      moderator: showModerators,
      contributor: showContributors,
      viewer: showViewers
    };

    const roles = Object.keys(options).filter((current) => !options[current]);

    let filter;
    if (roles.length > 0) {
      filter = roles.length > 1 ? `role:notin:${roles.join()}` : `role:ne:${roles[0]}`;
    }

    setFilter(filter);
  }, [ showAdmins, showModerators, showContributors, showViewers ]);

  useEffect(() => {
    // get an updated "first" page every time we load
    fetchMoreSpaceMembers();
    return () => {
      dispatch(spaceActions.clearSpaceMembers());
    };
  }, [ fetchMoreSpaceMembers, dispatch ]);

  let listStatus = null;
  if (loading) {
    // getting more spinner
    listStatus = <GettingMore label={translate('space.members.loading')} />;
  } else if (hasMore) {
    // get more "button"
    listStatus = <GetMore label={translate('space.members.getMore')} onClick={() => fetchMoreSpaceMembers(offset)} autoFire />;
  }

  return (
    <div className="splitColumns withMobileMargins">
      <div className="column100">

        <div className="sectionTitle flex">
          <IString stringKey="space.members.title" />
        </div>

        <div className="flexSpaceBetween">
          <div className="smallMarginBelow">
            <div className="flexCenter">
              <div className="marginRight">
                <input
                  checked={showAdmins}
                  id="spaceMembers_admins"
                  onChange={(e) => setShowAdmins(e.target.checked)}
                  type="checkbox"
                />
                <label htmlFor="spaceMembers_admins"><IString stringKey="space.members.options.admins" /></label>
              </div>
              <div className="marginRight">
                <input
                  checked={showModerators}
                  id="spaceMembers_moderators"
                  onChange={(e) => setShowModerators(e.target.checked)}
                  type="checkbox"
                />
                <label htmlFor="spaceMembers_moderators"><IString stringKey="space.members.options.moderators" /></label>
              </div>
              <div className="marginRight">
                <input
                  checked={showContributors}
                  id="spaceMembers_contributors"
                  onChange={(e) => setShowContributors(e.target.checked)}
                  type="checkbox"
                />
                <label htmlFor="spaceMembers_contributors"><IString stringKey="space.members.options.contributors" /></label>
              </div>
              <div className="marginRight">
                <input
                  checked={showViewers}
                  id="spaceMembers_viewers"
                  onChange={(e) => setShowViewers(e.target.checked)}
                  type="checkbox"
                />
                <label htmlFor="spaceMembers_viewers"><IString stringKey="space.members.options.viewers" /></label>
              </div>
            </div>
          </div>

          <div className="flex"><IString stringKey="general.total" placeholders={{ total }} /></div>
        </div>

        <div className="tableContainer">
          <table className="contentListing sortableColumns">
            <thead>
              <tr>
                <SortableHeader field={memberSortBy.NAME}>
                  <IString stringKey="general.name" />
                </SortableHeader>
                <th><IString stringKey="general.role" /></th>
                <th className="action1 unsortable" />
              </tr>
            </thead>
            <tbody>
              {members && members.map(m =>
                <tr key={m.id}>
                  <td><UserLink user={m} /></td>
                  <td>{m?.grant?.space_role}</td>
                  <td>
                    <div className="actionBar inline showTitles">
                      <ActionButton className="edit" onClick={() => handleEdit(m)} title={translate('general.edit')} />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {listStatus}
      </div>
    </div>
  );
}

SpaceMembers.propTypes = {
  spaceId: PropTypes.number
};

export default withSortContext(SpaceMembers, { sortBy: memberSortBy.NAME });
