import PropTypes from 'prop-types';
import React from 'react';

import IString from '~components/general/i-string';
import Time from '~components/general/time';

function LicenseSuspension({
  license: {
    exempt, exempt_until, suspended, suspended_reason, suspended_until, suspension_pending, suspension_pending_until
  }
}) {
  if (suspended) {
    return (
      <>
        <IString
          placeholders={{ when: <Time value={suspended_until} /> }}
          stringKey="account.license.suspension.suspended"
        />
        <dl className="smallMarginAbove">
          <dt><IString stringKey="account.license.suspension.suspended_reason" /></dt>
          <dd>{ suspended_reason }</dd>
        </dl>
      </>
    );
  } else if (exempt) {
    return (
      <IString
        placeholders={{ when: <Time value={exempt_until} /> }}
        stringKey="account.license.suspension.exempt"
      />
    );
  } else if (suspension_pending) {
    return (
      <IString
        placeholders={{ when: <Time value={suspension_pending_until} /> }}
        stringKey="account.license.suspension.suspension_pending"
      />
    );
  } else {
    return <em><IString stringKey="account.license.suspension.not_suspended" /></em>;
  }
}

LicenseSuspension.propTypes = {
  license: PropTypes.shape({
    exempt: PropTypes.bool,
    exempt_until: PropTypes.string,
    suspended: PropTypes.bool,
    suspended_reason: PropTypes.string,
    suspended_until: PropTypes.string,
    suspension_pending: PropTypes.bool,
    suspension_pending_until: PropTypes.string
  })
};

export default LicenseSuspension;
