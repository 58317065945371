import * as types from '~actions/action-types';

const initialState = {
  currentTask: undefined
};

const taskReducer = function (state = initialState, action) {

  switch (action.type) {

    case types.SET_CURRENT_TASK:
      return {
        ...state,
        currentTask: action.taskData
      };

    case types.CLEAR_CURRENT_TASK:
      return {
        ...state,
        currentTask: null
      };

  }

  return state;
};

export default taskReducer;
