import PropTypes from 'prop-types';
import React from 'react';

import AccountLink from '~components/general/account-link';
import IString from '~components/general/i-string';
import Time from '~components/general/time';

const LegacyApplicationDetailsTable = ({ application }) => {

  return (
    <table className="propertiesTable">
      <tbody>
        <tr>
          <th className="label"><IString stringKey="general.id" /></th>
          <td className="value">{application.id}</td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="general.name" /></th>
          <td className="value">{application.name}</td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="general.type" /></th>
          <td className="value">{application.type}</td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="general.status" /></th>
          <td className="value">{application.status}</td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="application.details.scheduler" /></th>
          <td className="value">{application.scheduler}</td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="application.details.url" /></th>
          <td className="value">{application.url}</td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="general.account" /></th>
          <td className="value"><AccountLink accountId={application?.account_id} /></td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="general.created_time" /></th>
          <td className="value"><Time value={application.created_time} /></td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="general.updated_time" /></th>
          <td className="value"><Time value={application.updated_time} /></td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="general.visibility" /></th>
          <td className="value">{application.deployment?.properties['application.visibility']}</td>
        </tr>
      </tbody>
    </table>
  );
};

LegacyApplicationDetailsTable.propTypes = {
  application: PropTypes.shape({
    account_id: PropTypes.number,
    created_time: PropTypes.string,
    deployment: PropTypes.shape({
      properties: PropTypes.shape({
        'application.visibility': PropTypes.string
      })
    }),
    id: PropTypes.number,
    name: PropTypes.string,
    scheduler: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    updated_time: PropTypes.string,
    url: PropTypes.string,
    visibility: PropTypes.string
  }).isRequired
};

export default LegacyApplicationDetailsTable;
