import * as types from '~actions/action-types';

const initialState = {
  currentUser: undefined
};

const authReducer = function (state = initialState, action) {

  switch (action.type) {

    case types.SET_AUTH_USER:
      return Object.assign({}, state, { currentUser: action.user });

    case types.CLEAR_AUTH_USER:
      return Object.assign({}, state, { currentUser: null });

  }

  return state;
};

export default authReducer;
