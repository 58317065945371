import PropTypes from 'prop-types';
import React from 'react';

import classnames from '~components/util/classnames';

function InfoToggle(props) {
  const { label, toggled, onToggle } = props;

  return (
    <button
      aria-label={label}
      type="button"
      className={classnames({ 'action toggleInfo first': true, 'current': toggled })}
      onClick={() => onToggle(!toggled)}
    />
  );
}

InfoToggle.propTypes = {
  label: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  toggled: PropTypes.bool.isRequired
};

export default InfoToggle;
