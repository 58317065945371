import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as authActions from '~actions/auth-actions';

import * as userSelectors from '~selectors/auth-selectors';

// This component is a placeholder which waits for all the required pieces --
// user, (ui) ready, and isAuthenticated -- before sending the
// user on to whatever they need to do. this compoent is only hit during login
function LoginPage({ dispatch, isAuthenticated }) {

  const history = useHistory();

  useEffect(() => {

    const code = new URL(document.location).searchParams.get('code');
    const state = new URL(document.location).searchParams.get('state');

    // to the dance
    dispatch(authActions.login(code, state));

  }, [ dispatch ]);

  useEffect(() => {
    if (isAuthenticated) {
      const returnTo = '/';
      history.replace(returnTo);
    }
  }, [ history, isAuthenticated ]);

  return (
    <div>
      Logging in...
    </div>
  );
}

LoginPage.propTypes = {
  dispatch: PropTypes.func,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: userSelectors.isAuthenticated(state)
  };
};

export default connect(mapStateToProps)(LoginPage);

