import { useCallback, useEffect, useRef, useState } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [ windowDimensions, setWindowDimensions ] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

/**
 * Hook to generate an "open" state that can be closed when clicking anywhere on the page
 *
 * @param   {boolean} initialState The initial value for the open state
 * @returns                        The open state and a function to update it
 */
export const useOpenState = (initialState = false) => {
  const [ open, setOpen ] = useState(initialState);

  const handleClickAnywhere = useCallback(() => {
    document.removeEventListener('click', handleClickAnywhere);
    setOpen(false);
  }, [ setOpen ]);

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClickAnywhere);
    } else {
      document.removeEventListener('click', handleClickAnywhere);
    }
    return () => document.removeEventListener('click', handleClickAnywhere);
  }, [ open, handleClickAnywhere ]);

  return [ open, setOpen ];
};

/**
 * Hook to listen for a native browser event. The listener is intentionally attached to the window in the capturing
 * phase so that it is executed before any React synthetic events which are attached to the document.
 *
 * This is useful when you need to control event bubbling for events that are handled natively. See above how
 * useOpenState() attaches a native event handler to the document. Using this function for example will allow you to
 * stop propagation of a native event so that event will not be executed by an event handler that was added natively.
 *
 * @param   {string}   type     The type of event to listen to
 * @param   {function} listener The function to execute when the given event is dispatched within the ref
 * @returns                     The ref to attach to the node you wish to listen to these native events for
 */
export const useNativeEvent = (type, listener) => {
  const ref = useRef();

  const callback = useCallback((e) => {
    if (ref.current.contains(e.target)) {
      listener(e);
    }
  }, [ listener ]);

  useEffect(() => {
    window.addEventListener(type, callback, true);
    return () => window.removeEventListener(type, callback, true);
  }, [ type, callback ]);

  return ref;
};
