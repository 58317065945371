import { combineReducers } from 'redux';

import * as types from '~actions/action-types';

import { removeOne, update, updateMany, updateOne } from '~reducers/reducer-utils';

const applicationsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_APPLICATION_SEARCH_SUCCESS:
    case types.FETCH_ACCOUNT_APPLICATIONS_SUCCESS:
      return updateMany(state, action.applications);

    case types.DEPLOY_APPLICATION_SUCCESS:
    case types.FETCH_APPLICATION_SUCCESS:
    case types.RESUME_APPLICATION_SUCCESS:
    case types.SUSPEND_APPLICATION_SUCCESS:
    case types.RESTART_APPLICATION_SUCCESS:
    case types.TERMINATE_APPLICATION_SUCCESS:
      return updateOne(state, action.application);

    case types.PURGE_APPLICATION_SUCCESS:
      return removeOne(state, action.applicationId);

  }
  return state;
};

const currentApplicationReducer = (state = null, action) => {
  switch (action.type) {
    case types.SET_CURRENT_APPLICATION:
      return action.applicationId;

    case types.CLEAR_CURRENT_APPLICATION:
      return null;
  }

  return state;
};

const authorizationsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_APPLICATION_AUTHORIZATIONS_SUCCESS:
      return update(state, {
        [action.applicationId]: {
          total: action.total,
          authorizations: action.authorizations
        }
      });
  }

  return state;
};

const logsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_APPLICATION_LOGS_SUCCESS:
      return update(state, { [action.applicationId]: action.applicationLogs });
  }

  return state;
};

const searchReducer = (state = { loading: false, query: '', resultIds: [] }, action) => {
  switch (action.type) {
    case types.SET_APPLICATION_SEARCH:
      return update(state, { loading: true, query: action.query, resultIds: [] });

    case types.FETCH_APPLICATION_SEARCH_SUCCESS:
      return update(state, { loading: false, resultIds: action.applications.map(a => a.id) });

    case types.CLEAR_APPLICATION_SEARCH:
    case types.FETCH_APPLICATION_SEARCH_FAILURE:
      return update(state, { loading: false, query: '', resultIds: [] });
  }

  return state;
};

export default combineReducers({
  applications: applicationsReducer,
  applicationAuthorizations: authorizationsReducer,
  applicationLogs: logsReducer,
  currentApplicationId: currentApplicationReducer,
  search: searchReducer
});
