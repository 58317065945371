import PropTypes from 'prop-types';
import React, { useState } from 'react';

import * as dialogs from '~components/dialogs/dialog-types';
import ModalDialog from '~components/dialogs/modal-dialog';
import IString from '~components/general/i-string';
import InfoToggle from '~components/general/info-toggle';
import RolePicker from '~components/general/role-picker';

import * as role from '~constants/role';

export function AccountCloudRoleEditorDialog({ data: { member, onSubmit }  }) {
  const [ accountCloudRole, setAccountCloudRole ] = useState(member.grant.cloud_role);
  const [ showAccountCloudRoleInfo, setShowAccountCloudRoleInfo ] = useState(false);

  const changed = accountCloudRole !== member.grant.cloud_role;
  const roles = role.accountRoles;

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(accountCloudRole);
  };

  return (
    <ModalDialog type={dialogs.ACCOUNT_CLOUD_ROLE_EDITOR_DIALOG} >
      <div className="purpose">
        <IString stringKey="account.members.roles.dialog.edit.purpose" />
      </div>
      <div className="chunk">
        <label htmlFor="roles" className="fieldLabel">
          <IString stringKey="account.members.roles.dialog.edit.label" />
        </label>
        <div className="actionBar inline">
          <InfoToggle onToggle={setShowAccountCloudRoleInfo} toggled={showAccountCloudRoleInfo} />
        </div>
        {showAccountCloudRoleInfo &&
          <div className="toggleableInfo">
            <ul><IString stringKey="account.members.roles.accountRoleInfoListItems" /></ul>
          </div>
        }
      </div>
      <RolePicker currentRole={member.grant.cloud_role} roles={roles} roleContext="account.members" onChange={setAccountCloudRole} />

      <form noValidate onSubmit={handleSubmit}>
        <div className="details">
          <div className="chunk messageContainer mark important">
            <div className="message">
              <IString stringKey="account.members.roles.dialog.edit.confirmation" placeholders={{ member: member.display_name }} />
            </div>
          </div>
        </div>
        <div className="chunk actions">
          <button type="submit" disabled={!changed}><IString stringKey="general.save" /></button>
        </div>
      </form>
    </ModalDialog>
  );
}

AccountCloudRoleEditorDialog.propTypes = {
  data: PropTypes.shape({
    member: PropTypes.shape({
      display_name: PropTypes.string.isRequired,
      grant: PropTypes.shape({
        cloud_role: PropTypes.string.isRequired
      }),
      id: PropTypes.number
    }),
    onSubmit: PropTypes.func.isRequired
  }).isRequired
};

export default AccountCloudRoleEditorDialog;
