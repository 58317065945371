import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import ApplicationPage from '~components/applications/application-page';
import ApplicationSearch from '~components/applications/application-search';
import StandardPage from '~components/pages/standard-page';

function ApplicationsPage() {

  const { path } = useRouteMatch();

  return (
    <StandardPage>
      <Switch>
        <Route path={`${path}/:applicationId`}>
          <ApplicationPage />
        </Route>
        <Route path={path}>
          <ApplicationSearch />
        </Route>
      </Switch>
    </StandardPage>
  );
}

export default ApplicationsPage;
