import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import HTMLText from '~components/general/html-text';

import { translateElement } from '~i18n/localize';

// Creates <span> with localized content based on the stringKey and any provided placeholders.
// The localized content can contain HTML and/or string placeholders, or React element placeholders.
class IString extends React.Component {

  render() {
    const { className, currentLanguage, placeholders, stringKey, trustPlaceholders } = this.props;

    const translated = translateElement(currentLanguage, stringKey, placeholders);

    // key not found
    if (translated === null) {
      return <span className={className} style={{ backgroundColor: '#ff0000', color: '#fff' }}>{this.props.stringKey + ' not found'}</span>;
    } else if (typeof translated === 'string' && (!placeholders || trustPlaceholders)) {
      // danger! this renders whatever it's given as HTML
      // NOT TO BE COMBINED WITH RAW USER INPUT.

      // if there are *no* placeholders, this string came from our localization
      // and any HTML in it is ours.

      // otherwise, if we're here, trustPlaceholders was explicitly set to true
      return <HTMLText className={className}>{translated}</HTMLText>;
    } else {
      return <span className={className}>{translated}</span>;
    }
  }
}

IString.defaultProps = {
  trustPlaceholders: false
};

IString.propTypes = {
  className: PropTypes.string,
  currentLanguage: PropTypes.string,
  placeholders: PropTypes.object,
  stringKey: PropTypes.string.isRequired,
  trustPlaceholders: PropTypes.bool
};

const mapStateToProps = function (store) {
  return {
    currentLanguage: store.uiState.currentLanguage
  };
};

export default connect(mapStateToProps)(IString);
// export the unconnected component for testing
export { IString };
