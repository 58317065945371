import PropTypes from 'prop-types';
import React, { useState } from 'react';

import GetMore from '~components/general/get-more';
import GettingMore from '~components/general/getting-more';
import IString from '~components/general/i-string';

import { translate } from '~i18n/localize';

/**
 * Component to wrap around a <table> of data that will
 * render the UI elements necessary for pagination.
 *
 * The component will render a "GetMore" button to fetch
 * the next chunk of data if there are records to still fetch
 *
 * The component will render a loading indicator while the
 * next chunk of data is being fetched.
 *
 * The component will render an empty table indicator
 * if there are no records to display.
 *
 * The component will render the total number of records
 *
 * The `getMore` prop is a callback function that takes an `offset`
 * (a number) to fetch the next chunk of data. The callback is fired
 * when the user clicks the "GetMore" button.
 *
 * @param   {JSX.Element}        children The <table> component to paginate
 * @param   {object}             data     Data to currently display in the table
 * @param   {function}           getMore  Callback function that will fetch the next chunk of data.
 *                                        Must accept an `offset` parameter
 * @param   {JSX.Element|string} header   Any header content to render above the <table>
 * @param   {number}             total    Total number of records
 * @returns {JSX.Element}                 Component to paginate a <table> of data
 */
const PaginatedTableContainer = ({ children, data, getMore, header, total }) => {
  const [ loading, setLoading ] = useState(false);
  const hasMore = data?.length < total;
  const emptyList = !loading && data?.length === 0;

  const fetchMoreData = async (offset) => {
    try {
      setLoading(true);
      await getMore(offset);
    } catch (error) {
      /* ignored */
    } finally {
      setLoading(false);
    }
  };

  let listStatus = null;
  if (loading) {
    // getting more spinner
    listStatus = <GettingMore label={translate('general.loading')} />;
  } else if (hasMore) {
    // get more "button"
    listStatus =
      <GetMore
        autoFire
        label={translate('general.getMore')}
        onClick={() => fetchMoreData(data.length)}
      />;
  }

  return (
    <div className="splitColumns withMobileMargins">
      <div className="column100">
        <div className="flexSpaceBetween">
          <div className="smallMarginBelow">
            {header}
          </div>
          <div className="right smallMarginBelow">
            <IString stringKey="general.total" placeholders={{ total }} />
          </div>
        </div>
        <div className="tableContainer">
          {emptyList
            ? <div className="emptyListMessage"><IString stringKey="general.empty" /></div>
            : children
          }
        </div>
        {listStatus}
      </div>
    </div>
  );
};

PaginatedTableContainer.propTypes = {
  children: PropTypes.node,
  data: PropTypes.array,
  getMore: PropTypes.func.isRequired,
  header: PropTypes.node,
  total: PropTypes.number
};

export default PaginatedTableContainer;
