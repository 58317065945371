export default {
  account: {
    applications: {
      title: 'Account Applications',
      table: {
        id: 'Application ID'
      }
    },
    content: {
      title: 'Account Content'
    },
    delete: {
      dialog: {
        purpose: 'Delete Account: {name} (id: {id})',
        warning: 'Deleting an account is permanent and cannot be undone.',
        willDelete: 'Below is a list of resources owned by this account that will be deleted as part of this process. Would you like to proceed?',
        subscription: {
          title: 'Account Subscriptions',
          cloud: 'Posit Cloud subscription',
          shinyapps: 'shinyapps.io subscription',
          exists: '<strong>This account has not been downgraded.</strong> All subscriptions must be canceled and this account must be downgraded to "free" before it can be deleted.'
        },
        applications: {
          title: 'Applications ({total})',
          unterminated: '<strong>This account has unterminated applications.</strong> Applications must be terminated before they can be cleaned up by this process. Please ensure these applications have been terminated and try again.'
        },
        content: {
          title: 'Content ({total})',
          untrashed: '<strong>This account has untrashed content.</strong> Content must be trashed before it can be cleaned up by this process. Please ensure all content has been trashed and try again.'
        },
        spaces: 'Spaces ({total})'
      },
      status: {
        failure: {
          application: 'Application {id} could not be deleted. Please check the status of this application.',
          content: 'Content {id} could not be deleted. Please check the status of this content.',
          space: 'Space {id} could not be deleted. Please ensure that this space contains no content.'
        },
        success: 'Success! Account #{id} has been deleted.'
      }
    },
    details: {
      title: 'Account Details',
      transfer: 'Change Owner',
      not_suspended: 'Not Suspended',
      suspended_until: 'Suspended Until',
      suspended_reason: 'Suspended Reason',
      sso_enabled: 'SSO Enabled',
      sso_hint: 'SSO Hint',
      dialog: {
        edit: {
          error: {
            ssoHint: 'Please enter the proper format (alphanumeric and can include dashes) ',
            suspendedUntil: 'Please enter the proper time format (e.g. 2021-09-01T00:00:00)'
          },
          purpose: 'Edit Account ID #{id}',
          sso_hint_optional: 'Must be alphanumeric and can include dashes',
          suspended_until_optional: 'e.g. 2021-09-01T00:00:00'
        },
        transfer: {
          purpose: 'Transfer Ownership of Account ID #{id}',
          details: 'This account is currently owned by {email}.',
          transferDetails: 'Transferring account ownership will remove <strong>{email}</strong> from this account, and from any spaces that this account owns. As a result, <strong>{email}</strong> will no longer have access to any resources owned by this account (e.g. spaces and content), unless they are granted access again in the future.',
          error: 'valid email required',
          email: 'Transfer to',
          branding: {
            details: 'Upon account transfer completion, the new owner of the account will receive an email about the transfer. The email can be branded to come from shinyapps.io or Posit Cloud. Please choose the branding for the email based off the product the account is for.',
            title: 'Email Branding',
            cloud: 'Posit Cloud',
            shinyapps: 'shinyapps.io'
          },
          placeholder: 'new owner\'s email',
          content: {
            title: '<strong>This account has content in the owner\'s personal workspace.</strong> Content must be moved out of the owner\'s personal workspace before the account can be transferred.',
            total: 'Content ({total})'
          }
        }
      }
    },
    license: {
      title: '{type} License',
      dialog: {
        details: 'Are you sure you want to save changes to Account #{id}?',
        purpose: 'Save Changes to Account #{id}',
        properties: {
          name: 'License Name',
          status: 'License Status',
          expires: 'License Expires',
          license_seats: {
            academic: 'Academic',
            disabled: {
              individual: 'You cannot modify these seats on an individual account',
              subscription: 'You cannot modify these seats directly. You must update them through the subscription.'
            },
            individual: 'Individual Seats',
            instructor: 'Instructor Seats',
            member: 'Member Seats',
            nonAcademic: 'Non-Academic',
            student: 'Student Seats (including TAs)',
            title: 'License Seats'
          },
          suspended_until: 'License Suspended Until',
          suspended_reason: 'License Suspended Reason',
          exempt_from_suspension_until: 'License Exempt From Suspension Until'
        },
        title: 'Edit Account License',
        date_example: 'Full date in UTC, e.g. 2021-09-01T00:00:00'
      },
      headers: {
        cycle_start_time: 'Cycle Start Time',
        cycle_end_time: 'Cycle End Time',
        individual: 'Individuals: {max}',
        instructor: 'Instructors: {max}',
        license_seats: 'License Seats',
        member: 'Members: {max}',
        student: {
          count: 'Students: {max}',
          unlimited: 'Students: <em>Unlimited</em>'
        },
        suspension_status: 'Suspension Status'
      },
      quickConfigure: {
        label: 'Quick Configure',
        dialog: {
          entitlements: 'This will <strong>configure</strong> the following entitlement overrides:',
          existing: '<strong>All existing entitlement overrides will be reset.</strong>',
          seats: {
            student: {
              ['cloud-instructor']: 'For <strong>unlimited</strong>, clear this field.',
              ['cloud-instructor-student-pay']: 'These seats are <strong>unlimited</strong> for this configuration.',
              ['cloud-instructor-fee-per-student']: 'These seats are <strong>required</strong> for this configuration.'
            }
          },
          select: {
            label: 'Configure As'
          },
          status: 'Updating seats and entitlements. Please wait.',
          success: 'Success! This license has been configured with ${type} entitlements and the specified seats.',
          title: 'Quick-Configure License'
        }
      },
      suspension: {
        suspended: 'Suspended until {when}',
        suspended_reason: 'Suspended Reason',
        exempt: 'Exempt until {when}',
        suspension_pending: 'Grace period until {when}',
        not_suspended: 'not suspended'
      },
      entitlement: {
        configuration: {
          configured: 'configured',
          default: 'default',
          max: 'max={value}',
          min: 'min={value}',
          unlimited: 'unlimited'
        },
        description: {
          account_compute_hours_soft_max: 'Max number of compute hours an account can consume in<br/>a billing period before they are either suspended (free licenses)<br/>or start getting charged for compute hour overages (paid licenses).',
          account_job_run_cpu_range: 'Range of how many CPUs a job run in an account can have',
          account_job_run_execution_time_range: 'Range of minutes users can select from<br/> to set a timeout for job execution.',
          account_job_run_mem_range: 'Range of how many GBs a job run in an account can have',
          account_member_max: 'Max number of users an account can have with any role that<br/>is not "participant".',
          account_member_project_concurrent_max: 'Max number of concurrent projects that<br/> any individual user can have running at a time.',
          account_output_cpu_range: 'Range of how many CPUs an output in an account can have.',
          account_output_max: 'Max number of outputs an account can have across all of its spaces.',
          account_output_mem_range: 'Range of how many GBs of RAM an output in an account can have.',
          account_participant_max: 'Max number of users an account can have with the "participant" role.',
          account_private_space_max: 'Max number of spaces an account can have with "private" visibility.',
          account_project_cpu_range: 'Range of how many CPUs a project in an account can have.',
          account_project_max: 'Max number of projects an account can have across all of its spaces.',
          account_project_mem_range: 'Range of how many GBs of RAM a project in an account can have.',
          account_project_timeout_kill_minutes_range: 'Range of minutes users can pick from for how long their project<br/>sessions can be idle before timing out.',
          account_public_space_max: 'Max number of spaces an account can have with "public" visibility.',
          account_require_paid_participants_flag: 'True if participants on an account are required to have their own<br/>paid subscriptions in order to make or access content in an account.<br/>False otherwise. Used for cloud-instructor-student-pay licenses, and some custom license configurations.',
          space_connection_max: 'Max number of data connections a space can have.',
          space_content_max: 'Max content (projects, output) a space can have.',
          space_custom_list_max: 'Max number of custom lists a space can have.',
          space_custom_list_content_max: 'Max number of content a custom list in a space can have.',
          space_member_max: 'Max number of members (including pending invitations) a space can have.',
          space_template_max: 'Max number of project templates a space can have.'
        },
        editor: {
          enabled: 'Enabled',
          max: 'Max',
          min: 'Min',
          title: 'Edit Account Entitlement: {entitlement}',
          unlimited: 'For <strong>unlimited</strong>, clear this field.',
          warning: {
            nowThere: 'Changes to an account\'s seat count should be made on the Cloud License, not this entitlement.',
            wasHere: {
              account_member_max: 'The account_member_max license entitlement has been superseded by License Seats. This entitlement now acts as an upper limit on the number of account members in an account to preserve system stability.',
              account_participant_max: 'The account_participant_max license entitlement has been superseded by License Seats. This entitlement now acts as an upper limit on the number of account participants in an account to preserve system stability.'
            }
          }
        },
        table: {
          columns: {
            configuration: 'Configuration',
            name: 'Entitlement Name'
          },
          sections: {
            account_membership: 'Account Membership Entitlements',
            content: 'Content Entitlements',
            job_run: 'Job Run Entitlements',
            output: 'Output Entitlements',
            project: 'Project Entitlements',
            space: 'Space Entitlements'
          }
        }
      }
    },
    menu: {
      details: 'Details',
      license: 'License',
      members: 'Members',
      properties: 'Properties',
      subscription: 'Subscription',
      usage: 'Usage'
    },
    members: {
      title: 'Account Members',
      getMore: 'Get more members',
      loading: 'Getting members',
      table: {
        checkboxes: {
          owner: 'Owner',
          admins: 'Admins',
          moderators: 'Moderators',
          contributors: 'Contributors',
          participants: 'Participants',
          members: 'Members'
        }
      },
      roles: {
        dialog: {
          edit: {
            purpose: 'Edit Account Cloud Role',
            label: 'Account Cloud Roles:',
            confirmation: 'You are editing {member}\'s account cloud role'
          }
        },
        admin: {
          name: 'Admin'
        },
        moderator: {
          name: 'Moderator'
        },
        contributor: {
          name: 'Contributor'
        },
        participant: {
          name: 'Participant'
        },
        accountRoleInfoListItems: '<li>If this account is an <strong>individual account</strong>, the user will not see this in the account in Posit Cloud, as membership is not surfaced there for individual accounts.</li>' +
        '<li>If this account is an  <strong>organization account</strong>, the user will have access to the account in Posit Cloud as per the updated account role. The <a href="https://posit.cloud/learn/guide#organization-account-members" rel="noopener noreferrer" target="_blank">guide</a> has additional information about what these roles imply.</li>' +
        '<li>In both cases, account members will continue to have space access as per their space role. Note: admin and moderator roles grant users greater access to the account itself, and imply admin access to all spaces the account owns, whether or not explicit space membership exists.</li>'
      },
      menu: {
        cloud: 'Cloud',
        shinyapps: 'shinyapps.io'
      }
    },
    properties: {
      none: 'No Properties',
      section: 'Account Properties',
      modal: {
        create: {
          title: 'Create Account Property'
        },
        edit: {
          title: 'Edit Account Property'
        },
        delete: {
          details: 'Are you sure you want to delete the {name} property?',
          purpose: 'Delete Property'
        }
      }
    },
    search: {
      getMore: 'Get more accounts',
      title: {
        results: 'Account Search: Results',
        prompt: 'Account Search'
      },
      prompt: 'search for an Account by name or ID',
      table: {
        licenses: 'Licenses'
      }
    },
    spaces: {
      getMore: 'Get more spaces',
      loading: 'Getting spaces',
      none: 'no spaces',
      section: 'Account Spaces',
      table: {
        id: 'Space ID',
        user_count: 'Users'
      }
    },
    subscription: {
      actions: {
        cancel: 'Cancel Subscription',
        downgrade: 'Downgrade',
        upgrade: 'Upgrade'
      },
      activity: {
        downgrade: 'Downgrading account',
        upgrade: 'Upgrading account'
      },
      cloud: {
        title: 'Cloud Subscription',
        license: 'License',
        plan: 'Plan',
        cycle_anchor: 'Billing Cycle Anchor',
        period_start: 'Current Period Start',
        period_end: 'Current Period End',
        canceled: 'Canceled',
        subscription_end: 'Subscription End',
        cancel_at_period_end: 'Cancel at Period End',
        scheduled_renewal: 'Scheduled Renewal'
      },
      delete: {
        cloud: {
          details: 'Are you sure you want to cancel the {subscriptionLicense} subscription for Account #{id}?',
          result: {
            custom: 'This will cancel the current subscription effective immediately.',
            default: 'This will cancel the current subscription effective immediately, and reset the license to Cloud Free.',
            success: 'Success! This Cloud subscription has been canceled and the license has set to Cloud Free.'
          }
        },
        shinyapps: {
          details: 'Are you sure you want to downgrade Account #{id}\'s {subscriptionName} shinyapps.io subscription to Free?',
          result: 'Downgrading this subscription will first terminate all shinyapps.io applications owned by the account.',
          success: 'Success! This account has been downgraded to a Free shinyapps.io subscription.'
        },
        title: 'Cancel Subscription for #{id}'
      },
      empty: 'no subscription',
      manage: {
        error: {
          shinyappsStripe: 'This account has an active shinyapps.io Stripe subscription, which must be canceled first.'
        }
      },
      shinyapps: {
        title: 'shinyapps.io Subscription',
        cycle_end_date: 'Current Cycle End',
        cycle_start_date: 'Current Cycle Start',
        hours_used_in_cycle: 'Active hours used in cycle',
        max_hours: 'Maximum active hours',
        plan: 'Plan',
        premium: 'Premium',
        start_date: 'Started'
      },
      upgrade: {
        title: 'Upgrade Subscription for #{id}',
        shinyapps: {
          details: 'Upgrade the shinyapps.io subscription for Account #{id} to Professional?',
          success: 'Success! This account has been upgraded to a shinyapps.io Professional subscription.'
        }
      }
    },
    usage: {
      title: 'Account Usage'
    },
    usageCredits: {
      creditHours: 'Credit Hours',
      deleteDetails: 'Are you sure you want to delete usage credits?',
      deletePurpose: 'Delete Usage Credits',
      expireCycles: 'Cycles Until Credits Expire',
      modal: {
        create: {
          title: 'Creating Usage Credits'
        },
        edit: {
          details: 'Are you sure you want to save changes to usage credits?',
          purpose: 'Save Changes to Usage Credits',
          title: 'Editing Usage Credits'
        }
      },
      noCredits: 'No usage credits',
      section: 'Usage Credits'
    }
  },
  application: {
    authorizations: {
      columns: {
        access: 'Access'
      },
      access: {
        direct: 'Application',
        membership: 'Member of {resource}'
      },
      title: 'Authorized Users'
    },
    containers: {
      columns: {
        template: 'Template'
      },
      title: 'Application Containers'
    },
    deployment: {
      connect: {
        version: 'Application Connect Version'
      },
      empty: 'Application Has No Deployment',
      instances: {
        'agent-pool': 'Instances Agent Pool',
        'idle-threshold': 'Instances Idle Threshold',
        load: {
          factor: 'Instances Load Factor'
        },
        start: 'Instances Start',
        template: 'Instances Template'
      },
      created_time: 'Created Time',
      id: 'Deployment ID',
      package: {
        cache: 'Application Package Cache'
      },
      shiny: {
        timeout: {
          conn: 'Shiny Connection Timeout',
          idle: 'Shiny Idle Timeout',
          init: 'Shiny Init Timeout',
          read: 'Shiny Read Timeout',
          session: 'Shiny Session Timeout'
        },
        scheduler: {
          load: {
            factor: 'Shiny Scheduler Load Factor'
          },
          max: {
            processes: 'Shiny Scheduler Max Processes',
            requests: 'Shiny Scheduler Max Requests'
          },
          min: {
            processes: 'Shiny Scheduler Min Processes'
          }
        }
      },
      title: 'Application Deployment',
      updated_time: 'Updated Time'
    },
    details: {
      actions: {
        deploy: 'Deploy',
        export: 'Export',
        purge: 'Purge',
        resume: 'Resume',
        restart: 'Restart',
        suspend: 'Suspend',
        terminate: 'Terminate'
      },
      clone: 'Clone of',
      copy: 'Copy of',
      cpu: 'CPU',
      hibernating: 'Hibernating (in cold storage)',
      idleTimeout: 'Timeout Minutes (idle session timeout)',
      notSource: 'This application is not a source for a project or an output.',
      ram: 'RAM',
      runningTimeout: 'Timeout Kill Minutes (running session timeout) - only applies to IDE',
      scheduler: 'Scheduler',
      title: 'Application Details',
      url: 'URL'
    },
    export: {
      title: {
        downloading: 'Export Application',
        ready: 'Application Export Complete'
      },
      applicationIsCold: '<p>This application has been moved to cold storage and may take up to 2 minutes to export.</p>',
      creating: 'Creating application file for Application {applicationId}.',
      created: 'Application export complete. You can now download Application {applicationId}.',
      errorMessage: 'Sorry, something went wrong while exporting your application. Please retry exporting.'
    },
    logs: 'Application Logs',
    menu: {
      containers: 'Containers',
      deployment: 'Deployment',
      details: 'Details',
      logs: 'Logs',
      users: 'Authorized Users',
      metrics: 'Metrics'
    },
    metrics: {
      connections: {
        connect_count: 'connections',
        subtitle: 'average connections per day',
        title: 'Connections'
      },
      cpu: {
        subtitle: 'average CPU jiffies per day',
        title: 'CPU Usage',
        usage_in_usermode: 'CPU jiffies'
      },
      memory: {
        subtitle: 'average GBs per day',
        title: 'Memory Usage',
        total_rss: 'GBs'
      },
      workers: {
        connect_procs: 'workers',
        subtitle: 'average workers per day',
        title: 'Workers'
      },

      labels: {
        cache: 'Cache',
        kernel: 'Kernel',
        rss: 'RSS',
        shinyConnections: 'Shiny Connections',
        user: 'User',
        workerProcesses: 'Worker Processes'
      },
      measurements: {
        connections: 'Connections',
        jiffies: 'CPU Jiffies',
        megabytes: 'MegaBytes (MB)',
        processes: 'R Processes'
      },
      charts: {
        cpu: 'CPU Usage',
        memory: 'Memory Usage',
        shinyConnections: 'Shiny Connections',
        workerProcesses: 'Worker Processes'
      },
      connectionsInfo: '<p><strong>Shiny Connections:</strong> The number of connections.</p>',
      cpuInfo: '<p><strong>User:</strong> The amount of CPU time used by user space code.</p>' +
      '<p><strong>Kernel:</strong> The amount of CPU time used by kernel space code.</p>',
      memoryInfo: '<p><strong>RSS:</strong> The amount of memory that does not correspond to anything on disk: stacks, heaps, and anonymous memory maps.</p>' +
      '<p><strong>Cache:</strong> The amount of memory used that can be associated precisely with a block on a block device.</p>',
      processesInfo: '<p><strong>Worker Processes:</strong> The number of worker processes.</p>',
      title: 'Application Metrics'
    },
    search: {
      prompt: 'Search for an Application by name or ID',
      title: {
        prompt: 'Application Search',
        results: 'Application Search: Results'
      }
    },
    task: {
      dialog: {
        deploy: {
          details: 'Are you sure you want to deploy the application? This may take some time.',
          purpose: 'Deploy Application'
        },
        purge: {
          details: 'Are you sure you want to purge the application? <strong>This cannot be undone</strong>.',
          purpose: 'Purge Application'
        },
        restart: {
          details: 'Are you sure you want to restart the application? This may take time.',
          purpose: 'Restart Application'
        },
        resume: {
          details: 'Are you sure you want to resume the application? This may take time.',
          purpose: 'Resume Application'
        },
        suspend: {
          details: 'Are you sure you want to suspend the application? This may take time.',
          purpose: 'Suspend Application'
        },
        terminate: {
          details: 'Are you sure you want to terminate the application? This may take time.',
          purpose: 'Terminate Application'
        }
      },
      error: 'Task {task_id} failed: {error}'
    }
  },
  content: {
    details: {
      actions: {
        updateState: {
          active: 'Success! {name} #{id} has been restored.',
          archived: 'Success! {name} #{id} has been archived.',
          deleted: 'Success! {name} #{id} has been deleted.',
          trashed: 'Success! {name} #{id} has been trashed.'
        }
      },
      dialogs: {
        delete: {
          prompt: 'Are you sure you want to delete {name}?',
          purpose: 'Delete Content: {name} (#{id})',
          warning: 'Content deletion is permanent and cannot be undone.'
        }
      },
      table: {
        id: 'Content ID',
        parent_id: 'Parent Project',
        project_id: 'Published From',
        source_id: 'Source Application'
      },
      title: {
        output: 'Output Details',
        project: 'Project Details'
      }
    },
    search: {
      getMore: 'Get more content',
      prompt: 'search for Content by name or ID',
      title: {
        prompt: 'Content Search',
        results: 'Content Search: Results'
      }
    },
    title: {
      output: 'Output {name} (#{id})',
      project: 'Project {name} (#{id})'
    },
    usage: {
      context: 'Context: {account}',
      title: 'Content Usage'
    }
  },
  general: {
    account: 'Account',
    add: 'Add',
    application: 'Application',
    archive: 'Archive',
    author: 'Author',
    cancel: 'Cancel',
    content: 'Content',
    created: 'Created',
    created_time: 'Created Time',
    delete: 'Delete',
    display_name: 'Display Name',
    download: 'Download',
    edit: 'Edit',
    email: 'Email',
    empty: 'no results',
    error: {
      unauthorized: 'Sorry, but you do not have access.'
    },
    expires: 'Expires',
    exUser: 'ex-user',
    first_name: 'First Name',
    getMore: 'Get More',
    id: 'ID',
    last_name: 'Last Name',
    loading: 'Loading',
    login: 'Log In',
    logout: 'Log Out',
    menu: {
      accounts: 'Accounts',
      applications: 'Applications',
      content: 'Content',
      select: 'Select',
      spaces: 'Spaces',
      users: 'Users'
    },
    mode: 'Mode',
    name: 'Name',
    no: 'No',
    none: 'none',
    notSet: 'Not Set',
    ok: 'OK',
    optional: 'Optional',
    output: 'Output',
    owner: 'Owner',
    password: 'Password',
    preferences: 'Preferences',
    private: 'Private',
    project: 'Project',
    public: 'Public',
    restore: 'Restore',
    role: 'Role',
    rum: 'See RUM',
    save: 'Save',
    showUTC: 'Show data in UTC',
    source: 'Source',
    space: 'Space',
    status: 'Status',
    state: 'State',
    tasks: 'See Tasks',
    timeseries: {
      range: {
        options: {
          day: 'Day',
          month: 'Month',
          week: 'Week'
        },
        start: 'Start',
        steps: {
          back: 'go back one {rangeSize}',
          forward: 'go forward one {rangeSize}'
        },
        time: 'at 12:00 AM {zone}',
        view: 'View one:'
      }
    },
    total: 'Total: {total}',
    trash: 'Trash',
    type: 'Type',
    unlimited: 'Unlimited',
    updated: 'Updated',
    updated_time: 'Updated Time',
    user: 'User',
    value: 'Value',
    visibility: 'Visibility',
    yes: 'Yes'
  },
  project: {
    children: {
      none: 'no child projects',
      section: 'Project Children'
    },
    outputs: {
      section: 'Project Outputs'
    },
    sessions: {
      none: 'no current sessions',
      section: 'Project Sessions'
    }
  },
  space: {
    connections: {
      title: 'Connections',
      columns: {
        driver: 'Driver'
      },
      loading: 'Getting connections',
      getMore: 'Get more connections'
    },
    content: {
      getMore: 'Get more content',
      loading: 'Getting content'
    },
    details: {
      actions: {
        transfer: 'Change Account'
      },
      dialog: {
        delete: {
          purpose: 'Delete Space: {name} (#{id})',
          info: 'A space will only be deleted if it contains no content.',
          warning: 'Deleting a space is permanent and cannot be undone.',
          prompt: 'Are you sure you want to delete {name}?'
        },
        edit: {
          confirm: {
            details: 'Are you sure you want to save changes to Space ID #{id}?',
            purpose: 'Save Changes to Space ID #{id}'
          },
          globalTemplateSource: 'Global Template Source',
          purpose: 'Edit Space ID #{id}'
        },
        transfer: {
          details: 'This space is currently owned by account #{accountId}.',
          error: 'account id must be numeric',
          label: 'Transfer to account',
          placeholder: 'destination account id',
          purpose: 'Transfer Space: {name} (#{id})',
          success: 'Success! This space has been transferred to account #{account_id}.',
          warning: 'Transferring a space to a different account will <strong>permanently</strong> remove all existing space members.'
        }
      },
      table: {
        account_id: 'Account ID',
        default_rstudio_project_id: 'RStudio Base Template',
        default_jupyter_project_id: 'Jupyter Base Template',
        access: 'Access',
        default_member_role: 'Default Member Role',
        user_count: 'User Count (Members + Active Invitations)',
        content_count: 'Content Count',
        global_template_source: 'Global Template Source'
      },
      title: 'Space Details'
    },
    search: {
      list_global_template_sources: 'List global template sources',
      title: {
        global_template_sources: 'Space Search: Global Template Sources',
        results: 'Space Search: Results',
        prompt: 'Space Search'
      },
      prompt: 'search for a Space by name or ID'
    },
    members: {
      title: 'Space Members',
      getMore: 'Get more members',
      loading: 'Getting members',
      members: 'members',
      options: {
        admins: 'Show Admins',
        moderators: 'Show Moderators',
        contributors: 'Show Contributors',
        viewers: 'Show Viewers'
      },
      roles: {
        dialog: {
          edit: {
            purpose: 'Edit Space Role',
            label: 'Space Roles:',
            confirmation: 'You are editing {member}\'s space role'
          }
        },
        viewer: {
          name: 'Viewer',
          info: 'Viewers can view content shared with everyone.'
        },
        contributor: {
          name: 'Contributor',
          info: 'Contributors can create, edit and manage their own content, and view space usage data.'
        },
        moderator: {
          name: 'Moderator',
          info: 'Moderators can view, edit and manage all content, and view space usage data.'
        },
        admin: {
          name: 'Admin',
          info: 'Admins can manage users, view, edit and manage all content, and view space usage data.'
        }
      }
    },
    menu: {
      connections: 'Connections',
      details: 'Details',
      members: 'Space Members',
      usage: 'Usage'
    },
    usage: {
      title: 'Space Usage'
    }
  },
  user: {
    authoredContent: {
      title: 'User Authored Content'
    },
    details: {
      userId: 'User ID',
      lockoutUntil: 'Lockout Until',
      lastLoginAttempt: 'Last Login Attempt',
      ssoPaired: 'SSO Paired',
      loginAttempts: 'Login Attempts',
      localAuth: 'Local Auth',
      cleverLoginId: 'Clever Login ID',
      githubLoginId: 'Github Login ID',
      googleLoginId: 'Google Login ID',
      accounts: {
        noAccounts: 'No accounts',
        section: 'User Accounts (owned)',
        members: 'Members'
      },
      superuser: 'Super User',
      dialog: {
        edit: {
          title: 'Edit User ID #{id}',
          placeholders: {
            firstName: 'Enter First Name',
            lastName: 'Enter Last Name'
          },
          superuserInfo: {
            permissions: 'Super Users have access to and can perform operations on all data in the system.',
            requirements: 'A Super User must have a verified RStudio or Posit email address.',
            warning: 'Please ensure you are promoting the correct user before you change this status.'
          }
        },
        confirmEdit: {
          title: 'Save Changes to User ID #{id}',
          details: 'Are you sure you want to save changes to User ID #{id}?'
        }
      }
    },
    create: {
      title: 'Create New User',
      errors: {
        email: 'Invalid email',
        password: 'Invalid password'
      }
    },
    menu: {
      authoredContent: 'Authored Content',
      details: 'User Details',
      utc: 'Display Dates in UTC'
    },
    search: {
      add_user: 'Add User',
      getMore: 'Get more users',
      title: {
        prompt: 'User Search',
        results: 'User Search: Results'
      },
      prompt: 'search for a User by email'
    },
    title: 'User (id: {id})'
  },
  usage: {
    space: {
      title: 'Space Usage'
    },
    table: {
      columns: {
        compute: 'Compute Hours',
        active_users: 'Active Users',
        active_content: 'Active Content',
        content_type: 'Content Type'
      }
    },
    cards: {
      compute: {
        name: 'Compute Hours'
      },
      active_users: {
        name: 'Active Users'
      },
      active_content: {
        name: 'Active Content'
      }
    },
    chart: {
      empty: 'no data'
    }
  }
};
