import { combineReducers } from 'redux';

import * as types from '~actions/action-types';

import { update } from '~reducers/reducer-utils';

const currentChildrenReducer = (state = { ids: [] }, action) => {
  switch (action.type) {
    case types.FETCH_PROJECT_CHILDREN_SUCCESS:
      return update(state, { ids: action.children.map(c => c.id) });

    case types.CLEAR_PROJECT_CHILDREN:
      return { ids: [] };
  }
  return state;
};

const currentOutputsReducer = (state = { ids: [], total: undefined }, action) => {
  switch (action.type) {
    case types.FETCH_PROJECT_OUTPUTS_SUCCESS:
      return update(state, {
        ids: action.outputs.map(o => o.id),
        total: action.total
      });

    case types.CLEAR_PROJECT_OUTPUTS:
      return { ids: [], total: undefined };
  }
  return state;
};

const sessionsReducer = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_PROJECT_SESSIONS_SUCCESS:
      return action.sessions;

    case types.CLEAR_PROJECT_SESSIONS:
      return [];
  }
  return state;
};

export default combineReducers({
  currentChildren: currentChildrenReducer,  // ids
  currentOutputs: currentOutputsReducer,    // ids, total
  sessions: sessionsReducer                 // []
});
