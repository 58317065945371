import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { translate } from '~i18n/localize';

export function RolePicker({ currentRole, onChange, roleContext, roles }) {
  const [ role, setRole ] = useState(currentRole);

  const handleChange = (newRole) => {
    setRole(newRole);
    onChange(newRole);
  };

  return (
    <fieldset>
      <select name="role" value={role ? role : currentRole} className="full" onChange={e => handleChange(e.target.value)}>
        {roles?.map(r => <option value={r} key={r}>{translate(`${roleContext}.roles.${r}.name`)}</option>)}
      </select>
    </fieldset>
  );
}

RolePicker.propTypes = {
  currentRole: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  roleContext: PropTypes.string,
  roles: PropTypes.array
};

export default RolePicker;
