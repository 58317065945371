import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { isAuthenticated } from '~selectors/auth-selectors';

import { getLoginURL } from '~util/auth-helpers';

export default (WrappedComponent) => {

  // RequireAuthentication component wrapper
  // This a higher-order component that will wrap other components to enforce authentication.
  // This is an improved way of doing access control over the router onEnter hooks because it allows
  // composition of multiple components.
  class RequireAuthentication extends React.Component {

    doLogin() {
      const loginURL = getLoginURL();
      window.location.assign(loginURL);
    }

    componentDidMount() {
      const { isAuthenticated } = this.props;

      if (!isAuthenticated) {
        this.doLogin();
      }
    }

    componentDidUpdate() {
      const { isAuthenticated } = this.props;
      if (!isAuthenticated) {
        this.doLogin();
      }
    }

    render() {
      const { isAuthenticated } = this.props;
      // don't try to render the component if we make it this far and we're not authenticated
      return isAuthenticated ? <WrappedComponent {...this.props} /> : null;
    }
  }

  RequireAuthentication.propTypes = {
    dispatch: PropTypes.func,
    isAuthenticated: PropTypes.bool
    // ready: PropTypes.bool,
  };

  function mapStateToProps(state) {
    return {
      isAuthenticated: isAuthenticated(state)
      // ready: state.uiState.ready,
    };
  }

  return connect(mapStateToProps)(RequireAuthentication);
};
