import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as accountActions from '~actions/account-actions';
import * as uiActions from '~actions/ui-actions';

import * as dialogTypes from '~components/dialogs/dialog-types';
import ActionButton from '~components/general/action-button';
import IString from '~components/general/i-string';
import Time from '~components/general/time';
import UserName from '~components/general/user-name';

import * as accountType from '~constants/account-type';

import { translate } from '~i18n/localize';

import * as accountOperations from '~operations/account-operations';

import * as configSelectors from '~selectors/config-selectors';

import * as connectApps from '~util/connect-apps';
import * as datetime from '~util/datetime';

function AccountDetails({ account, datadogURL, dispatch, environment }) {

  const isSuspended = !!account && account.suspended_until && datetime.parse(account.suspended_until) > new Date();

  const handleEdit = (e) => {
    e.preventDefault();

    dispatch(uiActions.openDialog({
      type: dialogTypes.ACCOUNT_DETAILS_EDITOR_DIALOG,
      data: {
        account
      },
      onSubmit: (changed) => {
        if (changed) {
          dispatch(accountActions.updateAccount(account.id, changed));
        }
        dispatch(uiActions.closeDialog()); // close editor dialog
      }
    }));
  };

  const handleEditOwner = () => {
    dispatch(uiActions.openDialog({
      type: dialogTypes.TRANSFER_ACCOUNT_DIALOG,
      data: { account },
      onSubmit: (email, branding) => {
        dispatch(accountOperations.transferAccount(account.id, email, branding));
        // close editor dialog regardless of what happens
        dispatch(uiActions.closeDialog());
      }
    }));
  };

  const handleDelete = () => {
    dispatch(uiActions.openDialog({
      type: dialogTypes.ACCOUNT_DELETE_DIALOG,
      data: {
        onSubmit: () => {
          dispatch(accountActions.deleteAccount(account.id));
        }
      }
    }));
  };

  const handleTasks = () => {
    window.open(connectApps.makeTaskinatorURL({ environment, account_id: account.id }), '_blank');
  };

  const handleRUM = () => {
    const u = `${datadogURL}/rum/explorer?agg_q=%40type&cols=%40action.name%2C%40context.result%2C%40context.attributes.source.status%2C%40context.attributes.project_id%2C%40context.attributes.total_duration_ms&live=1d&query=%40action.name%3A%28CREATE_PROJECT+OR+OPEN_PROJECT%29+-%40context.result%3Aidle++%40context.attributes.source.account_id%3A${account.id}&tab=action`;
    window.open(u, '_blank');
  };

  return (
    <div className="splitColumns withMobileMargins">
      <div className="column100">

        <div className="sectionTitle flex">
          <IString stringKey="account.details.title" />
          <div className="actionBar inline showTitles">
            <ActionButton className="edit" title={translate('general.edit')} onClick={handleEdit} />
            <ActionButton className="delete" title={translate('general.delete')} onClick={handleDelete} />
            <ActionButton className="viewLog" title={translate('general.tasks')} onClick={handleTasks} />
            <ActionButton className="support" title={translate('general.rum')} onClick={handleRUM} />
          </div>
        </div>

        <table className="propertiesTable">
          {/*<caption className='tableTitle'>Account Details</caption>*/}
          <tbody>
            <tr id="account-row-id">
              <th className="label"><IString stringKey="general.id" /></th>
              <td className="value">{account.id}</td>
            </tr>
            <tr id="account-row-name">
              <th className="label"><IString stringKey="general.name" /></th>
              <td className="value">{account.name}</td>
            </tr>
            <tr id="account-row-display-name">
              <th className="label"><IString stringKey="general.display_name" /></th>
              <td className="value">{account.display_name ? account.display_name : <em><IString stringKey="general.notSet" /></em>}</td>
            </tr>
            <tr id="account-row-owner">
              <th className="label"><IString stringKey="general.owner" /></th>
              <td className="value">
                <Link to={`/users/${account.owner.id}`}><UserName user={account.owner} /></Link>
              </td>
              <td className="actionBar inline showTitles" style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                <ActionButton className="edit" title={translate('account.details.transfer')} onClick={handleEditOwner} />
              </td>
            </tr>
            <tr id="account-row-type">
              <th className="label"><IString stringKey="general.type" /></th>
              <td className="value">{account.type}</td>
            </tr>
            {account.type === accountType.ORGANIZATION &&
              <tr id="account-row-sso-enabled">
                <th className="label"><IString stringKey="account.details.sso_enabled" /></th>
                <td className="value">
                  <em>{account.sso_enabled ? <IString stringKey="general.yes" /> : <IString stringKey="general.no" />}</em>
                </td>
              </tr>
            }
            {account.sso_enabled && account.type === accountType.ORGANIZATION &&
              <tr id="account-row-sso-hint">
                <th className="label"><IString stringKey="account.details.sso_hint" /></th>
                <td className="value">{account.sso_hint ? account.sso_hint : <em><IString stringKey="general.notSet" /></em>}</td>
              </tr>
            }
            <tr id="account-row-suspended-until">
              <th className="label"><IString stringKey="account.details.suspended_until" /></th>
              <td className="value">
                {isSuspended ? <Time value={account.suspended_until} /> : <em><IString stringKey="account.details.not_suspended" /></em>}
              </td>
            </tr>
            <tr id="account-row-suspended-reason">
              <th className="label"><IString stringKey="account.details.suspended_reason" /></th>
              <td className="value">
                {isSuspended ? account.suspended_reason || <em><IString stringKey="general.notSet" /></em> : <em><IString stringKey="account.details.not_suspended" /></em>}
              </td>
            </tr>
            <tr id="account-row-created-time">
              <th className="label"><IString stringKey="general.created_time" /></th>
              <td className="value"><Time value={account.created_time} /></td>
            </tr>
            <tr id="account-row-updated-time">
              <th className="label"><IString stringKey="general.updated_time" /></th>
              <td className="value"><Time value={account.updated_time} /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

AccountDetails.propTypes = {
  account: PropTypes.shape({
    created_time: PropTypes.string,
    display_name: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    owner: PropTypes.shape({
      id: PropTypes.number
    }),
    sso_enabled: PropTypes.bool,
    sso_hint: PropTypes.string,
    suspended_reason: PropTypes.string,
    suspended_until: PropTypes.string,
    type: PropTypes.oneOf(accountType.ALL_TYPES),
    updated_time: PropTypes.string
  }),
  datadogURL: PropTypes.string,
  dispatch: PropTypes.func,
  environment: PropTypes.string
};

const mapStateToProps = (state) => ({
  environment: configSelectors.getEnvironment(state),
  datadogURL: configSelectors.getDatadogURL(state)
});

export default connect(mapStateToProps)(AccountDetails);

export { AccountDetails };
