import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as accountActions from '~actions/account-actions';

import ApplicationLink from '~components/general/application-link';
import IString from '~components/general/i-string';
import PaginatedTableContainer from '~components/general/paginated-table-container';
import Time from '~components/general/time';

import * as accountSelectors from '~selectors/account-selectors';

function AccountApplications({ accountId }) {
  const dispatch = useDispatch();

  const applications = useSelector(accountSelectors.getAccountApplications);
  const total = useSelector(accountSelectors.getAccountApplicationsTotal);

  const fetchMoreApplications = useCallback((offset) => {
    dispatch(accountActions.fetchAccountApplications(accountId, undefined, offset));
  }, [ accountId, dispatch ]);

  // initialize the page
  useEffect(() => {
    fetchMoreApplications();
    return () => dispatch(accountActions.clearAccountApplications());
  }, [ fetchMoreApplications, dispatch ]);

  return (
    <>
      <div className="sectionTitle flex">
        <IString stringKey="account.applications.title" />
      </div>
      <PaginatedTableContainer
        data={applications}
        getMore={fetchMoreApplications}
        total={total}
      >
        <table className="contentListing sortableColumns">
          <thead>
            <tr>
              <th><IString stringKey="account.applications.table.id" /></th>
              <th><IString stringKey="general.name" /></th>
              <th><IString stringKey="general.status" /></th>
              <th><IString stringKey="general.type" /></th>
              <th><IString stringKey="general.created_time" /></th>
              <th><IString stringKey="general.updated_time" /></th>
            </tr>
          </thead>
          <tbody>
            {applications?.map(a =>
              <tr key={a.id}>
                <td><ApplicationLink appId={a.id} /></td>
                <td>{a.name}</td>
                <td>{a.status}</td>
                <td>{a.type}</td>
                <td><Time value={a.created_time} /></td>
                <td><Time value={a.updated_time} /></td>
              </tr>
            )}
          </tbody>
        </table>
      </PaginatedTableContainer>
    </>
  );
}

AccountApplications.propTypes = {
  accountId: PropTypes.number,
  applications: PropTypes.array,
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  total: PropTypes.number
};

export default AccountApplications;
