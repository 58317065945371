import { API, createAdvancedSearchParams } from '~api/api';

export const getCurrentUser = () => {
  return getUser('me');
};

export const getUser = (userId) => {
  if (!userId) {
    return Promise.reject();
  }
  return API().get(`/users/${userId}`);
};

export const createUser = (data) => {
  return API().post('/users',  data);
};

export const updateUser = (userId, data) => {
  if (!userId) {
    return Promise.reject();
  }
  return API().put(`/users/${userId}`, data);
};

export const deleteUser = (userId) => {
  if (!userId) {
    return Promise.reject();
  }
  return API().delete(`/users/${userId}`);
};

// search and list users (returns paginated list of users)
export const listUsers = (q, offset = 0, count = 25) => {
  return API().get('/users', {
    params: {
      ...createAdvancedSearchParams(q, [ 'email', 'first_name', 'last_name', 'display_name' ]),
      count,
      offset
    }
  });
};
