import { createSelector } from 'reselect';

import * as contentSelectors from '~selectors/content-selectors';

export const getCurrentApplicationId = (state) => state.applicationState.currentApplicationId;

export const getCurrentApplication = (state) => getApplication(state, getCurrentApplicationId(state));

export const getApplications = (state) => state.applicationState.applications;

export const getApplication = (state, id) => getApplications(state)[id];

export const getCurrentApplicationAuthorizations = createSelector(
  [ state => state.applicationState.applicationAuthorizations, getCurrentApplicationId ],
  (authorizations, applicationId) => authorizations[applicationId]?.authorizations
);
export const getCurrentApplicationAuthorizationsTotal = createSelector(
  [ state => state.applicationState.applicationAuthorizations, getCurrentApplicationId ],
  (authorizations, applicationId) => authorizations[applicationId]?.total
);

export const getCurrentApplicationLogs = createSelector(
  [ state => state.applicationState.applicationLogs, getCurrentApplicationId ],
  (applicationLogs, currentApplicationId) => {
    return applicationLogs[currentApplicationId];
  }
);

export const isSearchLoading = (state) => state.applicationState.search.loading;
export const getSearchQuery = (state) => state.applicationState.search.query;
export const getSearchResults = createSelector(
  [ getApplications, state => state.applicationState.search.resultIds ],
  (applications, ids) => ids.map(id => applications[id])
);

export const getCurrentApplicationContent = createSelector(
  [ contentSelectors.getContent, getCurrentApplicationId ],
  (content, applicationId) => content ? Object.values(content).find(c => c.source_id === applicationId) : undefined);
