import moment from 'moment';
import { call, put, delay } from 'redux-saga/effects';

import * as authActions from '~actions/auth-actions';

import * as api from '~api';

import * as tokenUtil from '~util/token';

export function* login(action) {
  try {
    // fetch token
    const token = yield call(api.requestAccessToken, action.code, action.state);

    // save token
    yield call(tokenUtil.saveToken, token.data);

    // fetch user
    const user = yield call(api.getCurrentUser);

    // store user
    yield put(authActions.setAuthUser(user.data));

    // begin token refresh
    yield put(authActions.maintainAccessToken());
  } catch (error) {
    if (error.response.status === 403) {
      // redirect unauthorized users
      window.location.assign('/app/unauthorized');
    }
  }

}

export function* logout() {

  // logout from the backend
  yield call(api.logout);

  // remove the token from localStorage
  yield call(tokenUtil.clearToken);

  // finally, send the user off to finish up the logout process
  window.location.href = '/';

}

export function* maintainAccessToken() {
  try {
    const currentToken = yield call(tokenUtil.loadToken);

    const tokenExpires = moment(currentToken.expires_at);

    // refresh delay is the seconds from now until token expires - 5 minutes
    const refreshDelay = tokenExpires.subtract(5, 'minutes').diff();
    if (refreshDelay > 0) {
      yield delay(refreshDelay);
    }

    const newToken = yield call(api.refreshAccessToken);

    yield call(tokenUtil.saveToken, newToken.data);

    yield put(authActions.maintainAccessToken());

  } catch (error) {

    // eslint-disable-next-line no-console
    console.log(error);

    yield delay(10000); // chill out for a bit

    yield call(maintainAccessToken);

  }
}
