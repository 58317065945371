import * as types from '~actions/action-types';

export const setSpaceSearch = (query) => ({
  type: types.SET_SPACE_SEARCH,
  query
});

export const clearSpaceSearch = () => ({
  type: types.CLEAR_SPACE_SEARCH
});

export const fetchSpaceSearchSuccess = (spaces) => ({
  type: types.FETCH_SPACE_SEARCH_SUCCESS,
  spaces
});

export const fetchSpaceSearchFailure = () => ({
  type: types.FETCH_SPACE_SEARCH_FAILURE
});

export const setCurrentSpace = (spaceId) => ({
  type: types.SET_CURRENT_SPACE,
  spaceId
});

export const clearCurrentSpace = () => ({
  type: types.CLEAR_CURRENT_SPACE
});

export const fetchSpaceSuccess = (space) => ({
  type: types.FETCH_SPACE_SUCCESS,
  space
});

export const deleteSpace = (spaceId) => ({
  type: types.DELETE_SPACE,
  spaceId
});

export const deleteSpaceSuccess = (spaceId) => ({
  type: types.DELETE_SPACE_SUCCESS,
  spaceId
});

export const deleteSpaceFailure = (spaceId) => ({
  type: types.DELETE_SPACE_FAILURE,
  spaceId
});

// Space Members

export const fetchSpaceMembersPage = (spaceId, filter, order, offset) => ({
  type: types.FETCH_SPACE_MEMBERS_PAGE,
  spaceId,
  filter,
  order,
  offset
});

export const fetchSpaceMembersPageSuccess = (spaceId, members, total) => ({
  type: types.FETCH_SPACE_MEMBERS_PAGE_SUCCESS,
  spaceId,
  members,
  total
});

export const fetchSpaceMembersPageFailure = (spaceId) => ({
  type: types.FETCH_SPACE_MEMBERS_PAGE_FAILURE,
  spaceId
});

export const clearSpaceMembers = () => ({ type: types.CLEAR_SPACE_MEMBERS });

export const updateSpaceRole = (spaceId, userId, role) => ({
  spaceId,
  userId,
  role,
  type: types.UPDATE_SPACE_ROLE
});

export const updateSpaceRoleSuccess = (member) => ({
  member,
  type: types.UPDATE_SPACE_ROLE_SUCCESS
});

// Space Content
export const fetchSpaceContentPage = (spaceId, offset) => ({
  type: types.FETCH_SPACE_CONTENT_PAGE,
  spaceId,
  offset
});

export const clearSpaceContent = () => ({ type: types.CLEAR_SPACE_CONTENT });

export const fetchSpaceContentPageSuccess = (spaceId, content, total) => ({
  type: types.FETCH_SPACE_CONTENT_PAGE_SUCCESS,
  spaceId,
  content,
  total
});

export const fetchSpaceContentPageFailure = (spaceId) => ({
  type: types.FETCH_SPACE_CONTENT_PAGE_FAILURE,
  spaceId
});

// Space Connections

export const fetchSpaceConnectionsSuccess = (spaceId, connections, total) => ({
  type: types.FETCH_SPACE_CONNECTIONS_SUCCESS,
  spaceId,
  connections,
  total
});

export const clearSpaceConnections = () => ({ type: types.CLEAR_SPACE_CONNECTIONS });
