export const DISPLAY_DATES_IN_UTC = 'DISPLAY_DATES_IN_UTC';

export const getLocalState = (key) => {
  const raw = window.localStorage.getItem(key) || null;
  return JSON.parse(raw);
};

export const hasLocalState = (key) => {
  const state = getLocalState(key);
  return state !== null;
};

export const setLocalState = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const clearLocalState = (key) => {
  window.localStorage.removeItem(key);
};
