import AccountCreateDialog from '~components/accounts/account-create-dialog';
import AccountDeleteDialog from '~components/accounts/account-delete-dialog';
import AccountDetailsEditorDialog from '~components/accounts/account-details-editor-dialog';
import AccountPropertiesCreateEditDialog from '~components/accounts/account-properties-create-edit-dialog';
import AccountLicenseEditorDialog from '~components/accounts/license/account-license-details-editor-dialog';
import AccountLicenseEntitlementEditorDialog from '~components/accounts/license/account-license-entitlement-editor-dialog';
import AccountLicenseQuickConfigureDialog from '~components/accounts/license/account-license-quick-configure-dialog';
import AccountUsageCreditsCreateEditDialog from '~components/accounts/license/account-license-usage-credits-create-edit-dialog';
import AccountCloudRoleEditorDialog from '~components/accounts/members/account-cloud-role-editor-dialog';
import TransferAccountDialog from '~components/accounts/transfer-account-dialog';
import ApplicationTaskDialog from '~components/applications/application-task-dialog';
import ExportApplicationDialog from '~components/applications/export-application-dialog';
import ConfirmDialog from '~components/dialogs/confirm-dialog';
import SpaceDetailsEditDialog from '~components/spaces/space-details-edit-dialog';
import SpaceMemberRoleEditorDialog from '~components/spaces/space-member-role-editor-dialog';
import SpaceTransferDialog from '~components/spaces/space-transfer-dialog';
import UserCreateDialog  from '~components/users/user-create-dialog';
import UserDetailsEditorDialog from '~components/users/user-details-editor-dialog';

export const ACCOUNT_CLOUD_ROLE_EDITOR_DIALOG = 'ACCOUNT_CLOUD_ROLE_EDITOR_DIALOG';
export const ACCOUNT_CREATE_DIALOG = 'CREATE_ACCOUNT_DIALOG';
export const ACCOUNT_DELETE_DIALOG = 'DELETE_ACCOUNT_DIALOG';
export const ACCOUNT_DETAILS_EDITOR_DIALOG = 'ACCOUNT_DETAILS_EDITOR_DIALOG';
export const ACCOUNT_LICENSE_EDITOR_DIALOG = 'ACCOUNT_DETAILS_LICENSE_EDITOR_DIALOG';
export const ACCOUNT_LICENSE_ENTITLEMENT_EDITOR_DIALOG = 'ACCOUNT_LICENSE_ENTITLEMENT_EDITOR_DIALOG';
export const ACCOUNT_LICENSE_QUICK_CONFIGURE_DIALOG = 'ACCOUNT_LICENSE_QUICK_CONFIGURE_DIALOG';
export const TRANSFER_ACCOUNT_DIALOG = 'TRANSFER_ACCOUNT_DIALOG';
export const ACCOUNT_PROPERTIES_CREATE_EDIT_DIALOG = 'ACCOUNT_PROPERTIES_CREATE_EDIT_DIALOG';
export const ACCOUNT_USAGE_CREDITS_CREATE_EDIT_DIALOG = 'ACCOUNT_USAGE_CREDITS_CREATE_EDIT_DIALOG';
export const APPLICATION_TASK_DIALOG = 'APPLICATION_TASK_DIALOG';
export const CONFIRM_DIALOG = 'CONFIRM_DIALOG';
export const EXPORT_APPLICATION_DIALOG = 'EXPORT_APPLICATION_DIALOG';
export const SPACE_DETAILS_EDIT_DIALOG = 'SPACE_DETAILS_EDIT_DIALOG';
export const SPACE_MEMBER_ROLE_EDITOR_DIALOG = 'SPACE_MEMBER_ROLE_EDITOR_DIALOG';
export const SPACE_TRANSFER_DIALOG = 'SPACE_TRANSFER_DIALOG';
export const USER_CREATE_DIALOG = 'USER_CREATE_DIALOG';
export const USER_DETAILS_EDITOR_DIALOG = 'USER_DETAILS_EDITOR_DIALOG';

export const dialogs = {
  [ACCOUNT_CREATE_DIALOG]: AccountCreateDialog,
  [ACCOUNT_DELETE_DIALOG]: AccountDeleteDialog,
  [ACCOUNT_DETAILS_EDITOR_DIALOG]: AccountDetailsEditorDialog,
  [ACCOUNT_LICENSE_EDITOR_DIALOG]: AccountLicenseEditorDialog,
  [ACCOUNT_LICENSE_ENTITLEMENT_EDITOR_DIALOG]: AccountLicenseEntitlementEditorDialog,
  [TRANSFER_ACCOUNT_DIALOG]: TransferAccountDialog,
  [ACCOUNT_PROPERTIES_CREATE_EDIT_DIALOG]: AccountPropertiesCreateEditDialog,
  [ACCOUNT_CLOUD_ROLE_EDITOR_DIALOG]: AccountCloudRoleEditorDialog,
  [ACCOUNT_USAGE_CREDITS_CREATE_EDIT_DIALOG]: AccountUsageCreditsCreateEditDialog,
  [ACCOUNT_LICENSE_QUICK_CONFIGURE_DIALOG]: AccountLicenseQuickConfigureDialog,
  [APPLICATION_TASK_DIALOG]: ApplicationTaskDialog,
  [CONFIRM_DIALOG]: ConfirmDialog,
  [EXPORT_APPLICATION_DIALOG]: ExportApplicationDialog,
  [SPACE_DETAILS_EDIT_DIALOG]: SpaceDetailsEditDialog,
  [SPACE_MEMBER_ROLE_EDITOR_DIALOG]: SpaceMemberRoleEditorDialog,
  [SPACE_TRANSFER_DIALOG]: SpaceTransferDialog,
  [USER_DETAILS_EDITOR_DIALOG]: UserDetailsEditorDialog,
  [USER_CREATE_DIALOG]: UserCreateDialog
};

export const get = (type) => dialogs[type] || null;
