/**
 * Formats a UTC time (string or number)
 *
 * @param   {string|number} utcTime     Either a UTC string in ISO standard format or an epoch timestamp
 * @param   {boolean}       formatInUTC True if we wish to keep the time in UTC and format it in ISO standard time,
 *                                      false if we wish to convert the UTC time to local time and format it in the
 *                                      locale format.
 * @returns {string}                    A formatted time string for the given utcTime
 */
export const format = (utcTime, formatInUTC = false) => {
  if (utcTime) {
    const dateTime = parse(utcTime);
    const options = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short'
    };
    if (formatInUTC) {
      options.timeZone = 'UTC';
    }
    return new Intl.DateTimeFormat('default', options).format(dateTime);
  }
};

/**
 * Parses a UTC time (string or number)
 *
 * @param   {string|number} utcTime Either a UTC string in ISO standard format or an epoch timestamp
 * @returns {Date}                  Date for the given UTC time
 */
export const parse = (utcTime) => {
  if (utcTime) {
    return typeof utcTime === 'string' ? new Date(`${utcTime}Z`) : new Date(utcTime);
  }
};
