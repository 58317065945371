import PropTypes from 'prop-types';
import React from 'react';

import IString from '~components/general/i-string';
import Spinner from '~components/general/spinner';
import classnames from '~components/util/classnames';

function ChartContainer({ children, empty, loading }) {
  const cn = classnames({
    ['chartContainer shorter placeholder flexCenterCenter']: empty || loading
  });

  let content = children;

  if (loading) {
    content = <div className="spinnerContainer biggish"><Spinner /></div>;
  } else if (empty) {
    content = <IString stringKey="usage.chart.empty" />;
  }

  return (
    <div className={cn}>
      { content }
    </div>
  );
}

ChartContainer.defaultProps = {
  empty: false,
  loading: false
};

ChartContainer.propTypes = {
  children: PropTypes.node,
  empty: PropTypes.bool,
  loading: PropTypes.bool
};

export default ChartContainer;
