import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import * as types from '~actions/action-types';

import * as accountSagas from '~sagas/account-sagas';
import * as applicationSagas from '~sagas/application-sagas';
import * as authSagas from '~sagas/auth-sagas';
import * as contentSagas from '~sagas/content-sagas';
import { initialize } from '~sagas/initialize';
import * as projectSagas from '~sagas/project-sagas';
import * as spaceSagas from '~sagas/space-sagas';
import * as userSagas from '~sagas/user-sagas';

export default function* rootSaga() {
  yield all([
    takeEvery(types.FETCH_ACCOUNT_LICENSE_ENTITLEMENT, accountSagas.fetchAccountLicenseEntitlement),
    takeLatest(types.FETCH_ACCOUNT_LICENSE_ENTITLEMENTS, accountSagas.fetchAccountLicenseEntitlements),
    takeLatest(types.CREATE_ACCOUNT, accountSagas.createAccount),
    takeLatest(types.TRANSFER_ACCOUNT, accountSagas.transferAccount),
    takeLatest(types.SET_CURRENT_ACCOUNT, accountSagas.fetchAccount),
    takeLatest(types.FETCH_ACCOUNT_SEARCH_PAGE, accountSagas.searchAccounts),
    takeLatest(types.UPDATE_ACCOUNT, accountSagas.updateAccount),
    takeLatest(types.DELETE_ACCOUNT, accountSagas.deleteAccount),
    takeLatest(types.UPDATE_ACCOUNT_LICENSE, accountSagas.updateAccountLicense),
    takeLatest(types.FETCH_ACCOUNT_SUBSCRIPTION, accountSagas.fetchAccountSubscription),
    takeLatest(types.DELETE_ACCOUNT_SUBSCRIPTION, accountSagas.deleteAccountSubscription),
    takeLatest(types.FETCH_ACCOUNT_SUBSCRIPTION_ENTITLEMENT_STATUS, accountSagas.fetchAccountSubscriptionEntitlementStatus),
    takeLatest(types.DELETE_ACCOUNT_LICENSE_ENTITLEMENT, accountSagas.deleteAccountLicenseEntitlement),
    takeLatest(types.CREATE_ACCOUNT_LICENSE_ENTITLEMENT, accountSagas.createAccountLicenseEntitlement),
    takeLatest(types.UPDATE_ACCOUNT_LICENSE_ENTITLEMENT, accountSagas.updateAccountLicenseEntitlement),
    takeLatest(types.FETCH_ACCOUNT_RESOURCES, accountSagas.fetchAccountResources),
    takeLatest(types.FETCH_ACCOUNT_MEMBERS_PAGE, accountSagas.fetchAccountMembersPage),
    takeLatest(types.FETCH_ACCOUNT_APPLICATIONS, accountSagas.fetchAccountApplications),
    takeLatest(types.FETCH_ACCOUNT_SPACES, accountSagas.fetchAccountSpaces),
    takeLatest(types.FETCH_ACCOUNT_PROPERTIES, accountSagas.fetchAccountProperties),
    takeLatest(types.CREATE_ACCOUNT_PROPERTY, accountSagas.createAccountProperty),
    takeLatest(types.DELETE_ACCOUNT_PROPERTY, accountSagas.deleteAccountProperty),
    takeLatest(types.CREATE_ACCOUNT_USAGE_CREDITS, accountSagas.createAccountUsageCredits),
    takeLatest(types.DELETE_ACCOUNT_USAGE_CREDITS, accountSagas.deleteAccountUsageCredits),
    takeLatest(types.FETCH_ACCOUNT_USAGE_CREDITS, accountSagas.fetchAccountUsageCredits),
    takeLatest(types.UPDATE_ACCOUNT_USAGE_CREDITS, accountSagas.updateAccountUsageCredits),
    takeLatest(types.FETCH_ACCOUNT_CONTENT, accountSagas.fetchAccountContent),
    takeLatest(types.UPDATE_ACCOUNT_CLOUD_ROLE, accountSagas.updateAccountMemberCloudRole),

    takeLatest(types.SET_APPLICATION_SEARCH, applicationSagas.searchApplications),
    takeLatest(types.SET_CURRENT_APPLICATION, applicationSagas.fetchApplication),
    takeLatest(types.DEPLOY_APPLICATION, applicationSagas.deployApplication),
    takeLatest(types.RESUME_APPLICATION, applicationSagas.resumeApplication),
    takeLatest(types.SUSPEND_APPLICATION, applicationSagas.suspendApplication),
    takeLatest(types.RESTART_APPLICATION, applicationSagas.restartApplication),
    takeLatest(types.TERMINATE_APPLICATION, applicationSagas.terminateApplication),
    takeLatest(types.PURGE_APPLICATION, applicationSagas.purgeApplication),
    takeLatest(types.FETCH_APPLICATION_AUTHORIZATIONS, applicationSagas.fetchApplicationAuthorizations),
    takeLatest(types.FETCH_APPLICATION_LOGS, applicationSagas.fetchApplicationLogs),

    takeLatest(types.SET_CONTENT_SEARCH, contentSagas.searchContent),
    takeLatest(types.FETCH_CONTENT, contentSagas.fetchContent),
    takeLatest(types.FETCH_PROJECT_CHILDREN, projectSagas.fetchProjectChildren),
    takeLatest(types.FETCH_PROJECT_SESSIONS, projectSagas.fetchProjectSessions),

    takeLatest(types.LOGIN, authSagas.login),
    takeLatest(types.LOGOUT, authSagas.logout),
    takeLatest(types.MAINTAIN_ACCESS_TOKEN, authSagas.maintainAccessToken),

    takeLatest(types.CREATE_USER, userSagas.createUser),
    takeLatest(types.FETCH_USER_SEARCH_PAGE, userSagas.searchUsers),
    takeLatest(types.SET_CURRENT_USER, userSagas.fetchUser),
    takeLatest(types.FETCH_CURRENT_USER_OWNED_ACCOUNTS, userSagas.fetchCurrentUserOwnedAccounts),
    takeLatest(types.FETCH_CONTENT_BY_AUTHOR, userSagas.fetchUserAuthoredContent),

    takeLatest(types.SET_SPACE_SEARCH, spaceSagas.searchSpaces),
    takeLatest(types.SET_CURRENT_SPACE, spaceSagas.fetchSpace),
    takeLatest(types.DELETE_SPACE, spaceSagas.deleteSpace),
    takeLatest(types.FETCH_SPACE_MEMBERS_PAGE, spaceSagas.fetchSpaceMembersPage),
    takeLatest(types.FETCH_SPACE_CONTENT_PAGE, spaceSagas.fetchSpaceContentPage),
    takeLatest(types.UPDATE_SPACE_ROLE, spaceSagas.updateSpaceMemberRole),

    takeLatest(types.INITIALIZE, initialize)
  ]);
}
