import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

function ApplicationLink(props) {
  const { appId } = props;

  return (
    <Link to={`/applications/${appId}`}><span>Application {appId}</span></Link>
  );
}

ApplicationLink.propTypes = {
  appId: PropTypes.number
};

export default ApplicationLink;
