import * as uiActions from '~actions/ui-actions';

import * as api from '~api/api';
import * as containersApi from '~api/containers';

import * as statusTypes from '~components/status/status-types';

/**
 * Thunk action creator to get the container instances for a legacy application
 *
 * @param   {number}   applicationId The ID of the application for which to fetch containers
 * @returns {function}               Thunk to fetch container instances for a legacy application
 */
export const getContainersByApplicationId = (applicationId) => async dispatch => {
  try {
    const filters = { app_id: applicationId };
    const { data } = await containersApi.getContainers(filters);
    return data;
  } catch (error) {
    const message = api.getErrorMessage(error.response);
    dispatch(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
  }
};
