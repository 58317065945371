import PropTypes from 'prop-types';
import React from 'react';

function ActionButton(props) {
  const { className, disabled, title, onClick } = props;

  return (
    <button type="button" className={`action ${className}`} onClick={onClick} disabled={disabled}>
      { title && <span className="actionTitle">{title}</span> }
    </button>
  );
}

ActionButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.oneOfType([ PropTypes.node, PropTypes.string ])
};

export default ActionButton;
