import * as types from '~actions/action-types';

// --- project children ---

export const fetchProjectChildren = (projectId) => ({
  type: types.FETCH_PROJECT_CHILDREN,
  projectId
});

export const fetchProjectChildrenSuccess = (projectId, children) => ({
  type: types.FETCH_PROJECT_CHILDREN_SUCCESS,
  projectId,
  children
});

export const clearProjectChildren = () => ({ type: types.CLEAR_PROJECT_CHILDREN });

//  --- project outputs actions ---

export const fetchProjectOutputsSuccess = (projectId, outputs, total) => ({
  type: types.FETCH_PROJECT_OUTPUTS_SUCCESS,
  projectId,
  outputs,
  total
});

export const clearProjectOutputs = () => ({ type: types.CLEAR_PROJECT_OUTPUTS });

// --- project sessions ---

export const fetchProjectSessions = (projectId) => ({
  type: types.FETCH_PROJECT_SESSIONS,
  projectId
});

export const fetchProjectSessionsSuccess = (projectId, sessions) => ({
  type: types.FETCH_PROJECT_SESSIONS_SUCCESS,
  projectId,
  sessions
});

export const clearProjectSessions = () => ({ type: types.CLEAR_PROJECT_SESSIONS });
