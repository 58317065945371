import PropTypes from 'prop-types';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import ModalContainer from '~components/dialogs/modal-container';
import CurrentUser from '~components/general/current-user';
import Footer from '~components/general/footer';
import IString from '~components/general/i-string';
import MobileMenu from '~components/general/mobile-menu';
import StatusMessage from '~components/status/status-message';
import classnames from '~components/util/classnames';
import { useWindowDimensions } from '~components/util/hooks';

function StandardPage({ children, fullWidth, showHeader, showStatus }) {
  const { width } = useWindowDimensions();
  const links =
    <>
      <NavLink to="/users" className="menuItem" activeClassName="active"><IString stringKey="general.menu.users" /></NavLink>
      <NavLink to="/accounts" className="menuItem" activeClassName="active"><IString stringKey="general.menu.accounts" /></NavLink>
      <NavLink to="/content" className="menuItem" activeClassName="active"><IString stringKey="general.menu.content" /></NavLink>
      <NavLink to="/spaces" className="menuItem" activeClassName="active"><IString stringKey="general.menu.spaces" /></NavLink>
      <NavLink to="/applications" className="menuItem" activeClassName="active"><IString stringKey="general.menu.applications" /></NavLink>
    </>;

  return (
    <div id="main">

      {showHeader &&
        <header id="rStudioHeader">
          <div className="band">
            <div className="innards bandContent">
              <div id="logoContainer">
                <Link to="/">
                  <div className="productLogo" />
                </Link>
              </div>
              { width < 1024 ? <MobileMenu>{links}</MobileMenu> : <div className="menu main">{links}</div> }
              <CurrentUser />
            </div>
          </div>
          {showStatus && <StatusMessage />}
        </header>
      }

      <div className={classnames({ band: !fullWidth, pushFooter: true })}>
        <div className={classnames({ bandContent: !fullWidth, dontPadExtra: true, mainPage: true })}>
          {children}
        </div>
      </div>
      <Footer />
      <ModalContainer />
    </div>
  );
}

StandardPage.propTypes = {
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  showHeader: PropTypes.bool,
  showStatus: PropTypes.bool
};

StandardPage.defaultProps = {
  fullWidth: false,
  showHeader: true,
  showStatus: true
};

export default StandardPage;
