import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as applicationActions from '~actions/application-actions';

import AccountLink from '~components/general/account-link';
import IString from '~components/general/i-string';
import SpaceLink from '~components/general/space-link';
import UserLink from '~components/general/user-link';

import * as applicationSelectors from '~selectors/application-selectors';

export function ApplicationAuthorizations(props) {
  const { applicationId, dispatch, total, authorizations } = props;

  useEffect(() => {
    dispatch(applicationActions.fetchApplicationAuthorizations(applicationId));
  }, [ dispatch, applicationId ]);

  return (
    <div className="splitColumns withMobileMargins">
      <div className="column100">

        <div className="sectionTitle flex">
          <IString stringKey="application.authorizations.title" />
        </div>
        <div className="smallMarginBelow">
          <div className="right"><IString stringKey="general.total" placeholders={{ total }} /></div>
        </div>

        <div className="tableContainer">
          <table className="contentListing">
            <thead>
              <tr>
                <th><IString stringKey="general.id" /></th>
                <th><IString stringKey="general.email" /></th>
                <th><IString stringKey="general.first_name" /></th>
                <th><IString stringKey="general.last_name" /></th>
                <th><IString stringKey="general.display_name" /></th>
                <th><IString stringKey="application.authorizations.columns.access" /></th>
              </tr>
            </thead>
            <tbody>
              { authorizations?.map(({ account, user, space }) =>
                <tr key={user.id}>
                  <td><UserLink user={{ id: user.id }} /></td>
                  <td>{user.email}</td>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.display_name}</td>
                  <td>
                    { (account || space)
                      ? <IString
                          placeholders={{ resource: account
                            ? <AccountLink accountId={account} />
                            : <SpaceLink spaceId={space} />
                          }}
                          stringKey="application.authorizations.access.membership"
                          trustPlaceholders
                        />
                      : <IString stringKey="application.authorizations.access.direct" />
                    }
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

ApplicationAuthorizations.propTypes = {
  applicationId: PropTypes.number,
  authorizations: PropTypes.array,
  dispatch: PropTypes.func,
  hasMore: PropTypes.bool,
  offset: PropTypes.number,
  total: PropTypes.number
};

const mapStateToProps = (state) => ({
  authorizations: applicationSelectors.getCurrentApplicationAuthorizations(state),
  total: applicationSelectors.getCurrentApplicationAuthorizationsTotal(state)
});

export default connect(mapStateToProps)(ApplicationAuthorizations);
