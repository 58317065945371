import React from 'react';

import StandardPage from '~components/pages/standard-page';

function HomePage() {

  return (
    <StandardPage>
      <h1>Welcome to Posit Mechanic!</h1>
      <h3>This tool is for performing administrative tasks on Posit Cloud and shinyapps.io. Proceed with caution!</h3>
    </StandardPage>
  );

}

export default HomePage;
