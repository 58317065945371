import * as types from '~actions/action-types';

import { update } from '~reducers/reducer-utils';

import * as storageUtil from '~util/storage';

const displayDatesInUTC = storageUtil.getLocalState(storageUtil.DISPLAY_DATES_IN_UTC);
const initialState = {
  config: undefined,
  visibleDialogs: [],
  uiReady: false,
  currentLanguage: 'en',
  statusMessage: undefined,
  utc: displayDatesInUTC === null || displayDatesInUTC, // default to true if unset
  windowSize: {
    width: 0,
    height: 0
  },
  scrollTop: 0,
  scrollHeight: 0,
  clientHeight: 0
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.OPEN_DIALOG:
      return update(state, { visibleDialogs: [ ...state.visibleDialogs, action.dialog ] });

    case types.CLOSE_DIALOG:
      return update(state, { visibleDialogs: state.visibleDialogs.slice(0, -1) });

    case types.SET_UI_READY:
      return update(state, { uiReady: action.isReady });

    case types.SET_STATUS_MESSAGE:
      return update(state, { statusMessage: action.message });

    case types.CLEAR_STATUS_MESSAGE:
      return update(state, { statusMessage: false });

    case types.UPDATE_SCROLL_INFO:
      return Object.assign({}, state, { scrollTop: action.top, scrollHeight: action.height });

    case types.UPDATE_CLIENT_HEIGHT:
      return Object.assign({}, state, { clientHeight: action.height });

    case types.SET_UI_CONFIG:
      return {
        ...state,
        config: action.config
      };

    case types.SET_UTC:
      return update(state, { utc: action.utcIt });

  }
  return state;
};

export default uiReducer;
