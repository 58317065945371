// `existing` is an object containing normalized data (indexed by id).
// `updatedList` is an array of objects to be added/updated
// returns a new copy of the object containing normalized data
// with the new data added/updated
export const updateMany = (existing, updatedList, prefix = '', property = 'id') => {
  if (!existing || !updatedList) {
    return existing;
  }
  const result = {
    ...existing,
    ...updatedList.reduce((accumulator, current) => {
      if (current) {
        accumulator[prefix + current[property]] = current;
      }
      return accumulator;
    }, {})
  };
  return result;
};

// `existing` is an object containing normalized data (indexed by id).
// `updated` is an object to be added/updated
// returns a new copy of the object containing normalized data
// with the new data added/updated
export const updateOne = (existing, updated, prefix = '', property = 'id') => {
  return {
    ...existing,
    [prefix + updated[property]]: updated
  };
};

// removes a property from the object and returns a new copy
export const removeOne = (existing, key) => {
  const updated = { ...existing };
  delete updated[key];
  return updated;
};

// boilerplate function for updating state
// given an original object and an object of key-value pair updates , 
// creates a new object from the original with the updates applied
export const update = (original, updates) => {
  return Object.assign({}, original, updates);
};
