import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as accountActions from '~actions/account-actions';

import IString from '~components/general/i-string';
import Time from '~components/general/time';

import { MAX_APPLICATION_HOURS_LIMIT } from '~constants/subscription-entitlements';

import { getMaxApplicationHoursLimitEntitlementStatus } from '~selectors/account-selectors';

export function ShinyappsSubscription(props) {
  const { dispatch, hoursUsedInCycle, id, subscription } = props;

  useEffect(() => {
    // MaxApplicationHoursLimit is the entitlement, this api call returns the current status of the entitlement
    dispatch(accountActions.fetchAccountSubscriptionEntitlementStatus(id, MAX_APPLICATION_HOURS_LIMIT));
  }, [ id, dispatch ]);

  if (!subscription) {
    return <div className="emptyListMessage"><IString stringKey="account.subscription.empty" /></div>;
  }

  return (
    <div className="splitColumns">
      <div className="column60">
        <dl className="separateLines">
          <dt><IString stringKey="account.subscription.shinyapps.premium" /></dt>
          <dd>{`${subscription.type.premium}`}</dd>
          <dt><IString stringKey="general.type" /></dt>
          <dd>{subscription.type.name}</dd>
          <dt><IString stringKey="account.subscription.shinyapps.cycle_start_date" /></dt>
          <dd><Time value={subscription.cycle_start_date} /></dd>
          <dt><IString stringKey="account.subscription.shinyapps.cycle_end_date" /></dt>
          <dd><Time value={subscription.cycle_end_date} /></dd>
        </dl>
      </div>
      { subscription.plan &&
        <div className="column35">
          <dl className="separateLines">
            <dt><IString stringKey="account.subscription.shinyapps.plan" /></dt>
            <dd>{subscription.plan.name}</dd>
            <dt><IString stringKey="general.status" /></dt>
            <dd>{subscription.status}</dd>
            <dt><IString stringKey="account.subscription.shinyapps.start_date" /></dt>
            <dd><Time value={subscription.start_date} /></dd>
            <dt><IString stringKey="account.subscription.shinyapps.max_hours" /></dt>
            <dd>{subscription.entitlements.MaxApplicationHoursLimit.limit}</dd>
            <dt><IString stringKey="account.subscription.shinyapps.hours_used_in_cycle" /></dt>
            <dd>{hoursUsedInCycle?.toFixed(2)}</dd>
          </dl>
        </div> }
    </div>
  );
}

ShinyappsSubscription.propTypes = {
  dispatch: PropTypes.func,
  hoursUsedInCycle: PropTypes.number,
  id: PropTypes.number,
  subscription: PropTypes.shape({
    cycle_end_date: PropTypes.string,
    cycle_start_date: PropTypes.string,
    entitlements: PropTypes.shape({
      MaxApplicationHoursLimit: PropTypes.shape({
        limit: PropTypes.number
      })
    }),
    plan: PropTypes.shape({
      name: PropTypes.string
    }),
    start_date: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.shape({
      name: PropTypes.string,
      premium: PropTypes.bool
    })
  })
};

const mapStateToProps = (state) => ({
  hoursUsedInCycle: getMaxApplicationHoursLimitEntitlementStatus(state, MAX_APPLICATION_HOURS_LIMIT)
});

export default connect(mapStateToProps)(ShinyappsSubscription);
