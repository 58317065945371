import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccountLink from '~components/general/account-link';
import IString from '~components/general/i-string';
import RangePicker, { rangeToUnit } from '~components/general/range-picker';
import TimeseriesChart, { formats } from '~components/general/timeseries-chart';
import ChartContainer from '~components/usage/chart-container';
import OverviewCards from '~components/usage/overview-cards';

import * as usageMetrics from '~constants/usage-metrics';

import * as accountOperations from '~operations/account-operations';

import * as contentSelectors from '~selectors/content-selectors';
import * as uiSelectors from '~selectors/ui-selectors';

import mo, { ISO_DATE } from '~util/mo';
import * as usageUtils from '~util/usage';

export const ContentUsage = ({ contentId }) => {
  const dispatch = useDispatch();

  const [ from, setFrom ] = useState();
  const [ rangeSize, setRangeSize ] = useState();
  const [ until, setUntil ] = useState();

  const [ overview, setOverview ] = useState();
  const [ series, setSeries ] = useState();

  const accountId = useSelector((state) => contentSelectors.getContentById(state, contentId).account_id);
  const utc = useSelector(uiSelectors.getUTC);

  const handleRangeChange = useCallback((from, until, rangeSize) => {
    setFrom(from);
    setRangeSize(rangeSize);
    setUntil(until);
  }, []);

  useEffect(() => {
    const fetchUsageOverviewData = async () => {
      const overview = await dispatch(
        accountOperations.getAccountUsageOverview(accountId, { from, content_id: contentId, until })
      );
      setOverview(overview);
    };

    if (accountId && from && contentId && until) {
      fetchUsageOverviewData();
    }

  }, [ accountId, dispatch, from, contentId, until ]);

  useEffect(() => {
    const fetchUsageSeriesData = async () => {
      const series = await dispatch(
        accountOperations.getAccountUsageSeries(accountId, {
          from,
          interval: usageUtils.rangeToInterval[rangeSize],
          content_id: contentId,
          until
        })
      );
      setSeries(series);
    };

    if (accountId && from && contentId && rangeSize && until) {
      fetchUsageSeriesData();
    }

  }, [ accountId, dispatch, from, contentId, rangeSize, until ]);

  const tooltipFormatters = {
    label: usageUtils.computeHoursFormatter,
    title: (tips, data) =>
      mo(
        utc,
        data.datasets[tips[0].datasetIndex].data[tips[0].index].x
      ).format(formats.tooltip[rangeToUnit[rangeSize]])
  };

  return (
    <>
      <div className="sectionTitle">
        <IString stringKey="content.usage.title" />
      </div>

      <div className="sectionTitle small">
        <IString
          placeholders={{ account: <AccountLink accountId={accountId} /> }}
          stringKey="content.usage.context"
          trustplaceholders
        />
      </div>

      <RangePicker
        earliest={mo(utc).subtract(1, 'year').format(ISO_DATE)}
        onRangeChange={handleRangeChange}
        utc={utc}
      />

      <OverviewCards metrics={[ usageMetrics.COMPUTE ]} data={overview} />

      <ChartContainer loading={!series} empty={series && series.length === 0}>
        <TimeseriesChart
          chartType="bar"
          datasets={usageUtils.buildDataset(series)}
          tooltipFormatters={tooltipFormatters}
          xAxisFormatter={
            (value, index, ticks) =>
              mo(utc, ticks[index].value).format(formats.axis[rangeToUnit[rangeSize]])
          }
          unit={rangeToUnit[rangeSize]}
        />
      </ChartContainer>
    </>
  );
};

ContentUsage.propTypes = {
  contentId: PropTypes.number
};

export default ContentUsage;
