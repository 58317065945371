import * as uiActions from '~actions/ui-actions';

import * as api from '~api';
import * as contentApi from '~api/content';

import * as statusTypes from '~components/status/status-types';

import * as contentState from '~constants/content-state';
import * as sortOrder from '~constants/sort-order';

/**
 * Thunk action creator to get the number of content items in the null space for an individual account
 * that have been authored by the account owner
 *
 * @param   {number} account The account to check
 * @returns                  A thunk that returns the number of content items in the null space for the provided account owner
 */
export const getAccountOwnerNullspaceContent = (account) => async (dispatch) => {

  const trashedStates = [ contentState.TRASHED, contentState.DELETED ];

  try {
    const filters = { account_id: account.id, author_id: account.owner.id, space_id: null, state: [ trashedStates.join(), 'notin' ] };
    const order = { 'state': sortOrder.ASC };
    const { data } = await contentApi.getFilteredContentList(filters, order);
    return data;
  } catch (err) {
    const message = api.getErrorMessage(err.response);
    dispatch(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
  }
};
