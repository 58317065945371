import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as accountActions from '~actions/account-actions';
import * as uiActions from '~actions/ui-actions';

import * as dialogsTypes from '~components/dialogs/dialog-types';
import ActionButton from '~components/general/action-button';
import IString from '~components/general/i-string';
import Time from '~components/general/time';

import * as accountSelectors from '~selectors/account-selectors';

function AccountLicenseUsageCredits(props) {

  const { dispatch, accountId, licenseType, usageCredits } = props;

  useEffect(() => {
    if (accountId) {
      dispatch(accountActions.fetchAccountUsageCredits(accountId, licenseType));
    }
  }, [ dispatch, accountId, licenseType ]);

  const handleAdd = () => {
    dispatch(uiActions.openDialog({
      type: dialogsTypes.ACCOUNT_USAGE_CREDITS_CREATE_EDIT_DIALOG,
      data: {
        create: true,
        usageCredits: { credit_hours: undefined }
      },
      onSubmit: (newUsageCredits) => {
        dispatch(accountActions.createAccountUsageCredits(accountId, licenseType, newUsageCredits));
        dispatch(uiActions.closeDialog()); // close editor dialog
      }
    }));
  };

  const handleEdit = () => {
    dispatch(uiActions.openDialog({
      type: dialogsTypes.ACCOUNT_USAGE_CREDITS_CREATE_EDIT_DIALOG,
      data: {
        create: false,
        usageCredits
      },
      onSubmit: (updatedUsageCredits) => {
        if (updatedUsageCredits) {
          dispatch(accountActions.updateAccountUsageCredits(accountId, licenseType, updatedUsageCredits));
        }
        dispatch(uiActions.closeDialog()); // close editor dialog
      }
    }));
  };

  const handleDelete = () => {
    dispatch(uiActions.openDialog({
      type: dialogsTypes.CONFIRM_DIALOG,
      data: {
        purpose: <IString stringKey="account.usageCredits.deletePurpose" />,
        details: <IString stringKey="account.usageCredits.deleteDetails" />,
        confirm: () => {
          dispatch(accountActions.deleteAccountUsageCredits(accountId, licenseType));
          dispatch(uiActions.closeDialog()); // close confirm dialog
        }
      }
    }));
  };

  return (
    <div className="splitColumns withMobileMargins">
      <div className="column100">

        <div className="sectionTitle flex">
          <IString stringKey="account.usageCredits.section" />
          <div className="actionBar inline showTitles">
            { !usageCredits ?
              <ActionButton
                className="action new"
                title={<IString stringKey="general.add" />}
                onClick={handleAdd}
              /> :
              <>
                <ActionButton
                  className="edit"
                  title={<IString stringKey="general.edit" />}
                  onClick={handleEdit}
                />
                <ActionButton
                  className="delete"
                  title={<IString stringKey="general.delete" />}
                  onClick={handleDelete}
                />
              </>
            }
          </div>
        </div>

        <div className="tableContainer">
          { !usageCredits ?
            <div className="emptyListMessage">
              <IString stringKey="account.usageCredits.noCredits" />
            </div> :
            <div className="section sectionBottomBorder">
              <dl className="separateLines">
                <dt><IString stringKey="account.usageCredits.creditHours" /></dt>
                <dd>{usageCredits.credit_hours}</dd>
                <dt><IString stringKey="general.expires" /></dt>
                <dd><Time value={usageCredits.expires} /></dd>
              </dl>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

AccountLicenseUsageCredits.propTypes = {
  accountId: PropTypes.number,
  dispatch: PropTypes.func,
  licenseType: PropTypes.string,
  usageCredits: PropTypes.shape({
    credit_hours: PropTypes.number,
    expires: PropTypes.string,
    license_type: PropTypes.string
  })
};

const mapStateToProps = (state, ownProps) => ({
  usageCredits: accountSelectors.getAccountUsageCredits(state, ownProps.accountId, ownProps.licenseType)
});

export default connect(mapStateToProps)(AccountLicenseUsageCredits);
