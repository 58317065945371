import { createSelector } from 'reselect';

export const getVisibleDialogs = (state) => state.uiState.visibleDialogs;

export const hasVisibleDialogs = createSelector(
  [ getVisibleDialogs ],
  (dialogs) => {
    return !!dialogs && dialogs.length > 0;
  }
);

export const getTopDialog = createSelector(
  [ hasVisibleDialogs, getVisibleDialogs ],
  (hasDialogs, dialogs) => hasDialogs && dialogs[0] ? dialogs[0].type : undefined
);

export const getUTC = (state) => state.uiState.utc;

export const getScreenHeight = (state) => state.uiState.windowSize.height;
export const getScrollTop = (state) => state.uiState.scrollTop;

export const getStatusMessage = (state) => state.uiState.statusMessage;
