import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import * as uiActions from '~actions/ui-actions';

import BannerMessage from '~components/general/banner-message';
import HTMLText from '~components/general/html-text';
import Spinner from '~components/general/spinner';
import * as types from '~components/status/status-types';

import { translate } from '~i18n/localize';

import * as uiSelectors from '~selectors/ui-selectors';

// Display a status message of a particular type
// The 'message' property is either a message key (and should be translated),
// or a complete message itself. This component attempts to translate, and falls
// back to displaying the value if there is no translation.

export function StatusMessage({ dispatch, statusMessage }) {
  const type = statusMessage?.type;
  const message = statusMessage?.message;
  const translated = translate(message);

  const closeMessage = useCallback(() => {
    dispatch(uiActions.clearStatusMessage());
  }, [ dispatch ]);

  useEffect(() => {
    let timeout;
    if (types.isStatus(type) || types.isInfo(type)) {
      // "status" and "info" type messages auto-close after 3 seconds
      timeout = window.setTimeout(closeMessage, 3000);
    }

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [ closeMessage, type ]);

  if (!type) {
    // include an empty placeholder in the DOM so when/if
    // the status message appears, we can animate it in
    return <BannerMessage showing={false} />;
  }

  return (
    <BannerMessage type={type} onClose={closeMessage}>
      {types.isActivity(type) && <div className="messageSpinnerContainer"><Spinner /></div>}
      { translated ? <HTMLText>{translated}</HTMLText> : message }
    </BannerMessage>
  );
}

StatusMessage.propTypes = {
  dispatch: PropTypes.func,
  statusMessage: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.bool.isRequired
  ])
};

const mapStateToProps = (state) => ({
  statusMessage: uiSelectors.getStatusMessage(state)
});

export default connect(mapStateToProps)(StatusMessage);
