import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import * as actions from '~actions/application-actions';

import ApplicationAuthorizations from '~components/applications/application-authorizations';
import ApplicationContainers from '~components/applications/application-containers';
import ApplicationDeployment from '~components/applications/application-deployment';
import ApplicationDetails from '~components/applications/application-details';
import ApplicationLogs from '~components/applications/application-logs';
import ApplicationMenu from '~components/applications/application-menu';
import ApplicationMetrics from '~components/applications/metrics/application-metrics';

export default function ApplicationPage() {
  const dispatch = useDispatch();

  const { params, path } = useRouteMatch();

  const applicationId = Number(params.applicationId);

  useEffect(() => {
    dispatch(actions.setCurrentApplication(applicationId));

    return () => {
      dispatch(actions.clearCurrentApplication());
    };
  }, [ dispatch, applicationId ]);

  return (
    <>
      <h1>Application (id: {applicationId})</h1>
      <ApplicationMenu applicationId={applicationId} />
      <Switch>
        <Route exact path={`${path}/`}>
          <ApplicationDetails />
        </Route>
        <Route path={`${path}/users`}>
          <ApplicationAuthorizations applicationId={applicationId} />
        </Route>
        <Route path={`${path}/logs`}>
          <ApplicationLogs applicationId={applicationId} />
        </Route>
        <Route path={`${path}/metrics`}>
          <ApplicationMetrics applicationId={applicationId} />
        </Route>
        <Route path={`${path}/deployment`}>
          <ApplicationDeployment applicationId={applicationId} />
        </Route>
        <Route path={`${path}/containers`}>
          <ApplicationContainers applicationId={applicationId} />
        </Route>
      </Switch>
    </>
  );
}
