import PropTypes from 'prop-types';
import React from 'react';

import IString from '~components/general/i-string';
import Time from '~components/general/time';

function CloudSubscription({ subscription }) {
  if (!subscription) {
    return <div className="emptyListMessage"><IString stringKey="account.subscription.empty" /></div>;
  }

  return (
    <div className="splitColumns">
      <div className="column60">
        <dl className="separateLines">
          <dt><IString stringKey="account.subscription.cloud.license" /></dt>
          <dd>{subscription.metadata.license_name}</dd>
          <dt><IString stringKey="account.subscription.cloud.plan" /></dt>
          <dd>{subscription.metadata.plan_name}</dd>
          <dt><IString stringKey="general.created" /></dt>
          <dd><Time value={subscription.created * 1000} /></dd>
          <dt><IString stringKey="account.subscription.cloud.cycle_anchor" /></dt>
          <dd><Time value={subscription.billing_cycle_anchor * 1000} /></dd>
          <dt><IString stringKey="account.subscription.cloud.period_start" /></dt>
          <dd><Time value={subscription.current_period_start * 1000} /></dd>
          <dt><IString stringKey="account.subscription.cloud.period_end" /></dt>
          <dd><Time value={subscription.current_period_end * 1000} /></dd>
        </dl>
      </div>
      <div className="column35">
        <dl className="separateLines">
          { subscription.canceled_at ?
            <>
              <dt><IString stringKey="account.subscription.cloud.canceled" /></dt>
              <dd><Time value={subscription.canceled_at * 1000} /></dd>
              <dt><IString stringKey="account.subscription.cloud.subscription_end" /></dt>
              <dd><Time value={subscription.cancel_at * 1000} /></dd>
              <dt><IString stringKey="account.subscription.cloud.cancel_at_period_end" /></dt>
              <dd>{`${subscription.cancel_at_period_end}`}</dd>
            </>
            :
            <>
              <dt><IString stringKey="account.subscription.cloud.scheduled_renewal" /></dt>
              <dd><Time value={subscription.current_period_end * 1000} /></dd>
            </>}
        </dl>
      </div>
    </div>
  );
}

CloudSubscription.propTypes = {
  subscription: PropTypes.shape({
    billing_cycle_anchor: PropTypes.number,
    cancel_at: PropTypes.number,
    cancel_at_period_end: PropTypes.bool,
    canceled_at: PropTypes.number,
    created: PropTypes.number,
    current_period_end: PropTypes.number,
    current_period_start: PropTypes.number,
    metadata: PropTypes.shape({
      license_name: PropTypes.string,
      plan_name: PropTypes.string
    })
  })
};

export default CloudSubscription;
