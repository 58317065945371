import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as accountActions from '~actions/account-actions';
import * as uiActions from '~actions/ui-actions';

import * as dialogs from '~components/dialogs/dialog-types';
import ModalDialog from '~components/dialogs/modal-dialog';
import ExpandoPropertiesTable from '~components/general/expando-properties-table';
import IString from '~components/general/i-string';

import * as accountType from '~constants/account-type';
import * as entitlements from '~constants/license-entitlements';
import * as licenses from '~constants/license-name';
import * as licenseSeat from '~constants/license-seat';

import { translate } from '~i18n/localize';

import * as accountOperations from '~operations/account-operations';

import * as accountSelectors from '~selectors/account-selectors';

const overrides = {
  [licenses.CLOUD_CUSTOM]: {},

  [licenses.CLOUD_BASIC]: {
    project: [
      { type: entitlements.ACCOUNT_PROJECT_CPU_RANGE, value: { min: 0.5, max: 2 } },
      { type: entitlements.ACCOUNT_PROJECT_MEM_RANGE, value: { min: 512, max: 8192 } },
      { type: entitlements.ACCOUNT_PROJECT_SESSION_KILL_TIMEOUT_MINUTES_RANGE, value: { min: 60, max: 1440 } }
    ],
    output: [
      { type: entitlements.ACCOUNT_OUTPUT_MAX, value: { max: 50 } },
      { type: entitlements.ACCOUNT_OUTPUT_CPU_RANGE, value: { min: 0.5, max: 2 } },
      { type: entitlements.ACCOUNT_OUTPUT_MEM_RANGE, value: { min: 512, max: 8192 } }
    ],
    job_run: [
      { type: entitlements.ACCOUNT_JOB_RUN_CPU_RANGE, value: { min: 0.5, max: 2 } },
      { type: entitlements.ACCOUNT_JOB_RUN_MEM_RANGE, value: { min: 512, max: 8192 } }
    ]
  },

  [licenses.CLOUD_STANDARD]: {
    project: [
      { type: entitlements.ACCOUNT_PROJECT_CPU_RANGE, value: { min: 0.5, max: 8 } },
      { type: entitlements.ACCOUNT_PROJECT_MEM_RANGE, value: { min: 512, max: 32768 } },
      { type: entitlements.ACCOUNT_PROJECT_SESSION_KILL_TIMEOUT_MINUTES_RANGE, value: { min: 60, max: 5760 } }
    ],
    output: [
      { type: entitlements.ACCOUNT_OUTPUT_CPU_RANGE, value: { min: 0.5, max: 8 } },
      { type: entitlements.ACCOUNT_OUTPUT_MEM_RANGE, value: { min: 512, max: 32768 } }
    ],
    job_run: [
      { type: entitlements.ACCOUNT_JOB_RUN_CPU_RANGE, value: { min: 0.5, max: 8 } },
      { type: entitlements.ACCOUNT_JOB_RUN_MEM_RANGE, value: { min: 512, max: 32768 } }
    ]
  },

  [licenses.CLOUD_INSTRUCTOR]: {},

  [licenses.CLOUD_INSTRUCTOR_STUDENT_PAY]: {
    account_membership: [
      { type: entitlements.ACCOUNT_REQUIRE_PAID_PARTICIPANTS_FLAG, value: { enabled: true } }
    ]
  },

  [licenses.CLOUD_INSTRUCTOR_FEE_PER_STUDENT]: {}
};

export default function AccountLicenseQuickConfigureDialog({ data: { accountId, license }, onSubmit }) {
  const dispatch = useDispatch();

  const account = useSelector(state => accountSelectors.getAccount(state, accountId));
  const currentConfiguredEntitlements = useSelector(state => accountSelectors.getAllConfiguredAccountLicenseEntitlements(state, accountId));

  const currentMemberMax = license.license_seats?.[licenseSeat.MEMBER]?.max || null;
  const currentInstructorMax = license.license_seats?.[licenseSeat.INSTRUCTOR]?.max || null;
  const currentStudentMax = license.license_seats?.[licenseSeat.STUDENT]?.max || null;
  const initialConfigureAs = currentInstructorMax ? licenses.CLOUD_INSTRUCTOR : licenses.CLOUD_CUSTOM;

  const isOrgAccount = accountType.isOrgAccount(account);

  const [ configureAs, setConfigureAs ] = useState(initialConfigureAs);
  const [ instructorMax, setInstructorMax ] = useState(currentInstructorMax || 1);
  const [ memberMax, setMemberMax ] = useState(currentMemberMax || 1);
  const [ studentMax, setStudentMax ] = useState(currentStudentMax);

  const [ pending, setPending ] = useState(false);

  const handleConfigureAsChange = ({ target: { value } }) => {
    setConfigureAs(value);

    if (licenses.isAcademic(value)) {
      if (value === licenses.CLOUD_INSTRUCTOR_STUDENT_PAY) {
        setStudentMax(null);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setPending(true);

    // update the license seats
    const license_seats = {};
    if (licenses.isAcademic(configureAs)) {
      if (instructorMax !== currentInstructorMax || studentMax !== currentStudentMax) {
        license_seats[licenseSeat.INSTRUCTOR] = { max: instructorMax };
        if (studentMax) {
          // only set this if we have a value, since it can be unlimited
          license_seats[licenseSeat.STUDENT] = { max: studentMax };
        }
      }
    } else if (memberMax !== currentMemberMax) {
      license_seats[licenseSeat.MEMBER] = { max: memberMax };
    }

    if (Object.keys(license_seats).length > 0) {
      dispatch(accountActions.updateAccountLicense(accountId, license.type, { license_seats }));
    }

    // delete any existing entitlement overrides
    if (currentConfiguredEntitlements?.length > 0) {

      await Promise.all(
        currentConfiguredEntitlements.map(entitlement =>
          dispatch(accountOperations.deleteAccountLicenseEntitlement(account.id, 'cloud', entitlement.type))
        )
      );
    }

    // finally, create each entitlement override
    if (Object.keys(overrides[configureAs]).length > 0) {
      const entitlements = Object.values(overrides[configureAs]).flat();
      await Promise.all(
        entitlements.map(entitlement =>
          dispatch(accountOperations.createAccountLicenseEntitlement(account.id, 'cloud', entitlement.type, { ...entitlement.value, type: entitlement.type, id: null }))
        )
      );
    }

    onSubmit();

    dispatch(uiActions.setSuccessMessage(
      <IString placeholders={{ type: configureAs }} stringKey="account.license.quickConfigure.dialog.success" />
    ));
  };

  return (
    <ModalDialog type={dialogs.ACCOUNT_LICENSE_QUICK_CONFIGURE_DIALOG}>
      <div className="purpose">
        <IString stringKey="account.license.quickConfigure.dialog.title" />
      </div>

      <form onSubmit={handleSubmit}>
        <fieldset className="chunk">
          <label htmlFor="accountLicenseQuickConfigureAs" className="fieldLabel">
            <IString stringKey="account.license.quickConfigure.dialog.select.label" />
          </label>
          <select
            className="full"
            id="accountLicenseQuickConfigureAs"
            onChange={handleConfigureAsChange}
            value={configureAs}
          >
            {Object.keys(overrides).map(licenseName =>
              <option key={licenseName} value={licenseName}>{licenseName}</option>
            )}
          </select>
        </fieldset>

        { configureAs &&
          <>
            { licenses.isAcademic(configureAs)
            ?
              <>
                <fieldset className="chunk">
                  <label className="fieldLabel" htmlFor="instructorMax">
                    <IString stringKey="account.license.dialog.properties.license_seats.instructor" />
                  </label>
                  <input
                    className="full"
                    disabled={!isOrgAccount}
                    id="instructorMax"
                    min="1"
                    onChange={({ target: { value } }) => setInstructorMax(+value || 1)}
                    required
                    title={(isOrgAccount) ? undefined : translate('account.license.dialog.properties.license_seats.disabled.individual')}
                    type="number"
                    value={instructorMax}
                  />
                </fieldset>
                <fieldset className="chunk">
                  <label className="fieldLabel" htmlFor="studentMax">
                    <IString stringKey="account.license.dialog.properties.license_seats.student" />
                    <IString className="smallerFont" stringKey={`account.license.quickConfigure.dialog.seats.student.${configureAs}`} />
                  </label>
                  <input
                    className="full"
                    disabled={configureAs === licenses.CLOUD_INSTRUCTOR_STUDENT_PAY}
                    id="studentMax"
                    min="1"
                    onChange={({ target: { value } }) => setStudentMax(value === '' ? null : +value)}
                    placeholder={configureAs !== licenses.CLOUD_INSTRUCTOR_FEE_PER_STUDENT ? translate('general.unlimited') : ''}
                    required={configureAs === licenses.CLOUD_INSTRUCTOR_FEE_PER_STUDENT}
                    type="number"
                    value={studentMax ?? ''}
                  />
                </fieldset>
              </>
            :
              <fieldset className="chunk">
                <label className="fieldLabel" htmlFor="memberMax">
                  <IString stringKey="account.license.dialog.properties.license_seats.member" />
                </label>
                <input
                  id="memberMax"
                  min="1"
                  onChange={({ target: { value } }) => setMemberMax(+value || 1)}
                  required
                  type="number"
                  value={memberMax}
                />
              </fieldset>
            }

            { !!Object.keys(overrides[configureAs]).length &&
              <>
                <p className="bigMarginAbove"><IString stringKey="account.license.quickConfigure.dialog.entitlements" /></p>
                <table className="propertiesTable">
                  <tbody>
                    {Object.keys(overrides[configureAs]).map(category =>
                      <React.Fragment key={category}>
                        <tr>
                          <td className="tableTitle sectionLabel" colSpan={4} >
                            <IString stringKey={`account.license.entitlement.table.sections.${category}`} />
                          </td>
                        </tr>
                        {overrides[configureAs][category].map(({ type, value: { enabled, max, min } }) =>
                          <tr key={type}>
                            <td>{type}</td>
                            <td>
                              { min !== undefined &&
                                <div><IString placeholders={{ value: min }} stringKey="account.license.entitlement.configuration.min" /></div>
                              }
                              { max !== undefined &&
                                <div><IString placeholders={{ value: max }} stringKey="account.license.entitlement.configuration.max" /></div>
                              }
                              { enabled !== undefined && <div>{String(enabled)}</div> }
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    )}
                  </tbody>
                </table>
              </>
            }

            {!!currentConfiguredEntitlements?.length > 0 &&
              <ExpandoPropertiesTable title={<IString stringKey="account.license.quickConfigure.dialog.existing" />}>
                {currentConfiguredEntitlements.map(({ type, enabled, max, min }) =>
                  <tr className="smallerFont" key={type}>
                    <td>{type}</td>
                    <td>
                      { min !== undefined &&
                        <div><IString placeholders={{ value: min }} stringKey="account.license.entitlement.configuration.min" /></div>
                      }
                      { max !== undefined &&
                        <div><IString placeholders={{ value: max }} stringKey="account.license.entitlement.configuration.max" /></div>
                      }
                      { enabled !== undefined && <div>{String(enabled)}</div> }
                    </td>
                  </tr>
                  )}
              </ExpandoPropertiesTable>
            }
          </>
        }
        <div className="actions midMarginAbove">
          <button disabled={!configureAs || pending} type="submit"><IString stringKey="general.ok" /></button>
        </div>
      </form>
    </ModalDialog>
  );
}

AccountLicenseQuickConfigureDialog.propTypes = {
  data: PropTypes.shape({
    accountId: PropTypes.number.isRequired,
    license: PropTypes.shape({
      license_seats: PropTypes.object,
      name: PropTypes.string,
      type: PropTypes.string
    }).isRequired
  }).isRequired,
  onSubmit: PropTypes.func
};
