import { combineReducers } from 'redux';

import * as types from '~actions/action-types';

import { removeOne, updateOne, updateMany, update } from '~reducers/reducer-utils';

const contentReducer = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_CONTENT_SUCCESS:
    case types.UPDATE_CONTENT_SUCCESS:
      return updateOne(state, action.content);

    case types.FETCH_CONTENT_SEARCH_SUCCESS:
      return updateMany(state, action.content);

    case types.FETCH_PROJECT_CHILDREN_SUCCESS:
      return updateMany(state, action.children);

    case types.FETCH_PROJECT_OUTPUTS_SUCCESS:
      return updateMany(state, action.outputs);

    case types.FETCH_ACCOUNT_CONTENT_SUCCESS:
    case types.FETCH_SPACE_CONTENT_PAGE_SUCCESS:
    case types.FETCH_CONTENT_BY_AUTHOR_SUCCESS:
      return updateMany(state, action.content);

    case types.DELETE_CONTENT_SUCCESS:
      return removeOne(state, action.id);

  }
  return state;
};

const searchReducer = (state = { loading: false, query: undefined, resultIds: [], total: undefined }, action) => {
  switch (action.type) {
    case types.SET_CONTENT_SEARCH:
      return update(state, { loading: true, query: action.query });

    case types.FETCH_CONTENT_SEARCH_SUCCESS:
      return update(state,
        {
          loading: false,
          resultIds: (action.offset === 0 ? [] : state.resultIds).concat(action.content.map(c => c.id)),
          total: action.total
        });

    case types.FETCH_CONTENT_SEARCH_FAILURE:
    case types.CLEAR_CONTENT_SEARCH:
      return update(state, { loading: false, query: undefined, resultIds: [], total: undefined });
  }
  return state;
};

export default combineReducers({
  content: contentReducer,                // object of { [content.id]: content }
  search: searchReducer                   // loading, query, resultIds, total
});
