import { combineReducers } from 'redux';

import * as types from '~actions/action-types';

import { updateOne, removeOne, update, updateMany } from '~reducers/reducer-utils';

const usersReducer = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_USER_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
      return updateOne(state, action.user);

    case types.FETCH_USER_SEARCH_PAGE_SUCCESS:
      return updateMany(state, action.users);

    case types.DELETE_USER_SUCCESS:
      return removeOne(state, action.user.id);
  }
  return state;
};

const currentUserReducer = (state = { id: undefined }, action) => {
  switch (action.type) {
    case types.SET_CURRENT_USER:
      return update(state, { id: action.userId });

    case types.CLEAR_CURRENT_USER:
      return update(state, { id: undefined });
  }
  return state;
};

const ownedAccountIdsReducer = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_CURRENT_USER_OWNED_ACCOUNTS_SUCCESS:
      return action.accounts.map(a => a.id);

    case types.CREATE_ACCOUNT_SUCCESS:
      return [ ...state, action.account.id ];

    case types.DELETE_ACCOUNT_SUCCESS:
      return state.filter(id => id !== action.accountId);

    case types.CLEAR_CURRENT_USER:
      return [];
  }
  return state;
};

const userAuthoredContentReducer = (state = { loading: false, ordered: [] }, action) => {
  switch (action.type) {
    case types.FETCH_CONTENT_BY_AUTHOR:
      return update(state, { loading: true });

    case types.FETCH_CONTENT_BY_AUTHOR_SUCCESS:
      return update(state, {
        loading: false,
        ordered: action.content.map(c => c.id)
      });

    case types.CLEAR_CURRENT_USER_AUTHORED_CONTENT:
      return update(state, { loading: false, ordered: [] });
  }
  return state;
};

const searchReducer = (state = { loading: false, query: undefined, resultIds: [], total: undefined }, action) => {
  switch (action.type) {
    case types.FETCH_USER_SEARCH_PAGE:
      return update(state, { loading: true, query: action.query });

    case types.FETCH_USER_SEARCH_PAGE_SUCCESS:
      return update(state,
        { loading: false,
          resultIds: (action.offset === 0 ? [] : state.resultIds).concat(action.users.map(u => u.id)),
          total: action.total });

    case types.DELETE_USER_SUCCESS:
      return update(state, { resultIds: state.resultIds.filter(id => id !== action.user.id) });

    case types.FETCH_USER_SEARCH_PAGE_FAILURE:
    case types.CLEAR_USER_SEARCH:
      return update(state, { loading: false, query: undefined, resultIds: [], total: undefined });
  }
  return state;
};

export default combineReducers({
  users: usersReducer,                  // object of this shape: { [user.id]: user }
  currentUser: currentUserReducer,      // id
  ownedAccountIds: ownedAccountIdsReducer,  // array of ids []
  search: searchReducer,                 // loading, query, resultIds
  userAuthoredContent: userAuthoredContentReducer // ordered, total, loading
});
