import { call, put } from 'redux-saga/effects';

import * as contentActions from '~actions/content-actions';
import * as uiActions from '~actions/ui-actions';

import * as contentAPI from '~api/content';

export function* searchContent(action) {
  try {
    const { data } = yield call(contentAPI.searchContent, action.query, action.offset);
    yield put(contentActions.fetchContentSearchSuccess(data.content, data.total, action.offset));
  } catch (err) {
    if (err) {
      yield put(uiActions.setErrorMessage(err));
    }
    yield put(contentActions.fetchContentSearchFailure());
  }
}

export function* fetchContent(action) {
  const { data } = yield call(contentAPI.getContentById, action.id);
  yield put(contentActions.fetchContentSuccess(data));
}
