import React from 'react';

export function Footer() {

  return (
    <footer id="rStudioFooter" className="band">
      <div className="bandContent">
        <div>
          <div id="footerLogo" />
        </div>
        <div id="logos">
          <a href="https://pos.it/facebook" className="footerLogo facebook" aria-label="Facebook" target="_blank" rel="noopener noreferrer" />
          <a href="https://pos.it/twitter" className="footerLogo twitter" aria-label="Twitter" target="_blank" rel="noopener noreferrer" />
          <a href="https://pos.it/linkedin" className="footerLogo linkedIn" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer" />
          <a href="https://pos.it/linkedin" className="footerLogo instagram" aria-label="Instagram" target="_blank" rel="noopener noreferrer" />
          <a href="https://pos.it/github" className="footerLogo gitHub" aria-label="GitHub" target="_blank" rel="noopener noreferrer" />
          <div id="copyright">&copy; 2022 Posit Software, PBC</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
