import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import IString from '~components/general/i-string';
import Time from '~components/general/time';

import * as containerOperations from '~operations/container-operations';

export const ApplicationContainers = ({ applicationId }) => {
  const dispatch = useDispatch();

  const [ containers, setContainers ] = useState();
  const [ total, setTotal ] = useState();

  useEffect(() => {
    const fetchContainers = async () => {
      const data = await dispatch(containerOperations.getContainersByApplicationId(applicationId));

      setContainers(data?.containers);
      setTotal(data?.total);
    };

    fetchContainers();

  }, [ applicationId, dispatch ]);

  return (
    <>
      <div className="splitColumns withMobileMargins">
        <div className="column100" />
      </div>

      <div className="splitColumns withMobileMargins">
        <div className="column100">

          <div className="sectionTitle flex">
            <IString stringKey="application.containers.title" />
          </div>

          <div className="smallMarginBelow">
            <div className="right">
              {total !== undefined && <IString stringKey="general.total" placeholders={{ total }} />}
            </div>
          </div>

          <div className="tableContainer">
            <table className="contentListing">
              <thead>
                <tr>
                  <th><IString stringKey="general.id" /></th>
                  <th><IString stringKey="general.status" /></th>
                  <th><IString stringKey="application.containers.columns.template" /></th>
                  <th><IString stringKey="general.created" /></th>
                  <th><IString stringKey="general.updated" /></th>
                </tr>
              </thead>
              <tbody>
                {containers?.map(container =>
                  <tr key={container.id}>
                    <td>{container.id}</td>
                    <td>{container.status}</td>
                    <td>{container.template.name}</td>
                    <td><Time value={container.created_time} /></td>
                    <td><Time value={container.updated_time} /></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>

  );
};

ApplicationContainers.propTypes = {
  applicationId: PropTypes.number
};

export default ApplicationContainers;
