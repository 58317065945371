import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import AccountLicenseDetails from '~components/accounts/license/account-license-details';
import AccountLicenseEntitlements from '~components/accounts/license/account-license-entitlements';
import AccountLicenseUsageCredits from '~components/accounts/license/account-license-usage-credits';

import * as accountSelectors from '~selectors/account-selectors';

function AccountLicense(props) {

  const { accountId, license } = props;

  return (
    <>
      { license &&
        <div className="splitColumns withMobileMargins">
          <div className="column50">
            <AccountLicenseDetails accountId={accountId} license={license} />
            <AccountLicenseUsageCredits accountId={accountId} licenseType={license.type} />
          </div>

          <div className="column50">
            <AccountLicenseEntitlements license={license} />
          </div>

        </div>
      }
    </>
  );
}

AccountLicense.propTypes = {
  accountId: PropTypes.number,
  license: PropTypes.shape({
    cycle_end: PropTypes.string,
    cycle_start: PropTypes.string,
    exempt: PropTypes.bool,
    exempt_until: PropTypes.string,
    expires: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.string,
    suspended: PropTypes.bool,
    suspended_reason: PropTypes.string,
    suspended_until: PropTypes.string,
    type: PropTypes.string
  })
};

const mapStateToProps = (state) => ({
  license: accountSelectors.getCurrentAccountCloudLicense(state) // FIXME: currently only fetching the cloud license
});

export default connect(mapStateToProps)(AccountLicense);
