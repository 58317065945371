import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import * as contentType from '~constants/content-type';

function ContentMenu({ content }) {

  return (
    <div className="menu showOnMobile">
      <div className="menuItems">
        <NavLink exact to={`/content/${content.id}`} className="menuItem" activeClassName="active">Details</NavLink>
        { contentType.isProject(content) &&
          <>
            <NavLink to={`/content/${content.id}/children`} className="menuItem" activeClassName="active">Child Projects</NavLink>
            <NavLink to={`/content/${content.id}/outputs`} className="menuItem" activeClassName="active">Project Outputs</NavLink>
            <NavLink to={`/content/${content.id}/sessions`} className="menuItem" activeClassName="active">Sessions</NavLink>
          </>
        }
        <NavLink to={`/content/${content.id}/usage`} className="menuItem" activeClassName="active">Usage</NavLink>
      </div>
    </div>
  );
}

ContentMenu.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.number
  })
};

export default ContentMenu;
