import axios from 'axios';

import * as configSelectors from '~selectors/config-selectors';

import * as tokenUtil from '~util/token';

import { getStore } from '../store';

export const API = () => {
  const token = tokenUtil.loadToken();

  return axios.create({
    baseURL: configSelectors.getAPIBaseURL(getStore().getState()), // FIXME: seems hacky to reach right into the store to get the config
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token.access_token
    }
  });
};

// transforms an object of key-value pairs into query params:
// input: { id: 24 }, { foo: [bar, baz] }
// output: "?id=24" or "?foo=bar&foo=baz"
export const makeParams = (params = {}) => {
  let key, value, v;
  const results = [];

  for (key in params) {
    if (!Object.prototype.hasOwnProperty.call(params, key)) {
      continue;
    }
    value = params[key];
    if (value === undefined) {
      continue;
    } else if (Array.isArray(value)) {
      for (v of value) {
        results.push(`${key}=${encodeURIComponent(v)}`);
      }
    } else {
      results.push(`${key}=${encodeURIComponent(value)}`);
    }
  }
  return results.length > 0 ? `?${results.join('&')}` : '';
};

// transforms an object of key-value pairs into query params:
// input: { account_id: <account_id>, type: shiny }
// output: "filter=account_id:<account_id>&filter=type:shiny"
// TODO: what does this need to look like?
export const filterize = (filters = {}) =>
  Object.entries(filters)
    .map(([ key, value ]) => 'filter=' + encodeURIComponent(`${key}:eq:${value}`))
    .join('&');

export const getErrorMessage = (response) => {
  let message = response?.data?.error;
  if (!message) {
    message = response?.statusText;
  }

  return message || 'unknown error occurred';
};

// helper function to create filter parameter query string
// transforms an object like {column: value} or {column: [operator, value]}
const filtersParamsToString = (filters) => {
  if (typeof filters === 'string') {
    // this is a fancy filter of some kind. assume it's formatted correctly,
    // encode it and pass it on
    return `filter=${encodeURIComponent(filters)}`;
  }

  let key, value, op;
  const results = [];
  for (key in filters) {
    if (!Object.prototype.hasOwnProperty.call(filters, key)) {
      continue;
    }
    value = filters[key];
    if (value === undefined) {
      continue;
    }
    if (Array.isArray(value)) {
      [ value, op ] = value;
    } else {
      op = 'eq';
    }
    results.push('filter=' + encodeURIComponent(key + ':' + op + ':' + value));
  }
  return results.join('&');
};

// helper function to create order parameter query string
// transforms an object like {column} or {column:sort}
export const orderParamsToString = (order) => {
  let key, sort;
  const results = [];
  for (key in order) {
    if (!Object.prototype.hasOwnProperty.call(order, key)) {
      continue;
    }
    sort = order[key];
    if (sort !== undefined) {
      results.push('order=' + encodeURIComponent(key + ':' + sort));
    } else {
      results.push('order=' + encodeURIComponent(key));
    }
  }
  return results.join('&');
};

// construct a query string for list calls
export const listParamsToString = (filters, order) => {
  let query = '';
  if (filters) {
    query += filtersParamsToString(filters);
  }
  if (order) {
    if (query) {
      query += '&';
    }
    query += orderParamsToString(order);
  }
  return query;
};

/**
 * Given a set of fields and a search string, builds an advanced filtering parameter object
 * to search all provided fields with "like", returning anything that matches any fields.
 * If search is numeric, the filter will also include a search for id (no need to provide it).
 * If a search is provided, the returned object will also include use_advanced_filters=1,
 * as that is necessary to use this syntax.
 *
 * This kind of filter is only compatible with endpoints that support advanced filtering.
 *
 * @example
 * {
 *   filter: 'name:like:%10%|email:like:%10%|id:eq:10',
 *   use_advanced_filters: true
 * }
 * @param   {string} search The text to search for
 * @param   {array}  fields The fields to search
 * @returns {object}        An object for use as params; if search is provided, this object includes
 *                          an encoded filter parameter and a use_advanced_filters parameter.
 */
export const createAdvancedSearchParams = (search, fields) => {
  if (search && fields && fields.length > 0) {
    const idFilter = +search > 0 ? [ `id:eq:${search}` ] : []; // include id only if the search is numeric

    const filter =
      fields
        .map(f => `${f}:like:%${search}%`) // build the "like" filters for the provided fields
        .concat(idFilter)  // id uses an "eq" filter, handle it separately
        .join('|');  // these are all "or"s

    return {
      filter,
      use_advanced_filters: true
    };
  }
  return {};
};
