import * as localStorage from '~util/storage';

const TOKEN_KEY = 'token';

const transformToken = (token) => {
  return {
    ...token,
    expires_at: Date.now() + token.expires_in * 1000  // expires_in is seconds, we need millis
  };
};

export const saveToken = (token) => {
  localStorage.setLocalState(TOKEN_KEY, transformToken(token));
};

export const loadToken = () => {
  return localStorage.getLocalState(TOKEN_KEY);
};

export const clearToken = () => {
  return localStorage.clearLocalState(TOKEN_KEY);
};
