import axios from 'axios';

import { makeParams } from '~api/api';

const getAuthURL = () => {
  const baseURL = location.protocol + '//' + location.host;
  return new URL('/auth', baseURL).toJSON();
};

export const AUTH = () => {
  return axios.create({
    baseURL: getAuthURL()
  });
};

export const requestAccessToken = (code, state) => {
  const query = makeParams({ code, state });
  return AUTH().post(`/token${query}`); //then(transformToken);
};

export const refreshAccessToken = () => {
  return AUTH().post('/refresh', {}); //.then(transformToken);
};

export const logout = () => {
  return AUTH().get('/logout');
};
