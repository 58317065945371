import { createSelector } from 'reselect';

import { getContent } from '~selectors/content-selectors';

// current project children
export const getCurrentProjectChildren = createSelector(
  [ getContent, state => state.projectState.currentChildren.ids ],
  (projects, ids) => ids.map(id => projects[id])
);

// current project outputs
export const getCurrentProjectOutputs = createSelector(
  [ getContent, state => state.projectState.currentOutputs.ids ],
  (outputs, ids) => ids.map(id => outputs[id])
);

export const getCurrentProjectOutputsTotal = (state) => state.projectState.currentOutputs.total;

// current project sessions
export const getCurrentProjectSessions = (state) => state.projectState.sessions;
