import * as types from '~actions/action-types';

import * as statusTypes from '~components/status/status-types';

export const initialize = () => ({ type: types.INITIALIZE });

export const setReady = (isReady) => ({
  type: types.SET_UI_READY,
  isReady
});

export const setConfig = (config) => ({
  type: types.SET_UI_CONFIG,
  config
});

export const openDialog = (dialog) => ({
  type: types.OPEN_DIALOG,
  dialog
});

export const closeDialog = () => ({
  type: types.CLOSE_DIALOG
});

export const setActivityMessage = (message) => setStatusMessage({ message, type: statusTypes.ACTIVITY_MESSAGE });

export const setErrorMessage = (error) => {
  const { response } = error;
  const message = response ? response.data?.error || response.statusText || 'unknown error occurred' : error.toString();

  return {
    type: types.SET_STATUS_MESSAGE,
    message: {
      type: statusTypes.ERROR_MESSAGE,
      message
    }
  };
};

export const setSuccessMessage = (message) => {
  return setStatusMessage({ message, type: statusTypes.INFO_MESSAGE });
};

export const setStatusMessage = (message) => ({
  type: types.SET_STATUS_MESSAGE,
  message
});

export const clearStatusMessage = () => ({
  type: types.CLEAR_STATUS_MESSAGE
});

export const setUTC = (utcIt) => ({
  type: types.SET_UTC,
  utcIt
});

export function updateWindowSize(width, height) {
  return {
    type: types.UPDATE_WINDOW_SIZE,
    width,
    height
  };
}

export function updateScrollInfo(top, height) {
  return {
    type: types.UPDATE_SCROLL_INFO,
    top,
    height
  };
}

export function updateClientHeight(height) {
  return {
    type: types.UPDATE_CLIENT_HEIGHT,
    height
  };
}
