import { API, filterize, makeParams, listParamsToString } from '~api/api';

// account lists //

// returns a paginated list of accounts for accounts search
export const searchAccountsPage = (search, offset = 0, count = 25) => {
  if (!search?.trim()) {
    return Promise.reject();
  }
  const query = search && 'filter=' + encodeURIComponent(`name:like:%${search}%|id:eq:${search}`);
  return API().get(`/accounts?${query}`, {
    params: { offset, count }
  });
};

export const listAccounts = (filters) => {
  const query = filterize(filters);
  return API().get(`accounts?${query}`);
};

// account CRUD //

export const createAccount = (account) => {
  return API().post('/accounts',  { ...account });
};

export const getAccount = (accountId) => {
  return API().get(`/accounts/${accountId}`);
};

export const deleteAccount = (accountId) => {
  return API().delete(`/accounts/${accountId}`);
};

export const transferAccount = (accountId, email, branding) => {
  return API().post(`/accounts/${accountId}/transfer`, { email, branding });
};

export const updateAccount = (accountId, accountData) => {
  return API().put(`/accounts/${accountId}`, accountData);
};

// account licenses/subscriptions/entitlements (cloud)

export const updateAccountLicense = (accountId, licenseType, licenseData) => {
  return API().patch(`/accounts/${accountId}/licenses/${licenseType}`, licenseData);
};

export const getAccountSubscription = (accountId, licenseType) => {
  return API().get(`/accounts/${accountId}/subscriptions/${licenseType}`);
};

export const deleteAccountSubscription = (accountId, licenseType) => {
  return API().delete(`/accounts/${accountId}/subscriptions/${licenseType}`);
};

export const getAccountLicenseEntitlement = (accountId, licenseType, entitlementName) => {
  return API().get(`/accounts/${accountId}/licenses/${licenseType}/entitlements/${entitlementName}`);
};

export const deleteAccountLicenseEntitlement = (accountId, licenseType, entitlementName) => {
  return API().delete(`/accounts/${accountId}/licenses/${licenseType}/entitlements/${entitlementName}`);
};

export const updateAccountLicenseEntitlement = (accountId, licenseType, entitlementName, entitlementData) => {
  return API().patch(`/accounts/${accountId}/licenses/${licenseType}/entitlements/${entitlementName}`, entitlementData);
};

export const createAccountLicenseEntitlement = (accountId, licenseType, entitlementName, entitlementData) => {
  return API().post(`/accounts/${accountId}/licenses/${licenseType}/entitlements/${entitlementName}`, entitlementData);
};

// account legacy subscriptions and entitlements (shinyapps.io) //

export const getAccountSubscriptionEntitlementStatus = (accountId, entitlement) => {
  return API().get(`/accounts/${accountId}/subscription/entitlements/${entitlement}/status`);
};

export const updateAccountShinyappsSubscription = (accountId, accountData) => {
  return API().post(`/accounts/${accountId}/subscription`, accountData);
};

// account members //

export const updateAccountMemberCloudRole = (accountId, memberId, role) => {
  return API().patch(`/accounts/${accountId}/cloud/members/${memberId}`, { role });
};

// fetch paginated list of account members
// NOTE:the shape of the order param: {[sortBy]: sortOrder}
export const listAccountMembers = (account_id, brand, filters = {}, order = null, offset = 0, count = 25) => {
  const query = filters ? `?${listParamsToString(filters, order)}` : '';
  return API().get(`/accounts/${account_id}/${brand}/members${query}`, {
    params: { offset, count }
  });
};

// account properties //

export const createAccountProperty = (accountId, propertyData, options = { force: true }) => {
  return API().post(`/accounts/${accountId}/properties/${makeParams(options)}`, propertyData);
};

export const getAccountProperties = (accountId, options = {}) => {
  return API().get(`/accounts/${accountId}/properties${makeParams(options)}`) ;
};

export const deleteAccountProperty = (accountId, property, options = { force: true }) => {
  return API().delete(`/accounts/${accountId}/properties/${property}${makeParams(options)}`);
};

// account usage //

export const getAccountUsageSeries = (accountId, options = {}) => {
  return API().get(`/accounts/${accountId}/usage/series${makeParams(options)}`);
};

export const getAccountUsageSummary = (accountId, options = {}) => {
  return API().get(`/accounts/${accountId}/usage${makeParams(options)}`);
};

export const createAccountUsageCredits = (accountId, licenseType, usageCredits) => {
  return API().post(`accounts/${accountId}/usage_credits/${licenseType}`, usageCredits);
};

export const deleteAccountUsageCredits = (accountId, licenseType) => {
  return API().delete(`accounts/${accountId}/usage_credits/${licenseType}`);
};

export const getAccountUsageCredits = (accountId, licenseType) => {
  return API().get(`accounts/${accountId}/usage_credits/${licenseType}`);
};

export const updateAccountUsageCredits = (accountId, licenseType, usageCredits) => {
  return API().patch(`accounts/${accountId}/usage_credits/${licenseType}`, usageCredits);
};
