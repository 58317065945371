import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as accountActions from '~actions/account-actions';
import * as uiActions from '~actions/ui-actions';
import * as userActions from '~actions/user-actions';

import * as api from '~api/api';
import * as usersAPI from '~api/users';

import * as dialogsTypes from '~components/dialogs/dialog-types';
import AccountLink from '~components/general/account-link';
import ActionButton from '~components/general/action-button';
import IString from '~components/general/i-string';
import Time from '~components/general/time';
import * as statusTypes from '~components/status/status-types';
import UserDetailsAccountList from '~components/users/user-details-account-list';

import { translate } from '~i18n/localize';

import * as configSelectors from '~selectors/config-selectors';
import * as userSelectors from '~selectors/user-selectors';

import * as connectApps from '~util/connect-apps';
import * as datetime from '~util/datetime';

function UserDetails({ datadogURL, dispatch, environment, user }) {
  const history = useHistory();

  const isLockedOut = !!user && user.lockout_until && datetime.parse(user.lockout_until) > new Date();

  const handleEdit = (e) => {
    e.preventDefault();

    // TODO: move this to a saga
    dispatch(uiActions.openDialog({
      type: dialogsTypes.USER_DETAILS_EDITOR_DIALOG,
      data: {
        user
      },
      onSubmit: (changed) => {
        if (changed) {
          usersAPI.updateUser(user.id, changed)
            .then((response) => {
              dispatch(userActions.updateUserSuccess(response.data));
            });
        }
        dispatch(uiActions.closeDialog()); // close editor dialog
      }
    }));
  };

  const handleDelete = (e) => {
    e.preventDefault();

    // TODO: move this to a saga
    dispatch(uiActions.openDialog({
      type: dialogsTypes.CONFIRM_DIALOG,
      data: {
        purpose: `Delete User ID #${user.id}`,
        details: `Are you sure you want to delete User ID #${user.id}?`,
        confirm: () => {

          usersAPI.deleteUser(user.id)
            .then(() => {
              dispatch(userActions.deleteUserSuccess(user));

              dispatch(uiActions.closeDialog()); // close this dialog

              history.push('/users');
            })
            .catch((err) => {
              const message = api.getErrorMessage(err.response);
              dispatch(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
            });

        }
      }
    }));

  };

  const handleAddAccount = (e) => {
    e.preventDefault();

    dispatch(uiActions.openDialog({
      type: dialogsTypes.ACCOUNT_CREATE_DIALOG,
      data: {
        user
      },
      onSubmit: (account) => {
        if (account) {
          dispatch(accountActions.createAccount({ user: user.id, ...account } ));
        }
        dispatch(uiActions.closeDialog()); // close editor dialog
      }
    }));
  };

  const handleTasks = () => {
    window.open(connectApps.makeTaskinatorURL({ environment, user_id: user.id }), '_blank');
  };

  const handleRUM = () => {
    const u = `${datadogURL}/rum/explorer?agg_q=%40type&cols=%40action.name%2C%40context.result%2C%40context.attributes.source.status%2C%40context.attributes.project_id%2C%40context.attributes.total_duration_ms&live=1d&query=%40action.name%3A%28CREATE_PROJECT+OR+OPEN_PROJECT%29+-%40context.result%3Aidle++%40context.usr.id%3A${user.id}&tab=action`;
    window.open(u, '_blank');
  };

  if (user) {
    return (
      <div className="splitColumns withMobileMargins">
        <div className="column100">
          <div className="sectionTitle flex">
            User Details
            <div className="actionBar inline showTitles">
              <ActionButton className="edit" title={translate('general.edit')} onClick={handleEdit} />
              <ActionButton className="delete" title={translate('general.delete')} onClick={handleDelete} />
              <ActionButton className="viewLog" title={translate('general.tasks')} onClick={handleTasks} />
              <ActionButton className="support" title={translate('general.rum')} onClick={handleRUM} />
            </div>
          </div>
          <table className="propertiesTable">
            <tbody>
              <tr>
                <th className="label"><IString stringKey="user.details.userId" /></th>
                <td className="value">{user.id}</td>
              </tr>
              <tr>
                <th className="label"><IString stringKey="general.email" /></th>
                <td className="value">{user.email}</td>
                <td><em className="marginLeft">{user.email_verified ? 'verified' : 'not verified'}</em></td>
              </tr>
              <tr>
                <th className="label"><IString stringKey="general.first_name" /></th>
                <td className="value">{user.first_name || <em><IString stringKey="general.notSet" /></em>}</td>
              </tr>
              <tr>
                <th className="label"><IString stringKey="general.last_name" /></th>
                <td className="value">{user.last_name || <em><IString stringKey="general.notSet" /></em>}</td>
              </tr>
              <tr>
                <th className="label"><IString stringKey="general.display_name" /></th>
                <td className="value">{user.display_name || <em><IString stringKey="general.notSet" /></em>}</td>
              </tr>
              <tr>
                <th className="label"><IString stringKey="user.details.superuser" /></th>
                <td className="value">
                  <em><IString stringKey={user.superuser ? 'general.yes' : 'general.no'} /></em>
                </td>
              </tr>
              <tr>
                <th className="label"><IString stringKey="user.details.lockoutUntil" /></th>
                <td className="value">
                  {isLockedOut
                    ? <Time value={user.lockout_until} />
                    : <em><IString stringKey="general.notSet" /></em>}
                </td>
              </tr>
              <tr>
                <th className="label"><IString stringKey="user.details.lastLoginAttempt" /></th>
                <td className="value">
                  {user.last_login_attempt
                    ? <Time value={user.last_login_attempt} />
                    : <em><IString stringKey="general.notSet" /></em>}
                </td>
              </tr>
              <tr>
                <th className="label"><IString stringKey="user.details.loginAttempts" /></th>
                <td className="value">{user.login_attempts}</td>
              </tr>
              <tr>
                <th className="label"><IString stringKey="user.details.localAuth" /></th>
                <td className="value">
                  <em><IString stringKey={user.local_auth ? 'general.yes' : 'general.no'} /></em>
                </td>
              </tr>
              <tr>
                <th className="label"><IString stringKey="user.details.cleverLoginId" /></th>
                <td className="value">{user.clever_auth_id || <em><IString stringKey="general.notSet" /></em>}</td>
              </tr>
              <tr>
                <th className="label"><IString stringKey="user.details.githubLoginId" /></th>
                <td className="value">{user.github_auth_id || <em><IString stringKey="general.notSet" /></em>}</td>
              </tr>
              <tr>
                <th className="label"><IString stringKey="user.details.googleLoginId" /></th>
                <td className="value">{user.google_auth_id || <em><IString stringKey="general.notSet" /></em>}</td>
              </tr>
              <tr>
                <th className="label"><IString stringKey="user.details.ssoPaired" /></th>
                <td className="value">
                  {user.sso_account_id ? <AccountLink accountId={user.sso_account_id} /> : <em><IString stringKey={'general.no'} /></em>}
                </td>
              </tr>
              <tr>
                <th className="label"><IString stringKey="general.created_time" /></th>
                <td className="value"><Time value={user.created_time} /></td>
              </tr>
              <tr>
                <th className="label"><IString stringKey="general.updated_time" /></th>
                <td className="value"><Time value={user.updated_time} /></td>
              </tr>
            </tbody>
          </table>
          <br />
          <div className="sectionTitle flex">
            <IString stringKey="user.details.accounts.section" />
            <div className="actionBar inline">
              <ActionButton className="action new" onClick={handleAddAccount} />
            </div>
          </div>
          <UserDetailsAccountList />
        </div>
      </div>
    );
  } else {
    return (
      <span>Loading...</span>
    );
  }

}

UserDetails.propTypes = {
  datadogURL: PropTypes.string,
  dispatch: PropTypes.func,
  environment: PropTypes.string,
  match: PropTypes.object,
  user: PropTypes.shape({
    clever_auth_id: PropTypes.string,
    created_time: PropTypes.string,
    display_name: PropTypes.string,
    email: PropTypes.string,
    email_verified: PropTypes.bool,
    first_name: PropTypes.string,
    github_auth_id: PropTypes.string,
    google_auth_id: PropTypes.string,
    id: PropTypes.number,
    last_login_attempt: PropTypes.string,
    last_name: PropTypes.string,
    local_auth: PropTypes.bool,
    lockout_until: PropTypes.string,
    login_attempts: PropTypes.number,
    sso_account_id: PropTypes.number,
    superuser: PropTypes.bool,
    updated_time: PropTypes.string
  })
};

const mapStateToProps = (state) => {
  return {
    user: userSelectors.getCurrentUser(state),
    environment: configSelectors.getEnvironment(state),
    datadogURL: configSelectors.getDatadogURL(state)
  };
};

export default connect(mapStateToProps)(UserDetails);
