import * as types from '~actions/action-types';

export const login = (code, state) => ({
  type: types.LOGIN,
  code,
  state
});

export const logout = () => ({
  type: types.LOGOUT
});

export const setAuthUser = (user) => ({
  type: types.SET_AUTH_USER, user
});

export const clearAuthUser = () => ({
  type: types.CLEAR_AUTH_USER
});

export const maintainAccessToken = () => ({
  type: types.MAINTAIN_ACCESS_TOKEN
});
