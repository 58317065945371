import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

function AccountLink(props) {
  const { accountId } = props;

  return (
    <Link to={`/accounts/${accountId}`}><span>Account {accountId}</span></Link>
  );
}

AccountLink.propTypes = {
  accountId: PropTypes.number
};

export default AccountLink;
