import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as projectActions from '~actions/project-actions';

import ContentLink from '~components/general/content-link';
import IString from '~components/general/i-string';
import { useSortContext, withSortContext } from '~components/general/sort/sort-context';
import SortableHeader from '~components/general/sortable-header';
import Time from '~components/general/time';
import UserLink from '~components/general/user-link';

import * as contentSortBy from '~constants/content-sortby';

import * as projectOperations from '~operations/project-operations';

import * as projectSelectors from '~selectors/project-selectors';

export const ProjectOutputs = ({ projectId }) => {
  const dispatch = useDispatch();

  const outputs = useSelector(projectSelectors.getCurrentProjectOutputs);
  const total = useSelector(projectSelectors.getCurrentProjectOutputsTotal);

  const { order, sortBy } = useSortContext();

  const fetchProjectOutputs = useCallback(() => {
    dispatch(projectOperations.fetchProjectOutputs(projectId, `${sortBy}:${order}` ));
  }, [ dispatch, order, projectId, sortBy ]);

  useEffect(() => {
    fetchProjectOutputs();

    return () => {
      dispatch(projectActions.clearProjectOutputs());
    };
  }, [ dispatch, fetchProjectOutputs ]);

  return (
    <>
      <div className="sectionTitle flex">
        <IString stringKey="project.outputs.section" />
      </div>

      <div className="smallMarginBelow">
        <div className="right">
          <IString stringKey="general.total" placeholders={{ total }} />
        </div>
      </div>

      <div className="tableContainer">
        { outputs && outputs.length > 0
        ? <table className="contentListing sortableColumns">
          <thead>
            <tr>
              <SortableHeader field={contentSortBy.ID}>
                <IString stringKey="general.id" />
              </SortableHeader>
              <SortableHeader field={contentSortBy.NAME}>
                <IString stringKey="general.name" />
              </SortableHeader>
              <th className="unsortable">
                <IString stringKey="general.state" />
              </th>
              <th className="unsortable">
                <IString stringKey="general.author" />
              </th>
              <SortableHeader field={contentSortBy.CREATED}>
                <IString stringKey="general.created" />
              </SortableHeader>
              <th className="unsortable">
                <IString stringKey="general.type" />
              </th>
            </tr>
          </thead>
          <tbody>
            { outputs.map(output =>
              <tr key={output.id}>
                <td><ContentLink contentId={output.id} /></td>
                <td>{output.name}</td>
                <td>{output.state}</td>
                <td><UserLink user={output.author} /></td>
                <td><Time value={output.created_time} /></td>
                <td>{output.output_type}</td>
              </tr>) }
          </tbody>
        </table>
        : <div className="emptyListMessage">
          <IString stringKey="general.none" />
        </div>
        }
      </div>
    </>
  );
};

ProjectOutputs.propTypes = {
  projectId: PropTypes.number
};

export default withSortContext(ProjectOutputs, { sortBy: contentSortBy.ID });
