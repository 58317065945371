import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import reducers from '~reducers';

import rootSaga from '~sagas';

// we use the sagas because we're hip af
const sagaMiddleware = createSagaMiddleware();

const makeStore = preloadedState => {
  /*
   * We will use the default middleware which includes redux-thunk. Currently, we need to exclude the serializableCheck
   * since sometimes we pass in a function to redux state. When we use our modals we allow you to pass in an onSubmit prop
   * to the action that opens a modal. That prop is saved in redux state so it can be accessed by the modal.
   */
  const store = configureStore({
    reducer: reducers,
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
    devTools: process.env.NODE_ENV === 'development' && typeof window === 'object' && { trace: true, traceLimit: 25 }, // eslint-disable-line no-undef
    preloadedState
  });

  // and away we go
  sagaMiddleware.run(rootSaga);
  return store;
};

let singleton = null;
/**
 * Function to retrieve the one instance of the store running in the app. This uses the singleton pattern.
 *
 * @return {object} The app's instance of the Redux Store
 */
export const getStore = preloadedState => {
  if (!singleton) {
    singleton = makeStore(preloadedState);
  }
  return singleton;
};
