export const CLOUD_FREE = 'cloud-free';
export const CLOUD_PLUS = 'cloud-plus';
export const CLOUD_PREMIUM = 'cloud-premium';
export const CLOUD_BASIC = 'cloud-basic';
export const CLOUD_STANDARD = 'cloud-standard';
export const CLOUD_INSTRUCTOR = 'cloud-instructor';
export const CLOUD_INSTRUCTOR_FEE_PER_STUDENT = 'cloud-instructor-fee-per-student';
export const CLOUD_INSTRUCTOR_STUDENT_PAY = 'cloud-instructor-student-pay';
export const CLOUD_STUDENT = 'cloud-student';
export const CLOUD_CUSTOM = 'cloud-custom';

export const names = [
  CLOUD_FREE, CLOUD_PLUS, CLOUD_PREMIUM, CLOUD_BASIC, CLOUD_STANDARD, CLOUD_INSTRUCTOR,
  CLOUD_INSTRUCTOR_FEE_PER_STUDENT, CLOUD_INSTRUCTOR_STUDENT_PAY, CLOUD_STUDENT, CLOUD_CUSTOM
];

const academicLicenses = [ CLOUD_INSTRUCTOR, CLOUD_INSTRUCTOR_FEE_PER_STUDENT, CLOUD_INSTRUCTOR_STUDENT_PAY ];

/**
 * Returns whether the given license or license name is an academic license
 *
 * @param   {object|string} license License if it is an object, license name if it is a string
 * @returns {boolean}               True if the given license (or license name) is an academic license, false otherwise
 */
export const isAcademic = license => academicLicenses.includes(license?.name || license);

/**
 * Returns whether the given license or license name is Cloud Free
 *
 * @param   {object|string} license License if it is an object, license name if it is a string
 * @returns {boolean}               True if the given license (or license name) is Cloud Free, false otherwise
 */
export const isCloudFree = license => (license?.name || license) === CLOUD_FREE;

/**
 * Returns whether the given license or license name is Cloud Custom
 *
 * @param   {object|string} license License if it is an object, license name if it is a string
 * @returns {boolean}               True if the given license (or license name) is Cloud Custom, false otherwise
 */
export const isCloudCustom = license => (license?.name || license) === CLOUD_CUSTOM;
