import PropTypes from 'prop-types';
import React from 'react';

function ExpandoPropertiesTable({ children, open, title }) {
  return (
    <details open={open}>
      <summary
        className="smallMarginBelow"
        style={{
          backgroundColor: '#f9f8f8',
          color: '#444',
          fontSize: '12px',
          paddingBottom: '12px',
          paddingLeft: '12px',
          paddingTop: '12px'
        }}
      >
        {title}
      </summary>
      <div className="tableContainer">
        <table className="propertiesTable">
          <tbody>
            { children }
          </tbody>
        </table>
      </div>
    </details>
  );
}

ExpandoPropertiesTable.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  title: PropTypes.node
};

export default ExpandoPropertiesTable;
