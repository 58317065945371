import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as accountActions from '~actions/account-actions';
import * as uiActions from '~actions/ui-actions';

import * as dialogs from '~components/dialogs/dialog-types';
import ActionButton from '~components/general/action-button';
import IString from '~components/general/i-string';
import PaginatedTableContainer from '~components/general/paginated-table-container';
import { useSortContext, withSortContext } from '~components/general/sort/sort-context';
import SortableHeader from '~components/general/sortable-header';
import UserLink from '~components/general/user-link';

import * as memberSortBy from '~constants/member-sortby';

import { translate } from '~i18n/localize';

import * as accountSelectors from '~selectors/account-selectors';

export function AccountMembersCloud({ account }) {
  const dispatch = useDispatch();

  const members = useSelector(accountSelectors.getAccountMembersList);
  const total = useSelector(accountSelectors.getAccountMembersTotal);

  const [ showAdmins, setShowAdmins ] = useState(true);
  const [ showModerators, setShowModerators ] = useState(true);
  const [ showContributors, setShowContributors ] = useState(true);
  const [ showParticipants, setShowParticipants ] = useState(true);
  const [ showOwner, setShowOwner ] = useState(true);

  const [ filter, setFilter ] = useState();
  const { order, sortBy } = useSortContext();

  const fetchMoreAccountMembers = useCallback((offset) => {
    dispatch(accountActions.fetchAccountMembersPage(account.id, filter, { [sortBy]: order }, offset, 'cloud'));
  }, [ dispatch, account?.id, sortBy, order, filter ]);

  useEffect(() => {
    const options = {
      admin: showAdmins,
      moderator: showModerators,
      contributor: showContributors,
      participant: showParticipants,
      owner: showOwner
    };

    const roles = Object.keys(options).filter((current) => !options[current]);

    let filter;
    if (roles.length > 0) {
      filter = roles.length > 1 ? `role:notin:${roles.join()}` : `role:ne:${roles[0]}`;
    }

    setFilter(filter);
  }, [ showAdmins, showModerators, showContributors, showParticipants, showOwner ]);

  // initialize page
  useEffect(() => {
    fetchMoreAccountMembers();
    // clear account members when component is unmounted
    return () => {
      dispatch(accountActions.clearAccountMembers());
    };
  }, [ account.id, dispatch, fetchMoreAccountMembers ]);

  const handleEdit = (member) => {
    dispatch(uiActions.openDialog({
      type: dialogs.ACCOUNT_CLOUD_ROLE_EDITOR_DIALOG,
      data: {
        member,
        onSubmit: (newRole) => {
          if (newRole) {
            dispatch(accountActions.updateAccountCloudRole(account.id, member.id, newRole));
          }
          dispatch(uiActions.closeDialog());
        }
      }
    }));
  };

  return (
    <PaginatedTableContainer
      data={members}
      getMore={fetchMoreAccountMembers}
      header={
        <div className="flexCenter">
          <div className="marginRight">
            <input
              checked={showOwner}
              id="accountMembersCloud_owner"
              onChange={(e) => setShowOwner(e.target.checked)}
              type="checkbox"
            />
            <label htmlFor="accountMembersCloud_owner">
              <IString stringKey="account.members.table.checkboxes.owner" />
            </label>
          </div>
          <div className="marginRight">
            <input
              checked={showAdmins}
              id="accountMembersCloud_admins"
              onChange={(e) => setShowAdmins(e.target.checked)}
              type="checkbox"
            />
            <label htmlFor="accountMembersCloud_admins">
              <IString stringKey="account.members.table.checkboxes.admins" />
            </label>
          </div>
          <div className="marginRight">
            <input
              checked={showModerators}
              id="accountMembersCloud_moderators"
              onChange={(e) => setShowModerators(e.target.checked)}
              type="checkbox"
            />
            <label htmlFor="accountMembersCloud_moderators">
              <IString stringKey="account.members.table.checkboxes.moderators" />
            </label>
          </div>
          <div className="marginRight">
            <input
              checked={showContributors}
              id="accountMembersCloud_contributors"
              onChange={(e) => setShowContributors(e.target.checked)}
              type="checkbox"
            />
            <label htmlFor="accountMembersCloud_contributors">
              <IString stringKey="account.members.table.checkboxes.contributors" />
            </label>
          </div>
          <div className="marginRight">
            <input
              checked={showParticipants}
              id="accountMembersCloud_participants"
              onChange={(e) => setShowParticipants(e.target.checked)}
              type="checkbox"
            />
            <label htmlFor="accountMembersCloud_participants">
              <IString stringKey="account.members.table.checkboxes.participants" />
            </label>
          </div>
        </div>
        }
      total={total}
    >
      <table className="contentListing sortableColumns">
        <thead>
          <tr>
            <SortableHeader field={memberSortBy.NAME}>
              <IString stringKey="general.name" />
            </SortableHeader>
            <th><IString stringKey="general.role" /></th>
            <th className="action1 unsortable" />
          </tr>
        </thead>
        <tbody>
          {members?.map(m =>
            <tr key={m.id}>
              <td><UserLink user={m} /></td>
              <td>{m.grant.cloud_role}</td>
              <td>
                <div className="actionBar inline showTitles">
                  <ActionButton className="edit" title={translate('general.edit')} onClick={() => handleEdit(m)} />
                </div>
              </td>
            </tr>
            )}
        </tbody>
      </table>
    </PaginatedTableContainer>
  );
}

AccountMembersCloud.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export default withSortContext(AccountMembersCloud, { sortBy: memberSortBy.NAME });
