import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import AccountLink from '~components/general/account-link';
import ApplicationLink from '~components/general/application-link';
import ContentLink from '~components/general/content-link';
import IString from '~components/general/i-string';
import Time from '~components/general/time';

import * as applicationType from '~constants/application-type';

import * as applicationSelectors from '~selectors/application-selectors';

const K8sApplicationDetailsTable = ({ application }) => {

  const content = useSelector(applicationSelectors.getCurrentApplicationContent);

  return (
    <table className="propertiesTable">
      <tbody>
        <tr>
          <th className="label"><IString stringKey="general.id" /></th>
          <td className="value">{application.id}</td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="general.type" /></th>
          <td className="value">{application.type}</td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="general.status" /></th>
          <td className="value">{application.status}</td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="application.details.hibernating" /></th>
          <td className="value">{JSON.stringify(application.hibernating)}</td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="application.details.scheduler" /></th>
          <td className="value">{application.scheduler}</td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="application.details.url" /></th>
          <td className="value">{application.url}</td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="general.account" /></th>
          <td className="value"><AccountLink accountId={application.account_id} /></td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="general.content" /></th>
          <td className="value">
            { applicationType.isContentSource(application) && content
              ? <ContentLink contentId={content.id} />
              : <IString stringKey="application.details.notSource" />}
          </td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="application.details.clone" /></th>
          <td className="value">
            { application?.clone_parent_id
              ? <ApplicationLink appId={application.clone_parent_id} />
              : <em><IString stringKey="general.notSet" /></em>
            }
          </td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="application.details.copy" /></th>
          <td className="value">
            { application?.copy_parent_id
              ? <ApplicationLink appId={application.copy_parent_id} />
              : <em><IString stringKey="general.notSet" /></em>
            }
          </td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="general.created_time" /></th>
          <td className="value"><Time value={application.created_time} /></td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="general.updated_time" /></th>
          <td className="value"><Time value={application.updated_time} /></td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="application.details.ram" /></th>
          <td className="value">
            {application?.resources.memory_limit}
            { application?.resources.memory_limit !== application?.resources.effective_memory_limit &&
              <> (effective: {application?.resources.effective_memory_limit})</> }
          </td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="application.details.cpu" /></th>
          <td className="value">
            {application?.resources.cpu_limit}
            {application?.resources.cpu_limit !== application?.resources.effective_cpu_limit &&
              <> (effective: {application?.resources.effective_cpu_limit})</>}
          </td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="application.details.idleTimeout" /></th>
          <td className="value">{application?.configuration.effective_timeout_minutes}</td>
        </tr>
        <tr>
          <th className="label"><IString stringKey="application.details.runningTimeout" /></th>
          <td className="value">
            {application?.configuration.timeout_kill_minutes}
            {application?.configuration.timeout_kill_minutes !== application?.configuration.effective_timeout_kill_minutes &&
              <> (effective: {application?.configuration.effective_timeout_kill_minutes})</>}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

K8sApplicationDetailsTable.propTypes = {
  application: PropTypes.shape({
    account_id: PropTypes.number,
    clone_parent_id: PropTypes.number,
    configuration: PropTypes.shape({
      effective_timeout_kill_minutes: PropTypes.number,
      effective_timeout_minutes: PropTypes.number,
      timeout_kill_minutes: PropTypes.number
    }),
    copy_parent_id: PropTypes.number,
    created_time: PropTypes.string,
    hibernating: PropTypes.bool,
    id: PropTypes.number,
    resources: PropTypes.shape({
      cpu_limit: PropTypes.number,
      effective_cpu_limit: PropTypes.number,
      effective_memory_limit: PropTypes.number,
      memory_limit: PropTypes.number
    }),
    scheduler: PropTypes.string,
    source_id: PropTypes.number,
    status: PropTypes.string,
    type: PropTypes.string,
    updated_time: PropTypes.string,
    url: PropTypes.string
  }).isRequired
};

export default K8sApplicationDetailsTable;
