import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import * as uiSelectors from '~selectors/ui-selectors';

function GetMore(props) {
  const { onClick, label } = props;
  const buttonRef = React.createRef();

  useEffect(() => {
    fireIfVisible();
  }, [ fireIfVisible ]);

  const fireIfVisible = useCallback((screenHeight, onClick) => {
    if (buttonRef.current) {
      const topPos = buttonRef.current.getBoundingClientRect().top;
      if (screenHeight > topPos && topPos > 0) {
        onClick();
      }
    }
  }, [ buttonRef ]);
  // TODO: can this be a button?
  return (
    <div className="moreItems moreToGet" onClick={onClick} ref={buttonRef}>
      <span>{label}</span>
    </div>
  );
}

GetMore.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  screenHeight: PropTypes.number
};

const mapStateToProps = (state) => {
  // Note: scrollTop is necessary to include here
  // get this component to update when it comes into view.
  return {
    screenHeight: uiSelectors.getScreenHeight(state),
    scrollTop: uiSelectors.getScrollTop(state)
  };
};

export default connect(mapStateToProps)(GetMore);
// export the component for testing
export { GetMore };
