import { makeParams } from '~api/api';

export const TASKINATOR = 'https://connect.rstudioservices.com/content/b88b95bc-ff76-493e-b911-97b23c464bd5';

// Builds the correct taskinator URL to get the 100 most recent tasks within the last week,
// based on the current time and any provided parameters.
// Given that it uses the current time, this should be called when the link is being opened
// to set the time in the app properly.
export const makeTaskinatorURL = ({ account_id = '', environment = '', content_id = '', space_id = '', user_id = '' } = {}) => {
  const now = new Date();

  const before = new Date();
  before.setDate(now.getDate() - 7);

  const lastWeek = before.toJSON().slice(0, 10);
  const today = now.toJSON().slice(0, 10);

  // taskinator rounds to nearest 5 minutes for better caching
  const coeff = 1000 * 60 * 5;
  const nowTime = new Date(Math.round(now.getTime() / coeff) * coeff).toJSON().slice(11, 16);

  const params = makeParams({
    account_id: `"${account_id}"`,
    user_id: `"${user_id}"`,
    content_id: `"${content_id}"`,
    space_id: `"${space_id}"`,
    since_time: `"${lastWeek}T00:00:00Z"`,
    count: 0,
    since: `"${lastWeek}"`,
    until: `"${today}"`,
    until_time: `"${today}T${nowTime}:59Z"`,
    refresh: 2,
    taskNames: '["deploy-application","resume-application","deploy-output","resume-output"]',
    env: `"${environment}"`,
    limit: 100
  }).slice(1);  // remove the ? that makeParams prepends, since we need to jam something else in there first or it won't work

  return `${TASKINATOR}/?_inputs_&${params}`;
};
