import moment from 'moment';

// this function takes an array of timeseries data with a certain format:
// [{ time: <timestamp>, [<metric>]: some value, ... }]
// and collects all the <metric>s that happen in the same "day" (according to the browser's timezone)
// into a single timestamp (at the start of the interval) "bucket", summing the metrics, in order to render
// chart data. the resulting array will have objects for each bucket, of this format:
// [{ y: <moment for the start of the interval>, x: <sum of metrics for the interval>}]
// including a formatter will format the values along the way for, say, rounding values.
//
// future improvements might include other math operations (instead of always adding values)
export function bucketize(series, metric = '', formatter, granularity = 'day') {
  const dayified = [];
  let bucket = {};

  if (Array.isArray(series)) {
    series.forEach(item => {
      const current = moment(item.time).startOf(granularity);
      if (bucket.x && current.isSame(bucket.x, granularity)) {
        // this timestamp happened on a day we already know about, add our value to it
        bucket.y = bucket.y + item[metric] || 0;
      } else {
        // this timestamp is not a day we've seen!
        if (bucket.x) {
          // if we have a previous day, we're done with it, so
          if (formatter) {
            // do whatever formatting that needs to be done
            bucket.y = formatter(bucket.y);
          }
          // and push the previous day
          dayified.push(bucket);
        }
        // start a new bucket for the new day
        bucket = { x: current.toDate(), y: item[metric] || 0 };
      }
    });
    // make sure the last bucket gets counted
    dayified.push(bucket);
  }

  return dayified;
}

// this function takes an array of timeseries data with a certain format:
// [{ time: <timestamp>, [<metric>]: some value, ... }]
// and translates it directly into this format:
// [{ y: new Date(timestamp), x: <metric value>}]
// including a formatter will format the values along the way for, say, rounding values.
export function translate(series, metric = '', formatter ) {
  return series.map(item => ({
    x: new Date(item.time),
    y: formatter ? formatter(item[metric]) : item[metric]
  }));
}

export function intervalToGrain(interval) {
  return {
    '1h': 'hour',
    '1d': 'day',
    '1w': 'day'
  }[interval] || 'day';
}
