import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as spaceActions from '~actions/space-actions';

import IString from '~components/general/i-string';
import PaginatedTableContainer from '~components/general/paginated-table-container';
import Time from '~components/general/time';

import * as spaceOperations from '~operations/space-operations';

import * as spaceSelectors from '~selectors/space-selectors';

export const SpaceConnectionsList = ({ spaceId }) => {
  const dispatch = useDispatch();

  const connections = useSelector(spaceSelectors.getSpaceConnections);
  const total = useSelector(spaceSelectors.getSpaceConnectionsTotal);

  // initialize page
  useEffect(() => {
    if (spaceId) {
      dispatch(spaceOperations.getConnections(spaceId));
    }

    // clear connections when component is unmounted
    return () => {
      dispatch(spaceActions.clearSpaceConnections());
    };
  }, [ dispatch, spaceId ]);

  const handleGetData = async (offset) => {
    await dispatch(spaceOperations.getConnections(spaceId, offset));
  };

  return (
    <>
      <div className="sectionTitle flex">
        <IString stringKey="space.connections.title" />
      </div>
      <PaginatedTableContainer
        data={connections}
        getMore={handleGetData}
        total={total}
      >
        <table className="contentListing">
          <thead>
            <tr>
              <th><IString stringKey="general.id" /></th>
              <th><IString stringKey="general.name" /></th>
              <th><IString stringKey="space.connections.columns.driver" /></th>
              <th><IString stringKey="general.created" /></th>
              <th><IString stringKey="general.updated" /></th>
            </tr>
          </thead>
          <tbody>
            {connections?.map(c =>
              <tr key={c.id}>
                <td>{c.id}</td>
                <td>{c.name}</td>
                <td>{c.driver}</td>
                <td><Time value={c.created_time} /></td>
                <td><Time value={c.updated_time} /></td>
              </tr>
                )}
          </tbody>
        </table>
      </PaginatedTableContainer>
    </>
  );
};

SpaceConnectionsList.propTypes = {
  spaceId: PropTypes.number
};

export default SpaceConnectionsList;
