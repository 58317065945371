import PropTypes from 'prop-types';
import React from 'react';

import classnames from '~components/util/classnames';

export const marks = Object.freeze({
  GOOD: 'good',
  IMPORTANT: 'important',
  INFO: 'info',
  URGENT: 'urgent'
});

/**
 * Creates a message with an emphasis provided by one of the above "marks"
 *
 * @param   {object}      children  Content of the message
 * @param   {string}      className Additional class to add to the message container
 * @param   {string}      mark      The message's mark which will drive which icon is displayed next to it
 * @returns {JSX.Element}           A marked message
 */
function MarkedMessage({ children, className, mark }) {
  return (
    <div className={classnames('messageContainer mark marginBelow', className, mark )}>
      <div className="message">
        { children }
      </div>
    </div>
  );
}

MarkedMessage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  mark: PropTypes.oneOf(Object.values(marks))
};

export default MarkedMessage;
