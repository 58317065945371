import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as uiActions from '~actions/ui-actions';

import * as dialogs from '~components/dialogs/dialog-types';
import ModalDialog from '~components/dialogs/modal-dialog';
import IString from '~components/general/i-string';

function ConfirmDialog({ data: { confirm, details, purpose } }) {

  const dispatch = useDispatch();

  const [ submitted, setSubmitted ] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (confirm) {
      setSubmitted(true);
      try {
        await confirm(event);
      } catch (error) {
        setSubmitted(false);
      }
    } else {
      dispatch(uiActions.closeDialog());
    }
  };

  return (
    <ModalDialog type={dialogs.CONFIRM_DIALOG}>
      <div className="purpose">{purpose}</div>
      <form noValidate onSubmit={handleSubmit}>
        <div className="formSection">
          {details}
        </div>
        <div className="actions">
          <button autoFocus disabled={submitted} type="submit">
            <IString stringKey="general.ok" />
          </button>
        </div>
      </form>
    </ModalDialog>
  );
}

ConfirmDialog.propTypes = {
  data: PropTypes.shape({
    confirm: PropTypes.func,
    details: PropTypes.oneOfType([ PropTypes.node, PropTypes.string ]),
    purpose: PropTypes.oneOfType([ PropTypes.node, PropTypes.string ])
  })
};

export default ConfirmDialog;
