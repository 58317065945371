import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as accountActions from '~actions/account-actions';

import ContentLink from '~components/general/content-link';
import GettingMore from '~components/general/getting-more';
import IString from '~components/general/i-string';
import { useSortContext, withSortContext } from '~components/general/sort/sort-context';
import SortableHeader from '~components/general/sortable-header';
import SpaceLink from '~components/general/space-link';
import Time from '~components/general/time';
import UserLink from '~components/general/user-link';

import * as contentSortBy from '~constants/content-sortby';
import * as sortOrder from '~constants/sort-order';

import * as accountSelectors from '~selectors/account-selectors';

export function AccountContent({ accountId }) {
  const dispatch = useDispatch();

  const content = useSelector(accountSelectors.getAccountContent);
  const loading = useSelector(accountSelectors.isAccountContentLoading);
  const total = useSelector(accountSelectors.getAccountContentTotal);

  const { order, sortBy } = useSortContext();

  const emptyList = !loading && (content?.length === 0);

  useEffect(() => {
    if (accountId) {
      dispatch(accountActions.fetchAccountContent(accountId, `${sortBy}:${order}`));
    }
  }, [ dispatch, accountId, sortBy, order ]);

  return (
    <div className="splitColumns withMobileMargins">
      <div className="column100">

        <div className="sectionTitle flex">
          <IString stringKey="account.content.title" />
        </div>

        <div className="smallMarginBelow">
          <div className="right">
            <IString stringKey="general.total" placeholders={{ total }} />
          </div>
        </div>

        <div className="tableContainer">
          {emptyList
            ? <div className="emptyListMessage"><IString stringKey="general.empty" /></div>
            : <>
              <table className="contentListing sortableColumns allTightRight">
                <thead>
                  <tr>
                    <SortableHeader field={contentSortBy.ID}>
                      <IString stringKey="general.id" />
                    </SortableHeader>
                    <SortableHeader field={contentSortBy.NAME}>
                      <IString stringKey="general.name" />
                    </SortableHeader>
                    <th className="unsortable">
                      <IString stringKey="general.type" />
                    </th>
                    <th className="unsortable">
                      <IString stringKey="general.state" />
                    </th>
                    <th className="unsortable">
                      <IString stringKey="general.mode" />
                    </th>
                    <SortableHeader field={contentSortBy.SPACE}>
                      <IString stringKey="general.space" />
                    </SortableHeader>
                    <th className="unsortable">
                      <IString stringKey="general.author" />
                    </th>
                    <SortableHeader field={contentSortBy.CREATED}>
                      <IString stringKey="general.created" />
                    </SortableHeader>
                    <SortableHeader field={contentSortBy.UPDATED}>
                      <IString stringKey="general.updated" />
                    </SortableHeader>
                  </tr>
                </thead>
                <tbody>
                  {content?.map(c =>
                    <tr key={c.id}>
                      <td><ContentLink contentId={c.id} /></td>
                      <td>{c.name}</td>
                      <td>{c.content_type}</td>
                      <td>{c.state}</td>
                      <td>{c.project_mode}</td>
                      <td>{c.space_id ? <SpaceLink spaceId={c.space_id} /> : <em><IString stringKey="general.notSet" /></em>}</td>
                      <td><UserLink user={c.author} /></td>
                      <td><Time value={c.created_time} /></td>
                      <td><Time value={c.updated_time} /></td>
                    </tr>
                )}
                </tbody>
              </table>
              {loading && <GettingMore label={<IString stringKey="general.loading" />} />}
            </>}
        </div>
      </div>
    </div>
  );
}

AccountContent.propTypes = {
  accountId: PropTypes.number
};

export default withSortContext(AccountContent, { order: sortOrder.DESC, sortBy: contentSortBy.CREATED });
