/**
 * Expects arguments of strings or objects and concatenates them into a className prop. Strings are automatically appended
 * to the class names. For the objects, we add all the keys as class names if the values for those keys are truthy.
 *
 * @example
 * 'one', 'two' -> 'one two'
 * { one: true, two: false, three: true} -> 'one three'
 * 'one', { two: false, three: true} -> 'one three'
 * { one: false } -> undefined
 * @returns Concatenation of the class names for the given strings and objects
 */
export default function classnames() {
  const classNames = [];
  for (const arg of arguments) {
    if (arg) {
      if (typeof arg === 'string') {
        classNames.push(arg.trim());
      } else if (typeof arg === 'object') {
        Object.keys(arg).forEach(key => {
          if (arg[key]) {
            classNames.push(key.trim());
          }
        });
      }
    }
  }
  return classNames.join(' ') || undefined;
}
