import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as authActions from '~actions/auth-actions';
import * as uiActions from '~actions/ui-actions';

import IString from '~components/general/i-string';
import UserName from '~components/general/user-name';
import { useNativeEvent } from '~components/util/hooks';

import * as uiSelectors from '~selectors/ui-selectors';

import * as storageUtil from '~util/storage';

/**
 * The popup that is displayed when someone clicks on the <CurrentUser> component at the top of the application
 *
 * @param   {object}      user The current logged-in user
 * @returns {JSX.Element}      Popup displayed once a user clicks the <CurrentUser> component
 */
export default function UserPopup({ user }) {
  const dispatch = useDispatch();
  const utc = useSelector(uiSelectors.getUTC);

  const handleUTCClick = useCallback((e) => {
    e.stopPropagation();
    const utc = e.target.checked;
    if (utc !== undefined) {
      dispatch(uiActions.setUTC(utc));
      storageUtil.setLocalState(storageUtil.DISPLAY_DATES_IN_UTC, utc);
    }
  }, [ dispatch ]);

  const utcMenuItemRef = useNativeEvent('click', handleUTCClick);

  return (
    <div className="userPopupPanel">
      <div className="userPopupPanelInnards">
        <div className="navMenu popupNavMenu">
          <div className="menuSectionTitle">
            <UserName user={user} />
          </div>
          <div className="menu">
            <a className="menuItem logout" onClick={() => dispatch(authActions.logout())}>
              <IString stringKey="general.logout" />
            </a>
          </div>
          <div className="menuSectionTitle marginAbove">
            <IString stringKey="general.preferences" />
          </div>
          <div className="menu">
            <label className="menuItem" ref={utcMenuItemRef}>
              <input
                defaultChecked={utc}
                id="displayDatesInUTC"
                type="checkbox"
              />
              <IString stringKey="user.menu.utc" />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

UserPopup.propTypes = {
  user: PropTypes.object.isRequired
};
