import PropTypes from 'prop-types';
import React, { useState } from 'react';

import * as dialogs from '~components/dialogs/dialog-types';
import ModalDialog from '~components/dialogs/modal-dialog';
import IString from '~components/general/i-string';
import RolePicker from '~components/general/role-picker';

import * as role from '~constants/role';

import { translate } from '~i18n/localize';

export function SpaceMemberRoleEditorDialog({ data: { member, onSubmit } }) {
  const [ spaceRole, setSpaceRole ] = useState(member.grant.space_role);

  const changed = spaceRole !== member.grant.space_role;
  const roles = role.spaceRoles;

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(spaceRole);
  };

  return (
    <ModalDialog type={dialogs.SPACE_MEMBER_ROLE_EDITOR_DIALOG} >
      <div className="purpose">
        <IString stringKey="space.members.roles.dialog.edit.purpose" />
      </div>
      <div className="chunk">
        <label htmlFor="roles" className="fieldLabel">
          <IString stringKey="space.members.roles.dialog.edit.label" />
        </label>
        <ul className="instructions">
          {roles.map(r => <li key={r}>{translate(`space.members.roles.${r}.info`)}</li>)}
        </ul>
      </div>
      <RolePicker currentRole={member.grant.space_role} roles={roles} roleContext="space.members" onChange={setSpaceRole} />

      <form noValidate onSubmit={handleSubmit}>
        <div className="details">
          <div className="chunk messageContainer mark important">
            <div className="message">
              <IString stringKey="space.members.roles.dialog.edit.confirmation" placeholders={{ member: member.display_name }} />
            </div>
          </div>
        </div>
        <div className="chunk actions">
          <button type="submit" disabled={!changed}><IString stringKey="general.save" /></button>
        </div>
      </form>
    </ModalDialog>
  );
}

SpaceMemberRoleEditorDialog.propTypes = {
  data: PropTypes.shape({
    member: PropTypes.shape({
      display_name: PropTypes.string.isRequired,
      grant: PropTypes.shape({
        space_role: PropTypes.string.isRequired
      }),
      id: PropTypes.number
    }),
    onSubmit: PropTypes.func.isRequired
  }).isRequired
};

export default SpaceMemberRoleEditorDialog;
