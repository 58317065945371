import PropTypes from 'prop-types';
import React from 'react';

import Spinner from '~components/general/spinner';

function GettingMore(props) {
  const { label } = props;

  return (
    <div className="moreItems gettingMore">
      <div className="moreItemsSpinnerContainer"><Spinner /></div>
      {label}
    </div>
  );
}

GettingMore.propTypes = {
  label: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ])
};

export default GettingMore;
