import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as uiActions from '~actions/ui-actions';

import * as dialogs from '~components/dialogs/dialog-types';
import ModalDialog from '~components/dialogs/modal-dialog';
import ActionButton from '~components/general/action-button';
import IString from '~components/general/i-string';

import * as accountType from '~constants/account-type';
import * as licenseName from '~constants/license-name';
import * as licenseSeat from '~constants/license-seat';
import * as licenseStatus from '~constants/license-status';

import { translate } from '~i18n/localize';

import * as accountSelectors from '~selectors/account-selectors';

const ACADEMIC_CUSTOM_LICENSE_TYPE = 'academic';
const NON_ACADEMIC_CUSTOM_LICENSE_TYPE = 'nonAcademic';

const maybeChanged = (update, original) => update !== original ? update : undefined;

/**
 * Dialog that contains a form to update the details of an account license
 *
 * @param   {number}      accountId ID of the account
 * @param   {object}      license   The account's license
 * @param   {function}    onSubmit  Callback function to call upon a form submission
 * @returns {JSX.Element}           Dialog to update an account license
 */
export default function AccountLicenseDetailsEditorDialog({ data: { accountId, license }, onSubmit }) {
  const dispatch = useDispatch();
  const account = useSelector(state => accountSelectors.getAccount(state, accountId));

  const [ name, setName ] = useState(license.name);
  const [ status, setStatus ] = useState(license.status);
  const [ expires, setExpires ] = useState(license.expires);
  const [ suspendedUntil, setSuspendedUntil ] = useState(license.suspended_until);
  const [ suspendedReason, setSuspendedReason ] = useState(license.suspended_reason);
  const [ exemptUntil, setExemptUntil ] = useState(license.exempt_until);

  const isOrgAccount = accountType.isOrgAccount(account);
  const isCloudCustom = licenseName.isCloudCustom(name);
  const isCloudFree = licenseName.isCloudFree(name);

  const isAcademic = licenseName.isAcademic(name) || license.license_seats?.[licenseSeat.INSTRUCTOR];
  const [ customLicenseType, setCustomLicenseType ] = useState(isAcademic ? ACADEMIC_CUSTOM_LICENSE_TYPE : NON_ACADEMIC_CUSTOM_LICENSE_TYPE);

  const currentMemberMax = license.license_seats?.[licenseSeat.MEMBER]?.max || null;
  const currentInstructorMax = license.license_seats?.[licenseSeat.INSTRUCTOR]?.max || null;
  const currentStudentMax = license.license_seats?.[licenseSeat.STUDENT]?.max || null;

  const [ memberMax, setMemberMax ] = useState(currentMemberMax || 1);
  const [ instructorMax, setInstructorMax ] = useState(currentInstructorMax || 1);
  const [ studentMax, setStudentMax ] = useState(currentStudentMax);

  const handleSubmit = (event) => {
    event.preventDefault();

    const updates = {
      name: maybeChanged(name, license.name),
      status: maybeChanged(status, license.status),
      expires: maybeChanged(expires, license.expires),
      suspended_until: maybeChanged(suspendedUntil, license.suspended_until),
      suspended_reason: maybeChanged(suspendedReason, license.suspended_reason),
      exempt_until: maybeChanged(exemptUntil, license.exempt_until)
    };
    /*
     * License seats can only be modified for Cloud Custom licenses. All other licenses will need to update license
     * seats through the subscription API. We don't want to accidentally cause someone to pay more or less without
     * them making the change themselves.
     */
    if (isCloudCustom) {
      const license_seats = {};
      if (customLicenseType === NON_ACADEMIC_CUSTOM_LICENSE_TYPE) {
        if (memberMax !== currentMemberMax) {
          license_seats[licenseSeat.MEMBER] = { max: memberMax };
        }
      } else {
        if (instructorMax !== currentInstructorMax || studentMax !== currentStudentMax) {
          license_seats[licenseSeat.INSTRUCTOR] = { max: instructorMax };
          /*
           * Students can be unlimited, so only add the student max to the changes if a specific value is defined. An
           * empty student record means an unlimited number of students.
           */
          if (studentMax) {
            license_seats[licenseSeat.STUDENT] = { max: studentMax };
          }
        }
      }
      // If any license seats change, then add them to the updates
      if (Object.keys(license_seats).length > 0) {
        updates.license_seats = license_seats;
      }
    }

    if (updates.suspended_until === null && license.suspension_pending_until) {
      // if this account was in a grace period before it was suspended,
      // we'll have a suspension_pending_until value. if we're currently
      // clearing the suspension (updates.suspened_until is null),
      // we'll need to clear the suspension_pending_until value as well.
      updates.suspension_pending_until = null;
    }

    const changed = Object.values(updates).some(v => v !== undefined);

    dispatch(uiActions.openDialog({
      type: dialogs.CONFIRM_DIALOG,
      data: {
        purpose: <IString stringKey="account.license.dialog.purpose" placeholders={{ id: accountId }} />,
        details: <IString stringKey="account.license.dialog.details" placeholders={{ id: accountId }} />,
        confirm: () => {
          onSubmit?.(changed ? updates : undefined);
          dispatch(uiActions.closeDialog()); // close confirm dialog
        }
      }
    }));

  };

  return (
    <ModalDialog type={dialogs.ACCOUNT_LICENSE_EDITOR_DIALOG}>
      <div className="purpose">
        <IString stringKey="account.license.dialog.title" />
      </div>
      <form noValidate onSubmit={handleSubmit}>

        <fieldset className="chunk">
          <label htmlFor="licenseName" className="fieldLabel">
            <IString stringKey="account.license.dialog.properties.name" />
          </label>
          <select
            className="full"
            id="licenseName"
            onChange={e => setName(e.target.value)}
            value={name}
          >
            {licenseName.names.map(n => <option value={n} key={n}>{n}</option>)}
          </select>
        </fieldset>

        <fieldset className="chunk">
          <label htmlFor="licenseStatus" className="fieldLabel">
            <IString stringKey="account.license.dialog.properties.status" />
          </label>
          <select
            className="full"
            id="licenseStatus"
            onChange={e => setStatus(e.target.value)}
            value={status}
          >
            {licenseStatus.statuses.map(n => <option value={n} key={n}>{n}</option>)}
          </select>
        </fieldset>

        <fieldset className="chunk">
          <label htmlFor="licenseExpires" className="fieldLabel">
            <IString stringKey="account.license.dialog.properties.expires" />
            <IString className="optional" stringKey="account.license.dialog.date_example" />
          </label>

          <div className="flex">
            <input
              className="full"
              id="licenseExpires"
              onChange={e => setExpires(e.target.value || null)}
              type="text"
              value={expires || ''}
            />
            <div className="actionBar inline" style={{ paddingTop: '3px' }}>
              <ActionButton className="delete noTitle" onClick={() => setExpires(null)} />
            </div>
          </div>
        </fieldset>

        <fieldset className="chunk">
          <label htmlFor="licenseSuspendedUntil" className="fieldLabel">
            <IString stringKey="account.license.dialog.properties.suspended_until" />
            <IString className="optional" stringKey="account.license.dialog.date_example" />
          </label>
          <div className="flex">
            <input
              className="full"
              id="licenseSuspendedUntil"
              onChange={e => setSuspendedUntil(e.target.value || null)}
              type="text"
              value={suspendedUntil || ''}
            />
            <div className="actionBar inline" style={{ paddingTop: '3px' }}>
              <ActionButton className="delete noTitle" onClick={() => setSuspendedUntil(null)} />
            </div>
          </div>
        </fieldset>

        <fieldset className="chunk">
          <label htmlFor="licenseSuspendedReason" className="fieldLabel">
            <IString stringKey="account.license.dialog.properties.suspended_reason" />
          </label>
          <div className="flex">
            <input
              className="full"
              id="licenseSuspendedReason"
              onChange={e => setSuspendedReason(e.target.value || null)}
              type="text"
              value={suspendedReason || ''}
            />
            <div className="actionBar inline" style={{ paddingTop: '3px' }}>
              <ActionButton className="delete noTitle" onClick={() => setSuspendedReason(null)} />
            </div>
          </div>
        </fieldset>

        <fieldset className="chunk">
          <label htmlFor="licenseExemptUntil" className="fieldLabel">
            <IString stringKey="account.license.dialog.properties.exempt_from_suspension_until" />
            <IString className="optional" stringKey="account.license.dialog.date_example" />
          </label>
          <div className="flex">
            <input
              className="full"
              id="licenseExemptUntil"
              onChange={e => setExemptUntil(e.target.value || null)}
              type="text"
              value={exemptUntil || ''}
            />
            <div className="actionBar inline" style={{ paddingTop: '3px' }}>
              <ActionButton className="delete noTitle" onClick={() => setExemptUntil(null)} />
            </div>
          </div>
        </fieldset>

        {!isCloudFree &&
          <>
            {isCloudCustom &&
              <fieldset className="chunk">
                <label className="fieldLabel"><IString stringKey="account.license.dialog.properties.license_seats.title" /></label>
                <div>
                  <input
                    checked={customLicenseType === ACADEMIC_CUSTOM_LICENSE_TYPE}
                    id="academicLicenseType"
                    name="licenseType"
                    onChange={e => setCustomLicenseType(e.target.value)}
                    type="radio"
                    value={ACADEMIC_CUSTOM_LICENSE_TYPE}
                  />
                  <label className="midMarginRight" htmlFor="academicLicenseType"><IString stringKey="account.license.dialog.properties.license_seats.academic" /></label>
                  <input
                    checked={customLicenseType === NON_ACADEMIC_CUSTOM_LICENSE_TYPE}
                    id="nonAcademicLicenseType"
                    name="licenseType"
                    onChange={e => setCustomLicenseType(e.target.value)}
                    type="radio"
                    value={NON_ACADEMIC_CUSTOM_LICENSE_TYPE}
                  />
                  <label htmlFor="nonAcademicLicenseType"><IString stringKey="account.license.dialog.properties.license_seats.nonAcademic" /></label>
                </div>
              </fieldset>
            }

            {customLicenseType === NON_ACADEMIC_CUSTOM_LICENSE_TYPE &&
              <>
                {(isOrgAccount || isCloudCustom)
                  ? <fieldset className="chunk">
                    <label className="fieldLabel" htmlFor="memberMax">
                      <IString stringKey="account.license.dialog.properties.license_seats.member" />
                    </label>
                    <input
                      className="full"
                      disabled={!isCloudCustom}
                      id="memberMax"
                      min="1"
                      onChange={({ target: { value } }) => setMemberMax(+value || 1)}
                      required
                      title={isCloudCustom ? undefined : translate('account.license.dialog.properties.license_seats.disabled.subscription')}
                      type="number"
                      value={memberMax}
                    />
                  </fieldset>
                  : <fieldset className="chunk">
                    <label className="fieldLabel" htmlFor="individualMax">
                      <IString stringKey="account.license.dialog.properties.license_seats.individual" />
                    </label>
                    <input
                      className="full"
                      disabled
                      id="individualMax"
                      title={translate('account.license.dialog.properties.license_seats.disabled.individual')}
                      type="number"
                      value="1"
                    />
                  </fieldset>
                }
              </>
            }

            {customLicenseType === ACADEMIC_CUSTOM_LICENSE_TYPE &&
              <>
                <fieldset className="chunk">
                  <label className="fieldLabel" htmlFor="instructorMax">
                    <IString stringKey="account.license.dialog.properties.license_seats.instructor" />
                  </label>
                  <input
                    className="full"
                    disabled={!isCloudCustom || !isOrgAccount}
                    id="instructorMax"
                    min="1"
                    onChange={({ target: { value } }) => setInstructorMax(+value || 1)}
                    required
                    title={(isCloudCustom && isOrgAccount) ? undefined : translate(`account.license.dialog.properties.license_seats.disabled.${isCloudCustom ? 'individual' : 'subscription'}`)}
                    type="number"
                    value={instructorMax}
                  />
                </fieldset>
                <fieldset className="chunk">
                  <label className="fieldLabel" htmlFor="studentMax">
                    <IString stringKey="account.license.dialog.properties.license_seats.student" />
                    <IString className="smallerFont" stringKey="account.license.entitlement.editor.unlimited" />
                  </label>
                  <input
                    className="full"
                    disabled={!isCloudCustom}
                    id="studentMax"
                    min="1"
                    onChange={({ target: { value } }) => setStudentMax(value === '' ? null : +value)}
                    placeholder={translate('general.unlimited')}
                    title={isCloudCustom ? undefined : translate('account.license.dialog.properties.license_seats.disabled.subscription')}
                    type="number"
                    value={studentMax ?? ''}
                  />
                </fieldset>
              </>
            }
          </>
        }
        <div className="actions">
          <button type="submit"><IString stringKey="general.save" /></button>
        </div>
      </form>
    </ModalDialog>
  );
}

AccountLicenseDetailsEditorDialog.propTypes = {
  data: PropTypes.shape({
    accountId: PropTypes.number,
    license: PropTypes.shape({
      exempt_until: PropTypes.string,
      expires: PropTypes.string,
      license_seats: PropTypes.objectOf(PropTypes.shape({ max: PropTypes.number })),
      name: PropTypes.string,
      status: PropTypes.string,
      suspended_reason: PropTypes.string,
      suspended_until: PropTypes.string,
      suspension_pending_until: PropTypes.string
    }).isRequired
  }).isRequired,
  onSubmit: PropTypes.func
};
