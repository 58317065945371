import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as uiActions from '~actions/ui-actions';

import * as dialogs from '~components/dialogs/dialog-types';
import ModalDialog from '~components/dialogs/modal-dialog';
import IString from '~components/general/i-string';

import * as spaceVisibility from '~constants/space-visibility';

import * as spaceOperations from '~operations/space-operations';

import { shallowDiffObject } from '~util/compare';

function SpaceDetailsEditDialog({ data: { space } }) {

  const dispatch = useDispatch();
  const [ spaceData, setSpaceData ] = useState(space);

  const handleVisibilityChange = (event) => {
    setSpaceData({ ...spaceData, visibility: event.target.value });
  };

  const handleToggleGlobalTemplateSource = (event) => {
    setSpaceData({
      ...spaceData,
      global_template_source: event.target.checked,
      visibility: event.target.checked ? spaceVisibility.PUBLIC : spaceData.visibility
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // If the space is enabled as a global template source, the visibility of the space needs to be public
    if (spaceData.global_template_source) {
      spaceData.visibility = spaceVisibility.PUBLIC;
    }

    const changed = shallowDiffObject(space, spaceData);

    dispatch(uiActions.openDialog({
      type: dialogs.CONFIRM_DIALOG,
      data: {
        purpose: <IString stringKey="space.details.dialog.edit.confirm.purpose" placeholders={{ id: space.id }} />,
        details: <IString stringKey="space.details.dialog.edit.confirm.details" placeholders={{ id: space.id }} />,
        confirm: async () => {
          dispatch(uiActions.closeDialog()); // Close the confirm dialog
          try {
            if (Object.keys(changed).length > 0) {
              await dispatch(spaceOperations.updateSpace(space.id, changed));
            }
            dispatch(uiActions.closeDialog()); // Close the edit dialog
          } catch (e) { /* ignore error */ }
        }
      }
    }));
  };

  return (
    <ModalDialog type={dialogs.SPACE_DETAILS_EDIT_DIALOG}>
      <div className="purpose">
        <IString stringKey="space.details.dialog.edit.purpose" placeholders={{ id: space.id }} />
      </div>
      <form noValidate onSubmit={handleSubmit}>
        <fieldset className="chunk">
          <div className="flex">
            <span>
              <input
                checked={spaceData.global_template_source}
                className="full"
                id="spaceGlobalTemplateSource"
                onChange={handleToggleGlobalTemplateSource}
                type="checkbox"
              />
              <label htmlFor="spaceGlobalTemplateSource">
                <IString stringKey="space.details.dialog.edit.globalTemplateSource" />
              </label>
            </span>
          </div>
        </fieldset>

        <fieldset className="chunk">
          <label className="fieldLabel"><IString stringKey="general.visibility" /></label>
          <div>
            <input
              checked={spaceData.visibility === spaceVisibility.PRIVATE}
              disabled={spaceData.global_template_source}
              id="privateVisibility"
              name="visibility"
              onChange={handleVisibilityChange}
              type="radio"
              value={spaceVisibility.PRIVATE}
            />
            <label className="midMarginRight" htmlFor="privateVisibility">
              <IString stringKey="general.private" />
            </label>
            <input
              checked={spaceData.visibility === spaceVisibility.PUBLIC}
              disabled={spaceData.global_template_source}
              id="publicVisibility"
              name="visibility"
              onChange={handleVisibilityChange}
              type="radio"
              value={spaceVisibility.PUBLIC}
            />
            <label htmlFor="publicVisibility">
              <IString stringKey="general.public" />
            </label>
          </div>
        </fieldset>

        <div className="actions">
          <button type="submit"><IString stringKey="general.save" /></button>
        </div>

      </form>
    </ModalDialog>
  );
}

SpaceDetailsEditDialog.propTypes = {
  data: PropTypes.shape({
    space: PropTypes.shape({
      global_template_source: PropTypes.bool,
      id: PropTypes.number,
      visibility: PropTypes.string
    }).isRequired
  }).isRequired
};

export default SpaceDetailsEditDialog;
