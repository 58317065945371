import React from 'react';
import { Link } from 'react-router-dom';

import Footer from '~components/general/footer';
import IString from '~components/general/i-string';

import { getLoginURL } from '~util/auth-helpers';

function Unauthorized() {
  // 403 responses, which occur if a user's session has been cleared or they aren't a superuser,
  // will be redirected to this page so the user can get a link back to the log in page

  const login = () => {
    const loginURL = getLoginURL();
    window.location.assign(loginURL);
  };

  return (
    <div id="main">
      <header id="rStudioHeader">
        <div className="band">
          <div className="innards bandContent">
            <div id="logoContainer">
              <Link to="/">
                <div className="productLogo" />
              </Link>
            </div>
          </div>
        </div>

      </header>
      <div className="band pushFooter">
        <div className="bandContent mainPage">
          <div className="splitColumns">
            <div className="column50">
              <div className="mainMessage">
                <p>
                  <IString stringKey="general.error.unauthorized" />
                </p>
              </div>
              {/* TODO: css style for this big button */}
              <button
                className="default"
                onClick={login}
                style={{
                  fontWeight: 'bold',
                  fontSize: '18px',
                  width: '100%'
                }}
                type="button"
              >
                <IString stringKey="general.login" />
              </button>
            </div>
            <div className="column50 noAccessImage" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Unauthorized;
