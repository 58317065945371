import { API, createAdvancedSearchParams, makeParams } from '~api/api';
import * as apiUtils from '~api/api-utils';

export const searchApplications = (search) => {
  if (!search?.trim()) {
    return Promise.reject();
  }

  return API().get('/applications', {
    params: createAdvancedSearchParams(search, [ 'name' ])
  });
};

export const getApplication = (appId) => {
  return API().get(`/applications/${appId}`);
};

export const getApplications = (options = {}) => {
  return API().get(`/applications${makeParams(options)}`);
};

export const getApplicationAuthorizations = (appId) => {
  return API().get(`/applications/${appId}/authorization?count=100`);
};

export const getApplicationLogs = (appId) => {
  return API().get(`/applications/${appId}/logs?count=1500`);
};

export const getApplicationMetrics = (appId, metricType, paramsList) => {
  return API().get(`/applications/${appId}/metrics/${metricType}?${apiUtils.parameterizeArray(paramsList).toString()}`);
};

export const resumeApplication = (appId) => {
  return API().patch(`/applications/${appId}/resume`);
};

export const deployApplication = (appId) => API().patch(`/applications/${appId}/deploy`);

export const suspendApplication = (appId) => API().patch(`/applications/${appId}/suspend`);

export const restartApplication = (appId) => API().patch(`/applications/${appId}/restart`);

export const terminateApplication = (appId) => API().patch(`/applications/${appId}/terminate`);

export const purgeApplication = (appId, options = {}) => API().patch(`/applications/${appId}/purge`, options);

export const exportApplication = (appId) => API().patch(`/applications/${appId}/export`);
