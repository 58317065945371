import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as uiActions from '~actions/ui-actions';

import * as dialogs from '~components/dialogs/dialog-types';
import ModalDialog from '~components/dialogs/modal-dialog';
import ActionButton from '~components/general/action-button';
import IString from '~components/general/i-string';
import InfoToggle from '~components/general/info-toggle';

import { translate } from '~i18n/localize';

import { shallowDiffObject } from '~util/compare';

const USER_PROPERTIES = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  SUPER_USER: 'superuser',
  GOOGLE_AUTH_ID: 'google_auth_id',
  GITHUB_AUTH_ID: 'github_auth_id',
  CLEVER_AUTH_ID: 'clever_auth_id'
};

export default function UserDetailsEditorDialog({ data, onSubmit }) {
  const { user } = data;

  const dispatch = useDispatch();
  const [ userData, setUserData ] = useState(user);
  // we only want the "Super User" checkbox to be enabled for
  // a) users who are superusers already (so we can disable it if necessary), or
  // b) users who have verified their emails and have @rstudio.com or @posit.co email addresses.
  const superUserable = user.superuser
                        || (user.email_verified
                            && (user.email.endsWith('@rstudio.com') || user.email.endsWith('@posit.co')));
  const [ showSuperUserInfo, setShowSuperUserInfo ] = useState(false);

  const handleChange = (property, value) => {
    setUserData({ ...userData, [property]: value });
  };

  const handleClear = (property) => {
    setUserData({ ...userData, [property]: null });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const changed = shallowDiffObject(user, userData);

    dispatch(uiActions.openDialog({
      type: dialogs.CONFIRM_DIALOG,
      data: {
        purpose: <IString stringKey="user.details.dialog.confirmEdit.title" placeholders={{ id: user.id }} />,
        details: <IString stringKey="user.details.dialog.confirmEdit.details" placeholders={{ id: user.id }} />,
        confirm: () => {
          if (onSubmit) {
            onSubmit(changed);
          }
          dispatch(uiActions.closeDialog()); // close confirm dialog
        }
      }
    }));
  };

  return (
    <ModalDialog type={dialogs.USER_DETAILS_EDITOR_DIALOG}>
      <div className="purpose">
        <IString stringKey="user.details.dialog.edit.title" placeholders={{ id: user.id }} />
      </div>
      <form noValidate onSubmit={handleSubmit}>

        <fieldset className="chunk">
          <label htmlFor="firstName" className="fieldLabel">
            <IString stringKey="general.first_name" />
          </label>

          <div className="flex">
            <input
              className="full"
              id="firstName"
              onChange={e => handleChange(USER_PROPERTIES.FIRST_NAME, e.target.value)}
              placeholder={translate('user.details.dialog.edit.placeholders.firstName')}
              type="text"
              value={userData.first_name || ''}
            />

            <div className="actionBar inline" style={{ paddingTop: '3px' }}>
              <ActionButton className="delete noTitle" onClick={() => handleClear(USER_PROPERTIES.FIRST_NAME)} />
            </div>
          </div>
        </fieldset>

        <fieldset className="chunk">
          <label htmlFor="lastName" className="fieldLabel">
            <IString stringKey="general.last_name" />
          </label>

          <div className="flex">
            <input
              className="full"
              id="lastName"
              onChange={e => handleChange(USER_PROPERTIES.LAST_NAME, e.target.value)}
              placeholder={translate('user.details.dialog.edit.placeholders.lastName')}
              type="text"
              value={userData.last_name || ''}
            />

            <div className="actionBar inline" style={{ paddingTop: '3px' }}>
              <ActionButton className="delete noTitle" onClick={() => handleClear(USER_PROPERTIES.LAST_NAME)} />
            </div>
          </div>
        </fieldset>

        <fieldset className="chunk">
          <input
            className="full"
            defaultChecked={userData.superuser}
            disabled={!superUserable}
            id="superUser"
            onClick={e => handleChange(USER_PROPERTIES.SUPER_USER, e.target.checked)}
            type="checkbox"
          />

          <label htmlFor="superUser"><IString stringKey="user.details.superuser" /></label>

          <div className="actionBar inline smallMarginLeft">
            <InfoToggle onToggle={() => setShowSuperUserInfo(!showSuperUserInfo)} toggled={showSuperUserInfo} />
          </div>

          { showSuperUserInfo &&
            <div className="toggleableInfo">
              <p><IString stringKey="user.details.dialog.edit.superuserInfo.permissions" /></p>

              { superUserable && !user.superuser &&
                <p><strong><IString stringKey="user.details.dialog.edit.superuserInfo.warning" /></strong></p> }

              { !superUserable && <p><IString stringKey="user.details.dialog.edit.superuserInfo.requirements" /></p> }
            </div>
          }

        </fieldset>

        <div className="actions">
          <button type="submit"><IString stringKey="general.save" /></button>
        </div>

      </form>
    </ModalDialog>
  );

}

UserDetailsEditorDialog.propTypes = {
  data: PropTypes.shape({
    user: PropTypes.shape({
      email: PropTypes.string,
      email_verified: PropTypes.bool,
      first_name: PropTypes.string,
      id: PropTypes.number,
      last_time: PropTypes.string,
      superuser: PropTypes.bool
    })
  }),
  dispatch: PropTypes.func,
  onSubmit: PropTypes.func
};
