import PropTypes from 'prop-types';
import React  from 'react';
import { Link } from 'react-router-dom';

import * as licenseType from '~constants/license-type';

const UserDetailsAccountListItem = ({ account }) =>
  <tr>
    <td className="value">{account.id}</td>
    <td className="value"><Link to={`/accounts/${account.id}`}>{account.display_name || account.name}</Link></td>
    <td className="value">{account.type}</td>
    <td className="value">{account.licenses.filter((l) => l.type === licenseType.CLOUD)[0].name}</td>
  </tr>;

UserDetailsAccountListItem.propTypes = {
  account: PropTypes.object
};

export default UserDetailsAccountListItem;
