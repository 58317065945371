import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as uiActions from '~actions/ui-actions';

import * as dialogs from '~components/dialogs/dialog-types';
import ModalDialog from '~components/dialogs/modal-dialog';
import IString from '~components/general/i-string';
import MarkedMessage, { marks } from '~components/general/marked-message';
import classnames from '~components/util/classnames';

import { translate } from '~i18n/localize';

import * as spaceOperations from '~operations/space-operations';

function SpaceTransferDialog({ data: { space } }) {

  const dispatch = useDispatch();

  const [ destinationAccountId, setDestinationAccountId ] = useState('');
  const [ error, setError ] = useState();
  const [ pending, setPending ] = useState(false);

  const disabled = !!error || pending || !destinationAccountId;

  const handleChange = (e) => {
    setDestinationAccountId(e.target.value);
    setError();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!disabled) {
      setPending(true);

      const accountId = +(e.target.destinationAccountId.value.trim());
      let error = '';

      if (!accountId || Number.isNaN(accountId)) {
        error = 'space.details.dialog.transfer.error';
      }

      if (error) {
        setError(error);
        setPending(false);
      } else {
        try {
          if (accountId !== space.account_id) {
            // only make the API call if the value is actually changing
            await dispatch(spaceOperations.updateSpace(space.id, { account_id: accountId }));
          }
          dispatch(uiActions.closeDialog());
          dispatch(uiActions.setSuccessMessage(
            <IString
              placeholders={{ account_id: accountId, name: space.name }}
              stringKey="space.details.dialog.transfer.success"
            />
          ));

        } catch (error) {
          setPending(false);
        }
      }
    }
  };

  return (
    <ModalDialog type={dialogs.SPACE_TRANSFER_DIALOG}>
      <div className="purpose">
        <IString stringKey="space.details.dialog.transfer.purpose" placeholders={{ id: space.id, name: space.name }} />
      </div>

      <form noValidate onSubmit={handleSubmit}>

        <p className="details">
          <IString
            placeholders={{
              accountId: space.account_id,
              name: space.name
            }}
            stringKey="space.details.dialog.transfer.details"
          />
        </p>

        <fieldset className="chunk">
          <label htmlFor="destinationAccountId" className={classnames('fieldLabel', { error })}>
            <IString stringKey="space.details.dialog.transfer.label" />
            {error && <IString className="errorText" stringKey={error} aria-live="polite" />}
          </label>
          <input
            autoFocus
            className="full"
            id="destinationAccountId"
            name="destinationAccountId"
            onChange={handleChange}
            placeholder={translate('space.details.dialog.transfer.placeholder')}
            required
            type="text"
            value={destinationAccountId}
          />
        </fieldset>

        <div className="details">
          <MarkedMessage mark={marks.IMPORTANT}>
            <p><IString stringKey="space.details.dialog.transfer.warning" /></p>
          </MarkedMessage>
        </div>

        <div className="actions">
          <button type="submit" disabled={disabled} ><IString stringKey="general.ok" /></button>
        </div>
      </form>
    </ModalDialog>
  );
}

SpaceTransferDialog.propTypes = {
  data: PropTypes.shape({
    space: PropTypes.shape({
      account_id: PropTypes.number,
      id: PropTypes.number,
      name: PropTypes.string
    }).isRequired
  }).isRequired,
  onSubmit: PropTypes.func
};

export default SpaceTransferDialog;
