import { call, put } from 'redux-saga/effects';

import * as spaceActions from '~actions/space-actions';
import * as uiActions from '~actions/ui-actions';

import * as api from '~api';
import * as contentAPI from '~api/content';

import * as statusTypes from '~components/status/status-types';

export function* searchSpaces(action) {
  try {
    const { data } = action.query === 'listGlobalTemplateSources'
      ? yield call(api.listSpaces, { global_template_source: true })
      : yield call(api.searchSpaces, action.query);
    yield put(spaceActions.fetchSpaceSearchSuccess(data.spaces));
  } catch (err) {
    if (err) {
      const message = yield call(api.getErrorMessage, err.response);
      yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
    }
    yield put(spaceActions.fetchSpaceSearchFailure());
  }
}

export function* fetchSpace(action) {
  try {
    const { data } = yield call(api.getSpace, action.spaceId);
    yield put(spaceActions.fetchSpaceSuccess(data));
  } catch (err) {
    const message = yield call(api.getErrorMessage, err.response);
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
  }
}

export function* deleteSpace(action) {
  const { spaceId } = action;
  try {
    // get a list of content in this space. since we only care about whether the
    // total is 0, don't actually get any data (offset: 0, count: 0).
    const { data: { total } } = yield call(contentAPI.listContent, { space_id: spaceId }, 0, 0);
    if (total > 0) {
      throw new Error('a space must be empty of content before it can be deleted.');
    }
    yield call(api.deleteSpace, spaceId);
    yield put(spaceActions.deleteSpaceSuccess(spaceId));
    yield put(uiActions.setStatusMessage({ type: statusTypes.INFO_MESSAGE, message: `Success! Space #${spaceId} has been deleted.` }));
  } catch (err) {
    let message = err.toString();
    if (err.response) {
      message = yield call(api.getErrorMessage, err.response);
    }
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
    yield put(spaceActions.deleteSpaceFailure(spaceId));
  }
}

export function* fetchSpaceMembersPage(action) {
  const { spaceId, order, filter, offset } = action;

  try {
    const { data } = yield call(api.listSpaceMembers, spaceId,  filter, order, offset );
    yield put(spaceActions.fetchSpaceMembersPageSuccess(spaceId, data.users, data.total));
  } catch (err) {
    const message = yield call(api.getErrorMessage, err.response);
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
    yield put(spaceActions.fetchSpaceMembersPageFailure(spaceId));
  }
}

export function* fetchSpaceContentPage(action) {
  const { spaceId, offset } = action;
  try {
    const { data } = yield call(contentAPI.listContent, { space_id: spaceId }, offset);
    yield put(spaceActions.fetchSpaceContentPageSuccess(spaceId, data.content, data.total));
  } catch (err) {
    const message = yield call(api.getErrorMessage, err.response);
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
    yield put(spaceActions.fetchSpaceContentPageFailure(spaceId));
  }
}

export function* updateSpaceMemberRole(action) {
  try {
    const { data } = yield call(api.updateSpaceRole, action.spaceId, action.userId, action.role);
    yield put(spaceActions.updateSpaceRoleSuccess(data));
  } catch (err) {
    const message = api.getErrorMessage(err.response);
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
  }
}
