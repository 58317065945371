import { createSelector } from 'reselect';

import { getAccounts } from '~selectors/account-selectors';
import { getContent } from '~selectors/content-selectors';

const getUsers = (state) => state.userState.users;
const getOwnedAccountIds = (state) => state.userState.ownedAccountIds;
const getSearchResultIds = (state) => state.userState.search.resultIds;

export const getUserAuthoredContentIds = (state) => state.userState.userAuthoredContent.ordered;
export const isUserAuthoredContentLoading = (state) => state.userState.userAuthoredContent.loading;

export const getSearchQuery = (state) => state.userState.search.query;
export const getSearchResults = createSelector(
  [ getUsers, getSearchResultIds ],
  (users, ids) => ids.map(id => users[id])
);

export const getSearchResultsTotal = (state) => state.userState.search.total;
export const isSearchLoading = (state) => state.userState.search.loading;

export const getCurrentUserId = (state) => state.userState.currentUser.id;

export const getCurrentUser = createSelector(
  [ getUsers, getCurrentUserId ],
  (users, currentUserId) => users[currentUserId]
);

export const getCurrentUsersAccounts = createSelector(
  [ getAccounts, getOwnedAccountIds ],
  (accounts, ids) => ids.map(id => accounts[id])
);
export const getUserAuthoredContent = createSelector(
  [ getContent, getUserAuthoredContentIds ],
  (content, ids) => ids.map(id => content[id])
);

export const hasMoreUsers = createSelector(
  [ getSearchResults,
    state => state.userState.search.total ],
  (users, total) => users.length < total);

// since pagination works by continually getting more (vs. stepping through
// discrete pages one by one) the offset is always the length of the list of items
export const getOffset = (state) => getSearchResults(state).length;
