import React from 'react';

import languageMaps from '~i18n/language-maps';
import { EN } from '~i18n/languages';

import { getStore } from '../store';

const defaultLang = EN;

// Gets the raw string corresponding to the key out of the specified locale.
// Returned undefined if not found.
const retrieve = (map, key) => {
  if (!map) {
    return undefined;
  }

  const keys = key.split('.');
  let result = map;
  for (const k of keys) {
    // walk down the map until we find what we're looking for
    result = result[k];
    if (typeof result === 'undefined') {
      // this is a dead-end
      break;
    }
  }
  return result;
};

// Retrieves the raw string from the specified language, using the defaultLang value if
// not found in the required vesion (aka English).
// Returns null if there is no match.
const lookup = (language, key) => {
  if (typeof key !== 'string') {
    return null;
  }

  let raw = retrieve(languageMaps[language], key);

  // key is not found in the current language, fall back
  if (typeof raw === 'undefined' && language !== defaultLang) {
    raw = retrieve(languageMaps[defaultLang], key);
  }

  if (typeof raw !== 'string') {
    return null;
  }

  return raw;
};

// Takes a raw string and replaces any {placeholder} tokens with the provided placeholder.
// Retunrs the input is no placeholders have been provided, or if there are no tokens in the string.
// Otherwise, returns an array of items in order where the {placeholder} tokens have been
// replaced by the provided placeholders.
//   > replace("hello, {place}", { place: "world"})
//   < ["hello", "world"]
// Note: this format is necessary to allow for React elements to be used as placeholders.
// joining this array results in rendering the string value of the element instead of the element itself.
const replace = (raw, placeholders) => {

  const sansPlaceholders = raw.split(/\{[^}]+\}/);
  if (sansPlaceholders.length <= 1 || !placeholders) {
    // no tokens, or no placeholders. return the original
    return raw;
  }

  // extract the placeholder names.
  const names = raw.match(/\{[^}]+\}/g);
  const stripped = names.map((placeholder) => {
    return placeholder.slice(1, -1);
  });

  const result = [];
  for (let i = 0; i < sansPlaceholders.length; i++) {
    result.push(sansPlaceholders[i]);
    if (i < sansPlaceholders.length - 1) {
      result.push(placeholders[stripped[i]]);
    }
  }
  return result;
};

// whether or not the set of placeholders is simple (that is, just strings)
const simple = (placeholders) => {
  let simple = true;

  if (placeholders) {
    for (const key in placeholders) {
      if (typeof placeholders[key] !== 'string' && typeof placeholders[key] !== 'number') {
        // at least one of these placeholders is an object. not simple.
        simple = false;
        break;
      }
    }
  }

  // we made it through!
  return simple;
};

// Translates the key into the provided language, replacing any {placeholder} tokens.
// if there are no placeholders, returns a string.
// if there only are simple (string) placeholders, returns a string.
// if there are any complex (React Element) placeholders, returns a React element.
// if the key is not found, returns null.
export const translateElement = (lang, key, placeholders) => {
  let result = lookup(lang, key);

  if (result) {
    if (!placeholders) {
      // nothing more to do, this is a simple string. hooray!
      return result;
    } else if (simple(placeholders)) {
      // we have placeholders, so replace any tokens.
      // result of that could either be an array or a string,
      // we want to make sure we return a string.
      result = replace(result, placeholders);
      return result.join ? result.join('') : result;
    } else {
      // this is has complex (aka React element placeholders),
      // so replace those and construct an element for our translation
      // so we can display them properly and return it
      return React.createElement(
        (props) => React.createElement(
          React.Fragment, null, ...replace(result, props)
        ), placeholders);
    }
  }

  return null;
};

// translates the provided key into a string for the current language.
export const translate = (key, placeholders) => {
  const lang = getStore().getState().uiState.currentLanguage;
  return translateElement(lang, key, placeholders);
};
