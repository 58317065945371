import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as accountActions from '~actions/account-actions';

import IString from '~components/general/i-string';
import PaginatedTableContainer from '~components/general/paginated-table-container';
import { useSortContext, withSortContext } from '~components/general/sort/sort-context';
import SortableHeader from '~components/general/sortable-header';
import UserLink from '~components/general/user-link';

import * as memberSortBy from '~constants/member-sortby';

import * as accountSelectors from '~selectors/account-selectors';

export function AccountMembersShinyapps({ account }) {
  const dispatch = useDispatch();

  const members = useSelector(accountSelectors.getAccountMembersList);
  const total = useSelector(accountSelectors.getAccountMembersTotal);

  const [ showMembers, setShowMembers ] = useState(true);
  const [ showOwner, setShowOwner ] = useState(true);

  const [ filter, setFilter ] = useState();
  const { order, sortBy } = useSortContext();

  const fetchMoreAccountMembersShinyapps = useCallback((offset) => {
    dispatch(accountActions.fetchAccountMembersPage(account.id, filter, { [sortBy]: order }, offset, 'shinyapps'));
  }, [ account.id, dispatch, filter, order, sortBy ]);

  useEffect(() => {
    const options = {
      member: showMembers,
      owner: showOwner
    };

    const roles = Object.keys(options).filter((current) => !options[current]);

    let filter;
    if (roles.length > 0) {
      filter = roles.length > 1 ? `role:notin:${roles.join()}` : `role:ne:${roles[0]}`;
    }

    setFilter(filter);
  }, [ showMembers, showOwner ]);

  // initialize page
  useEffect(() => {
    fetchMoreAccountMembersShinyapps();
    return () => {
      dispatch(accountActions.clearAccountMembers());
    };
  }, [ account.id, dispatch, fetchMoreAccountMembersShinyapps ]);

  return (
    <PaginatedTableContainer
      data={members}
      getMore={fetchMoreAccountMembersShinyapps}
      header={
        <div className="flexCenter">
          <div className="marginRight">
            <input
              checked={showOwner}
              id="accountMembersShinyapps_owner"
              onChange={(e) => setShowOwner(e.target.checked)}
              type="checkbox"
            />
            <label htmlFor="accountMembersShinyapps_owner">
              <IString stringKey="account.members.table.checkboxes.owner" />
            </label>
          </div>
          <div className="marginRight">
            <input
              checked={showMembers}
              id="accountMembersShinyapps_members"
              onChange={(e) => setShowMembers(e.target.checked)}
              type="checkbox"
            />
            <label htmlFor="accountMembersShinyapps_members">
              <IString stringKey="account.members.table.checkboxes.members" />
            </label>
          </div>
        </div>
      }
      total={total}
    >
      <table className="contentListing sortableColumns">
        <thead>
          <tr>
            <SortableHeader field={memberSortBy.NAME} >
              <IString stringKey="general.name" />
            </SortableHeader>
            <th><IString stringKey="general.role" /></th>
          </tr>
        </thead>
        <tbody>
          {members?.map(m =>
            <tr key={m.id}>
              <td><UserLink user={m} /></td>
              <td>{m.grant.shinyapps_role}</td>
            </tr>
              )}
        </tbody>
      </table>
    </PaginatedTableContainer>
  );
}

AccountMembersShinyapps.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export default withSortContext(AccountMembersShinyapps, { sortBy: memberSortBy.NAME });
