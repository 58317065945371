import { call, put } from 'redux-saga/effects';

import * as authActions from '~actions/auth-actions';
import * as uiActions from '~actions/ui-actions';

import * as api from '~api';

import { loadToken, clearToken } from '~util/token';

export function* initialize() {

  // load config
  const { data: config } =  yield call(api.getConfig);

  yield put(uiActions.setConfig(config));

  // load an existing token from if there is one
  const token = yield call(loadToken);

  if (token) {

    try {

      // fetch the current user using the existing token, if we get a user back then the token is good
      const { data } = yield call(api.getCurrentUser);

      yield put(authActions.setAuthUser(data));

      // begin token refresh
      yield put(authActions.maintainAccessToken());

      try {
        // eslint-disable-next-line no-console
        console.log(`[initialize] app_version: ${config.VERSION}`);
      } catch (e) { /* if this fails for whatever reason, we simply won't log anything */ }

    } catch (error) {

      // there was  problem, clear the token and forget this ever happened
      yield call(clearToken);

      yield put(authActions.clearAuthUser());

    }

  }

  // done!
  yield put(uiActions.setReady(true));
}
