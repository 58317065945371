import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import * as dialogs from '~components/dialogs/dialog-types';
import ModalDialog from '~components/dialogs/modal-dialog';
import IString from '~components/general/i-string';
import classnames from '~components/util/classnames';

import { translate } from '~i18n/localize';

export function UserCreateDialog(props) {

  const [ email, setEmail ] = useState(null);
  const [ password, setPassword ] = useState(null);
  const [ firstName, setFirstName ] = useState(null);
  const [ lastName, setLastName ] = useState(null);

  const [ emailError, setEmailError ] = useState(null);
  const [ passwordError, setPasswordError ] = useState(null);

  const handleChangeEmail = (value) => {
    if (value !== email) {
      setEmail(value);
      setEmailError(null);
    }
  };

  const handleChangePassword = (value) => {
    if (value !== password) {
      setPassword(value);
      setPasswordError(null);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const { onSubmit } = props;

    let valid = true;

    if (!event.target.email.validity.valid) {
      setEmailError(translate('user.create.errors.email'));
      valid = false;
    }

    if (!event.target.password.validity.valid) {
      setPasswordError(translate('user.create.errors.password'));
      valid = false;
    }

    if (valid) {
      if (onSubmit) {
        onSubmit({
          email,
          password,
          first_name: firstName,
          last_name: lastName
        });
      }
    }

  };

  return (
    <ModalDialog type={dialogs.USER_CREATE_DIALOG}>

      <div className="purpose">
        <IString stringKey="user.create.title" />
      </div>

      <form noValidate onSubmit={handleSubmit}>
        <fieldset className="chunk">
          <label htmlFor="email" className={classnames({ 'fieldLabel': true, 'error': emailError })}>
            <IString stringKey="general.email" />
            <div id="emailError" className="errorText" aria-live="polite">{emailError}</div>
          </label>
          <div className="flex">
            <input
              autoFocus
              className="full"
              id="email"
              name="email"
              onChange={e => handleChangeEmail(e.target.value)}
              required
              type="email"
              value={email || ''}
            />
          </div>
        </fieldset>

        <fieldset className="chunk">
          <label htmlFor="password" className={classnames({ 'fieldLabel': true, 'error': emailError })}>
            <IString stringKey="general.password" />
            <div id="passwordError" className="errorText" aria-live="polite">{passwordError}</div>
          </label>
          <div className="flex">
            <input
              className="full"
              id="password"
              name="password"
              onChange={e => handleChangePassword(e.target.value)}
              required
              type="password"
              value={password || ''}
            />
          </div>
        </fieldset>

        <fieldset className="chunk">
          <label htmlFor="firstName" className="fieldLabel">
            <IString stringKey="general.first_name" />
          </label>
          <div className="flex">
            <input
              className="full"
              id="firstName"
              onChange={e => setFirstName(e.target.value)}
              type="text"
              value={firstName || ''}
            />
          </div>
        </fieldset>

        <fieldset className="chunk">
          <label htmlFor="lastName" className="fieldLabel">
            <IString stringKey="general.last_name" />
          </label>
          <div className="flex">
            <input
              className="full"
              id="lastName"
              onChange={e => setLastName(e.target.value)}
              type="text"
              value={lastName || ''}
            />
          </div>
        </fieldset>

        <div className="actions">
          <button type="submit"><IString stringKey="general.save" /></button>
        </div>

      </form>

    </ModalDialog>
  );
}

UserCreateDialog.propTypes = {
  dispatch: PropTypes.func,
  onSubmit: PropTypes.func
};

export default connect()(UserCreateDialog);
