import PropTypes from 'prop-types';
import React from 'react';

class UserIcon extends React.Component {

  render() {
    const { display_name, picture_url } = this.props;

    let initials = '';

    if (display_name) {
      const names = display_name.split(' ');
      // first "name" initial
      initials += names[0].charAt(0);
      if (names.length > 1) {
        // last "name" initial
        initials += names[names.length - 1].charAt(0);
      }
    }

    return (
      <div className="userInitialsIconContainer">
        <div className="userIcon initials">{ initials ? initials : '?' }</div>
        { !!picture_url && <div className="userIcon"><img src={`${picture_url}?d=blank`} /></div> }
      </div>
    );
  }
}

UserIcon.propTypes = {
  display_name: PropTypes.string,
  picture_url: PropTypes.string
};

export default UserIcon;
