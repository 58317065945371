import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import IString from '~components/general/i-string';

import * as applicationScheduler from '~constants/application-scheduler';

import * as applicationSelectors from '~selectors/application-selectors';

function ApplicationMenu(props) {

  const { applicationId } = props;
  const application = useSelector(applicationSelectors.getCurrentApplication);

  return (
    <div className="menu showOnMobile">
      <div className="menuItems">
        <NavLink to={`/applications/${applicationId}/`} className="menuItem" exact activeClassName="active">
          <IString stringKey="application.menu.details" />
        </NavLink>
        <NavLink to={`/applications/${applicationId}/users`} className="menuItem" exact activeClassName="active">
          <IString stringKey="application.menu.users" />
        </NavLink>
        <NavLink to={`/applications/${applicationId}/logs`} className="menuItem" activeClassName="active">
          <IString stringKey="application.menu.logs" />
        </NavLink>
        <NavLink to={`/applications/${applicationId}/metrics`} className="menuItem" activeClassName="active">
          <IString stringKey="application.menu.metrics" />
        </NavLink>
        <NavLink to={`/applications/${applicationId}/deployment`} className="menuItem" activeClassName="active">
          <IString stringKey="application.menu.deployment" />
        </NavLink>
        { applicationScheduler.isLegacy(application) &&
          <NavLink to={`/applications/${applicationId}/containers`} className="menuItem" activeClassName="active">
            <IString stringKey="application.menu.containers" />
          </NavLink>
        }
      </div>
    </div>

  );
}

ApplicationMenu.propTypes = {
  applicationId: PropTypes.number
};

export default ApplicationMenu;
