import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import * as accountActions from '~actions/account-actions';
import * as uiActions from '~actions/ui-actions';

import LicenseSuspension from '~components/accounts/license/license-suspension';
import * as dialogs from '~components/dialogs/dialog-types';
import ActionButton from '~components/general/action-button';
import IString from '~components/general/i-string';
import Time from '~components/general/time';

import * as licenseName from '~constants/license-name';
import * as licenseSeat from '~constants/license-seat';

import { translate } from '~i18n/localize';

/**
 * Displays detailed information about an account's license
 *
 * @param   {number}           accountId ID of the account
 * @param   {object}           license   The account's license
 * @returns {null|JSX.Element}           Details about the account license if it is defined, null otherwise
 */
export default function AccountLicenseDetails({ accountId, license }) {

  const dispatch = useDispatch();

  const handleConfigure = () => {
    dispatch(uiActions.openDialog({
      type: dialogs.ACCOUNT_LICENSE_QUICK_CONFIGURE_DIALOG,
      data: { accountId, license },
      onSubmit: () => {
        dispatch(uiActions.closeDialog());
      }
    }));
  };

  const handleEdit = () => {
    dispatch(uiActions.openDialog({
      type: dialogs.ACCOUNT_LICENSE_EDITOR_DIALOG,
      data: { accountId, license },
      onSubmit: (changed) => {
        if (changed) {
          dispatch(accountActions.updateAccountLicense(accountId, license.type, changed));
        }
        dispatch(uiActions.closeDialog()); // close editor dialog
      }
    }));
  };

  return !license
    ? null
    : <div id="licenseDetails">
      <div className="sectionTitle flex" style={{ textTransform: 'capitalize' }}>
        <IString stringKey="account.license.title" placeholders={{ type: license.type }} />
        <div className="actionBar inline showTitles">
          <ActionButton className="edit" onClick={handleEdit} title={translate('general.edit')}  />
          { licenseName.isCloudCustom(license) &&
            <ActionButton className="toggleSettings" onClick={handleConfigure} title={<IString stringKey="account.license.quickConfigure.label" />} />
          }
        </div>
      </div>
      <div className="section sectionBottomBorder">
        <dl className="separateLines">
          <dt><IString stringKey="general.name" /></dt>
          <dd>{license.name}</dd>
          <dt><IString stringKey="general.status" /></dt>
          <dd>{license.status}</dd>
          <dt><IString stringKey="general.expires" /></dt>
          <dd>{license.expires ? <Time value={license.expires} /> : <em>never</em>}</dd>
          <dt><IString stringKey="account.license.headers.cycle_start_time" /></dt>
          <dd><Time value={license.cycle_start} /></dd>
          <dt><IString stringKey="account.license.headers.cycle_end_time" /></dt>
          <dd><Time value={license.cycle_end} /></dd>
          <dt><IString stringKey="account.license.headers.suspension_status" /></dt>
          <dd><LicenseSuspension license={license} /></dd>
          <dt><IString stringKey="account.license.headers.license_seats" /></dt>
          <dd>
            {license.license_seats && Object.keys(license.license_seats).length > 0
              ? <>
                {license.license_seats[licenseSeat.INDIVIDUAL] &&
                  <IString placeholders={{ max: license.license_seats[licenseSeat.INDIVIDUAL].max }} stringKey={`account.license.headers.${licenseSeat.INDIVIDUAL}`} />
                }
                {license.license_seats[licenseSeat.MEMBER] &&
                  <IString placeholders={{ max: license.license_seats[licenseSeat.MEMBER].max }} stringKey={`account.license.headers.${licenseSeat.MEMBER}`} />
                }
                {license.license_seats[licenseSeat.INSTRUCTOR] &&
                  <>
                    <div>
                      <IString className="blockSpan" placeholders={{ max: license.license_seats[licenseSeat.INSTRUCTOR].max }} stringKey={`account.license.headers.${licenseSeat.INSTRUCTOR}`} />
                    </div>
                    <div>
                      <IString
                        className="blockSpan"
                        placeholders={{ max: `${license.license_seats[licenseSeat.STUDENT]?.max}` }}
                        stringKey={`account.license.headers.${licenseSeat.STUDENT}.${license.license_seats[licenseSeat.STUDENT]?.max ? 'count' : 'unlimited'}`}
                        trustPlaceholders
                      />
                    </div>
                  </>
                }
              </>
              : <em><IString stringKey="general.none" /></em>
            }
          </dd>
        </dl>
      </div>
    </div>;
}

AccountLicenseDetails.propTypes = {
  accountId: PropTypes.number,
  dispatch: PropTypes.func,
  license: PropTypes.shape({
    cycle_end: PropTypes.string,
    cycle_start: PropTypes.string,
    exempt: PropTypes.bool,
    exempt_until: PropTypes.string,
    expires: PropTypes.string,
    id: PropTypes.number,
    license_seats: PropTypes.objectOf(PropTypes.shape({ max: PropTypes.number })),
    name: PropTypes.string,
    status: PropTypes.string,
    suspended: PropTypes.bool,
    suspended_reason: PropTypes.string,
    suspended_until: PropTypes.string,
    type: PropTypes.string
  })
};
