import { combineReducers } from 'redux';

import * as types from '~actions/action-types';

import { removeOne, update, updateMany, updateOne } from '~reducers/reducer-utils';

const spacesReducer = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_SPACE_SUCCESS:
      return updateOne(state, action.space);

    case types.FETCH_SPACE_SEARCH_SUCCESS:
    case types.FETCH_ACCOUNT_SPACES_SUCCESS:
      return updateMany(state, action.spaces);

    case types.DELETE_SPACE_SUCCESS:
      return removeOne(state, action.spaceId);
  }
  return state;
};

const spaceConnectionsReducer = (state = { connections: [], total: undefined }, action) => {
  switch (action.type) {
    case types.FETCH_SPACE_CONNECTIONS_SUCCESS:
      return update(state, {
        connections: [ ...state.connections, ...action.connections ],
        total: action.total
      });

    case types.CLEAR_CURRENT_SPACE:
    case types.CLEAR_SPACE_CONNECTIONS:
      return update(state, { connections: [], total: undefined });
  }
  return state;
};

const currentSpaceReducer = (state = { id: undefined }, action) => {
  switch (action.type) {
    case types.SET_CURRENT_SPACE:
      return update(state, { id: action.spaceId });

    case types.CLEAR_CURRENT_SPACE:
      return update(state, { id: undefined });
  }
  return state;
};

const spaceMembersReducer = (state = { members: {}, ordered: [], total: undefined, loading: false }, action) => {
  switch (action.type) {
    case types.FETCH_SPACE_MEMBERS_PAGE:
      return update(state, { loading: true });

    case types.FETCH_SPACE_MEMBERS_PAGE_SUCCESS:
      return update(state, {
        members: updateMany(state.members, action.members),
        total: action.total,
        loading: false,
        ordered: [ ...state.ordered, ...action.members.map(m => m.id) ]
      });

    case types.FETCH_SPACE_MEMBERS_PAGE_FAILURE:
    case types.CLEAR_CURRENT_SPACE:
    case types.CLEAR_SPACE_MEMBERS:
      return update(state, { members: {}, ordered: [], total: undefined, loading: false });

    case types.UPDATE_SPACE_ROLE_SUCCESS:
      return {
        ...state,
        members: updateOne(state.members, action.member)
      };
  }
  return state;
};

const spaceContentReducer = (state = { ordered: [], total: undefined, loading: false }, action) => {
  switch (action.type) {
    case types.FETCH_SPACE_CONTENT_PAGE:
      return update(state, { loading: true  });

    case types.FETCH_SPACE_CONTENT_PAGE_SUCCESS:
      return update(state, {
        total: action.total,
        loading: false,
        ordered: [ ...state.ordered, ...action.content.map(c => c.id) ]
      });

    case types.FETCH_SPACE_CONTENT_PAGE_FAILURE:
    case types.CLEAR_SPACE_CONTENT:
    case types.CLEAR_CURRENT_SPACE:
      return update(state, { ordered: [], total: undefined, loading: false });
  }
  return state;
};

const searchReducer = (state = { loading: false, resultIds: [] }, action) => {
  switch (action.type) {
    case types.SET_SPACE_SEARCH:
      return update(state, { loading: true, resultIds: [] });

    case types.FETCH_SPACE_SEARCH_SUCCESS:
      return update(state, { loading: false, resultIds: action.spaces.map(s => s.id) });

    case types.CLEAR_SPACE_SEARCH:
    case types.FETCH_SPACE_SEARCH_FAILURE:
      return update(state, { loading: false, resultIds: [] });
  }
  return state;
};

export default combineReducers({
  connections: spaceConnectionsReducer,
  currentSpace: currentSpaceReducer,
  members: spaceMembersReducer,
  search: searchReducer,
  spaces: spacesReducer, // object of this shape: { [space.id]: space }
  spaceContentList: spaceContentReducer // orderedIds, loading, total
});
