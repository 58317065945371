import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import IString from '~components/general/i-string';
import classnames from '~components/util/classnames';
import { useOpenState } from '~components/util/hooks';

function MobileMenu(props) {
  const { children } = props;

  const [ open, setOpen ] = useOpenState();
  const [ current, setCurrent ] = useState();

  const match = useRouteMatch();

  useEffect(() => {
    // strip slashes to get the top-level route, that'll be "current"
    setCurrent(match.path.replace(/^\/+/g, ''));
  }, [ match.path ]);

  const toggleMenu = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <div className="mobileSectionMenu" style={{ flexGrow: 10 }}>
      <div className="popupButtonAndMenuContainer">
        <button className="textMenuToggler" onClick={toggleMenu}>
          <IString stringKey={`general.menu.${current || 'select'}`} />
        </button>
        <div className={classnames({ popupMenu: true, alightLeft: true, open })}>
          <div className="navMenu popupNavMenu">
            <div className="menu textOnly">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

MobileMenu.propTypes = {
  children: PropTypes.node
};

export default MobileMenu;
