import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import * as actions from '~actions/content-actions';

import ContentDetails from '~components/content/content-details';
import ContentMenu from '~components/content/content-menu';
import ContentUsage from '~components/content/content-usage';
import IString from '~components/general/i-string';
import ProjectChildren from '~components/projects/project-children';
import ProjectOutputs from '~components/projects/project-outputs';
import ProjectSessions from '~components/projects/project-sessions';

import * as contentSelectors from '~selectors/content-selectors';

export default function Content() {
  const dispatch = useDispatch();

  const { params, path } = useRouteMatch();
  const contentId = +params.contentId;

  const content = useSelector((state) => contentSelectors.getContentById(state, contentId));

  useEffect(() => {
    if (contentId) {
      dispatch(actions.fetchContent(contentId));
    }
  }, [ dispatch, contentId ]);

  if (content) {
    return (
      <>
        <h1><IString stringKey={`content.title.${content.content_type}`} placeholders={{ id: content.id, name: content.name }} /></h1>
        <ContentMenu content={content} />
        <Switch>
          <Route exact path={`${path}/`}>
            <ContentDetails content={content} />
          </Route>
          <Route path={`${path}/children`}>
            <ProjectChildren project={content} />
          </Route>
          <Route path={`${path}/outputs`}>
            <ProjectOutputs projectId={contentId} />
          </Route>
          <Route path={`${path}/sessions`}>
            <ProjectSessions project={content} />
          </Route>
          <Route path={`${path}/usage`}>
            <ContentUsage contentId={contentId} />
          </Route>
        </Switch>
      </>
    );
  } else {
    return (
      <span>Loading...</span>
    );
  }
}

Content.propTypes = {
  children: PropTypes.node,
  content: PropTypes.shape({
    content_type: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string
  }),
  dispatch: PropTypes.func,
  match: PropTypes.object
};
