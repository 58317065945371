import PropTypes from 'prop-types';
import React from 'react';

import IString from '~components/general/i-string';
import classnames from '~components/util/classnames';

function UsageCard(props) {
  const { className: cn, titleKey, value } = props;
  return (
    <div className={classnames({ [cn]: !!cn, card: true })}>
      <div className="cardTitle">
        <IString stringKey={titleKey} />
      </div>
      <div className="cardValue">{value}</div>
    </div>
  );
}

UsageCard.propTypes = {
  className: PropTypes.string,
  titleKey: PropTypes.string,
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
};

function OverviewCards({ data, metrics }) {
  const ratio = Math.round(100 / metrics.length);

  return (
    <div className="section splitColumns withMobileMargins">
      { metrics.map((field, index) =>
        <UsageCard
          key={index}
          className={`column${ratio} slowGrow`}
          titleKey={`usage.cards.${field}.name`}
          value={data ? data[field] : null}
        />
      )}
    </div>
  );
}

OverviewCards.propTypes = {
  data: PropTypes.object,
  metrics: PropTypes.array
};

export default OverviewCards;
