import * as types from '~actions/action-types';

export const setContentSearch = (query, offset) => ({
  type: types.SET_CONTENT_SEARCH,
  query,
  offset
});

export const clearContentSearch = () => ({
  type: types.CLEAR_CONTENT_SEARCH
});

export const fetchContentSearchSuccess = (content, total, offset) => ({
  type: types.FETCH_CONTENT_SEARCH_SUCCESS,
  content,
  total,
  offset
});

export const fetchContentSearchFailure = () => ({
  type: types.FETCH_CONTENT_SEARCH_FAILURE
});

export const fetchContent = (id) => ({
  type: types.FETCH_CONTENT,
  id
});

export const fetchContentSuccess = (content) => ({
  type: types.FETCH_CONTENT_SUCCESS,
  content
});

export const deleteContentSuccess = (id) => ({
  type: types.DELETE_CONTENT_SUCCESS,
  id
});

export const fetchContentByAuthor = (userId, order) => ({
  type: types.FETCH_CONTENT_BY_AUTHOR,
  userId,
  order
});

export const fetchContentByAuthorSuccess = (content) => ({
  type: types.FETCH_CONTENT_BY_AUTHOR_SUCCESS,
  content
});

export const clearCurrentUserAuthoredContent = () => ({
  type: types.CLEAR_CURRENT_USER_AUTHORED_CONTENT
});

export const updateContentSuccess = (content) => ({
  type: types.UPDATE_CONTENT_SUCCESS,
  content
});
