import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as applicationActions from '~actions/application-actions';

import IString from '~components/general/i-string';

import * as applicationSelectors from '~selectors/application-selectors';

const ApplicationLogs = ({ applicationId, dispatch, logs }) => {

  useEffect(() => {
    dispatch(applicationActions.fetchApplicationLogs(applicationId));
  }, [ dispatch, applicationId ]);

  return (
    <>
      <div className="sectionTitle flex smallMarginLeft">
        <IString stringKey="application.logs" />
      </div>

      <div className="section exBand">
        { !logs ? <div className="emptyListMessage"><span>No Log Data</span></div> : <pre>{logs}</pre> }
      </div>

    </>
  );
};

ApplicationLogs.propTypes = {
  applicationId: PropTypes.number,
  dispatch: PropTypes.func,
  logs: PropTypes.string,
  match: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    logs: applicationSelectors.getCurrentApplicationLogs(state)
  };
};

export default connect(mapStateToProps)(ApplicationLogs);
