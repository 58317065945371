import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ContentLink from '~components/general/content-link';
import IString from '~components/general/i-string';
import RangePicker, { rangeToUnit } from '~components/general/range-picker';
import TimeseriesChart, { formats } from '~components/general/timeseries-chart';
import ChartContainer from '~components/usage/chart-container';
import OverviewCards from '~components/usage/overview-cards';
import SummaryTable from '~components/usage/summary-table';
import classnames from '~components/util/classnames';

import * as usageMetrics from '~constants/usage-metrics';

import * as accountOperations from '~operations/account-operations';

import * as spaceSelectors from '~selectors/space-selectors';
import * as uiSelectors from '~selectors/ui-selectors';

import mo, { ISO_DATE } from '~util/mo';
import * as usageUtils from '~util/usage';

export const ContentID = ({ deleted, id }) =>
  <span className={classnames({ strikeThru: !!deleted })}>
    { deleted ? <span>Content {id}</span> : <ContentLink contentId={id} /> }
  </span>;

ContentID.propTypes = {
  deleted: PropTypes.bool,
  id: PropTypes.number
};

export const SpaceUsage = ({ spaceId }) => {
  const dispatch = useDispatch();

  const [ from, setFrom ] = useState();
  const [ rangeSize, setRangeSize ] = useState();
  const [ until, setUntil ] = useState();

  const [ overview, setOverview ] = useState();
  const [ series, setSeries ] = useState();
  const [ summaries, setSummaries ] = useState();

  const accountId = useSelector((state) => spaceSelectors.getSpace(state, spaceId).account_id);
  const utc = useSelector(uiSelectors.getUTC);

  const handleRangeChange = useCallback((from, until, rangeSize) => {
    setFrom(from);
    setRangeSize(rangeSize);
    setUntil(until);
  }, []);

  useEffect(() => {
    const options = { from, space_id: spaceId, until };

    const fetchUsageOverviewData = async () => {
      const overview = await dispatch(
        accountOperations.getAccountUsageOverview(accountId, options)
      );
      setOverview(overview);
    };

    const fetchUsageSummaryData = async () => {
      const summaries = await dispatch(
        accountOperations.getAccountUsageSummary(accountId, 'content', options)
      );

      setSummaries(summaries);
    };

    if (accountId && from && spaceId && until) {
      fetchUsageOverviewData();
      fetchUsageSummaryData();
    }

  }, [ accountId, dispatch, from, spaceId, until ]);

  useEffect(() => {
    const fetchUsageSeriesData = async () => {
      const series = await dispatch(
        accountOperations.getAccountUsageSeries(accountId, {
          from,
          interval: usageUtils.rangeToInterval[rangeSize],
          space_id: spaceId,
          until
        })
      );
      setSeries(series);
    };

    if (accountId && from && rangeSize && spaceId && until) {
      fetchUsageSeriesData();
    }
  }, [ accountId, dispatch, from, rangeSize, spaceId, until ]);

  const tooltipFormatters = {
    label: usageUtils.computeHoursFormatter,
    title: (tips, data) =>
      mo(
        utc,
        data.datasets[tips[0].datasetIndex].data[tips[0].index].x
      ).format(formats.tooltip[rangeToUnit[rangeSize]])
  };

  return (
    <>
      <div className="sectionTitle">
        <IString stringKey="space.usage.title" />
      </div>

      <RangePicker
        earliest={mo(utc).subtract(1, 'year').format(ISO_DATE)}
        onRangeChange={handleRangeChange}
        utc={utc}
      />

      <OverviewCards metrics={usageMetrics.allMetrics} data={overview} />

      <ChartContainer loading={!series} empty={series && series.length === 0}>
        <TimeseriesChart
          chartType="bar"
          datasets={usageUtils.buildDataset(series)}
          tooltipFormatters={tooltipFormatters}
          xAxisFormatter={
            (value, index, ticks) =>
              mo(utc, ticks[index].value).format(formats.axis[rangeToUnit[rangeSize]])
          }
          unit={rangeToUnit[rangeSize]}
        />
      </ChartContainer>

      <SummaryTable
        columns={usageMetrics.contentFields}
        renderer={ContentID}
        rows={summaries}
      />
    </>
  );

};

SpaceUsage.propTypes = {
  spaceId: PropTypes.number
};

export default SpaceUsage;
