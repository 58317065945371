import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as uiActions from '~actions/ui-actions';

import * as dialogs from '~components/dialogs/dialog-types';
import ModalDialog from '~components/dialogs/modal-dialog';
import ActionButton from '~components/general/action-button';
import IString from '~components/general/i-string';
import classnames from '~components/util/classnames';

import * as accountType from '~constants/account-type';
import { ISO_8601_DATE_TIME_PATTERN, SSO_HINT_PATTERN } from '~constants/validation_patterns';

import { shallowDiffObject } from '~util/compare';

const ACCOUNT_PROPERTIES = {
  TYPE: 'type',
  DISPLAY_NAME: 'display_name',
  SSO_ENABLED: 'sso_enabled',
  SSO_HINT: 'sso_hint',
  SUSPENDED_UNTIL: 'suspended_until',
  SUSPENDED_REASON: 'suspended_reason'
};

export default function AccountDetailsEditorDialog({ data, onSubmit }) {
  const { account } = data;

  const dispatch = useDispatch();
  const [ accountData, setAccountData ] = useState(account);
  const [ detailErrors, setDetailErrors ] = useState({});

  const handleChange = (event, property) => {
    event.preventDefault();

    setAccountData({ ...accountData, [property]: event.target.value });
  };

  const handleToggle = (event, property) => {
    setAccountData({ ...accountData, [property]: event.target.checked });
  };

  const handleClear = (event, property) => {
    event.preventDefault();

    setAccountData({ ...accountData, [property]: null });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // If the account is not SSO enabled then clear the SSO hint
    if (!accountData.sso_enabled) {
      accountData.sso_hint = null;
    }

    const errors = {
      ssoHint: accountData.sso_enabled && (!SSO_HINT_PATTERN.test(accountData.sso_hint) || accountData.sso_hint.length === 0),
      suspendedUntil: accountData.suspended_until && !ISO_8601_DATE_TIME_PATTERN.test(accountData.suspended_until)
    };

    if (Object.values(errors).some(el => el)) {
      setDetailErrors(errors);
    }

    const changed = shallowDiffObject(account, accountData);

    const hasDetailErrors = Object.values(errors).find(element => element !== false);

    if (!hasDetailErrors) {
      dispatch(uiActions.openDialog({
        type: dialogs.CONFIRM_DIALOG,
        data: {
          purpose: `Save Changes to Account ID #${account.id}`,
          details: `Are you sure you want to save changes to Account ID #${account.id}?`,
          confirm: () => {
            if (onSubmit) {
              onSubmit(changed);
            }
            dispatch(uiActions.closeDialog()); // close confirm dialog
          }
        }
      }));
    }
  };

  return (
    <ModalDialog type={dialogs.ACCOUNT_DETAILS_EDITOR_DIALOG}>
      <div className="purpose">
        <IString stringKey="account.details.dialog.edit.purpose" placeholders={{ id: account?.id }} />
      </div>
      <form noValidate onSubmit={handleSubmit}>
        <fieldset className="chunk">
          <label htmlFor="displayName" className="fieldLabel">
            <IString stringKey="general.display_name" />
          </label>
          <div className="flex">
            <input
              className="full"
              id="displayName"
              onChange={e => handleChange(e, ACCOUNT_PROPERTIES.DISPLAY_NAME)}
              placeholder="Enter Display Name"
              type="text"
              value={accountData.display_name || ''}
            />
            <div className="actionBar inline" style={{ paddingTop: '3px' }}>
              <ActionButton className="delete noTitle" onClick={e => handleClear(e, ACCOUNT_PROPERTIES.DISPLAY_NAME)} />
            </div>
          </div>
        </fieldset>
        {accountData.type === accountType.ORGANIZATION &&
          <fieldset className="chunk">
            <div className="flex">
              <span>
                <input
                  checked={accountData.sso_enabled}
                  className="full"
                  id="ssoEnabled"
                  onChange={e => handleToggle(e, ACCOUNT_PROPERTIES.SSO_ENABLED)}
                  type="checkbox"
                />
                <label htmlFor="ssoEnabled"><IString stringKey="account.details.sso_enabled" /></label>
              </span>
            </div>
          </fieldset>
        }
        {accountData.sso_enabled &&
          <fieldset className="chunk">
            <label htmlFor="ssoHint" className={classnames({ fieldLabel: true, error: detailErrors.ssoHint })}>
              <IString stringKey="account.details.sso_hint" />
              { detailErrors.ssoHint ? <IString stringKey="account.details.dialog.edit.error.ssoHint" className="errorText" /> : null }
              <span className="optional"><IString stringKey="account.details.dialog.edit.sso_hint_optional" /></span>
            </label>
            <div className="flex">
              <input
                className="full"
                id="ssoHint"
                name="ssoHint"
                onChange={e => handleChange(e, ACCOUNT_PROPERTIES.SSO_HINT)}
                placeholder="Enter SSO Hint"
                required
                type="text"
                value={accountData.sso_hint || ''}
              />
              <div className="actionBar inline" style={{ paddingTop: '3px' }}>
                <ActionButton
                  className="delete noTitle"
                  onClick={e => handleClear(e, ACCOUNT_PROPERTIES.SSO_HINT)}
                />
              </div>
            </div>
          </fieldset>
        }

        <fieldset className="chunk">
          <label htmlFor="suspendedUntil" className={classnames({ fieldLabel: true, error: detailErrors.suspendedUntil })}>
            <IString stringKey="account.details.suspended_until" />
            { detailErrors.suspendedUntil ? <IString stringKey="account.details.dialog.edit.error.suspendedUntil" className="errorText" /> : null }
            <span className="optional"><IString stringKey="account.details.dialog.edit.suspended_until_optional" /></span>
          </label>
          <div className="flex">
            <input
              className="full"
              id="suspendedUntil"
              onChange={e => handleChange(e, ACCOUNT_PROPERTIES.SUSPENDED_UNTIL)}
              placeholder="Enter Suspended Until Date"
              type="text"
              value={accountData.suspended_until || ''}
            />
            <div className="actionBar inline" style={{ paddingTop: '3px' }}>
              <ActionButton
                className="delete noTitle"
                onClick={e => handleClear(e, ACCOUNT_PROPERTIES.SUSPENDED_UNTIL)}
              />
            </div>
          </div>
        </fieldset>

        <fieldset className="chunk">
          <label htmlFor="suspendedReason" className="fieldLabel">
            <IString stringKey="account.details.suspended_reason" />
          </label>
          <div className="flex">
            <input
              className="full"
              id="suspendedReason"
              onChange={e => handleChange(e, ACCOUNT_PROPERTIES.SUSPENDED_REASON)}
              placeholder="Enter Suspended Reason"
              type="text"
              value={accountData.suspended_reason || ''}
            />
            <div className="actionBar inline" style={{ paddingTop: '3px' }}>
              <ActionButton
                className="delete noTitle"
                onClick={e => handleClear(e, ACCOUNT_PROPERTIES.SUSPENDED_REASON)}
              />
            </div>
          </div>
        </fieldset>

        <div className="actions">
          <button type="submit"><IString stringKey="general.save" /></button>
        </div>

      </form>
    </ModalDialog>
  );
}

AccountDetailsEditorDialog.propTypes = {
  data: PropTypes.shape({
    account: PropTypes.shape({
      display_name: PropTypes.string,
      id: PropTypes.number,
      sso_enabled: PropTypes.bool,
      sso_hint: PropTypes.string,
      type: PropTypes.string
    })
  }),
  onSubmit: PropTypes.func
};
