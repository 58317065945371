import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import * as dialogTypes from '~components/dialogs/dialog-types';
import ModalDialog from '~components/dialogs/modal-dialog';
import IString from '~components/general/i-string';

const STRING_VALUE = 'string';
const NUMBER_VALUE = 'number';
const BOOLEAN_VALUE = 'boolean';

const PROPERTY_TYPES = [
  STRING_VALUE,
  NUMBER_VALUE,
  BOOLEAN_VALUE
];

function AccountPropertiesCreateEditDialog(props) {
  const { data, onSubmit } = props;
  const { create, property } = data;

  const [ name, setName ] = useState(property.name || '');
  const [ type, setType ] = useState(create ? STRING_VALUE : typeof(property.value));
  const [ value, setValue ] = useState(property.value || '');
  const [ checked, setChecked ] = useState(type === BOOLEAN_VALUE ? property.value : false);

  const inputType = (type === STRING_VALUE) ? 'text' : 'number';

  const handleSubmit = (event) => {
    event.preventDefault();
    let propertyData = {};

    if (type === BOOLEAN_VALUE) {
      const isTrue = (value === 'true' || value === '');
      setValue(isTrue);
      propertyData = { [name]: isTrue };
    } else if ( type === NUMBER_VALUE) {
      propertyData = { [name]: Number(value) };
    } else {
      propertyData = { [name]: value };
    }

    onSubmit(propertyData);
  };

  return (
    <ModalDialog type={dialogTypes.ACCOUNT_PROPERTIES_CREATE_EDIT_DIALOG}>
      <div className="purpose">
        <IString stringKey={`account.properties.modal.${create ? 'create' : 'edit'}.title`} />
      </div>
      <form noValidate onSubmit={handleSubmit}>

        <fieldset className="chunk">
          <label htmlFor="propertyName" className="fieldLabel">
            <IString stringKey="general.name" />
          </label>
          <div className="flex">
            <input
              className="full"
              id="propertyNameInput"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </fieldset>
        <fieldset className="chunk">
          <label htmlFor="propertyType" className="fieldLabel">
            <IString stringKey="general.type" />
          </label>
          <select
            className="full"
            id="propertyType"
            onChange={e => setType(e.target.value)}
            value={type}
          >
            {PROPERTY_TYPES.map(t => <option value={t} key={t}>{t}</option>)}
          </select>
        </fieldset>
        <fieldset className="chunk">
          <label htmlFor="propertyValue" className="fieldLabel">
            <IString stringKey="general.value" />
          </label>
          { type && (type === STRING_VALUE || type === NUMBER_VALUE) ?
            <div className="flex">
              <input
                className="full"
                id="propertyValueInput"
                type={inputType}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div> :
            <>
              <input
                checked={checked}
                className="full"
                name="propertyBooleanValue"
                id="propertyBooleanValue"
                type="checkbox"
                onChange={() => setChecked(!checked)}
              />
              <label htmlFor="propertyBooleanValue">Enabled</label>
            </>
          }
        </fieldset>
        <div className="actions">
          <button type="submit"><IString stringKey="general.save" /></button>
        </div>
      </form>
    </ModalDialog>
  );
}

AccountPropertiesCreateEditDialog.propTypes = {
  data: PropTypes.shape({
    create: PropTypes.bool,
    property: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.number
      ])
    })
  }),
  onSubmit: PropTypes.func
};

export default connect()(AccountPropertiesCreateEditDialog);

export { AccountPropertiesCreateEditDialog };
