/**
 *
 * @param   {number} value
 * @returns                The "compact" version of the given number (e.g. 13M or 2.5B), truncated to
 *                         a maximum of 3 decimal places. If the number is already compact, it will be unchanged.
 */
export const compactify = (value) => Intl.NumberFormat(undefined, {
  compactDisplay: 'short',
  maximumFractionDigits: 3,
  notation: 'compact',
  unitDisplay: 'narrow'
}).format(value);

/**
 * @param   {number} bytes
 * @returns                The given bytes in GiB.
 */
export const bytesToGiB = (bytes) => (bytes / Math.pow(1024, 3)).toFixed(2);
