import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import * as actions from '~actions/space-actions';

import SpaceConnectionsList from '~components/spaces/space-connections-list';
import SpaceContent from '~components/spaces/space-content';
import SpaceDetails from '~components/spaces/space-details';
import SpaceMembers from '~components/spaces/space-members';
import SpaceMenu from '~components/spaces/space-menu';
import SpaceUsage from '~components/spaces/space-usage';

import * as selectors from '~selectors/space-selectors';

function SpacePage({ dispatch, space }) {
  const { name, id } = space || {};

  const { params, path } = useRouteMatch();

  const [ spaceId, setSpaceId ] = useState(+params.spaceId);

  useEffect(() => {
    dispatch(actions.setCurrentSpace(spaceId));

    return () => {
      dispatch(actions.clearCurrentSpace());
    };
  }, [ dispatch, spaceId ]);

  useEffect(() => {
    setSpaceId(+params.spaceId);
  }, [ params ]);

  if (space) {
    return (
      <>
        <h1>Space: {name} (id: {id})</h1>
        <SpaceMenu spaceId={spaceId} />
        <Switch>
          <Route exact path={`${path}/`}>
            <SpaceDetails spaceId={spaceId} />
          </Route>
          <Route exact path={`${path}/members`}>
            <SpaceMembers spaceId={spaceId} />
          </Route>
          <Route path={`${path}/usage`}>
            <SpaceUsage spaceId={spaceId} />
          </Route>
          <Route path={`${path}/content`}>
            <SpaceContent spaceId={spaceId} />
          </Route>
          <Route path={`${path}/connections`}>
            <SpaceConnectionsList spaceId={spaceId} />
          </Route>
        </Switch>
      </>
    );
  } else {
    return (
      <span>Loading...</span>
    );
  }

}

SpacePage.propTypes = {
  dispatch: PropTypes.func,
  space: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })
};

const mapStateToProps = (state) => ({
  space: selectors.getCurrentSpace(state)
});

export default connect(mapStateToProps)(SpacePage);
