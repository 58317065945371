import moment from 'moment';

// ISO date format string for use when formatting moment dates (no time).
export const ISO_DATE = 'YYYY-MM-DD';

/**
 * @param   {boolean}       utc   Whether or not to use a utc moment.
 * @param   {number|string} value The value to momentize, either a numeric timestamp or a
 *                                properly-formatted datestring.
 * @returns                       A moment object created from the given value, either in local or utc time.
 */
const mo = (utc, value) =>
  utc ? moment.utc(value) : moment(value);

export default mo;
