import { createSelector } from 'reselect';

const getSpaces = (state) => state.spaceState.spaces;
const getCurrentSpaceId = (state) => state.spaceState.currentSpace.id;

export const getSpace = (state, id) => getSpaces(state)[id];

// space connections
export const getSpaceConnections = (state) => state.spaceState.connections.connections;
export const getSpaceConnectionsTotal = (state) => state.spaceState.connections.total ?? 0;

// space members
export const getSpaceMembers = (state) => state.spaceState.members.members;
export const getSpaceMembersTotal = (state) => state.spaceState.members.total;

export const getSpaceMembersList = createSelector(
  [ state => state.spaceState.members.members,
    state => state.spaceState.members.ordered ],
  (members, orderedIds) => orderedIds.map(id => members[id])
);

export const hasMoreMembers = createSelector(
  [ getSpaceMembersList,
    state => state.spaceState.members.total ],
  (members, total) => !!members && members.length < total);

export const isSpaceMembersLoading = (state) => state.accountState.members.loading;

// since pagination works by continually getting more (vs. stepping through
// discrete pages one by one) the offset is always the length of the list of items
export const getMembersOffset = createSelector(getSpaceMembersList, (members) => members.length);

const getSearchResultIds = (state) => state.spaceState.search.resultIds;
export const isSearchLoading = (state) => state.spaceState.search.loading;

export const getSearchResults = createSelector(
  [ getSpaces, getSearchResultIds ],
  (spaces, ids) => ids.map(id => spaces[id])
);

export const getCurrentSpace = createSelector(
  [ getSpaces, getCurrentSpaceId ],
  (spaces, id) => spaces[id]
);

// space content
export const getSpaceContentList = createSelector(
  [ state => state.contentState.content,
    state => state.spaceState.spaceContentList.ordered ],
  (content, orderedIds) => orderedIds.map(id => content[id])
);

export const hasMore = createSelector(
  [ getSpaceContentList,
    state => state.spaceState.spaceContentList.total ],
  (content, total) => !!content && content.length < total);

export const getSpaceContentListTotal = (state) => state.spaceState.spaceContentList.total;
export const isSpaceContentListLoading = (state) => state.spaceState.spaceContentList.loading;

// since pagination works by continually getting more (vs. stepping through
// discrete pages one by one) the offset is always the length of the list of items
export const getOffset = createSelector(getSpaceContentList, (content) => content.length);
