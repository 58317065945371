import PropTypes from 'prop-types';
import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { updateWindowSize, updateScrollInfo, updateClientHeight } from '~actions/ui-actions';

import * as uiSelectors from '~selectors/ui-selectors';

function Layout(props) {
  const { dispatch, isReady } = props;
  const bodyRef = React.createRef();

  useEffect(() => {
    handleResize();
    handleScroll();
  });

  const handleScroll = useCallback((event) => {
    if (bodyRef) {
      if (event?.target.id == 'main') {
        dispatch(updateScrollInfo(event.target.scrollTop, event.target.scrollHeight));
      }
    }
  }, [ bodyRef, dispatch ]);

  const handleResize = useCallback(() => {
    dispatch(updateWindowSize(window.innerWidth, window.innerHeight));
    dispatch(updateClientHeight(bodyRef.clientHeight));
    dispatch(updateScrollInfo(bodyRef.scrollTop, bodyRef.scrollHeight));
  }, [ dispatch, bodyRef ]);

  const appClass = 'shrinkHeader limitBandContentWidth ';

  if (!isReady) {
    return (
      <div />
    );
  }

  return (
    <div id="app" className={appClass}>
      <div id="appBody" onScroll={handleScroll} ref={bodyRef}>
        {props.children}
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  isReady: PropTypes.bool
};

const mapStateToProps = function (state) {
  return {
    isReady: state.uiState.uiReady,
    screenHeight: uiSelectors.getScreenHeight(state),
    scrollTop: uiSelectors.getScrollTop(state)
  };
};

export default connect(mapStateToProps)(Layout);
// export unconnected component for testing
export { Layout };
