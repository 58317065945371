import { call, delay, fork, put, putResolve, race, select, take } from 'redux-saga/effects';

import * as accountActions from '~actions/account-actions';
import * as actionTypes from '~actions/action-types';
import * as applicationActions from '~actions/application-actions';
import * as contentActions from '~actions/content-actions';
import * as spaceActions from '~actions/space-actions';
import * as uiActions from '~actions/ui-actions';

import * as accountsAPI from '~api/accounts';
import * as applicationsAPI from '~api/applications';
import * as contentAPI from '~api/content';
import * as spacesAPI from '~api/spaces';

import * as statusTypes from '~components/status/status-types';

import * as applicationStatus from '~constants/application-status';
import * as applicationType from '~constants/application-type';
import * as entitlements from '~constants/license-entitlements';

import { translate } from '~i18n/localize';

import * as taskOperations from '~operations/task-operations';

import * as accountSelectors from '~selectors/account-selectors';
import * as contentSelectors from '~selectors/content-selectors';

export function* createAccount(action) {
  try {
    const { data } = yield call(accountsAPI.createAccount, action.account);
    yield put(accountActions.createAccountSuccess(data));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

export function* fetchAccount(action) {
  try {
    const { data } = yield call(accountsAPI.getAccount, action.accountId);
    yield put(accountActions.fetchAccountSuccess(data));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

export function* searchAccounts(action) {
  const { query, offset } = action;
  try {
    const { data } = yield call(accountsAPI.searchAccountsPage, query, offset);
    yield put(accountActions.fetchAccountSearchPageSuccess(data.accounts, data.total, offset));
  } catch (err) {
    if (err) {
      yield put(uiActions.setErrorMessage(err));
    }
    yield put(accountActions.fetchAccountSearchPageFailure());
  }
}

export function* fetchAccountLicenseEntitlements(action) {
  const { accountId, licenseType } = action;
  const allEntitlements = Object.values(entitlements.cloudEntitlements).flat();

  for (let i = 0; i < allEntitlements.length; i++) {
    yield put(accountActions.fetchAccountLicenseEntitlement(accountId, licenseType, allEntitlements[i]));
  }
}

export function* fetchAccountLicenseEntitlement(action) {
  try {
    const { data } = yield  call(accountsAPI.getAccountLicenseEntitlement, action.accountId, action.licenseType, action.entitlementType);
    yield put(accountActions.fetchAccountLicenseEntitlementSuccess(action.accountId, action.licenseType, data));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

export function* deleteAccountLicenseEntitlement(action) {
  try {
    yield call(accountsAPI.deleteAccountLicenseEntitlement, action.accountId, action.licenseType, action.entitlementType);
    yield put(accountActions.deleteAccountLicenseEntitlementSuccess(action.accountId, action.licenseType, action.entitlementType));
    yield put(accountActions.fetchAccountLicenseEntitlement(action.accountId, action.licenseType, action.entitlementType));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

export function* updateAccountLicenseEntitlement(action) {
  try {
    const { data } = yield call(accountsAPI.updateAccountLicenseEntitlement, action.accountId, action.licenseType, action.entitlementType, action.entitlementData);
    yield put(accountActions.updateAccountLicenseEntitlementSuccess(action.accountId, action.licenseType, data));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

export function* createAccountLicenseEntitlement(action) {
  try {
    const { data } = yield call(accountsAPI.createAccountLicenseEntitlement, action.accountId, action.licenseType, action.entitlementType, action.entitlementData);
    yield put(accountActions.createAccountLicenseEntitlementSuccess(action.accountId, action.licenseType, data));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

export function* transferAccount(action) {
  try {
    const { data } = yield call(accountsAPI.transferAccount, action.accountId, action.email);
    yield put(accountActions.updateAccountSuccess(data));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

export function* updateAccount(action) {
  try {
    const { data } = yield call(accountsAPI.updateAccount, action.accountId, action.accountData);
    yield put(accountActions.updateAccountSuccess(data));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

export function* updateAccountMemberCloudRole(action) {
  try {
    const { data } = yield call(accountsAPI.updateAccountMemberCloudRole, action.accountId, action.memberId, action.role);
    yield put(accountActions.updateAccountCloudRoleSuccess(data));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

export function* updateAccountLicense(action) {
  try {
    const { data } = yield call(accountsAPI.updateAccountLicense, action.accountId, action.licenseType, action.licenseData);
    yield put(accountActions.updateAccountLicenseSuccess(action.accountId, data));
    yield put(accountActions.fetchAccountLicenseEntitlements(action.accountId, action.licenseType));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

export function* fetchAccountSubscription(action) {
  try {
    const { data } = yield call(accountsAPI.getAccountSubscription, action.accountId, action.licenseType);
    yield put(accountActions.fetchAccountSubscriptionSuccess(action.accountId, action.licenseType, data ));
  } catch (err) {
    if (err.response.status !== 404) {
      // 404 means this account has no subscription for this license type,
      // but we don't want to show a red error for that
      yield put(uiActions.setErrorMessage(err));
    }
    yield put(accountActions.fetchAccountSubscriptionFailure(action.accountId, action.licenseType));
  }
}

export function* fetchAccountSubscriptionEntitlementStatus(action) {
  try {
    const { data } = yield call(accountsAPI.getAccountSubscriptionEntitlementStatus, action.accountId, action.entitlement);
    yield put(accountActions.fetchAccountSubscriptionEntitlementStatusSuccess(action.accountId, action.entitlement, data.current));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

export function* deleteAccountSubscription(action) {
  try {
    yield call(accountsAPI.deleteAccountSubscription, action.accountId, action.licenseType);
    yield put(accountActions.deleteAccountSubscriptionSuccess(action.accountId, action.licenseType));

    // refetch the account now to get any updated license information
    yield delay(1000); // wait sec first though, because this update is async
    yield call(fetchAccount, action);
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

export function* fetchAccountApplications(action) {
  const { accountId, filters = [], offset } = action;

  try {
    const { data } = yield call(applicationsAPI.getApplications, { filter: [ ...filters, `account_id:${accountId}` ], offset });
    yield put(accountActions.fetchAccountApplicationsSuccess(accountId, data.applications, data.total));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
    yield put(accountActions.fetchAccountApplicationsFailure(accountId));
  }
}

export function* fetchAccountMembersPage(action) {
  const { accountId, order, filter, offset, brand } = action;
  try {
    const { data } = yield call(accountsAPI.listAccountMembers, accountId, brand, filter, order, offset );
    yield put(accountActions.fetchAccountMembersPageSuccess(accountId, data.users, data.total));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
    yield put(accountActions.fetchAccountMembersPageFailure(accountId));
  }
}

export function* fetchAccountSpaces(action) {
  const { accountId, offset } = action;

  try {
    const { data } = yield call(spacesAPI.listSpaces, { account_id: `${accountId}` }, offset);
    yield put(accountActions.fetchAccountSpacesSuccess(accountId, data.spaces, data.total));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
    yield put(accountActions.fetchAccountSpacesFailure(accountId));
  }
}

export function* fetchAccountContent(action) {
  const { accountId, order } = action;
  try {
    const { data } = yield call(contentAPI.getContentByAccountId, accountId, order);
    yield put(accountActions.fetchAccountContentSuccess(accountId, data.content, data.total));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
    yield put(accountActions.fetchAccountContentFailure(accountId));
  }
}

export function* createAccountUsageCredits(action) {
  const { accountId, licenseType, usageCredits } = action;

  try {
    const { data } = yield call(accountsAPI.createAccountUsageCredits, accountId, licenseType, usageCredits);
    yield put(accountActions.createAccountUsageCreditsSuccess(accountId, licenseType, data));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

export function* deleteAccountUsageCredits(action) {
  const { accountId, licenseType } = action;

  try {
    yield call(accountsAPI.deleteAccountUsageCredits, accountId, licenseType);
    yield put(accountActions.deleteAccountUsageCreditsSuccess(accountId, licenseType));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

export function* fetchAccountUsageCredits(action) {
  const { accountId, licenseType } = action;

  try {
    const { data } = yield call(accountsAPI.getAccountUsageCredits, accountId, licenseType);
    yield put(accountActions.fetchAccountUsageCreditsSuccess(accountId, licenseType, data));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

export function* updateAccountUsageCredits(action) {
  const { accountId, licenseType, usageCredits } = action;

  try {
    const { data } = yield call(accountsAPI.updateAccountUsageCredits, accountId, licenseType, usageCredits);
    yield put(accountActions.updateAccountUsageCreditsSuccess(accountId, licenseType, data));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

export function* fetchAccountProperties(action) {
  const { accountId } = action;

  try {
    const { data } = yield call(accountsAPI.getAccountProperties, accountId);
    yield put(accountActions.fetchAccountPropertiesSuccess(accountId, data.properties));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

export function* createAccountProperty(action) {
  const { accountId, propertyData } = action;

  try {
    // This does not have a success call because the create account property
    //call does not return a response body
    yield call(accountsAPI.createAccountProperty, accountId, propertyData);
    yield call(fetchAccountProperties, action);
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

export function* deleteAccountProperty(action) {
  const { accountId, propertyData } = action;

  try {
    yield call(accountsAPI.deleteAccountProperty, accountId, propertyData);
    yield put(accountActions.deleteAccountPropertySuccess(accountId, propertyData));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

// fetches all the "top-level" resources for a specified account (spaces, content, legacy applications),
// and is used to aggregate a list of what will be deleted as part of deleting the account
export function* fetchAccountResources(action) {
  const { accountId } = action;

  // spaces
  yield put(accountActions.fetchAccountSpaces(accountId));

  // content
  yield put(accountActions.fetchAccountContent(accountId));

  // shinyapps.io applications
  yield put(accountActions.fetchAccountApplications(accountId, [ `type:${applicationType.SHINY}` ]));
}

// Deletes an account as well as all of its "top-level" resources.
// This saga expects that the resources have all been fetched already.
//
// Note that this saga will fail if the account or the owned resources are
// not in the expected state:
//
//   a. The account must not have any active subscriptions (cloud or shinyapps.io)
//   b. Content must be deletable (source applications terminated or purged)
//   c. Non-Cloud-content-related applications must be purgeable
//   d. After deleting content, spaces must be empty
//   e. After successfully removing all "top-level" resources, the account must not own any resources
//
// This set of requirements is intentional -- deleting resources and cleaning accounts in
// Mechanic is potentially dangerous.
//
// At some point we might consider supporting account deletion where the account needs extra
// cleanup (i.e. taking some of the guardrails off resource deletion) and/or supporting
// recovery cases where deleting "top-level" resources didn't fully clean up the account
// (e.g. if volumes or domains get left around, but this shouldn't happen anymore).
//
// Until we have more examples of where this might be helpful/what leads to
// these failures, this saga is intentionally brittle.
export function* deleteAccount(action) {
  const { accountId } = action;
  let result;

  try {
    // 1. Wait for all the content to get deleted from the account
    yield call(deleteAccountContent, accountId);

    // 2. delete spaces -- these should now be empty
    const spaces = yield select(accountSelectors.getAccountSpacesList);
    for (const space of spaces) {
      if (space.account_id === accountId) { // be extra sure this is ours
        yield put(spaceActions.deleteSpace(space.id));
        result = yield race({
          success: take(a => a.type === actionTypes.DELETE_SPACE_SUCCESS && a.spaceId === space.id),
          failure: take(a => a.type === actionTypes.DELETE_SPACE_FAILURE && a.spaceId === space.id)
        });

        if (result.failure) {
          throw new Error(translate('account.delete.status.failure.space', { id: space.id }));
        }
      }
    }

    // 3. delete applications -- this list should be applications not associated with any content
    const applications = yield select(accountSelectors.getAccountApplications);
    for (const application of applications) {
      if (application.account_id === accountId) { // be extra sure this is ours
        yield put(applicationActions.purgeApplication(application.id));
        result = yield race({
          success: take(a => a.type === actionTypes.PURGE_APPLICATION_SUCCESS && a.applicationId === application.id),
          failure: take(a => a.type === actionTypes.PURGE_APPLICATION_FAILURE && a.applicationId === application.id)
        });

        if (result.failure) {
          throw new Error(translate('account.delete.status.failure.application', { id: application.id }));
        }
      }
    }

    // 4. delete the account -- this will fail if we didn't clean everything up
    yield call(accountsAPI.deleteAccount, accountId);
    yield put(accountActions.deleteAccountSuccess(accountId));

    yield put(uiActions.closeDialog());
    yield put(uiActions.setStatusMessage({ type: statusTypes.INFO_MESSAGE, message: translate('account.delete.status.success', { id: accountId }) }));
  } catch (err) {
    yield put(uiActions.setErrorMessage(err));
  }
}

/**
 * Retrieves all of the account's content and then forks off a process to delete each one. If any fail, this
 * call will throw that failure and terminate any pending forks.
 *
 * @param   {number}    accountId ID of the account to delete content for
 * @returns {Generator}           A generator for this function that returns when all of the forked processes have completed
 */
function* deleteAccountContent(accountId) {
  const content = yield select(accountSelectors.getAccountContent);
  for (const c of content) {
    if (c.account_id === accountId) { // be extra sure this is ours
      yield fork(deleteContent, c.id);
    }
  }
}

/**
 * Deletes the given content. It is expected that the content's source application is already terminated or purged.
 *
 * @param   {number}    contentId ID of the content to delete
 * @returns {Generator}           A generator for this function that returns once the content has been deleted.
 */
function* deleteContent(contentId) {
  try {
    const content = yield select(contentSelectors.getContentById, contentId);

    // make sure the source application is in the right state
    // note that account deletion uses this also, so if this logic changes make sure it makes sense over there
    const { data: application } = yield call(applicationsAPI.getApplication, content?.source_id);

    const deleteable = applicationStatus.isTerminated(application) || applicationStatus.isPurged(application);

    if (!deleteable) {
      throw new Error(`this content's source application is ${application?.status}. It must be ${applicationStatus.TERMINATED}, ${applicationStatus.TERMINATED_ARCHIVED}, or ${applicationStatus.PURGED} for this content to be deleted.`);
    }
    /*
     * We are going to purge the application. This will do some purgey things until it ultimately deletes the content.
     * Even if the application has already been purged (it obviously hasn't been fully deleted from the system) it will
     * purge it again in an effect to mirror Zombieland Rule #2 "Double Tap".
     */
    let task_id;
    try {
      ({ data: { task_id } } = yield call(applicationsAPI.purgeApplication, application.id, { delete: false }));
    } catch (error) {
      if (error.response?.status === 409 && error.response?.data?.name === 'purge-application') {
        ({ task_id } = error.response.data);
      } else {
        throw error;
      }
    }

    // Now await the purge task to complete
    yield putResolve(taskOperations.checkUntilDone(task_id));

    yield put(contentActions.deleteContentSuccess(contentId));
  } catch (error) {
    throw new Error(translate('account.delete.status.failure.content', { id: contentId }));
  }
}
