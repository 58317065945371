export const ACCOUNT_COMPUTE_HOURS_SOFT_MAX = 'account_compute_hours_soft_max';
export const ACCOUNT_JOB_RUN_CPU_RANGE = 'account_job_run_cpu_range';
export const ACCOUNT_JOB_RUN_EXECUTION_TIME_RANGE = 'account_job_run_execution_time_range';
export const ACCOUNT_JOB_RUN_MEM_RANGE = 'account_job_run_mem_range';
export const ACCOUNT_MEMBER_MAX = 'account_member_max';
export const ACCOUNT_MEMBER_PROJECT_CONCURRENT_MAX = 'account_member_project_concurrent_max';
export const ACCOUNT_OUTPUT_CPU_RANGE = 'account_output_cpu_range';
export const ACCOUNT_OUTPUT_MAX = 'account_output_max';
export const ACCOUNT_OUTPUT_MEM_RANGE = 'account_output_mem_range';
export const ACCOUNT_PARTICIPANT_MAX = 'account_participant_max';
export const ACCOUNT_PRIVATE_SPACE_MAX = 'account_private_space_max';
export const ACCOUNT_PROJECT_CPU_RANGE = 'account_project_cpu_range';
export const ACCOUNT_PROJECT_MAX = 'account_project_max';
export const ACCOUNT_PROJECT_MEM_RANGE = 'account_project_mem_range';
export const ACCOUNT_PROJECT_SESSION_KILL_TIMEOUT_MINUTES_RANGE = 'account_project_timeout_kill_minutes_range';
export const ACCOUNT_PUBLIC_SPACE_MAX = 'account_public_space_max';
export const ACCOUNT_REQUIRE_PAID_PARTICIPANTS_FLAG = 'account_require_paid_participants_flag';

export const SPACE_CONNECTION_MAX = 'space_connection_max';
export const SPACE_CONTENT_MAX = 'space_content_max';
export const SPACE_CUSTOM_LIST_MAX = 'space_custom_list_max';
export const SPACE_CUSTOM_LIST_CONTENT_MAX = 'space_custom_list_content_max';
export const SPACE_MEMBER_MAX = 'space_member_max';
export const SPACE_TEMPLATE_MAX = 'space_template_max';

export const isUnlimited = (entitlementValue) => entitlementValue === null;
export const isDefault = (entitlement) => entitlement.id === null;

export const cloudEntitlements = {
  account_membership: [ ACCOUNT_MEMBER_MAX, ACCOUNT_PARTICIPANT_MAX, ACCOUNT_REQUIRE_PAID_PARTICIPANTS_FLAG, ACCOUNT_MEMBER_PROJECT_CONCURRENT_MAX ],
  space: [ ACCOUNT_PRIVATE_SPACE_MAX, ACCOUNT_PUBLIC_SPACE_MAX, SPACE_MEMBER_MAX, SPACE_CUSTOM_LIST_MAX, SPACE_CUSTOM_LIST_CONTENT_MAX, SPACE_CONTENT_MAX, SPACE_CONNECTION_MAX, SPACE_TEMPLATE_MAX ],
  content: [ ACCOUNT_COMPUTE_HOURS_SOFT_MAX ],
  project: [ ACCOUNT_PROJECT_MAX, ACCOUNT_PROJECT_CPU_RANGE, ACCOUNT_PROJECT_MEM_RANGE, ACCOUNT_PROJECT_SESSION_KILL_TIMEOUT_MINUTES_RANGE ],
  output: [ ACCOUNT_OUTPUT_MAX, ACCOUNT_OUTPUT_CPU_RANGE, ACCOUNT_OUTPUT_MEM_RANGE ],
  job_run: [ ACCOUNT_JOB_RUN_CPU_RANGE, ACCOUNT_JOB_RUN_MEM_RANGE, ACCOUNT_JOB_RUN_EXECUTION_TIME_RANGE ]
};
