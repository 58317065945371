import * as contentActions from '~actions/content-actions';
import * as projectActions from '~actions/project-actions';
import * as uiActions from '~actions/ui-actions';

import * as projectsAPI from '~api/projects';

/**
 * Thunk action creator to fetch the outputs for a given project
 *
 * @param   {number}   projectId ID of the project for which to fetch outputs
 * @param   {object}   options   Sort options for the list
 * @returns {function}           Thunk to fetch outputs for a specified project
 */
export const fetchProjectOutputs = (projectId, sort) => async (dispatch) => {
  try {
    const { data } = await projectsAPI.getProjectOutputs(projectId, sort);
    dispatch(projectActions.fetchProjectOutputsSuccess(projectId, data.outputs, data.total));
  } catch (error) {
    dispatch(uiActions.setErrorMessage(error));
  }
};

/**
 * Thunk action creator to update a project's state
 *
 * @param   {number}   id    ID of the project to update
 * @param   {string}   state The state to update the project to
 * @returns {function}       Thunk to update a project's state
 */
export const updateProjectState = (id, state) => async (dispatch) => {
  const { data: project } = await projectsAPI.updateProject(id, { state });
  dispatch(contentActions.updateContentSuccess(project));
};
