import * as types from '~actions/action-types';

// Account
export const createAccount = (account) => ({
  type: types.CREATE_ACCOUNT,
  account
});

export const createAccountSuccess = (account) => ({
  type: types.CREATE_ACCOUNT_SUCCESS,
  account
});

export const deleteAccount = (accountId) => ({
  type: types.DELETE_ACCOUNT,
  accountId
});

export const deleteAccountSuccess = (accountId) => ({
  type: types.DELETE_ACCOUNT_SUCCESS,
  accountId
});

export const fetchAccountSuccess = (account) => ({
  type: types.FETCH_ACCOUNT_SUCCESS,
  account
});

export const updateAccount = (accountId, accountData) => ({
  type: types.UPDATE_ACCOUNT,
  accountId,
  accountData
});

export const updateAccountSuccess = (account) => ({
  type: types.UPDATE_ACCOUNT_SUCCESS,
  account
});

export const updateAccountCloudRole = (accountId, memberId, role) => ({
  type: types.UPDATE_ACCOUNT_CLOUD_ROLE,
  accountId,
  memberId,
  role
});

export const updateAccountCloudRoleSuccess = (member) => ({
  type: types.UPDATE_ACCOUNT_CLOUD_ROLE_SUCCESS,
  member
});

export const transferAccount = (accountId, email) => ({
  type: types.TRANSFER_ACCOUNT,
  accountId,
  email
});

export const fetchAccountSearchPage = (query, offset) => ({
  type: types.FETCH_ACCOUNT_SEARCH_PAGE,
  query,
  offset
});

export const clearAccountSearch = () => ({
  type: types.CLEAR_ACCOUNT_SEARCH
});

export const fetchAccountSearchPageSuccess = (accounts, total, offset) => ({
  type: types.FETCH_ACCOUNT_SEARCH_PAGE_SUCCESS,
  accounts,
  total,
  offset
});

export const fetchAccountSearchPageFailure = () => ({
  type: types.FETCH_ACCOUNT_SEARCH_PAGE_FAILURE
});

export const setCurrentAccount = (accountId) => ({
  type: types.SET_CURRENT_ACCOUNT,
  accountId
});

export const clearCurrentAccount = () => ({
  type: types.CLEAR_CURRENT_ACCOUNT
});

export const fetchAccountResources = (accountId) => ({
  type: types.FETCH_ACCOUNT_RESOURCES,
  accountId
});

export const clearAccountResources = (accountId) => ({
  type: types.CLEAR_ACCOUNT_RESOURCES,
  accountId
});

// Account Applications
export const fetchAccountApplications = (accountId, filters, offset) => ({
  type: types.FETCH_ACCOUNT_APPLICATIONS,
  accountId,
  filters,
  offset
});

export const fetchAccountApplicationsSuccess = (accountId, applications, total) => ({
  type: types.FETCH_ACCOUNT_APPLICATIONS_SUCCESS,
  accountId,
  applications,
  total
});

export const fetchAccountApplicationsFailure = (accountId) => ({
  type: types.FETCH_ACCOUNT_APPLICATIONS_FAILURE,
  accountId
});

export const clearAccountApplications = (accountId) => ({ type: types.CLEAR_ACCOUNT_APPLICATIONS, accountId });

// Account License

export const updateAccountLicense = (accountId, licenseType, licenseData) => ({
  type: types.UPDATE_ACCOUNT_LICENSE,
  accountId,
  licenseType,
  licenseData
});

export const updateAccountLicenseSuccess = (accountId, license) => ({
  type: types.UPDATE_ACCOUNT_LICENSE_SUCCESS,
  accountId,
  license
});

// Account License Entitlement

export const createAccountLicenseEntitlement = (accountId, licenseType, entitlementType, entitlementData) => ({
  type: types.CREATE_ACCOUNT_LICENSE_ENTITLEMENT,
  accountId,
  licenseType,
  entitlementType,
  entitlementData
});

export const createAccountLicenseEntitlementSuccess = (accountId, licenseType, entitlement) => ({
  type: types.CREATE_ACCOUNT_LICENSE_ENTITLEMENT_SUCCESS,
  accountId,
  licenseType,
  entitlement
});

export const deleteAccountLicenseEntitlement = (accountId, licenseType, entitlementType) => ({
  type: types.DELETE_ACCOUNT_LICENSE_ENTITLEMENT,
  accountId,
  licenseType,
  entitlementType
});

export const deleteAccountLicenseEntitlementSuccess = (accountId, licenseType, entitlementType) => ({
  type: types.DELETE_ACCOUNT_LICENSE_ENTITLEMENT_SUCCESS,
  accountId,
  licenseType,
  entitlementType
});

export const fetchAccountLicenseEntitlements = (accountId, licenseType) => ({
  type: types.FETCH_ACCOUNT_LICENSE_ENTITLEMENTS,
  accountId,
  licenseType
});

export const fetchAccountLicenseEntitlement = (accountId, licenseType, entitlementType) => ({
  type: types.FETCH_ACCOUNT_LICENSE_ENTITLEMENT,
  accountId,
  licenseType,
  entitlementType
});

export const fetchAccountLicenseEntitlementSuccess = (accountId, licenseType, entitlement) => ({
  type: types.FETCH_ACCOUNT_LICENSE_ENTITLEMENT_SUCCESS,
  accountId,
  licenseType,
  entitlement
});

export const updateAccountLicenseEntitlement = (accountId, licenseType, entitlementType, entitlementData) => ({
  type: types.UPDATE_ACCOUNT_LICENSE_ENTITLEMENT,
  accountId,
  licenseType,
  entitlementType,
  entitlementData
});

export const updateAccountLicenseEntitlementSuccess = (accountId, licenseType, entitlement) => ({
  type: types.UPDATE_ACCOUNT_LICENSE_ENTITLEMENT_SUCCESS,
  accountId,
  licenseType,
  entitlement
});

// Account Members

export const fetchAccountMembersPage = (accountId, filter, order, offset, brand) => ({
  type: types.FETCH_ACCOUNT_MEMBERS_PAGE,
  accountId,
  filter,
  order,
  offset,
  brand
});

export const fetchAccountMembersPageSuccess = (accountId, members, total) => ({
  type: types.FETCH_ACCOUNT_MEMBERS_PAGE_SUCCESS,
  accountId,
  members,
  total
});

export const fetchAccountMembersPageFailure = (accountId) => ({
  type: types.FETCH_ACCOUNT_MEMBERS_PAGE_FAILURE,
  accountId
});

export const clearAccountMembers = () => ({ type: types.CLEAR_ACCOUNT_MEMBERS });

// Account Spaces

export const fetchAccountSpaces = (accountId, offset) => ({
  type: types.FETCH_ACCOUNT_SPACES,
  accountId,
  offset
});

export const fetchAccountSpacesSuccess = (accountId, spaces, total) => ({
  type: types.FETCH_ACCOUNT_SPACES_SUCCESS,
  accountId,
  spaces,
  total
});

export const fetchAccountSpacesFailure = (accountId) => ({
  type: types.FETCH_ACCOUNT_SPACES_FAILURE,
  accountId
});

export const clearCurrentAccountSpaces = () => ({
  type: types.CLEAR_CURRENT_ACCOUNT_SPACES
});

// Account Content

export const fetchAccountContent = (accountId, order) => ({
  type: types.FETCH_ACCOUNT_CONTENT,
  accountId,
  order
});

export const fetchAccountContentSuccess = (accountId, content, total) => ({
  type: types.FETCH_ACCOUNT_CONTENT_SUCCESS,
  accountId,
  content,
  total
});

export const fetchAccountContentFailure = (accountId) => ({
  type: types.FETCH_ACCOUNT_CONTENT_FAILURE,
  accountId
});

// Account Properties

export const createAccountProperty = (accountId, propertyData) => ({
  type: types.CREATE_ACCOUNT_PROPERTY,
  accountId,
  propertyData
});

export const deleteAccountProperty = (accountId, propertyData) => ({
  type: types.DELETE_ACCOUNT_PROPERTY,
  accountId,
  propertyData
});

export const deleteAccountPropertySuccess = (accountId, property) => ({
  type: types.DELETE_ACCOUNT_PROPERTY_SUCCESS,
  accountId,
  property
});

export const fetchAccountProperties = (accountId) => ({
  type: types.FETCH_ACCOUNT_PROPERTIES,
  accountId
});

export const fetchAccountPropertiesSuccess = (accountId, properties) => ({
  type: types.FETCH_ACCOUNT_PROPERTIES_SUCCESS,
  accountId,
  properties
});

// Account Subscription

export const deleteAccountSubscription = (accountId, licenseType) => ({
  type: types.DELETE_ACCOUNT_SUBSCRIPTION,
  accountId,
  licenseType
});

export const deleteAccountSubscriptionSuccess = (accountId, licenseType) => ({
  type: types.DELETE_ACCOUNT_SUBSCRIPTION_SUCCESS,
  accountId,
  licenseType
});

export const fetchAccountSubscription = (accountId, licenseType) => ({
  type: types.FETCH_ACCOUNT_SUBSCRIPTION,
  accountId,
  licenseType
});

export const fetchAccountSubscriptionSuccess = (accountId, licenseType, subscription) => ({
  type: types.FETCH_ACCOUNT_SUBSCRIPTION_SUCCESS,
  accountId,
  licenseType,
  subscription
});

export const fetchAccountSubscriptionFailure = (accountId) => ({
  type: types.FETCH_ACCOUNT_SUBSCRIPTION_FAILURE,
  accountId
});

// Account Subcription Entitlements (legacy)

export const fetchAccountSubscriptionEntitlementStatus = (accountId, entitlement) => ({
  type: types.FETCH_ACCOUNT_SUBSCRIPTION_ENTITLEMENT_STATUS,
  accountId,
  entitlement
});

export const fetchAccountSubscriptionEntitlementStatusSuccess = (accountId, entitlement, entitlementStatus) => ({
  type: types.FETCH_ACCOUNT_SUBSCRIPTION_ENTITLEMENT_STATUS_SUCCESS,
  accountId,
  entitlement,
  entitlementStatus
});

// Account Usage Credits

export const createAccountUsageCredits = (accountId, licenseType, usageCredits) => ({
  type: types.CREATE_ACCOUNT_USAGE_CREDITS,
  accountId,
  licenseType,
  usageCredits
});

export const createAccountUsageCreditsSuccess = (accountId, licenseType, usageCredits) => ({
  type: types.CREATE_ACCOUNT_USAGE_CREDITS_SUCCESS,
  accountId,
  licenseType,
  usageCredits
});

export const deleteAccountUsageCredits = (accountId, licenseType) => ({
  type: types.DELETE_ACCOUNT_USAGE_CREDITS,
  accountId,
  licenseType
});

export const deleteAccountUsageCreditsSuccess = (accountId, licenseType) => ({
  type: types.DELETE_ACCOUNT_USAGE_CREDITS_SUCCESS,
  accountId,
  licenseType
});

export const fetchAccountUsageCredits = (accountId, licenseType) => ({
  type: types.FETCH_ACCOUNT_USAGE_CREDITS,
  accountId,
  licenseType
});

export const fetchAccountUsageCreditsSuccess = (accountId, licenseType, usageCredits) => ({
  type: types.FETCH_ACCOUNT_USAGE_CREDITS_SUCCESS,
  accountId,
  licenseType,
  usageCredits
});

export const fetchAccountUsageCreditsFailure = (accountId, licenseType) => ({
  type: types.FETCH_ACCOUNT_USAGE_CREDITS_FAILURE,
  accountId,
  licenseType
});

export const updateAccountUsageCredits = (accountId, licenseType, usageCredits) => ({
  type: types.UPDATE_ACCOUNT_USAGE_CREDITS,
  accountId,
  licenseType,
  usageCredits
});

export const updateAccountUsageCreditsSuccess = (accountId, licenseType, usageCredits) => ({
  type: types.UPDATE_ACCOUNT_USAGE_CREDITS_SUCCESS,
  accountId,
  licenseType,
  usageCredits
});
