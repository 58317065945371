import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import * as sortOrder from '~constants/sort-order';

export const SortContext = React.createContext();

/**
 * Context provider for storing a `sortBy` and `order` value.
 * The related hook provides setters to set the `sortBy` and `order` value to be used at your discretion.
 *
 * The `order` field only accepts one of two values: 'asc'|'desc'.
 *
 * @param  {object}      children      Content to scope to a sort context
 * @param  {string}      initialOrder  Initial order value, otherwise 'asc' by default
 * @param  {string}      initialSortBy Initial sortBy value
 * @return {JSX.Element}               Content wrapped in a sort context
 */
export function SortContextProvider({ children, initialOrder = sortOrder.ASC, initialSortBy }) {
  /*
   * This sort state will store the sort and order values.
   * {
   *   order: 'asc'|'desc',
   *   sortBy: 'id'
   * }
   */
  const [ sortState, setSortState ] = useState({ sortBy: initialSortBy, order: initialOrder });

  // Memoized object to store the sorting information and the function to update it
  const contextValue = useMemo(() => ({ sortState, setSortState }), [ sortState ]);

  return (
    <SortContext.Provider value={contextValue}>
      {children}
    </SortContext.Provider>
  );
}

SortContextProvider.propTypes = {
  children: PropTypes.node,
  initialOrder: PropTypes.oneOf(sortOrder.order),
  initialSortBy: PropTypes.string
};

/**
 * HOC for wrapping a component with a sort context provider so that it has access to the context value
 *
 * @param   {object}   Component            Definition of the component to wrap in sort context
 * @param   {object}   initialContextValues Static initial sort values for order and sortBy
 * @returns {function}                      A function that wrap the Component in a <SortContextProvider>
 */
export const withSortContext = (Component, { order, sortBy } = {}) => {
  /**
   * A function which will wrap the above Component with a <SortContextProvider>
   *
   * @param   {string}      initialOrder  Dynamic initial order value. This value value will override any static initial order.
   * @param   {string}      initialSortBy Dynamic initial sort value. This value value will override any static initial sortBy value.
   * @param   {object}      otherProps    Remaining props to spread on the <Component>
   * @returns {JSX.Element}               The above Component wrapped in a <SortContextProvider>
   */
  const withSortContext = ({ initialOrder, initialSortBy, ...otherProps }) => {
    return <SortContextProvider initialOrder={initialOrder || order} initialSortBy={initialSortBy || sortBy}><Component {...otherProps} /></SortContextProvider>;
  };

  withSortContext.propTypes = {
    initialOrder: PropTypes.oneOf(sortOrder.order),
    initialSortBy: PropTypes.string
  };

  withSortContext.displayName = `WithSortContext(${Component.displayName || Component.name || 'Component'})`;

  return withSortContext;
};

/**
 * Hook to retrieve the sorting context information
 *
 * @return {object} The sorting context values, and setter function to update the context values
 */
export function useSortContext() {
  const contextValue = useContext(SortContext);

  // contextValue will be undefined ONLY if the component using this hook hasn't been wrapped in a <SortContextProvider>
  if (!contextValue) {
    throw new Error('Could not find sort context value; please ensure the component is wrapped in a <SortContextProvider>');
  }

  const { sortState, setSortState } = contextValue;

  // Create memoized functions to update the `sortBy` value and the `order` value (if needed)
  const setSortBy = useCallback(
    (sortBy) => setSortState(prevState => {
      // if the field being sorted has not changed, reverse the sort order
      if (prevState.sortBy === sortBy) {
        return { ...prevState, order: sortOrder.flip(prevState.order) };
      } else {
        // set a default sort order of 'desc' for the new sortBy
        return { ...prevState, order: sortOrder.ASC, sortBy };
      }
    }),
    [ setSortState ]
  );

  return {
    order: sortState.order,
    sortBy: sortState.sortBy,
    setSortBy
  };
}
