import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as projectActions from '~actions/project-actions';

import AccountLink from '~components/general/account-link';
import ContentLink from '~components/general/content-link';
import IString from '~components/general/i-string';
import SpaceLink from '~components/general/space-link';
import Time from '~components/general/time';
import UserLink from '~components/general/user-link';

import * as projectSelectors from '~selectors/project-selectors';

const ProjectChildren = ({ children, dispatch, project: { id } }) => {
  useEffect(() => {
    dispatch(projectActions.fetchProjectChildren(id));

    return () => {
      dispatch(projectActions.clearProjectChildren());
    };
  }, [ dispatch, id ]);

  return (
    <>
      <div className="sectionTitle flex">
        <IString stringKey="project.children.section" />
      </div>

      <div className="tableContainer">
        { children && children.length > 0 ?
          <table className="contentListing">
            <thead>
              <tr>
                <th><IString stringKey="general.id" /></th>
                <th><IString stringKey="general.name" /></th>
                <th><IString stringKey="general.state" /></th>
                <th><IString stringKey="general.mode" /></th>
                <th><IString stringKey="general.account" /></th>
                <th><IString stringKey="general.space" /></th>
                <th><IString stringKey="general.author" /></th>
                <th><IString stringKey="general.created" /></th>
                <th><IString stringKey="general.updated" /></th>
              </tr>
            </thead>
            <tbody>
              { children.map(child =>
                <tr key={child.id}>
                  <td><ContentLink contentId={child.id} /></td>
                  <td>{child.name || <em>Untitled Project</em>}</td>
                  <td>{child.state}</td>
                  <td>{child.project_mode}</td>
                  <td><AccountLink accountId={child.account_id} /></td>
                  <td>{child.space_id ? <SpaceLink spaceId={child.space_id} /> : <em><IString stringKey="general.notSet" /></em>}</td>
                  <td><UserLink user={child.author} /></td>
                  <td><Time value={child.created_time} /></td>
                  <td><Time value={child.updated_time} /></td>
                </tr>
              ) }
            </tbody>
          </table> :
          <div className="emptyListMessage">
            <IString stringKey="project.children.none" />
          </div>
        }
      </div>

    </>
  );
};

ProjectChildren.propTypes = {
  children: PropTypes.array,
  dispatch: PropTypes.func,
  project: PropTypes.shape({
    id: PropTypes.number
  })
};

const mapStateToProps = (state) => ({
  children: projectSelectors.getCurrentProjectChildren(state)
});

export default connect(mapStateToProps)(ProjectChildren);
