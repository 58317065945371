import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import StandardPage from '~components/pages/standard-page';
import UserPage from '~components/users/user-page';
import UserSearch from '~components/users/user-search';

function UsersPage() {

  const { path } = useRouteMatch();

  return (
    <StandardPage>
      <Switch>
        <Route path={`${path}/:userId`} render={(props) => <UserPage {...props} />} />
        <Route path={`${path}`} component={UserSearch} />
      </Switch>
    </StandardPage>
  );
}

export default UsersPage;
