import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { dialogs } from '~components/dialogs/dialog-types';

import * as uiSelectors from '~selectors/ui-selectors';

const ModalContainer = (props) => {

  const { visibleDialogs } = props;

  if (visibleDialogs.length) {
    return (
      <div>
        {visibleDialogs.map(dialog => {
          const component = dialogs[dialog.type];
          if (component) {
            return React.createElement(component, {
              key: dialog.type,
              data: dialog.data,
              onSubmit: dialog.onSubmit,
              onCancel: dialog.onCancel
            });
          } else {
            // dialog type not found
            return null;
          }
        })}
      </div>
    );
  } else {
    return null;
  }
};

ModalContainer.propTypes = {
  visibleDialogs: PropTypes.array
};

const mapStateToProps = (state) => {
  return {
    visibleDialogs: uiSelectors.getVisibleDialogs(state)
  };
};

export default connect(mapStateToProps)(ModalContainer);
