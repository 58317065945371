import React from 'react';

export default function Spinner() {
  return <div className="spinner">
    <div className="dot one" />
    <div className="dot two" />
    <div className="dot three" />
    <div className="dot four" />
  </div>;
}
