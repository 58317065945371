import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as spaceActions from '~actions/space-actions';
import * as uiActions from '~actions/ui-actions';

import * as dialogTypes from '~components/dialogs/dialog-types';
import AccountLink from '~components/general/account-link';
import ActionButton from '~components/general/action-button';
import ContentLink from '~components/general/content-link';
import IString from '~components/general/i-string';
import Time from '~components/general/time';

import { translate } from '~i18n/localize';

import * as configSelectors from '~selectors/config-selectors';
import * as spaceSelectors from '~selectors/space-selectors';

import * as connectApps from '~util/connect-apps';

export function SpaceDetails({ spaceId }) {

  const dispatch = useDispatch();

  const datadogURL = useSelector(configSelectors.getDatadogURL);
  const environment = useSelector(configSelectors.getEnvironment);
  const space = useSelector(state => spaceSelectors.getSpace(state, spaceId));

  const handleDelete = () => {
    dispatch(uiActions.openDialog({
      type: dialogTypes.CONFIRM_DIALOG,
      data: {
        purpose: <IString stringKey="space.details.dialog.delete.purpose" placeholders={{ id: space.id, name: space.name }} />,
        details: <>
          <p><IString stringKey="space.details.dialog.delete.info" /></p>
          <p><IString stringKey="space.details.dialog.delete.prompt" placeholders={{ name: space.name }} /></p>
          <p><strong><IString stringKey="space.details.dialog.delete.warning" /></strong></p>
        </>,
        confirm: () => {
          dispatch(spaceActions.deleteSpace(space.id));
          dispatch(uiActions.closeDialog());
        }
      }
    }));
  };

  const handleRUM = () => {
    const u = `${datadogURL}/rum/explorer?agg_q=%40type&cols=%40action.name%2C%40context.result%2C%40context.attributes.source.status%2C%40context.attributes.project_id%2C%40context.attributes.total_duration_ms&live=1d&query=%40action.name%3A%28CREATE_PROJECT+OR+OPEN_PROJECT%29+-%40context.result%3Aidle++%40context.attributes.space.id%3A${space.id}&tab=action`;
    window.open(u, '_blank');
  };

  const handleTasks = () => {
    window.open(connectApps.makeTaskinatorURL({ environment, space_id: space.id }), '_blank');
  };

  const handleTransfer = () => {
    dispatch(uiActions.openDialog({
      type: dialogTypes.SPACE_TRANSFER_DIALOG,
      data: { space }
    }));
  };

  const handleEdit = () => {
    dispatch(uiActions.openDialog({
      type: dialogTypes.SPACE_DETAILS_EDIT_DIALOG,
      data: { space }
    }));
  };

  return (
    <div className="splitColumns withMobileMargins">
      <div className="column100">
        <div className="sectionTitle flex">
          <IString stringKey="space.details.title" />

          <div className="actionBar inline showTitles">
            <ActionButton className="edit" title={translate('general.edit')} onClick={handleEdit} />
            <ActionButton className="delete" title={translate('general.delete')} onClick={handleDelete} />
            <ActionButton className="viewLog" title={translate('general.tasks')} onClick={handleTasks} />
            <ActionButton className="support" title={translate('general.rum')} onClick={handleRUM} />
          </div>

        </div>

        <table className="propertiesTable">
          <tbody>
            <tr>
              <th className="label"><IString stringKey="general.id" /></th>
              <td className="value">{space.id}</td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="general.name" /></th>
              <td className="value">{space.name}</td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="space.details.table.account_id" /></th>
              <td className="value"><AccountLink accountId={space.account_id} /></td>
              <td className="actionBar inline showTitles" style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                <ActionButton className="edit" title={translate('space.details.actions.transfer')} onClick={handleTransfer} />
              </td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="space.details.table.global_template_source" /></th>
              <td className="value">
                <em>{space.global_template_source ? <IString stringKey="general.yes" /> : <IString stringKey="general.no" />}</em>
              </td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="general.state" /></th>
              <td className="value">{space.state}</td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="general.visibility" /></th>
              <td className="value">{space.visibility}</td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="space.details.table.access" /></th>
              <td className="value">{space.access}</td>
            </tr>
            { space.access === 'protected' &&
              <tr>
                <th className="label"><IString stringKey="space.details.table.default_member_role" /></th>
                <td className="value">{space.default_member_role}</td>
              </tr>
            }
            <tr>
              <th className="label"><IString stringKey="space.details.table.default_rstudio_project_id" /></th>
              <td className="value">
                {space.default_rstudio_project_id ? <ContentLink contentId={space.default_rstudio_project_id} /> : <em><IString stringKey="general.notSet" /></em> }
              </td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="space.details.table.default_jupyter_project_id" /></th>
              <td className="value">
                {space.default_jupyter_project_id ? <ContentLink contentId={space.default_jupyter_project_id} /> : <em><IString stringKey="general.notSet" /></em> }
              </td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="space.details.table.user_count" /></th>
              <td className="value">{space.user_count}</td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="space.details.table.content_count" /></th>
              <td className="value">{space.content_count}</td>
            </tr>

            <tr>
              <th className="label"><IString stringKey="general.created_time" /></th>
              <td className="value"><Time value={space.created_time} /></td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="general.updated_time" /></th>
              <td className="value"><Time value={space.updated_time} /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

SpaceDetails.propTypes = {
  spaceId: PropTypes.number
};

export default SpaceDetails;
