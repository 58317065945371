/**
 * Helper method to simply count the items in a list, without actually
 * getting any other paginated response data.
 *
 * @param  {function}                    listMethod The list method to call to count responses
 * @param  {object}                      options    The options to pass to the list method.
 * @return {Promise<AxiosResponse<any>>}            A promise for the Axios response that
 *                                                  contains the count of items at the given endpoint, with the provided parameters.
 */
export const countItems = async (listMethod, options = {}) => {
  return listMethod({ ...options, count: 0 }).then((response) => response?.data?.total);
};

/**
 * Helper method to turn an array of two-element lists into a URLSearchParams, which can be appended to an API call.
 * These lists should be ordered as [key, value], which will become "?key=value" when converted.
 *
 * This method is useful when needing to pass multiple parameters with the same name, which
 * parameterize() cannot do:
 *
 * parameterize(['metric', 'total_cache'], ['metric', 'total_rss']) -> ?metric=total_cache&metric=total_rss
 *
 * Note: this method does not yet handle three-element lists, which are needed to include an operator.
 *
 * @param   {array}           paramLists An array of [key, value] pairs for transforming into URLSearchParameters.
 * @returns {URLSearchParams}            A parameterized version of the options provided.
 */
export const parameterizeArray = (paramLists) => {
  const params = new URLSearchParams();

  paramLists?.forEach(([ key, value ]) => {
    if (key !== undefined && value !== undefined) {
      params.append(key, value);
    }
  });

  return params;
};
